import {MetaField} from './meta-field';
import {ExtendedSearchParams} from "./extended-search-params";

export interface SearchParameters {
  combine_horizontal: boolean;
  facets: string[];
  facet_range_groups: any[];
  fl: string[];
  // In order to ensure correct format, this field should only be
  // set using SolrFilterService addFq method!
  fq: string[];
  getAll: boolean;
  labelProp: string;
  overview: boolean;
  overview_simple: boolean;
  overview_fields: MetaField[];
  parents_only: boolean;
  query: string;
  rows: number;
  search_engine: string;
  sort: string;
  start: number;
  stat_fields: string[];
  template_group_id: string;
  group_search: boolean;
  group_field: string;
  write_collections_only: boolean;
}

export class SearchParametersForOverview implements SearchParameters {
  $$searchId: number;
  advanced_search_params: ExtendedSearchParams;
  combine_horizontal: boolean;
  facet_range_groups: any[];
  facets: string[];
  fl: string[];
  fq: string[];
  getAll: boolean;
  labelProp: string;
  overview: boolean;
  overview_fields: MetaField[];
  overview_simple: boolean;
  parents_only: boolean;
  query: string;
  query_orig: string;
  rows: number;
  search_engine: string;
  sort: string;
  start: number;
  stat_fields: string[];
  template_group_id: string;
  // This is only read server side, this is why it seems unused. DO NOT REMOVE!!!!
  timezone_offset = new Date().getTimezoneOffset();
  group_search: boolean;
  group_field: string;
  write_collections_only: boolean;
}
