import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {AConst} from '../../core/a-const.enum';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {LoggerService} from '../../core/logger.service';
import DMSVideo from '@web-components/dms';

@Component({
  selector: 'app-active-media',
  templateUrl: './active-media.component.html',
  styleUrls: ['./active-media.component.scss']
})
export class ActiveMediaComponent implements OnChanges, AfterViewInit, OnDestroy {
  AConst = AConst;
  dragItem: any;
  imageSize = {width: 0, height: 0};
  is3DModel = false;
  naturalImageSize = {width: 0, height: 0};
  imageLoaded = 0;
  playMedia = false;
  status: string;
  showPlayback = false;
  validSrc = true;
  imageUrl: string;
  private videoWait: any = 0;

  private waitForProgressId: any;
  private killMe = false;

  @Input() activeMedia: SuperObjectModel;
  @Input() zoomValue: number;
  @Input() container: HTMLDivElement;
  @Input() fullscreen: boolean;

  constructor(private readonly logger: LoggerService,
              private readonly elementRef: ElementRef,
              private readonly mediaHelper: MediaHelperService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setMediaInfo().then();

    if (changes.hasOwnProperty('activeMedia') && changes.activeMedia.previousValue !== changes.activeMedia.currentValue &&
      changes.activeMedia.currentValue !== undefined) {
      this.setActiveImageSize();
      this.resizeActiveImage(this.zoomValue);
    } else {
      if (changes.hasOwnProperty('zoomValue') && changes.zoomValue.previousValue !== changes.zoomValue.currentValue &&
        !!changes.zoomValue.currentValue) {
        this.resizeActiveImage(changes.zoomValue.currentValue);
      }
    }
  }

  onPlayMedia() {
    this.playMedia = true;

    this.setVideoElement();

  }

  private setVideoElement() {
    const videoElement = document.getElementById('dms-video');
    if (videoElement) {
      // @ts-ignore
      videoElement.innerHTML = `<kit-dms-video dms_api="${this.mediaHelper.getDmsApiUrl()}" dms_ids="${this.activeMedia.dms_identifier}"></kit-dms-video>`;

      setTimeout(() => {
        if (DMSVideo) {
          DMSVideo();
        } else {
          this.logger.warn('No DMSVideo found');
        }
      }, 50);
    } else {
      this.logger.warn('No video element found');
      this.videoWait = setTimeout(() => {
        this.setVideoElement();
      }, 500)
    }
  }

  ngAfterViewInit() {
    this.dragItem = this.getDragItem();
    this.imageLoaded = 1;
  }

  setDefaultImg() {
    this.validSrc = false;
  }

  getDragItem() {
    return this.activeMedia === undefined ? this.elementRef.nativeElement.querySelector('#mediaItem-') :
      this.elementRef.nativeElement.querySelector('#mediaItem-' + this.activeMedia.artifact_id);
  }

  setActiveImageSize() {
    setTimeout(() => {
      this.dragItem = this.getDragItem();
    }, 100);
    setTimeout(() => {
      if (this.dragItem) {
        this.imageSize = this.mediaHelper.setNewImageSize(
          this.container, this.dragItem, this.dragItem.naturalWidth, this.dragItem.naturalHeight);
      } else {
        this.logger.warn('Drag item object not defined');
      }
    }, 200);
  }

  resizeActiveImage(value: number) {
    if (this.imageLoaded === 1 && this.dragItem) {
      this.zoomValue = value;

      if (this.imageSize.width === 0 && this.imageSize.height === 0) {
        this.imageSize.width = this.dragItem.width;
        this.imageSize.height = this.dragItem.height;
      }

      this.naturalImageSize = {
        width: this.dragItem.naturalWidth,
        height: this.dragItem.naturalHeight
      };

      this.mediaHelper.resizeImage(
        this.container,
        this.zoomValue,
        this.dragItem,
        this.naturalImageSize,
        this.imageSize, false);

      if (this.dragItem.width > this.container.offsetWidth ||
        this.dragItem.height > this.container.offsetHeight) {
        this.mediaHelper.imgDrag(
          this.container,
          this.imageLoaded,
          this.dragItem,
          this.imageSize,
          this.zoomValue);
      } else {
        this.stopCallBack();
      }
    }

  }

  stopCallBack(): void {
    this.mediaHelper.stopCallBack();
  }

  ngOnDestroy() {
    this.stopCallBack();
    this.imageLoaded = 0;
    this.killMe = true;
    if (this.waitForProgressId) {
      clearTimeout(this.waitForProgressId);
    }
    if (this.videoWait) {
      clearTimeout(this.videoWait);
    }
  }

  private async setMediaInfo() {
    this.playMedia = false;
    this.showPlayback = false;
    this.status = '';
    this.is3DModel = false;
    if (this.fullscreen) {
      this.imageUrl = this.activeMedia.$$maxImageUrl;
    } else {
      this.imageUrl = this.activeMedia.$$largeImageUrl;
    }

    const mediaObjType = this.activeMedia.object_type;
    if (mediaObjType === 'Video' || mediaObjType === 'Audio') {
      if (!this.activeMedia.$$mediaPlaybackUrls) {
        this.status = await this.mediaHelper.getMediaUploadStatus(this.activeMedia);
        if (this.status === 'done') {
          this.activeMedia.$$mediaPlaybackUrls = await this.mediaHelper.getMediaPlaybackUrls(this.activeMedia);
        } else if (this.status === 'convert' || this.status === 'converting') {
          this.waitForProgress();
        }
      } else {
        this.status = 'done';
      }
      this.showPlayback = true;
    }
    if (mediaObjType === 'Model3D') {
      this.playMedia = true;
      this.is3DModel = true;
    }

  }

  private waitForProgress() {
    if (this.waitForProgressId) {
      clearTimeout(this.waitForProgressId);
    }
    this.waitForProgressId = setTimeout(() => {
      this.mediaHelper.getMediaUploadProgress(this.activeMedia).then(progress => {
        this.activeMedia.$$uploadProgress = progress;
        if (progress !== 100 && !this.killMe) {
          this.waitForProgress();
        }
      });
    }, 1000);
  }

}
