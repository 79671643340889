<div class="admin-create-new">
  <app-top-header [parent]="parent" class="admin-header-container"></app-top-header>

  <app-header-and-description *ngIf="operationContainer"
                              heading="{{'TRANS__ADMIN_PAGE__CREATE_NEW__TITLE' | translate}}"
                              description="{{'TRANS__ADMIN_PAGE__CREATE_NEW__INGRESS' | translate}}">
    <div class="admin-row">
      <mat-card appearance="outlined" class="admin-column admin-card admin-create-new__main-menu-card"
                *ngFor="let mainOp of operationContainer.operations">
        <mat-card-header class="admin-card__header">
          <mat-card-title>{{mainOp.menu_title | translate}}</mat-card-title></mat-card-header>

        <mat-card-content class="admin-card__content">
          <mat-list>
            <mat-list-item *ngFor="let subOp of mainOp.sub_operations" class="admin-create-new__menu-item">
              <a class="admin-create-new__menu-item-button blue-hover-icon"
                 [routerLink]="primusStateMapper.routerLinkFromState(subOp.operation_steps[0].change_state[0].state_name)"
                 [queryParams]="primusStateMapper.queryParams(subOp.operation_steps[0].change_state[0].state_params)"
                 queryParamsHandling="merge">
                <i *ngIf="subOp.menu_icon" class="{{subOp.menu_icon}}"></i>
                <span>{{subOp.menu_title | translate}}</span></a>

              <i *ngIf="subOp.menu_tooltip"
                 class="icon-help admin-create-new__help-icon"
                 [matTooltip]="subOp.menu_tooltip | translate"
                 matTooltipClass=""></i>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="admin-row">
      <mat-card appearance="outlined" *ngIf="searchContainer && searchContainer.searchResult && searchContainer.searchResult.artifacts"
          class="admin-column admin-card">
        <mat-card-header class="admin-card__header">
          <mat-card-title>{{'TRANS__ADMIN__LAST_CREATED__TITLE' | translate}}</mat-card-title></mat-card-header>

        <mat-card-content class="admin-card__content">
          <mat-list>
            <mat-list-item *ngFor="let art of searchContainer.searchResult.artifacts"
                           class="admin-create-new__last-created_item">

              <span class="admin-create-new__last-created-date bold">{{art.$$createdDate}}</span>
              <app-object-icon [iconClass]="'admin-last-created'" [object]="art"></app-object-icon>
              <app-object-name class="admin-create-new__last-created-object-name" [object]="art"></app-object-name>
              <app-object-url [object]="art" [rootObjId]="art.artifact_id">
                <span class="object-url-content">{{'TRANS__HEADER__EDIT' | translate}}</span></app-object-url>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined" class="admin-column admin-card admin-help">
        <mat-card-header class="admin-card__header">
          <mat-card-title>{{'TRANS__ADMIN__HELP__TITLE' | translate}}</mat-card-title></mat-card-header>

        <mat-card-content class="admin-card__content">
          <mat-list>
            <mat-list-item><span class="bold">{{'TRANS__ADMIN__HELP__CONTACT' | translate}}</span></mat-list-item>

            <mat-list-item><span>{{'TRANS__ADMIN__HELP__NUMBER' | translate}}</span></mat-list-item>

            <mat-list-item>
              <span>{{'TRANS__ADMIN__HELP__MAIL' | translate}}</span>
              <a class="admin-email text-blue-hover" href="mailto:support@kulturit.no">support&#64;kulturit.no</a></mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>

    </div>
  </app-header-and-description>
</div>
