import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {RootSearchView} from './definitions/search-objects';
import {Section} from './definitions/sections-container';
import {TemplateGroupInfo} from './definitions/template-group-info';
import {TemplateGroup} from './definitions/template-models';
import {AConst} from './a-const.enum';
import {UserData} from './definitions/user-data';
import {CmsQueueService} from './cms-queue.service';
import {ContentMenu, ContentMenuData} from './definitions/object-content-tab/content-menus';
import {LoggerService} from "./logger.service";

export class UserCacheData {
  userId: string;
  cachedTime: number;
  cachePurgeTime: number;
}

export class ContentMenuCache extends UserCacheData {
  contentMenuData: ContentMenuData;
  cachePurgeTime = 10 * 60 * 1000;
}

export class SearchViewCache extends UserCacheData {
  searchViews: { [key: string]: RootSearchView } = {};
  cachePurgeTime = 10 * 60 * 1000;
}

export class ModelSectionsCache extends UserCacheData {
  modelSections: { [key: string]: Section[] } = {};
  cachePurgeTime = 10 * 60 * 1000;
}

export class UserTemplateGroupCache extends UserCacheData {
  templateGroupInfo: TemplateGroupInfo;
  cachePurgeTime = 10 * 60 * 1000;
}

export class TemplateGroupsCache extends UserCacheData {
  templateGroups: TemplateGroup[] = [];
  cachePurgeTime = 3 * 60 * 1000;
}

export class UserDataCache extends UserCacheData {
  userData: UserData = null;
  cachePurgeTime = 3 * 60 * 1000;
}

@Injectable({
  providedIn: 'root'
})
export class UserCacheService {
  private contentMenuCache: ContentMenuCache;
  private searchViewCache: SearchViewCache;
  private modelSectionsCache: ModelSectionsCache;
  private userTemplateGroupCache: UserTemplateGroupCache;
  private templateGroupsCache: TemplateGroupsCache;
  private userDataCache: UserDataCache;
  private userId: string;

  constructor(private logger: LoggerService,
              private cms: CmsApiService,
              private cmsQueue: CmsQueueService) {

  }

  async getContentMenus(): Promise<{ [key: string]: ContentMenu[] }> {
    let res = {};
    const invalidData = await this.isCachedDataInvalid(this.contentMenuCache);
    if (invalidData.invalid) {
      this.contentMenuCache = new ContentMenuCache();
      this.contentMenuCache.contentMenuData = await this.cms.getContentMenus();
      this.setCachedProps(this.contentMenuCache, invalidData.userId);
    }
    if (this.contentMenuCache.contentMenuData) {
      res = this.contentMenuCache.contentMenuData.content_menus;
    } else {
      this.logger.warn('Content menus missing');
    }
    return res;
  }

  async getSearchView(searchViewName: string): Promise<RootSearchView> {
    const invalidData = await this.isCachedDataInvalid(this.searchViewCache);
    if (invalidData.invalid) {
      this.searchViewCache = new SearchViewCache();
    }
    if (!this.searchViewCache.searchViews[searchViewName]) {
      const searchView = await this.cms.getSearchView(searchViewName);
      if (searchView) {
        this.searchViewCache.searchViews[searchViewName] = searchView;
          this.setCachedProps(this.searchViewCache, invalidData.userId);
      } else {
        return null;
      }
    }
    return this.searchViewCache.searchViews[searchViewName];
  }

  async getModelSections(objectType: string, templateGroupId?: string): Promise<Section[]> {
    const key = templateGroupId ? objectType + ':' + templateGroupId : objectType;
    const invalidData = await this.isCachedDataInvalid(this.modelSectionsCache);
    if (invalidData.invalid) {
      this.modelSectionsCache = new ModelSectionsCache();
    }
    if (!this.modelSectionsCache.modelSections[key]) {
      // For some reason, setting ...modelSections[key] = await this.cms.getModelSections... directly doesn't work...
      const sections = await this.cms.getModelSections({
        object_type: objectType,
        template_group_id: templateGroupId
      });
      if (sections) {
        this.modelSectionsCache.modelSections[key] = sections;
        this.setCachedProps(this.modelSectionsCache, invalidData.userId);
      }
    }
    const res = this.modelSectionsCache.modelSections[key];
    if (!res) {
      this.logger.warn(`Unable to get object sections for ${objectType}, template id ${templateGroupId}`);
    }
    return res;
  }

  purgeModelSections() {
    this.modelSectionsCache = null;
  }

  async getUserTemplateGroupInfo(): Promise<TemplateGroupInfo> {
    const invalidData = await this.isCachedDataInvalid(this.userTemplateGroupCache);
    if (invalidData.invalid) {
      this.userTemplateGroupCache = new UserTemplateGroupCache();
      this.userTemplateGroupCache.templateGroupInfo = await this.cms.getUserTemplateGroupInfo();
      this.setCachedProps(this.userTemplateGroupCache, invalidData.userId);
    }
    return this.userTemplateGroupCache.templateGroupInfo;
  }

  async getTemplateGroups(): Promise<TemplateGroup[]> {
    const invalidData = await this.isCachedDataInvalid(this.templateGroupsCache);
    if (invalidData.invalid) {
      this.templateGroupsCache = new TemplateGroupsCache();
      const templateGroupsData = await this.cms.getMetaObject({objectType: 'TemplateGroups'});
      if (templateGroupsData) {
        this.templateGroupsCache.templateGroups = templateGroupsData[AConst.TEMPLATE_GROUPS];
        this.setCachedProps(this.templateGroupsCache, invalidData.userId);
      }
    }
    return this.templateGroupsCache.templateGroups;
  }

  async getUserData(forceReload?: boolean, suppressErrHandler?: boolean): Promise<UserData> {
    if (suppressErrHandler === undefined) {
      suppressErrHandler = true;
    }
    let invalid = true;
    if (!forceReload && this.userDataCache) {
      const invalidData = await this.isCachedDataInvalid(this.userDataCache);
      invalid = invalidData.invalid;
    }
    if (invalid) {
      this.userDataCache = new UserDataCache();
      const params = {suppressErrHandler: suppressErrHandler};
      const userData = await this.loadUserDataSetUserId(params);
      this.setCachedProps(this.userDataCache, userData.artifact_id);
      this.userDataCache.userData = userData;
      localStorage.setItem('isWhitelisted', userData.is_whitelisted ? 'yes': 'no');
    }
    return this.userDataCache.userData;
  }

  private async isCachedDataInvalid(cacheData: UserCacheData): Promise<any> {
    let res = true;
    const userId = await this.getUserId();
    if (cacheData) {
      res = cacheData.userId !== userId || cacheData.cachedTime < (new Date()).getTime() - cacheData.cachePurgeTime;
    }
    return {invalid: res, userId: userId};
  }

  private setCachedProps(cacheData: UserCacheData, userId: string) {
    cacheData.cachedTime = (new Date()).getTime();
    cacheData.userId = userId;
  }

  private async loadUserDataSetUserId(params?): Promise<UserData> {
    return new Promise<UserData>((resolve, reject) => {
      this.cmsQueue.runCmsFnWithQueue(this.cms.getUserData, params, false,
        (data) => {
          const userData: UserData = data[AConst.USER];
          this.userId = userData.artifact_id;
          resolve(userData);
        },
        (e) => {
          reject(e);
        });
    });
  }

  public async getUserId() {
    if (!this.userId) {
      await this.loadUserDataSetUserId();
    }
    return this.userId;
  }
}
