import {Injectable} from '@angular/core';
import {CmsApiService} from "./cms-api.service";
import {FeatureFlags} from "./definitions/feature-flags";

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;


@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  private featureFlags: FeatureFlags = {
    temporary: {
      placeCultureHubImport: false,
      multipleImageUrls: false,
      displayMediaSelectorsNewObjects: false,
      displaySendErrorViaEmailOption: false,
      newJoinOperation: false,
      newImageUrlApi: false,
      clientAccessConfigServer: false,
    },
    experimental: {
      useNewFilterMenu: false,
      useNewObjectPage: false,
      useNewMainMenu: false,
    },
    permanent: {
      featureFlags: false
    }
  };

  constructor(private cms: CmsApiService) {
  }

  async initFeatureFlags() {
    return this.cms.getFeatureFlags().then(featureFlags => {
      this.featureFlags = featureFlags;
      return this.applyOverrides(featureFlags);
    })
  }

  getFeatureFlags(): FeatureFlags {
    return this.applyOverrides(this.featureFlags);
  }

  getOverrides(): DeepPartial<FeatureFlags> | undefined {
    const overrides = localStorage.getItem('featureFlagsOverrides');
    try {
      return overrides ? JSON.parse(overrides) : undefined
    } catch (e) {
      return undefined;
    }
  }

  setOverrides(overrides: DeepPartial<FeatureFlags>) {
    localStorage.setItem('featureFlagsOverrides', JSON.stringify(overrides));
  }

  clearOverrides() {
    localStorage.removeItem('featureFlagsOverrides');
  }

  private applyOverrides(flags: FeatureFlags): FeatureFlags {
    const overrides = this.getOverrides();
    return {
      temporary: {
        ...flags.temporary,
        ...overrides?.temporary
      },
      experimental: {
        ...flags.experimental,
        ...overrides?.experimental
      },
      permanent: {
        ...flags.permanent,
        ...overrides?.permanent
      }
    }
  }

  enableFeatureOverride(feature: string) {
    const overrides: DeepPartial<FeatureFlags> = this.getOverrides() ?? {};
    setValueFromDotNotation(overrides, feature, true);
    this.setOverrides(overrides);
  }

  disableFeatureOverride(feature: string) {
    const overrides: DeepPartial<FeatureFlags> = this.getOverrides() ?? {};
    setValueFromDotNotation(overrides, feature, false);
    this.setOverrides(overrides);
  }

  getFeatureOverride(feature: string) {
    const overrides = this.getOverrides();
    return getValueFromDotNotation(overrides, feature);
  }
}

function setValueFromDotNotation(obj: any, path: string, value: any) {
  const [key, rest] = path.split(/\.(.+)/);
  if (!rest || rest.length === 0) {
    obj[key] = value;
  } else {
    if (!obj[key]) {
      obj[key] = {};
    }
    setValueFromDotNotation(obj[key], rest, value);
  }
}

function getValueFromDotNotation(obj: any, path: string) {
  const [key, rest] = path.split(/\.(.+)/);
  if (!obj || !obj[key]) {
    return undefined;
  }
  if (!rest || rest.length === 0) {
    return obj[key];
  } else {
    return getValueFromDotNotation(obj[key], rest);
  }
}

