<div class="search-view-folder-header__title" *ngIf="folder">

  <!-- <h1>
    <span>{{'TRANS__FOLDER__VIEW__TITLE_PREFIX' | translate}}</span>
    <span>: {{folder.artifact_name}}</span>
    <span *ngIf="searchContainer.searchResult && searchContainer.searchResult.search_count">
                    ({{searchContainer.searchResult.search_count}})</span>
  </h1> -->

  <span *ngIf="searchContainer.searchResult && searchContainer.objectCount &&
                (searchContainer.objectCount - searchContainer.searchResult.search_count) !== 0"
        class="alert-count">
    <span>{{'TRANS__FOLDER__VIEW__OBJECT_ACCESS' | translate:'{count: searchContainer.searchResult.search_count, objCount: searchContainer.objectCount}'}}</span>
  </span>
  <div class="folder-description">
    <div class="folder-description__tabs" #container>
      <div *ngIf="(196 * tabItems.length) > container.offsetWidth"
           class="folder-description__scroll-button left-scroll"
           (click)="scrollTo('prev')"
           [ngClass]="{'scroll-button-disabled': scrollMenu.scrollLeft === 0}">
        <mat-icon>chevron_left</mat-icon></div>
      <div class="folder-description__title" #scrollMenu
           [ngClass]="{'overflow': (196 * tabItems.length) > container.offsetWidth}">
        <ng-container *ngFor="let tabItem of tabItems">
          <button mat-button (click)="toggleOpen(tabItem.object)"
                  [style.max-width]="100 / tabItems.length + '%'"
                  [title]="tabItem.header"
                  [class.active-folder-header-tab]="tabItem.object.artifact_id === openedObject?.artifact_id">
            <span>{{tabItem.header}}</span>
            <mat-icon>{{tabItem.object.artifact_id === openedObject?.artifact_id ? 'expand_less' : 'expand_more'}}</mat-icon>
          </button></ng-container>
      </div>
      <div *ngIf="(196 * tabItems.length) > container.offsetWidth"
           class="folder-description__scroll-button right-scroll"
           (click)="scrollTo('next')"
           [ngClass]="{'scroll-button-disabled': scrollMenu.scrollWidth - (scrollMenu.scrollLeft + container.offsetWidth) <= 0}">
        <mat-icon>chevron_right</mat-icon></div>
    </div>

    <hr *ngIf="openedObject">
    <div *ngIf="openedObject"
         class="folder-description__container">
      <div class="folder-description__inner-container">
        <app-object-view-tabbed-sections
          [objectId]="openedObject.artifact_id"
          [folderId]="folder.artifact_id"
        ></app-object-view-tabbed-sections>
      </div>
    </div>
  </div>

</div>
