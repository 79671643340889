import {Injectable} from '@angular/core';
import {LoginService} from './login.service';
import {AConst} from './a-const.enum';
import {ModelFactoryService} from './model-factory.service';
import {CommonsService} from './commons.service';
import {ObjectEditService} from './object-edit.service';
import {UserData} from './definitions/user-data';
import {BaseModel} from './definitions/base-model';
import {CmsApiService} from './cms-api.service';
import {SuperObjectModel} from './definitions/super-object-model';
import {CmsQueueService} from './cms-queue.service';
import {LoggerService} from "./logger.service";

@Injectable({
  providedIn: 'root'
})
export class ObjectDeletionService {

  private currentUser: UserData;

  constructor(private logger: LoggerService,
              private cms: CmsApiService,
              private cmsQueue: CmsQueueService,
              private loginService: LoginService,
              private modelFactory: ModelFactoryService,
              private commons: CommonsService,
              private objectEditService: ObjectEditService) {

    this.loginService.currentUser.subscribe(user => this.currentUser = user);
  }

  deleteObject(object): Promise<void> {
    return new Promise(((resolve, reject) => {
      this.objectEditService.deleteObjectShowDialog(object).then(
        () => {
          resolve();
        },
        response => {
          reject(response.error.message);
        }
      );
    }));
  }

  deleteItem(mod, arrayName, index) {
    const arr = mod[arrayName];
    this.modelFactory.deleteArrayItem(arr, index, mod);
  }

  undoDeleteItem(mod, arrayName) {
    const arr = mod[arrayName];
    this.modelFactory.undoDeleteArrayItem(arr);
  }

  deleteItems(mod, targetArrayName, deleteArray, compProp1, compProp2?) {
    const targetArray = mod[targetArrayName];
    let deleted = false;

    this.commons.compareArrays(targetArray, deleteArray, compProp1, compProp2, (targetIndex) => {
      this.modelFactory.deleteArrayItem(targetArray, targetIndex, mod);
      deleted = true;
    });
    if (!deleted) {
      throw new Error('No items deleted! Target array probably did not ' + 'contain items');
    }
    return targetArray;
  }

  // Detach images from object in a process where the image is
  // removed from the image array, and then the artifact is stored
  detachImagesFromObject(art, images, fn, fnFailed) {
    this.getSetObjectImages(art, images, true,
      () => {
        this.objectEditService.storeObjectShowProgressModal(art).then(
          () => {
            fn();
          }, reason => {
            fnFailed(reason.error.message, 0);
          });
      },
      error => {
        fnFailed(error);
      }
    );
  }

  deleteFromTargetModel(sourceObject: any,
                        sourceArrayName: string,
                        deleteItem: any,
                        deleteTargetModel: string,
                        targetArrayName: string,
                        deleteContextMapParentToChild: Array<string>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const data = {};
      data[targetArrayName] = sourceObject[sourceArrayName];
      for (let t = 0; t < deleteContextMapParentToChild.length; t += 2) {
        data[deleteContextMapParentToChild[t + 1]] = sourceObject[deleteContextMapParentToChild[t]];
      }
      this.objectEditService.createModelItem(deleteTargetModel, data).then(deleteTargetObject => {
        let deleteIndex = -1;
        deleteTargetObject[targetArrayName].forEach((checkItem, index) => {
          if (checkItem[AConst.ORDER_NUMBER] === deleteItem[AConst.ORDER_NUMBER]) {
            deleteIndex = index;
          }
        });
        if (deleteIndex !== -1) {
          this.deleteItem(deleteTargetObject, targetArrayName, deleteIndex);
        } else {
          console.warn('Item to delete was not found');
        }
        this.objectEditService.storeObjectShowProgressModal(deleteTargetObject).then(
          () => {
            resolve();
          },
          reason => {
            reject(reason);
          });
      });
    });
  }

  async setObjectsDeletable(parentObject: SuperObjectModel, objects: BaseModel[]) {
    if (parentObject.artifact_id?.indexOf('template_group') === 0) {
      if (parentObject.$$deletable !== undefined) {
        return;
      }
      parentObject.$$deletable = false;
      for (const obj of objects) {
        obj.$$deletable = true;
      }
      return;
    }
    if (parentObject.artifact_id) {
      await this.cmsQueue.runCmsFnWithQueue(this.cms.canDelete, {artifact_id: parentObject.artifact_id}, false,
        deletableRes => {
          parentObject.$$deletable = deletableRes?.can_delete;
          this.setObjectsDeletablePart2(parentObject, objects).then();
        },
        e => {
          this.logger.error(`An error occurred checking deletable: ${e}`);
        })
    } else {
      parentObject.$$deletable = true;
      await this.setObjectsDeletablePart2(parentObject, objects);
    }
  }

  private async setObjectsDeletablePart2(parentObject: SuperObjectModel, objects: BaseModel[]) {
    const artifactIds = [];
    for (const art of objects) {
      if (art.$$deletable !== undefined) {
        continue;
      }
      if (!art.artifact_id) {
        art.$$deletable = true;
        continue;
      }
      if (art.meta_type === 'sub_model' || art.meta_type === 'model') {
        art.$$deletable = parentObject.$$deletable;
      } else {
        artifactIds.push(art.artifact_id);
      }
    }
    if (artifactIds.length) {
      const deletableMultiRes = await this.cms.canDeleteMultiple({artifact_ids: artifactIds});
      if (deletableMultiRes) {
        for (const art of objects) {
          art.$$deletable = deletableMultiRes[art.artifact_id];
        }
      }
    }
  }

  private getSetObjectImages(art, changedImages, remove, fn, failedFn) {
    let images;
    const arrayName = 'images';
    if (art[arrayName] === undefined) {
      console.error('ImageItems array not found');
    }
    if (changedImages) {
      if (remove) {
        try {
          images = this.deleteItems(art, arrayName, changedImages, 'image_id', 'artifact_id');
        } catch (e) {
          if (failedFn) {
            failedFn(e);
            return;
          } else {
            console.error('Error deleting images: ' + e);
          }
        }
      } else {
        // No longer used
        // images = addItems(art, arrayName, changedImages);
      }
    } else {
      images = art[arrayName];
    }
    if (fn) {
      fn(images);
    }
    return images;
  }


}
