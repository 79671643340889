import { Component, effect, input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { ExtendedFieldQuery, ExtendedFieldQueryGroup } from '../../../core/definitions/extended-search-params';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import {
  ExtendedSearchQueryBuilderValueDisplayComponent
} from '../extended-search-query-builder-value-display/extended-search-query-builder-value-display.component';
import { ExtendedSearchService } from '../../../core/extended-search.service';
import { SearchContainer } from '../../../core/definitions/search-container';
import { ExtendedSearchField, GetExtendedSearchFieldParams } from '../../../core/definitions/extended-search-field';
import { FormsModule } from '@angular/forms';
import { ExtendedSearchQueryBuilderFieldValueComponent } from "./extended-search-query-builder-field-value/extended-search-query-builder-field-value.component";
import { SearchExecutorService } from '../../search-executor.service';

@Component({
  selector: 'app-extended-search-query-builder-field',
  standalone: true,
  imports: [
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    TranslateModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    MatSelect,
    MatOption,
    ExtendedSearchQueryBuilderValueDisplayComponent,
    FormsModule,
    ExtendedSearchQueryBuilderFieldValueComponent
],
  templateUrl: './extended-search-query-builder-field.component.html',
  styleUrl: './extended-search-query-builder-field.component.scss'
})
export class ExtendedSearchQueryBuilderFieldComponent implements OnDestroy, OnInit {
  parentGroup = input.required<ExtendedFieldQueryGroup>();
  rootGroup = input.required<ExtendedFieldQueryGroup>();
  searchContainer = input.required<SearchContainer>();
  selfField = input.required<ExtendedFieldQuery>();

  fieldInputDebounce = null;
  fieldSelectorOpen: boolean = false;

  availableFields: WritableSignal<ExtendedSearchField[]> = signal([]);
  fieldQuery: WritableSignal<string> = signal<string>('');

  previousUpdateFlagState = null;

  constructor(
    public readonly extendedSearchService: ExtendedSearchService,
    private readonly searchExecutorService: SearchExecutorService
  ) {
    effect(() => {
      this.fieldQuery.set(this.selfField().field_title);
    }, {
      allowSignalWrites: true
    });

    effect(() => {
      if (this.extendedSearchService.updateFieldSelectorListFlag() !== this.previousUpdateFlagState) {
        this.previousUpdateFlagState = this.extendedSearchService.updateFieldSelectorListFlag();
        this.updateFieldSelectorOptions();
      }
    })
  }

  ngOnInit () {
    if (this.searchContainer()) {
      this.searchExecutorService.subscribeToSearchResult(this.searchContainer(), this.handleSearchContainerChange);

      this.updateFieldSelectorOptions();
    }
  }

  ngOnDestroy(): void {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer(), this.handleSearchContainerChange);
  }

  get hasNoFieldSelected(): boolean {
    return this.selfField().field_title === '';
  }

  get hasValueField(): boolean {
    return this.selfField().operator_selected !== 'empty' &&
      this.selfField().operator_selected !== 'not empty' &&
      this.selfField().operator_selected !== 'true' &&
      this.selfField().operator_selected !== 'false'
  }

  get ongoingSearch() {
    return localStorage.getItem('primus.searchOngoing') === 'true';
  }

  clearField(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    this.extendedSearchService.clearSelectedField(this.selfField());
    this.fieldQuery.set('');
    this.extendedSearchService.triggerFieldSelectorUpdate();
  }

  closeFieldDropdown() {
    this.fieldSelectorOpen = false;
    if (this.selfField().field_title !== '') {
      this.fieldQuery.set(this.selfField().field_title);
    }
    else {
      this.fieldQuery.set('');
    }
  }

  fieldInput(event: Event) {
    if (this.fieldInputDebounce !== null) {
      clearTimeout(this.fieldInputDebounce);
    }

    this.fieldInputDebounce = setTimeout(() => {
      // @ts-expect-error value DOES exist on target
      if (event.target.value === '') {
        this.clearField();
      }

      // @ts-expect-error value DOES exist on target
      this.fieldQuery.set(event.target.value);
    }, 500);
  }

  focusFirstNodeInDropdown(event) {
    event.preventDefault();

    if (this.fieldSelectorOpen) {
      // @ts-ignore
      document.querySelectorAll('.value-tree__node:not(.value-tree__node--hidden) > .value-tree__node-button')[0].focus();
      // Select the children with class value-tree__node-button of every element WITH class value-tree__node and WITHOUT .value-tree__node--hidden
      // then focus the first of the resulting array
    }
    else {
      this.fieldSelectorOpen = true;

      setTimeout(() => {
        // @ts-ignore
        document.querySelectorAll('.value-tree__node:not(.value-tree__node--hidden) > .value-tree__node-button')[0].focus();
        // Select the children with class value-tree__node-button of every element WITH class value-tree__node and WITHOUT .value-tree__node--hidden
        // then focus the first of the resulting array
      }, 100);
    }
  }

  removeSelf(): void {
    this.extendedSearchService.removeField(this.selfField());
  }

  selectedNode(title: string) {
    this.fieldQuery.set(title);
    this.fieldSelectorOpen = false;
  }

  updateFieldOperator(event: MatSelectChange) {
    this.extendedSearchService.setFieldOperator(this.selfField(), event.value);
  }

  onFocusOut(event: FocusEvent): void {
    const relatedTarget = event.relatedTarget as HTMLElement;

    // Check if the focus is moving to an element inside the overlay
    if (
      this.fieldSelectorOpen &&
      relatedTarget &&
      this.overlayContainsElement(relatedTarget)
    ) {
      return; // Don't close the overlay if focus moves to an element inside it
    }

    // Close the overlay if focus moves completely outside
    this.closeFieldDropdown();
  }

  private async addAllFields(fields: ExtendedSearchField[]): Promise<ExtendedSearchField[]> {
    const allFields = await this.extendedSearchService.getExtendedSearchFields({
      superobject_types: this.searchContainer().currentPathView.search_view.superobject_types.join(', '),
      is_relation_query: false,
      is_sub_group: this.parentGroup().level > 0,
      child_document_type: null,
      db_search: this.searchContainer().extendedSearchParams.db_search
    } as GetExtendedSearchFieldParams, this.searchContainer().extendedSearchParams.query_groups, this.parentGroup());

    fields.push({
      field_title: 'divider',
      input_type: 'divider'
    } as ExtendedSearchField);

    return fields.concat(allFields);
  }

  private readonly handleSearchContainerChange = () => {
    this.updateFieldSelectorOptions();
  }

  private overlayContainsElement(element: HTMLElement): boolean {
    const overlayElement = document.querySelector('.field-dropdown');
    return overlayElement?.contains(element) ?? false;
  }

  private updateFieldSelectorOptions() {
    const relationInfo = this.extendedSearchService.getRelationInfoFromGroup(this.parentGroup(), this.rootGroup());
    const relationId = relationInfo?.superobjectTypeId ? relationInfo.superobjectTypeId : null;

    this.extendedSearchService.getExtendedSearchFields({
      superobject_types: this.parentGroup().relation_superobject_type_id || this.searchContainer().currentPathView.search_view.superobject_types.join(', '),
      is_relation_query: !!relationId,
      is_sub_group: this.parentGroup().level > 0,
      child_document_type: (this.parentGroup().restriction_name === 'root' || relationId !== null) ? null : relationInfo?.childDocumentType,
      db_search: this.extendedSearchService.extendedSearchParameters().db_search
    } as GetExtendedSearchFieldParams, this.extendedSearchService.extendedSearchParameters().query_groups, this.parentGroup()).then((fields) => {
      let f = fields;
      if (relationId || relationInfo?.childDocumentType) {
        return this.addAllFields(f);
      }
      else {
        return Promise.resolve(f);
      }
    }).then((fields) => {
      this.availableFields.set(fields);
    });
  }
}
