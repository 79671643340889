import {Component, Input, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";

import {FieldSearchQueryFragment} from '../../../core/query-container';
import {CmsApiService} from '../../../core/cms-api.service';
import {FieldInputType} from '../../../core/definitions/field-input-type.enum';
import {GetSolrFieldsParams} from '../../../core/definitions/get-solr-fields-params';
import {SearchContainer} from '../../../core/definitions/search-container';
import {SearchExecutorService} from '../../search-executor.service';
import {SearchParameters} from '../../../core/definitions/search-parameters';
import {SolrField} from '../../../core/definitions/solr-field';
import {SearchService} from '../../../core/search.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-search-query-field-search',
  templateUrl: './search-query-field-search.component.html',
  styleUrls: ['./search-query-field-search.component.scss']
})

export class SearchQueryFieldSearchComponent {
  @Input() queryPlaceholder: string;
  @Input() searchContainer: SearchContainer;

  autocompleteFormControl = new FormControl('');
  autocompleteOptions = null;

  // Null to be able to trigger subscribe on value change
  selectedFragment: FieldSearchQueryFragment = null;

  fieldSuggestions: SolrField[] = [];

  isEditing: boolean = false;
  isValidQuery: boolean = true;

  searchString: string = '';
  searchValue: string | number | boolean = '';

  private timeoutHandler;

  constructor(
    private cms: CmsApiService,
    private searchExecutor: SearchExecutorService,
    private searchService: SearchService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.rebuildQuery();
  }

  rebuildQuery() {
    if (this.searchContainer.queryContainer.query !== undefined) {
      this.searchContainer.queryContainer.fieldSearchQueryFragments = [];

      let fragments = [];
      let tempFragments = this.searchContainer.queryContainer.query.replaceAll('"', '').split(' OR ');

      for (const f of tempFragments) {
        if (f.includes(' AND ')) {
          let t = f.split(' AND ');
          for (const ft of t) {
            fragments.push(ft);
          }
        }
        else {
          fragments.push(f);
        }
      }

      for (const f of fragments) {
        let keyValue = f.split(':');

        // Hvordan får jeg tak på name?
        // Hvordan får jeg tak på input_type?
        // Hvordan sikrer jeg at det kommer inn i samme rekkefølge som URL query er i?
        this.searchContainer.queryContainer.fieldSearchQueryFragments.push({
          inputType: FieldInputType.TEXT_AREA,
          isExcluded: keyValue[0].startsWith('-'),
          name: keyValue[0].replaceAll('-', ''),
          solrOperand: this.searchContainer.queryContainer.query.includes(`AND ${keyValue[0]}`),
          solrQueryField: keyValue[0].replaceAll('-', ''),
          value: keyValue[1] === '***' ? '*' : keyValue[1].replaceAll('*', '')
        } as FieldSearchQueryFragment);
      }
    }
  }

  clearSearch() {
    this.autocompleteOptions = null;
    this.fieldSuggestions = [];
    this.isValidQuery = true;
    this.searchString = '';
    this.searchValue = '';

    this.searchContainer.queryContainer.fieldSearchQueryFragments = [];
    this.searchContainer.queryContainer.query = '';
  }

  addFragment(fragment: FieldSearchQueryFragment) {
    if (this.isEditing) {
      this.editFragment(fragment);
      this.isEditing = false;
    }
    else {
      this.searchContainer.queryContainer.fieldSearchQueryFragments.push(fragment);
    }

    this.searchString = '';
    this.searchValue = '';
    this.fieldSuggestions = [];
    this.selectedFragment = null;

    this.search();
  }

  editFragment(fragment: FieldSearchQueryFragment) {
    const editIndex = this.searchContainer.queryContainer.fieldSearchQueryFragments.findIndex((f) => f.solrQueryField === fragment.solrQueryField);

    if (editIndex !== -1) {
      this.searchContainer.queryContainer.fieldSearchQueryFragments[editIndex] = fragment;
    }

    this.searchValue = '';
    this.selectedFragment = null;

    this.search();
  }

  removeFragment(fragment: FieldSearchQueryFragment) {
    const remIndex = this.searchContainer.queryContainer.fieldSearchQueryFragments.findIndex((f) => f.solrQueryField === fragment.solrQueryField);
    if (remIndex !== -1) {
      this.searchContainer.queryContainer.fieldSearchQueryFragments.splice(remIndex, 1);
    }

    this.search();
  }

  search() {
    let query = this.searchContainer.queryContainer.fieldSearchQueryFragments.map((fragment, index) => {
      if (index === 0) {
        return `${fragment.isExcluded ? '-' : ''}${fragment.solrQueryField}${fragment.inputType === FieldInputType.MAP_ID ? '_value' : ''}:"*${fragment.value}*"`;
      }
      return `${fragment.solrOperand ? 'OR' : 'AND'} ${fragment.isExcluded ? '-' : ''}${fragment.solrQueryField}${fragment.inputType === FieldInputType.MAP_ID ? '_value' : ''}:"*${fragment.value}*"`;
    })

    if (query.length > 0) {
      this.searchContainer.queryContainer.query = `"${query.join(' ')}"`;
      this.searchExecutor.runSearch(this.searchContainer);
    }
    else {
      this.searchContainer.queryContainer.query = undefined;
      this.searchExecutor.runSearch(this.searchContainer);
    }
  }

  selectFragment(fragment: FieldSearchQueryFragment) {
    this.isEditing = true;
    this.selectedFragment = fragment;
    this.searchValue = fragment.value;
  }

  selectSuggestion($event, suggestion: SolrField) {
    $event.stopPropagation();
    this.selectedFragment = {
      inputType: suggestion.input_type,
      isExcluded: false,
      name: suggestion.name,
      solrOperand: false,
      solrQueryField: suggestion.index_query_field,
      value: this.searchValue
    } as FieldSearchQueryFragment;
  }

  updateAutocompleteOptions(value: string, preSelected = null) {
    // Checks if this is the first time the autocomplete has been clicked (options === null)
    // if it is, a timeout is set to query for ALL applicable options for this field
    if (this.autocompleteOptions === null) {
      this.autocompleteFormControl.valueChanges.subscribe((value) => {
        if (this.autocompleteFormControl.dirty) {
          clearTimeout(this.timeoutHandler);
          this.timeoutHandler = setTimeout(() => {
            this.updateAutocompleteOptions(value);
          }, 500);
        }
      });
    }

    if (preSelected) {
      this.autocompleteFormControl.setValue(preSelected);
    }

    let params: SearchParameters = {
      combine_horizontal: false,
      facets: [
        `${this.selectedFragment?.solrQueryField}_value`
      ],
      facet_range_groups: [],
      fl: [],
      fq: [
        `${this.selectedFragment?.solrQueryField}_value:*${value}*`
      ],
      getAll: false,
      labelProp: '',
      overview: true,
      overview_simple: false,
      overview_fields: [],
      parents_only: false,
      query: '',
      rows: 0,
      search_engine: '',
      sort: '',
      start: 0,
      stat_fields: [],
      template_group_id: '',
      group_search: false,
      group_field: '',
      write_collections_only: false
    };

    if (this.selectedFragment?.solrQueryField === 'collection.collection_id') {
      params.fq.push('-meta_type:spectrum_procedure');
      params.fq.push('-collection.collection_id:(e6ea5640-0464-4874-8b2d-a2fa532504fb-59a702ea-360f-42eb-a000-d6d7b9fa4d3c)')
    }

    // console.log(params);

    this.searchService.search(params).then((result) => {
      this.autocompleteOptions = result.facets[0].items;
      // console.log(result);
    });
  }

  onKeyPressed($event) {
    if($event.key === 'Enter' && this.isValidQuery) {
      this.search();
    }
  }

  get query() {
    return this.searchString;
  }

  get value() {
    return this.searchValue as string;
  }

  set query(value: string) {
    clearTimeout(this.timeoutHandler);
    this.searchString = value;
    if (value !== '') {
      this.timeoutHandler = setTimeout(() => {
        this.cms.getSolrFields({
          input_types: [FieldInputType.MAP_ID, FieldInputType.INPUT, FieldInputType.TEXT_AREA],
          query: value,
          superobject_types: this.searchContainer.currentPathView.search_view.superobject_types
        } as GetSolrFieldsParams).then((result) => {
          this.fieldSuggestions = result;
          // console.log(result);
        });
      }, 500);
    }
  }

  set value(value: string) {
    this.selectedFragment.value = value;
    this.searchValue = value;
  }
}
