import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchContainer} from '../../../core/definitions/search-container';
import {ContentListSourceContainer} from '../../../core/definitions/object-content-tab/content-list-source-container';
import {FeatureFlagsService} from "../../../core/feature-flags.service";

@Component({
  selector: 'app-search-menu-select-all',
  templateUrl: './search-menu-select-all.component.html',
  styleUrls: ['./search-menu-select-all.component.scss']
})
export class SearchMenuSelectAllComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Input() contentListSource: ContentListSourceContainer;

  AConst = AConst;
  alwaysEnabled = false;

  constructor(private searchResultSelections: SearchResultSelectionsService,
              private featureFlagService: FeatureFlagsService) {}

  ngOnInit() {
    this.alwaysEnabled = this.featureFlagService.getFeatureFlags().experimental.useNewMainMenu;
  }

  async selectAll() {
    await this.searchResultSelections.selectAll(this.searchContainer, this.contentListSource);
  }

  indeterminate() {
    const sc = this.searchContainer;
    const allSelectedMinusSomeItems = sc.selections.allSelected && (sc.selections.selectedItems.length < sc.searchResult.search_count);
    const itemsSelectedButNotAll = !sc.selections.allSelected && sc.selections.selectedItems.length > 0;
    return itemsSelectedButNotAll || allSelectedMinusSomeItems;
  }

}
