import {Model} from "../Model";
import {ReportViewType} from "./ReportViewType";

export interface Field {
  title: string;
  admin_title: string;
  name: string;
  path: string;
  inline: { prop: string} | null;
  field_name: string;
  isSelected: boolean;
}

export interface FieldSection {
  title: string,
  admin_title: string,
  name: string,
  path: string,
  isSelected: boolean;
  isExpanded: boolean;
  fields: Field[];
}

export class ReportUserGenerated implements Model {
  id: string;
  loading: Promise<Model> | undefined;
  status: string;
  name: string;
  reportStatus: string;
  reportDateStart: Date;
  reportDateEnd: Date;
  dmsId: string;
  thumbnailUrl: string;
  shortDescription: string;
  reportType: string;
  reportViewTypeId: string;
  reportViewOptions: { [key: string]: any } = {};
  fieldSections: FieldSection[] = []; // This is a list of field sections, each containing a list of fields
  artifactList: {}[];
  reportViewType: ReportViewType

  // Function that parses the report_data_json field (which is a JSON string) and returns a list of all superobject_type_ids
  getSuperObjectTypeIds(): string[] {
    let superObjectTypeIds: string[] = [];
    this.artifactList.forEach(artifact => {
      superObjectTypeIds.push(artifact['superobject_type_id']);
    });
    return superObjectTypeIds;
  }

  getArtifactIds(): string[] {
    let artifactIds: string[] = [];
    this.artifactList.forEach(artifact => {
      artifactIds.push(artifact['artifact_id']);
    });
    return artifactIds;
  }

  //Function that parses report_data_json field (which is a JSON string) and returns a list of all unique object_type
  getObjectTypes(): string[] {
    let objectTypes: string[] = [];
    this.artifactList.forEach(artifact => {
      if (!objectTypes.includes(artifact['object_type'])) {
        objectTypes.push(artifact['object_type']);
      }
    });
    return objectTypes;
  }

  /*
    Apply the backend field sections to the fieldSections property, but keep the isSelected property of the frontend
    field sections and the sort order of the frontend field sections. Any field sections that are not present in the
    frontend field sections should be added to the same index as they had on the backend.

    If frontend field are empty, apply from backend setting isSelected to false on both field sections and fields.
   */
  applyFieldSectionsFromBackend(backendFieldSections: FieldSection[]) {
    if (!this.fieldSections) {
      this.fieldSections = [];
    }
    if (this.fieldSections.length === 0) {
      //There are noe selections from the backend, so select default values
      backendFieldSections.forEach(backendFieldSection => {
        let isDefaultSelected = backendFieldSection['name'] === 'top' || backendFieldSection['name'] === 'class';
        let newFieldSection: FieldSection = {
          title: backendFieldSection['title'],
          admin_title: backendFieldSection['admin_title'],
          name: backendFieldSection['name'],
          path: backendFieldSection['path'],
          isSelected: isDefaultSelected,
          isExpanded: false,
          // @ts-ignore
          fields: []
        }

        backendFieldSection.fields.forEach(subField => {
          let name = subField['name'];
          if (subField.inline != null && subField.inline.prop != null) {
            name = subField['name'] + '.' + subField.inline.prop
          }
          newFieldSection.fields.push({
            title: subField['title'],
            admin_title: subField['admin_title'],
            name: name,
            path: subField['path'],
            field_name: subField['field_name'],
            isSelected: isDefaultSelected,
            inline: null
          });
        });

        this.fieldSections.push(newFieldSection);
      });
    } else {
      //merge backend field sections with frontend field sections, but only title, admin title, isSelected and isExpanded fields on both fieldSection and fields
      backendFieldSections.forEach(backendFieldSection => {
        let frontendFieldSection = this.fieldSections.find(fieldSection => fieldSection['name'] === backendFieldSection['name'] && fieldSection['path'] === backendFieldSection['path']);
        if (frontendFieldSection) {
          frontendFieldSection['title'] = backendFieldSection['title'];
          frontendFieldSection['admin_title'] = backendFieldSection['admin_title'];
          frontendFieldSection['name'] = backendFieldSection['name'];

          //also merge fields with properties isSelected and isExpanded
          backendFieldSection.fields.forEach(backendField => {
            let frontendField = frontendFieldSection.fields.find(field => field['name'] === backendField['name'] && field['path'] === backendField['path']);
            let name = backendField['name'];
            if (backendField.inline != null && backendField.inline.prop != null) {
              name = backendField['name'] + '.' + backendField.inline.prop
            }

            if (frontendField) {
              frontendField['title'] = backendField['title'];
              frontendField['admin_title'] = backendField['admin_title'];
              frontendField['name'] = name;
            } else {
              frontendFieldSection.fields.push({
                title: backendField['title'],
                admin_title: backendField['admin_title'],
                name: name,
                path: backendField['path'],
                field_name: backendField['field_name'],
                isSelected: backendField['isSelected'],
                inline: null
              });
            }
          });
        } else {
          let newFieldSection: FieldSection = {
            title: backendFieldSection['title'],
            admin_title: backendFieldSection['admin_title'],
            name: backendFieldSection['name'],
            path: backendFieldSection['path'],
            isSelected: false,
            isExpanded: false,
            // @ts-ignore
            fields: []
          }

          backendFieldSection.fields.forEach(subField => {
            let name = subField['name'];
            if (subField.inline != null && subField.inline.prop != null) {
              name = subField['name'] + '.' + subField.inline.prop
            }

            newFieldSection.fields.push({
              title: subField['title'],
              admin_title: subField['admin_title'],
              name: name,
              path: subField['path'],
              field_name: subField['field_name'],
              isSelected: subField['isSelected'],
              inline: null
            });
          });

          this.fieldSections.push(newFieldSection);
        }
      });
    }

    //filter out any duplicated fields
    this.fieldSections.forEach(fieldSection => {
      fieldSection.fields = fieldSection.fields.filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);
    });
  }
}
