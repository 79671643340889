<div class="query-builder">
  <div class="query-builder__rule-container">
    @for (
      group of extendedSearchService.extendedSearchParameters().query_groups;
      track $index;
      let first = $first
    ) {
      @if (!first) {
        <div
          class="query-builder__group-operator-container"
        >
          <mat-chip
            [disabled]="true"
          >
            {{"TRANS__OPERATOR__" + group.logical_operator + "__LITERAL" | translate}}
          </mat-chip>
        </div>
      }

      <app-extended-search-query-builder-group
        [rootGroup]="group"
        [searchContainer]="searchContainer()"
        [selfGroup]="group"
      />
    }
  </div>

  <div>
    <!--
    <button
      mat-stroked-button
      class="query-builder__add-group-button"
      color="primary"
      (click)="addNewGroupToRoot()"
    >
      <mat-icon>
        add
      </mat-icon>

      <span class="query-builder__add-group-label">
        {{ "TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__RULE_GROUP" | translate }}
      </span>
    </button>
    -->
  </div>

  <div class="query-builder__button-container">
    <button
      mat-flat-button
      class="query-builder__search-button"
      (click)="triggerExtendedSearch()"
      [disabled]="isSearching || ongoingSearch"
      [matTooltip]="'TRANS__EXTENDED_SEARCH__DB_SEARCH_ONGOING' | translate"
      [matTooltipDisabled]="!ongoingSearch"
    >
      <span class="query-builder__search-button-label">
        {{ isSearching ? ("TRANS__SEARCH_ADVANCED_SEARCH_QUERY_BUILDER__SEARCHING" | translate) : ("TRANS__SEARCH_ADVANCED_SEARCH_QUERY_BUILDER__START_SEARCH" | translate) }}
      </span>
    </button>

    @if (rulesHasValues && !isSearching) {
      <button mat-button (click)="resetExtendedSearch()" class="query-builder__clear-button">
        <span>
          {{ "TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__CLEAR_RULES" | translate }}
        </span>
      </button>
    }

    @if (isSearching) {
      <button mat-button (click)="cancelSearch()" class="query-builder__cancel-button">
        <span>
          {{ "TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__CANCEL_SEARCH" | translate }}
        </span>
      </button>
    }

    @if (extendedSearchService.extendedSearchParameters().db_search) {
      <div class="query-builder__sql-information-container">
        <mat-icon class="query-builder__sql-information-icon"> info </mat-icon>

        <span class="query-builder__sql-information-label">
        {{ "TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__SQL_INFORMATION" | translate }}
      </span>

        <!-- <button mat-button>
          {{ "TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__SQL_READ_MORE" | translate }}
        </button> -->
      </div>
    }
  </div>
</div>
