import {Component, Input} from '@angular/core';
import {JobStatusItem} from "../../../core/definitions/job-status-item";
import {MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle} from "@angular/material/expansion";
import {MatAnchor, MatButton} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {PrimusStateMapperService} from "../../../core/primus-state-mapper.service";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {CmsApiService} from "../../../core/cms-api.service";
import {FileSaverService} from "ngx-filesaver";
import {LoggerService} from "../../../core/logger.service";
import {saveAs} from "file-saver";

@Component({
  selector: 'app-job-status-item',
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatButton,
    TranslateModule,
    MatAnchor,
    RouterLink,
    MatExpansionPanelHeader,
    MatIcon,
    MatProgressSpinner
  ],
  templateUrl: './job-status-item.component.html',
  styleUrl: './job-status-item.component.scss'
})
export class JobStatusItemComponent {
  @Input() message: JobStatusItem;

  constructor(
    public primusStateMapperService: PrimusStateMapperService,
    private cms: CmsApiService,
    private fileSaverService: FileSaverService,
    private logger: LoggerService
  ) {}

  /**
   * Downloads the requested report to the client.
   * @param reportId
   * @param fileType
   */
  downloadReport = (reportId: string, fileType: string) => {
    this.cms.downloadReport(reportId).subscribe({
      next: (blob) => {
        this.fileSaverService.save(blob, reportId + '.' + fileType);
      },
      error: (error) => {
        this.logger.error('Error downloading report', error);
      }
    });
  }

  async downloadReportV2(reportId: string, dmsId: string) {
    let downloadUrl = await this.cms.requestReportPdfFromDms(reportId);
    if (downloadUrl.url) {
      let pdfFile = await fetch(downloadUrl.url);
      let pdfFileBlob = await pdfFile.blob();
      saveAs(pdfFileBlob, reportId + '.pdf');
    }
  }
}




