import {Injectable} from '@angular/core';
import {SearchParameters, SearchParametersForOverview} from "./definitions/search-parameters";
import {CmsApiService} from "./cms-api.service";
import {SearchResult} from "./definitions/search-result";
import {LoggerService} from "./logger.service";
import {CmsQueueService} from "./cms-queue.service";
import {SearchContainer} from "./definitions/search-container";
import {DbSearchCount} from "./definitions/db-search-count";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private cms: CmsApiService,
              private logger: LoggerService,
              private cmsQueue: CmsQueueService) {
  }

  private mustCancelDbCachePolling = false;
  private currentSearchDbCorrelationId = null;

  async search(searchParams: SearchParameters) {
    searchParams['q.op'] = searchParams['q.op'] || 'AND';
    return this.cms.search(searchParams);
  }

  async searchWithOverview(params: SearchParametersForOverview): Promise<SearchResult> {
    return new Promise((resolve, reject) => {

      params['q.op'] = params['q.op'] || 'AND';
      // Forcing all calls towards searchWithOverview to be queued in order to avoid async issues
      this.cmsQueue.runCmsFnWithQueue(
        this.cms.searchWithOverview,
        params,
        true,
        (res: any) => {
          resolve(res);
        },
        (e: any) => {
          reject(e);
        },
        true
      )

    });
  }

  async searchDbWithOverview(params: SearchParametersForOverview, searchContainer: SearchContainer, maxRetries: number): Promise<any> {
    let res: SearchResult = null;
    await this.checkRunningSearch();
    const queueRes = await this.cms.searchDbWithOverview(params);
    if (queueRes.status === 'queued') {
      this.currentSearchDbCorrelationId = queueRes.correlation_id;
      searchContainer.dbSearchCorrelationId = queueRes.correlation_id;
      res = await this.checkIfGettingCachedSearchData(queueRes.correlation_id, maxRetries);
      this.currentSearchDbCorrelationId = null;
    } else {
      throw new Error('Unknown status: ' + queueRes.status);
    }
    return res;
  }

  async getDbSearchCount(params: SearchParametersForOverview, searchContainer: SearchContainer, maxRetries: number): Promise<DbSearchCount> {
    let res: DbSearchCount = null;
    const queueRes = await this.cms.getDbSearchCount(params);
    if (queueRes.status === 'queued') {
      searchContainer.dbSearchCorrelationId = queueRes.correlation_id;
      res = await this.checkIfGettingCachedSearchData(queueRes.correlation_id, maxRetries);
    } else {
      throw new Error('Unknown status: ' + queueRes.status);
    }
    return res;
  }

  async cancelDbCachePolling(correlationId: string) {
    this.mustCancelDbCachePolling = true;
    return this.cms.cancelDbSearch(correlationId);
  }

  private async checkIfGettingCachedSearchData(correlationId: string, maxRetries: number): Promise<any> {
    if (!correlationId) {
      this.logger.debug('Correlation Id not found: ' + correlationId);
      return Promise.reject('Correlation Id not found');
    }
    const startTime = new Date().getTime();
    const cachedResult = await this.getCachedSearchDatatWithRetry(correlationId, 0, 500, maxRetries);
    const runTime = new Date().getTime() - startTime;
    this.logger.debug('Got cached search result with correlationId: ' + correlationId + ' in ' + runTime + ' ms');
    if (cachedResult) {
      await this.cms.deleteCachedSearchData(correlationId);
      this.logger.debug('Deleted cached search result with correlationId: ' + correlationId);
    }
    return cachedResult;
  }

  private async getCachedSearchDatatWithRetry(correlationId: string, retries = 0, waitNext = 500, maxRetries = 30): Promise<any> {
    try {
      if (this.mustCancelDbCachePolling) {
        this.logger.debug('Cancelled db search poll');
        this.mustCancelDbCachePolling = false;
        return this.getCanceledResult();
      }
      const res = await this.cms.getCachedSearchData(correlationId);
      if (res && !res['error']) {
        this.logger.debug('Got cached search result');
        return res;
      } else if (retries < maxRetries) {
        this.logger.debug(
          `Retrying getting cached search result for id ${correlationId}, wait for ${waitNext} ms`);
        await new Promise(resolve => setTimeout(resolve, waitNext));
        return this.getCachedSearchDatatWithRetry(correlationId, retries + 1, waitNext + 500, maxRetries);
      } else {
        this.logger.info(`Too many attempts to get cached search result for id ${correlationId}, canceling polling...`);
        this.cancelDbCachePolling(correlationId).then();
        return this.getCanceledResult();
      }
    } catch (error) {
      console.error('Error getting cached overview result:', error);
      throw error;
    }
  }

  private getCanceledResult(): SearchResult {
    const canceledResult = new SearchResult();
    canceledResult.artifacts = [];
    canceledResult.status_msg = 'cancelled';
    canceledResult.status = 'failed';
    return canceledResult;
  }

  private async checkRunningSearch() {
    if (this.currentSearchDbCorrelationId) {
      this.logger.debug('Cancelling previous search');
      await this.cancelDbCachePolling(this.currentSearchDbCorrelationId)
      this.currentSearchDbCorrelationId = null;
    }
  }
}
