import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef, OnInit
} from '@angular/core';
import {ReportUserGenerated} from "../../../../core/ModelStore/models/ReportUserGenerated";
import {MediaHelperService} from "../../../../core/media-helper.service";

@Component({
  selector: 'report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input() reportTemplate: ReportUserGenerated;
  @Input() extractedArtifacts: any;
  @Input() viewOptionsCheckboxValues: any;
  @Input() preview: boolean = false;
  @Input() numPhotographsInReport: number = 1;
  @Input() selectedPhotographSize: string = 'small';
  reportHeaderColumns : string[] = [];
  imageUrls : Map<string, string> = new Map();

  constructor(private renderer: Renderer2,
              private cdRef: ChangeDetectorRef,
              private mediaService: MediaHelperService) {

  }

  async ngOnInit(): Promise<void> {

  }

  isAllImagesSelected() {
    return this.viewOptionsCheckboxValues.find((option) => option.key === 'alle_bilder').value;
  }

  isShowTableHeader() {
    return this.reportHeaderColumns.length <= 3 && this.selectedPhotographSize !== 'large';
  }

  findFieldByKey(artifact: any, key: string) {
    let artifactList = [];

    artifact.sections.forEach(section => {
      section.fields.forEach(field => {
        if (field.key === key) {
          artifactList.push(field);
        }
      });
    })

    return artifactList;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.extractedArtifacts && this.extractedArtifacts && this.extractedArtifacts.length > 0) {

      if (this.extractedArtifacts && this.extractedArtifacts.length > 0) {
        let emptyImageIds = [];

        this.extractedArtifacts.forEach(artifact => {
          if (artifact.images && artifact.images.length > 0) {
            const imageIds = artifact.images.map(image => image.image_id);
            imageIds.forEach(imageId => {
              if (!this.imageUrls.has(imageId)) {
                emptyImageIds.push(imageId);
              }
            });
          }
        });

        console.log('fetching medium image URL for: ', emptyImageIds);

        if (emptyImageIds.length > 0) {
          this.mediaService.getImageUrlsFromImageIds(emptyImageIds, ['medium']).then((urls) => {
            const keys = Object.keys(urls);
            keys.forEach((key) => {
              this.imageUrls.set(key, urls[key]['medium']);
            });
          });
        }
      }

      console.log('------');
      console.log(this.extractedArtifacts);
      //Go through each extractedArtifacts.sections.section and add all unique field.key to this.reportHeaderColumns
      this.reportHeaderColumns = [];

      this.extractedArtifacts.forEach(artifact => {
        artifact.sections?.forEach(section => {
          section.fields.forEach(field => {
            if (!this.reportHeaderColumns.includes(field.key)) {
              this.reportHeaderColumns.push(field.key)
            }
          });
        });
      });

      console.log('NUM FIELDS SELECTED: ', this.reportHeaderColumns.length);
      this.cdRef.detectChanges();
    }
  }

  ngAfterViewChecked() {

  }
}
