import { Component, Input, OnChanges } from '@angular/core';
import {SearchContainer} from '../../../core/definitions/search-container';
import {SearchHierarchicBreadcrumbService} from '../../search-hierarchic-breadcrumb.service';
import {SearchHandlerService} from '../../search-handler.service';
import {LoggerService} from "../../../core/logger.service";
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-search-hierarchic-bread-crumbs',
  templateUrl: './search-hierarchic-bread-crumbs.component.html',
  styleUrls: ['./search-hierarchic-bread-crumbs.component.scss']
})
export class SearchHierarchicBreadCrumbsComponent implements OnChanges {
  @Input() searchContainer: SearchContainer;
  @Input() targetId: string;

  constructor(private logger: LoggerService,
              private searchHierarchicBreadcrumbService: SearchHierarchicBreadcrumbService,
              private searchHandler: SearchHandlerService) { }

  showMoreButton = false;
  finishedLoading = false;

  ngOnChanges(): void {
    if (this.targetId && this.targetId !== 'none' && this.searchContainer.currentPathView.search_view.search_view_type !== 'media_folder') {
      this.searchHierarchicBreadcrumbService.setBreadcrumbsFromSearch(this.searchContainer, this.targetId).then(() => {
        this.showMoreButton = this.searchContainer.breadcrumbs.length - 1 > 3;
        this.finishedLoading = true;
      });
    }
  }

  showAllItems() {
    this.showMoreButton = false;
  }

  goToMenuPath() {
    this.searchContainer.objectCount = 0;
    this.searchContainer.targetId = 'none';
    this.setPrevSearchPath();
    this.searchHandler.goPathView(this.searchContainer.path, this.searchContainer).then();
  }

  private setPrevSearchPath() {
    const lastSlashIndex = this.searchContainer.path.lastIndexOf('/');
    if (lastSlashIndex !== -1) {
      this.searchContainer.path = this.searchContainer.path.substring(0, lastSlashIndex);
    } else {
      this.logger.warn(`Unable to get last search path for ${this.searchContainer.path}`);
    }

  }
}
