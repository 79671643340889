<div class="search-view-full search-view">

  <mat-drawer-container class="search-view-full__container" *ngIf="searchContainer"
                        (backdropClick)="onToggleSideMenuSmallScreen()">
    <mat-drawer class="search-view-full__side-menu"
                [disableClose]="!mediumScreen"
                [mode]="mediumScreen ? 'over' : 'side'"
                [opened]="sideMenuSmallScreen && mediumScreen || !mediumScreen">
      @if(filterMenuV2) {
        <app-search-filter-menu-v2
          [searchContainer]="searchContainer"
        />
      }
      @else {
        <app-search-focus-menu
          *ngIf="!isFolder"
          [searchContainer]="searchContainer"></app-search-focus-menu>

        <app-search-filter-menu
          [ngClass]="{'has-focus-menu': !isFolder}"
          [sideMenuSmallScreen]="sideMenuSmallScreen"
          (toggleSideMenuSmallScreen)="onToggleSideMenuSmallScreen()"
          [sideMenu]="true"
          [searchContainer]="searchContainer"
        ></app-search-filter-menu>
      }
    </mat-drawer>

    <mat-drawer-content (scrollend)="PRIM5581_resetScrollToZero($event)" class="search-view-full__content" id="search-view-full">
      <div id="searchMainContent" class="search-view-full__main-content" [ngClass]="{'overflow-y-auto': searchContainer.queryContainer.selectedQueryMenu?.query_type === 'advanced'}">
        <div class="search-view-full__header">
          <app-search-header
            [sideMenuSmallScreen]="sideMenuSmallScreen"
            (toggleSideMenuSmallScreen)="onToggleSideMenuSmallScreen()"
            [searchContainer]="searchContainer"
          ></app-search-header>
        </div>
        @if (searchContainer.queryContainer.selectedQueryMenu?.query_type === 'advanced') {
          <app-extended-search-query-builder
            [searchContainer]="searchContainer"
          />
        }
        <div id="innerCon"
             class="search-view-full__main-content-container search-result-views-inner-container">
          <app-search-filter-chiplist class="p-2" [searchContainer]="searchContainer" (filterRemoved)="onFilterRemoved()"></app-search-filter-chiplist>
          <app-search-hierarchic-bread-crumbs
            class="search-view-full__hierarchic-bread-crumbs"
            [searchContainer]="searchContainer"
            [targetId]="searchContainer.targetId"
          ></app-search-hierarchic-bread-crumbs>
          <app-search-menus
            class="search-view-full__search-menus"
            [searchContainer]="searchContainer"
            (operationPerformed)="onOperationPerformed($event)"
          ></app-search-menus>
          <app-search-view-full-header
            *ngIf="!isFolder"
            [searchContainer]="searchContainer"
            [listFieldTemplateId]="searchContainer.listFieldTemplateId"
          ></app-search-view-full-header>
          <app-search-view-folder-header
            *ngIf="isFolder && searchContainer.targetObject"
            [searchContainer]="searchContainer"
            [folderId]="searchContainer.targetObject.artifact_id"
          ></app-search-view-folder-header>
          <app-search-result-view
            [ngClass]="{'search-view-full__result-view': !isListBased,
               'search-view-full__result-view-with-scroll': isListBased}"
            [searchContainer]="searchContainer"
            [templateGroupId]="searchContainer.templateGroupId"
          ></app-search-result-view>
        </div>
      </div>
    </mat-drawer-content>

  </mat-drawer-container>
</div>
