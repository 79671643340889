import {Injectable} from '@angular/core';
import {QueryMenu} from '../core/definitions/search-objects';
import {SearchContainer} from '../core/definitions/search-container';
import {TranslateService} from '@ngx-translate/core';
import {CmsApiService} from "../core/cms-api.service";

@Injectable({
  providedIn: 'root'
})
export class QueryFieldMenuService {

  private queryMenus: {[name: string]: QueryMenu} = null;

  constructor(private translateService: TranslateService,
              private cms: CmsApiService) {
  }

  async getQueryMenusForCurrentPathView(searchContainer: SearchContainer): Promise<QueryMenu> {
    let res = new QueryMenu();
    const queryMenus = await this.getQueryMenus();
    if (!queryMenus) {
      return res;
    }
    const queryMenuId = searchContainer.currentPathView.search_view.query_menus;
    if (queryMenuId) {
      const menu = queryMenus[queryMenuId];
      if (!menu) {
        console.error(`Query menu ${queryMenuId} not found`);
      } else {
        res = queryMenus[queryMenuId];
      }
    }
    return res;
  }

  async getCurrentQueryMenu(searchContainer: SearchContainer): Promise<QueryMenu> {
    let res = null;
    if (searchContainer.queryContainer.selectedQueryMenu) {
      const queryMenus = await this.getQueryMenusForCurrentPathView(searchContainer)
      res = queryMenus.menus.find(
        menu => menu.query_type === searchContainer.queryContainer.selectedQueryMenu.query_type);
    }
    return res;
  }

  async setSelectedQueryFieldMenu(searchContainer: SearchContainer) {
    let queryMenus: QueryMenu;
    const allQueryMenus = await this.getQueryMenus();
    if (allQueryMenus) {
      queryMenus = allQueryMenus[searchContainer.currentPathView.search_view.query_menus];
    }
    if (queryMenus) {
      for (const menu of queryMenus.menus) {
        menu.selected = false;
      }
    }
    const currentQueryMenu = await this.getCurrentQueryMenu(searchContainer);
    if (currentQueryMenu) {
      currentQueryMenu.selected = true;
      searchContainer.queryContainer.selectedQueryMenu = currentQueryMenu;
      searchContainer.queryContainer.queryPlaceholder = searchContainer.queryContainer.selectedQueryMenu.description;
      this.setCurrentQueryPlaceholder(searchContainer, searchContainer.queryContainer.queryPlaceholder);
    } else {
      await this.setDefaultSelectedQueryFieldMenu(searchContainer);
    }
  }

  private async getQueryMenus() {
    if (this.queryMenus) {
      return this.queryMenus;
    }
    const queryMenus = await this.cms.getSearchViewQueryMenus();
    if (queryMenus) {
      this.queryMenus = queryMenus;
    }
    return queryMenus;
  }

  private async setDefaultSelectedQueryFieldMenu(searchContainer: SearchContainer) {
    const queryMenus = await this.getQueryMenus();
    if (!queryMenus) {
      return;
    }
    const qm = queryMenus[searchContainer.currentPathView.search_view.query_menus];
    if (qm) {
      const selectedQueryMenuExists = searchContainer.queryContainer.selectedQueryMenu
        && qm.menus.some(menu => menu.query_type === searchContainer.queryContainer.selectedQueryMenu.query_type);
      if (!selectedQueryMenuExists) {
        searchContainer.queryContainer.selectedQueryMenu = qm.menus[0];
      }
      if (!searchContainer?.searchCategory) {
        searchContainer.queryContainer.queryPlaceholder = searchContainer.queryContainer.selectedQueryMenu.description;
        this.setCurrentQueryPlaceholder(searchContainer, searchContainer.queryContainer.queryPlaceholder);
      }
    }
  }

  private setCurrentQueryPlaceholder(searchContainer: SearchContainer, placeholder: string) {
    this.translateService.get(placeholder).subscribe(value => {
      searchContainer.queryContainer.currentQueryPlaceholder = value;
    });
  }

}
