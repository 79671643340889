import {Injectable} from '@angular/core';
import {SearchObject} from '../../../core/definitions/search-object';
import {SearchParameters} from '../../../core/definitions/search-parameters';
import {FilterOptionGroup} from '../../../core/definitions/extended-search/filter-option-group';
import {FilterOption} from '../../../core/definitions/extended-search/filter-option';
import {SettingsService} from '../../../core/settings.service';
import {SearchService} from "../../../core/search.service";

@Injectable({
  providedIn: 'root'
})
export class FieldFilterService {

  /**
   * Caching al the groups, to avoid regrouping
   * @type {Array<FilterOptionGroup>}
   * @private
   */
  private filterOptionsCache: Array<FilterOptionGroup>;

  /**
   *
   * @param {SearchService} searchService
   * @param settings
   */
  constructor(private readonly searchService: SearchService,
              private readonly settings: SettingsService) {
  }

  /**
   * Get all available filter-options
   * @return {Promise<Array<FilterOptionGroup>>}
   */
  public async getFilterOptions(): Promise<Array<FilterOptionGroup>> {
    if (!this.filterOptionsCache) {
      const superObjectParents = await this.getSuperObjectParents();
      const options = await this.getArtifactTypes();

      const filterOptions = options.map(option => ({
        id: option.artifact_id,
        name: option.artifact_name,
        description: option.description,
        parentId: option.parent_id
      } as FilterOption));

      this.filterOptionsCache = superObjectParents.map(parent => ({
          groupId: parent.artifact_id,
          groupName: parent.artifact_name,
          options: filterOptions.filter(opt => opt.parentId === parent.artifact_id)
        } as FilterOptionGroup))
        .filter(group => group.options.length > 0);
    }
    return this.filterOptionsCache;
  }

  /**
   * Fetches all MetaTypes (Parents/groups)
   * @return {Promise<Array<SearchObject>>}
   * @private
   */
  private async getSuperObjectParents(): Promise<Array<SearchObject>> {
    const res = await this.searchService.search({
      query: `object_type:${this.settings.getClientConfig().CONCEPT_TYPE_SUPEROBJECT_TYPE}`,
      fq: ['-parent_id:[* TO *]'],
      fl: ['artifact_id', 'artifact_name', 'code'],
      sort: 'artifact_name asc'
    } as SearchParameters);
    return res?.artifacts || [];
  }

  /**
   * Fetches all selectable options (children)
   * @return {Promise<Array<SearchObject>>}
   * @private
   */
  private async getArtifactTypes(): Promise<Array<SearchObject>> {
    const res = await this.searchService.search({
      query: `object_type:"${this.settings.getClientConfig().CONCEPT_TYPE_SUPEROBJECT_TYPE}"`,
      fl: ['parent_id', 'artifact_id', 'artifact_name'],
      sort: 'artifact_name asc'
    } as SearchParameters);
    return res?.artifacts || [];
  }
}
