import {Component, OnInit} from '@angular/core';
import {PrimusRouteService} from '../../core/primus-route.service';
import {SearchHandlerService} from '../../object-search/search-handler.service';
import {CommonsService} from '../../core/commons.service';
import {DateToolsService} from '../../core/date-tools.service';
import {AConst} from '../../core/a-const.enum';
import {TranslateService} from '@ngx-translate/core';
import {SearchExecutorService} from '../../object-search/search-executor.service';
import {SearchContainer, SearchContainerParams} from '../../core/definitions/search-container';
import {PrimusStateMapperService} from '../../core/primus-state-mapper.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {OperationService} from '../../operations/operation.service';
import {OperationTarget} from '../../core/definitions/operation-target.enum';
import {OperationContainer} from '../../core/definitions/operation-container';
import {OperationDef} from '../../core/definitions/operation-def';
import {TopHeaderParentInfo} from "../../core/definitions/top-header-parent-info";

@Component({
  selector: 'app-admin-create-new',
  templateUrl: './admin-create-new.component.html',
  styleUrls: ['./admin-create-new.component.scss']
})
export class AdminCreateNewComponent implements OnInit {

  AConst = AConst;
  parent: TopHeaderParentInfo;
  searchContainer: SearchContainer;
  operationContainer: OperationContainer;

  constructor(private primusRoute: PrimusRouteService,
              private primusRouter: PrimusRouterService,
              public primusStateMapper: PrimusStateMapperService,
              private searchHandler: SearchHandlerService,
              private translate: TranslateService,
              private commons: CommonsService,
              private dateTools: DateToolsService,
              private searchExecutorService: SearchExecutorService,
              private operationService: OperationService) {
  }

  ngOnInit() {
    this.setMenus().then();
    this.setParent();

    this.parent.hasSubMenus = false;
    this.getlastCreatedItems().then(() => {
      this.setSearchResultItemsDate();
    });
  }

  private async setMenus() {
    this.operationContainer = await this.operationService.createOperationContainer(OperationTarget.CREATE_OBJECT_VIEW);
    await this.operationService.setOperations(this.operationContainer);
    this.translateValues();
    this.sortSubMenu();
  }

  private sortSubMenu() {
    this.operationContainer.operations.forEach(mainOp => {
      mainOp.sub_operations = this.commons.orderArray(mainOp.sub_operations, 'menu_title');
    });
  }

  private translateValues() {
    this.operationContainer.operations.forEach(mainOp => {
      mainOp.sub_operations.forEach(subOp => {
        subOp.menu_title = this.translate.instant(subOp.menu_title);
      });
    });
  }

  private setParent() {
    this.parent = {
      path: 'admin',
      adminType: 'create-new',
      hasSubMenus: true,
      target_params: {admin_type: 'create-new'},
      currentState: this.primusRouter.currentState()
    };
  }

  private setSearchResultItemsDate() {
    if (this.searchContainer.searchResult) {
      this.searchContainer.searchResult.artifacts.forEach(item => {
        const date = item.created_at;
        item.$$createdDate = this.dateTools.isoDateToString(date);
      });
    }
  }

  private async getlastCreatedItems(): Promise<void> {
    const searchContainerParams = new SearchContainerParams();
    searchContainerParams.searchViewName = 'content_last_created';
    searchContainerParams.stateParams = this.primusRoute.params;
    searchContainerParams.rows = {'content-list': 5};
    searchContainerParams.runSearch = false;
    this.searchContainer = await this.searchHandler.createSearchContainer(searchContainerParams);
    this.searchContainer.rows = {'content-list': 5};
    this.searchContainer.searchResultViewName = 'content-list';
    this.searchContainer.searchResultViews.default = 'content-list';
    this.searchContainer.setOrder('created_at desc', false);
    await this.searchExecutorService.runSearch(this.searchContainer);
  }

}
