import {Component, OnDestroy, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ContentInfo} from '../../core/definitions/content-info';
import {CurrentObjectService} from '../../core/current-object.service';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {SearchSelectorService} from '../../core/search-selector.service';
import {TemplateSelectorContainer} from '../../core/definitions/template-selector-container';
import {UiToolsService} from '../../core/ui-tools.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {OperationAndSectionsContainerParams, OperationService} from '../../operations/operation.service';
import {OperationDialogService} from '../../operations/operation-dialog.service';
import {CmsApiService} from '../../core/cms-api.service';
import {SpectrumProcedureStep} from '../../core/definitions/setting-spectrum-procedure';
import {OperationDef} from '../../core/definitions/operation-def';
import {Selector} from '../../core/definitions/reference';
import {OperationStepType} from '../../core/definitions/operation-step-type.enum';
import {TranslateService} from '@ngx-translate/core';
import {OperationTarget} from '../../core/definitions/operation-target.enum';
import {ContentMenusService} from '../../object-content-tab/content-menus.service';
import {OperationContainer} from '../../core/definitions/operation-container';
import {ContentListSourceContainer} from '../../core/definitions/object-content-tab/content-list-source-container';
import {SelectorCreationParams} from '../../core/definitions/selector-creation-params';
import {OperationExecutorType} from '../../core/definitions/operation-executor-type.enum';
import {PrimusRouterService} from '../../core/primus-router.service';
import {PrimusRouteService} from '../../core/primus-route.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.scss'],
  animations: [
    trigger('showAllFields', [
      state('closedTop', style({height: '150px'})),
      state('openTop', style({height: '*'})),
      state('closedBottom', style({height: 'calc(100vh - 250px)'})),
      state('openBottom', style({height: '100%'})),

      transition('closedTop => openTop', [
        style({overflow: 'hidden'}),
        animate('300ms 100ms linear', style({height: '*'}))
      ]),

      transition('openTop => closedTop', [
        style({overflow: 'hidden'}),
        animate('200ms linear', style({height: '150px'}))
      ]),

      transition('closedBottom => openBottom', [
        animate('300ms 100ms linear', style({height: '100%'}))
      ]),

      transition('openBottom => closedBottom', [
        animate('200ms linear', style({height: 'calc(100vh - 250px)'}))
      ])
    ]),
    trigger('showContentMenu', [
      state('collapseMenu', style({left: '-80px'})),
      state('expandMenu', style({left: 0})),
      state('pullContent', style({left: 0})),
      state('pushContent', style({left: '80px'})),

      transition('collapseMenu => expandMenu', [
        animate('800ms ease', style({left: 0}))
      ]),

      transition('expandMenu => collapseMenu', [
        animate('800ms ease', style({left: '-80px'}))
      ]),

      transition('pullContent => pushContent', [
        animate('800ms ease', style({left: '80px'}))
      ]),

      transition('pushContent => pullContent', [
        animate('800ms ease', style({left: 0}))
      ]),
    ])
  ]
})
export class ProcedureComponent implements OnInit, OnDestroy {

  constructor(private readonly primusRoute: PrimusRouteService,
              private readonly primusRouter: PrimusRouterService,
              public readonly currentObjectService: CurrentObjectService,
              private readonly searchSelectorService: SearchSelectorService,
              private readonly mediaHelper: MediaHelperService,
              private readonly uiTools: UiToolsService,
              private readonly operationService: OperationService,
              private readonly contentMenusService: ContentMenusService,
              private readonly operationDialogService: OperationDialogService,
              private readonly cms: CmsApiService,
              private readonly translateService: TranslateService) {
  }

  showAllFieldInformation = false;
  loadingProcedureInfo: boolean;
  noProcedureId = false;
  artifactId: string;
  templateSelectorContainer = new TemplateSelectorContainer();
  smallScreen: boolean;
  openMenu = false;
  showImageOrder = false;
  loadFailed = false;
  operationContainer: OperationContainer;
  currentProcedureStep: SpectrumProcedureStep;
  procedureSteps: SpectrumProcedureStep[];
  currentProcedureContentViewName = 'steps';

  private templateGroupId;
  private stopSizeWatch;
  private routerSubscription: Subscription;

  ngOnInit() {
    this.routerSubscription = this.primusRouter.navigationHandler(() => {
      this.init().then();
    });
    this.init().then();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  onStepSelected(step: SpectrumProcedureStep) {
    this.currentProcedureStep = step;
    this.operationContainer.contentInfo.setContentListSource(step.contentListSource);
  }

  onSelectView(viewName: string) {
    this.currentProcedureContentViewName = viewName;
    const ci = this.operationContainer.contentInfo;
    if (viewName === 'steps') {
      ci.setContentListSource(this.currentProcedureStep.contentListSource);
    } else {
      ci.setContentListSource(ci.sources['spectrum_procedure_object']);
    }
  }

  onTemplateSelectorCreated(templateSelectorContainer) {
    this.templateSelectorContainer = templateSelectorContainer;
  }

  async onChangeTemplateGroup(newGroupId) {
    await this.primusRouter.navigateState(this.primusRouter.currentState(),
      {template_group_id: newGroupId},
      {location: 'replace', reload: true});
  }

  async onSelectOperation(operation: OperationDef) {
    this.operationService.setSelectedOperation(this.operationContainer, operation);
    this.operationService.setCurrentOperation(this.operationContainer, operation);

    if (this.smallScreen) {
      this.onToggleMenu();
    }
    this.setCurrentList(this.operationContainer.currentOperation.content_list);
    if (this.operationContainer.contentInfo.curListName === 'spectrum_procedure_object') {
      await this.setProcedureSteps();
    }
  }

  onToggleMenu() {
    this.openMenu = !this.openMenu;
  }

  onToggleChangeImageOrder(/*images?*/) {
    this.showImageOrder = !this.showImageOrder;
  }

  toggleFieldInformation() {
    this.showAllFieldInformation = !this.showAllFieldInformation;
  }

  async loadProcedure() {
    this.loadingProcedureInfo = true;
    if (this.artifactId) {
      try {
        this.operationContainer = await this.operationService.createOperationAndRootSectionsContainer(
          this.getCreateParams());
        await this.postLoadOperations();
      } catch (reason) {
        this.loadFailed = true;
        console.error('Failed loading object: ' + reason.error?.message || reason.message);
      }
    } else {
      this.loadingProcedureInfo = false;
      this.noProcedureId = true;
    }
  }

  selectorOverlay() {
    if (this.templateSelectorContainer?.selectorEnabled) {
      this.searchSelectorService.disableSelector(this.templateSelectorContainer);
    } else {
      this.disableSelector();
    }
  }

  get contentListHeadline() {
    let res = '';
    if (this.operationContainer.contentInfo.curContentListSource) {
      res = this.operationContainer.contentInfo.curContentListSource.caption;
      if (res.startsWith('TRANS__')) {
        res = this.translateService.instant(res);
      }
    }
    return res;
  }

  private async init() {
    this.currentObjectService.isEditing = this.primusRoute.params.edit === 'true';
    this.artifactId = this.primusRoute.params.artifactId;
    this.templateGroupId = this.primusRoute.params.template_group_id;
    const windowSize = this.uiTools.windowSize;
    this.smallScreen = windowSize.width < 800;
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        this.smallScreen = newVal.width < 800;
      }
    );
    await this.loadProcedure();
  }

  private getCreateParams(): OperationAndSectionsContainerParams {
    const params = {
      useExistingObject: true,
      objectId: this.artifactId,
      getSourceObject: !this.currentObjectService.isEditing,
      operationTarget: OperationTarget.PROCEDURE_VIEW,
      templateGroupId: this.templateGroupId
    } as OperationAndSectionsContainerParams;
    if (this.currentObjectService.isEditing) {
      params.executorType = OperationExecutorType.ARTIFACT;
      params.operationStepType = OperationStepType.EDIT_OBJECT;
    }
    return params;
  }

  private setSelectorUsed(selector: Selector) {
    const contentListSources: ContentListSourceContainer[] = [];
    if (this.procedureSteps) {
      this.procedureSteps.forEach(procedureStep => {
        if (procedureStep.artifact_id !== this.currentProcedureStep.artifact_id) {
          contentListSources.push(procedureStep.contentListSource);
        }
      });
    }
    this.operationContainer.contentInfo.setSelectorUsed(selector, contentListSources);
  }

  private enableSelector(selector: Selector, mc: SelectorContainer, params?: SelectorCreationParams) {
    this.setSelectorUsed(selector);
    this.searchSelectorService.enableSelector(selector, mc, params,
      {
        searchContainerCreated: () => {
        },
        selectorCallback: (selectedObj: any) => {
          this.searchSelectorService.disableSelector(mc);
          mc.selectorCallback(selectedObj, selector, () => {
            this.loadProcedure().then();
          });
        }
      }
    );
  }

  private disableSelector() {
    this.searchSelectorService.disableSelector(<SelectorContainer>this.operationContainer);
  }

  private async postLoadOperations() {
    const sectionsContainer = this.operationContainer.rootSectionsContainer;
    let listName = this.primusRoute.params.listName;
    await this.setOperationContainer();
    this.currentObjectService.currentObject = sectionsContainer.rootObject;
    this.operationContainer.contentInfo.updateContentInfo(
      this.currentObjectService.isEditing, sectionsContainer.rootObject, listName);
    if (this.operationContainer.contentInfo.curListName) {
      listName = this.operationContainer.contentInfo.curListName;
    }
    this.operationContainer.contentInfo.templateGroupId = this.templateGroupId;
    if (!this.operationContainer.contentInfo.curListName) {
      this.operationContainer.contentInfo.curListName = listName;
    }
    await this.getMedia();

    this.operationContainer.contentInfo.setContentMenus = () => {
      this.setContentMenus();
    };
    await this.setContentMenus();
    for (const element of this.operationContainer.operations) {
      const operation = element;
      if (operation.content_list !== 'overview' && operation.content_list === this.operationContainer.contentInfo.curListName) {
        await this.onSelectOperation(operation);
        break;
      }
    }
    this.loadingProcedureInfo = false;
  }

  private async setProcedureSteps() {
    const setting = await this.cms.getSpectrumProcedureSetting({
      spectrum_procedure_id: this.operationContainer.rootSectionsContainer.rootObject.superobject_type_id
    });
    this.procedureSteps = setting.procedure_steps;
    const procedureSource = this.operationContainer.contentInfo.sources['spectrum_procedure_object'];
    this.procedureSteps.forEach((step, index) => {
      step.contentListSource = procedureSource.subMenus[index];
    });

    if (this.procedureSteps?.length) {
      this.onStepSelected(this.procedureSteps[0]);
      if (this.primusRoute.params.listName === 'spectrum_procedure_object') {
        if (this.primusRoute.params.stepName) {
          this.procedureSteps.forEach(procedureStep => {
            if (procedureStep.procedure_object_status_id_value === this.primusRoute.params.stepName) {
              this.onStepSelected(procedureStep);
            }
          });
        } else {
          await this.primusRouter.navigateState(this.primusRouter.currentState(),
            {stepName: this.currentProcedureStep.procedure_object_status_id_value},
            {location: 'replace', reload: false});
        }
      }
    }
  }

  private async setOperationContainer() {
    this.operationContainer.art = this.operationContainer.rootSectionsContainer.rootObject;
    this.operationContainer.contentInfo = new ContentInfo();
    this.operationContainer.openOperationDialogFn = () => {
      this.operationContainer.currentOperation.$$showOperationView = true;
      this.operationDialogService.openOperationDialog(this.operationContainer).then(() => {
        this.loadProcedure().then();
      });
    };
    this.operationContainer.enableSelector = (selector, params) => {
      this.enableSelector(selector, this.operationContainer, params);
    };
    this.operationContainer.disableSelector = () => {
      this.disableSelector();
    };
  }

  private async setContentMenus() {
    const contentInfo = this.operationContainer.contentInfo;
    contentInfo.setCurrentList = (listName: string) => {
      this.setCurrentList(listName);
    };
    if (!contentInfo.menus) {
      contentInfo.menus = await this.contentMenusService.getMenus(contentInfo);
    }
    await this.contentMenusService.setVerticalContentMenus(contentInfo.menus, contentInfo);
  }

  private setCurrentList(listName) {
    const ci = this.operationContainer.contentInfo;
    ci.curListName = listName;
    ci.curListContainer = null;
    ci.setContentListSource(null);
    if (ci.menus) {
      this.contentMenusService.setActiveVerticalMenu(ci.menus, ci);
    }
    if (ci.contentListContainers[listName]) {
      this.contentMenusService.runListSearch(ci.curListName, ci).then();
    } else if (ci.sources?.[listName]) {
      ci.setContentListSource(ci.sources[listName]);
    }
  }

  private async getMedia() {
    const object = this.operationContainer.rootSectionsContainer.rootObject;
    object.$$mediaContainer = await this.mediaHelper.getMediaContainerForAllObjectMedia(object);
  }

}
