import {Component, OnInit} from "@angular/core";
import {CmsApiService} from "../../../core/cms-api.service";
import {ModelStore} from "../../../core/ModelStore/ModelStore";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {UrlQueryParam} from "../../../core/ModelStore/UrlQueryParam";

@Component({
  selector: 'report-templates',
  templateUrl: './report-templates.component.html',
  styleUrls: ['./report-templates.component.scss']
})
export class ReportTemplatesComponent implements OnInit {
  reportTemplates: any;
  selectedTemplates: string[] = [];

  constructor(private readonly cms: CmsApiService,
              private modelStore: ModelStore) {
    this.deleteTemplates = this.deleteTemplates.bind(this);
  }

  async ngOnInit(): Promise<void> {
    this.reportTemplates = this.modelStore.findAllModels('report_user_template')
    await this.reportTemplates.loading;

    let reportViewTypes = this.modelStore.findAllModels('report_view_type');
    await reportViewTypes.loading;

    if (this.reportTemplates.length > 0) {
      this.reportTemplates.forEach(async template => {
        if (template.reportViewTypeId) {
          template.reportViewType = await this.modelStore.findModel('report_view_type', template.reportViewTypeId);
        }
      });
    }


  }

  async deleteTemplates() {
    if (this.selectedTemplates.length > 0) {
      for (const reportId of this.selectedTemplates) {
        let deletedModel =  this.modelStore.deleteModel('report_user_template', reportId);
        await deletedModel.loading;
      }

      this.reportTemplates = this.modelStore.findAllModels('report_user_template')
    }
    console.log('DELETE TEMPLATES: ', this.selectedTemplates);
  }

  templateSelectionChanged(event: MatCheckboxChange, templateId : string) {
    if (event.checked && !this.selectedTemplates.includes(templateId)) {
      this.selectedTemplates.push(templateId);
    } else if (!event.checked && this.selectedTemplates.includes(templateId)) {
      this.selectedTemplates.splice(this.selectedTemplates.indexOf(templateId), 1);
    }
  }
}
