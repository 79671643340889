import {Component, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime, filter, map, tap} from 'rxjs/operators';
import {InputComponent} from '../input.component';
import {QueryField} from '../../../../../core/definitions/extended-search/query-field';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnChanges {

  constructor() {
    this.control = new UntypedFormControl(null);
    this.searchValueChanged =  this.control.valueChanges.pipe(
      filter(() => !!this.field),
      debounceTime(InputComponent.COMMON_DEBOUNCE_TIME),
      tap(val => this.field.inputOptions.additionalData = {value: val}),
      map(val => TextInputComponent.escapeSpecialChars(String(val || '')).trim()),
      map(val => `(*${val.replace(/\s/g, '* *')}*)`)
    );
  }

  @Input() field: QueryField;
  @Input() label: string;
  @Input() placeholder: string;

  @Output() public readonly searchValueChanged: Observable<string>;

  readonly control: UntypedFormControl;

  /**
   * Escapes all character that are reserved by Solr
   * @param {string} value
   * @return {string}
   */
  private static escapeSpecialChars(value: string): string {
    return value.replace(/([+\-&|!(){}\[\]^"~*?:\/])/g, '\\$1');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('field')) {
      this.field = changes.field.currentValue;
      const value = this.field?.inputOptions?.additionalData?.value || '';
      this.control.reset(value, {emitEvent: false});
    }
  }
}
