import {Injectable} from '@angular/core';
import {CmsApiService} from "../core/cms-api.service";
import {
  CheckFilterGroup,
  HierarchicFilterGroup, RangeGroup,
  SearchView,
  SearchViewFilters
} from "../core/definitions/search-objects";
import {SearchContainer} from "../core/definitions/search-container";
import {LoggerService} from "../core/logger.service";
import {SuperObjectModel} from "../core/definitions/super-object-model";
import {GetArtifactParams} from "../core/definitions/get-artifact-params";
import {CmsQueueService} from "../core/cms-queue.service";

@Injectable({
  providedIn: 'root'
})
export class SearchViewFilterService {

  constructor(
    private cms: CmsApiService,
    private cmsQueue: CmsQueueService,
    private logger: LoggerService) {
  }

  private searchViewFilters: SearchViewFilters;

  async setSearchViewFilters() {
    if (!this.searchViewFilters) {
      this.logger.info('Getting search view filters');
      this.searchViewFilters = await this.cms.getSearchViewFilters();
    }
  }

  getSearchViewFilters(): SearchViewFilters {
    if (!this.searchViewFilters) {
      this.logger.warn('Search view filters not set!')
      this.setSearchViewFilters().then();
    }
    return this.searchViewFilters;
  }

  setCheckFilterGroupsFromSearchView(searchContainer: SearchContainer) {
    console.log('Getting/setting search filter groups on search container')
    searchContainer.filtersFacets.filterGroups = this.getCheckFilterGroupsFromSearchView(
      searchContainer.currentPathView.search_view);
  }

  getCheckFilterGroupsFromSearchView(searchView: SearchView): CheckFilterGroup[] {
    let res: CheckFilterGroup[] = [];
    if (searchView?.check_filter_groups) {
      const checkFilterGroupNames = searchView.check_filter_groups;
      if (typeof checkFilterGroupNames[0] === "string") {
        res = [];
        const filters = this.getSearchViewFilters();
        for (const groupName of checkFilterGroupNames) {
          res.push(filters.check_filter_groups[groupName]);
        }
      } else {
        // This is the old definition, in which check_filter_groups contained filter group objects instead of names
        const groups = <unknown>checkFilterGroupNames;
        res = JSON.parse(JSON.stringify(<CheckFilterGroup[]>groups));
      }
    }
    return res;
  }

  getHierarchicFilterGroupFromSearchView(searchView: SearchView): HierarchicFilterGroup {
    const groupName = searchView.hierarchic_filter_group;
    if (!groupName) {
      return null;
    }
    if (typeof groupName === 'string') {
      const searchViewFilters = this.getSearchViewFilters();
      return searchViewFilters.hierarchic_filter_groups[groupName];
    } else {
      // Old version
      const group = <unknown>groupName;
      return <HierarchicFilterGroup>group;
    }
  }

  getFacetRangeGroupsFromSearchView(searchView: SearchView): RangeGroup[] {
    const filters = this.getSearchViewFilters();
    let res: RangeGroup[] = [];
    const facetRangeGroupNames = searchView.facet_range_groups;
    if (facetRangeGroupNames?.length) {
      if (typeof facetRangeGroupNames[0] === 'string') {
        for (const facetGroupName of facetRangeGroupNames || []) {
          res.push(filters.facet_range_groups[facetGroupName]);
        }
      } else {
        // Old version
        const groups = <unknown>facetRangeGroupNames;
        return <RangeGroup[]>groups;
      }
    }
    return res;
  }

  async loadSetTargetObject(searchContainer: SearchContainer): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const targetId = searchContainer.targetId;
      if (targetId && targetId !== 'none' && targetId !== searchContainer.targetObject?.artifact_id) {
        const params = new GetArtifactParams();
        params.artifact_id = searchContainer.targetId;
        this.cmsQueue.runCmsFnWithQueue(this.cms.getArtifact, params, true,
          (dta: SuperObjectModel) => {
            searchContainer.targetObject = dta;
            resolve();
          },
          (e: any) => {
            this.logger.error('Error loading target object', e);
            reject(e);
          }
        ).then();
        console.log('searchContainer.targetObject', searchContainer.targetObject?.artifact_id);
      } else {
        resolve();
      }
    })
  }

}
