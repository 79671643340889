import {Injectable} from '@angular/core';
import {FieldService} from './field.service';
import {SearchParameters} from '../../../core/definitions/search-parameters';
import {Operand} from '../../../core/definitions/extended-search/operand';
import {QueryGroup} from '../../../core/definitions/extended-search/query-group';
import {SearchableField} from '../../../core/definitions/extended-search/searchable-field';
import {QueryField} from '../../../core/definitions/extended-search/query-field';
import {InputFieldType} from '../../../core/definitions/extended-search/input-options';
import {MetaTypes} from '../../../core/definitions/meta-types';

/**
 * Denotes whether or not the search result should include deleted documents
 */
export type DeletedState = 'DELETED' | 'NOT_DELETED' | 'ALL';

/**
 * Service responsible for all logic concerning building an AdvancedSearchQuery
 */
@Injectable({
  providedIn: 'root'
})
export class QueryService {

  /**
   * constructor
   */
  constructor(private readonly fieldService: FieldService) {
    this.selectedQueryGroup = null;
    this.freeTextFields = [];
    this.currentQuery = [];
    this.deletedState = 'NOT_DELETED';
    this.restoreStoredQuery();
  }

  /**
   * The default operand to use when no operand has been selected
   * @type {Operand}
   * @private
   */
  public static readonly DEFAULT_OPERAND: Operand = 'AND';

  /**
   * Defines the available operands (and the order) the user can use between fields or groups of fields in an advanced search query.
   * @type {Array<{operand: Operand, label: string}>}
   * @private
   */
  public static readonly AVAILABLE_OPERANDS: Array<{ operand: Operand, label: string }> = [
    {
      operand: 'AND',
      label: 'TRANS__ADVANCED_SEARCH__PREDICATE__AND'
    },
    {
      operand: 'OR',
      label: 'TRANS__ADVANCED_SEARCH__PREDICATE__OR'
    },
    {
      operand: 'AND_NOT',
      label: 'TRANS__ADVANCED_SEARCH__PREDICATE__AND_NOT'
    },
    {
      operand: 'OR_NOT',
      label: 'TRANS__ADVANCED_SEARCH__PREDICATE__OR_NOT'
    },
  ];

  /**
   * The current query being built
   * @type {Array<QueryGroup>}
   * @private
   */
  private currentQuery: Array<QueryGroup>;

  /**
   * The currently selected query-group to add fields to.
   * Only used on mobile device when drag/drop is disabled.
   * @type {QueryGroup | null}
   * @private
   */
  private selectedQueryGroup: QueryGroup | null;

  /**
   * A list of all field-ids the user has
   * selected as free-search-fields
   * @type {{[p: string]: boolean}}
   * @private
   */
  private freeTextFields: Array<string>;

  /**
   * Whether or not to include deleted elements
   * @type {DeletedState}
   * @private
   */
  private deletedState: DeletedState;

  /**
   * A list of Superobject Type-ids to filter the result-set
   * @type {Array<string>}
   * @private
   */
  private allowedArtifactTypesInResult: Array<string>;

  /**
   * Parses the provided operand into Solr-syntax
   * @param {Operand} operand
   * @return {string}
   * @private
   */
  private static parseOperand(operand: Operand): string {
    switch (operand) {
      case 'AND': return ` AND `;
      case 'OR': return ` OR `;
      case 'AND_NOT': return ` AND -`;
      case 'OR_NOT': return ` OR -`;
    }
  }

  /**
   * Removes the stored query from session-storage
   * @private
   */
  private static clearStoredQuery(): void {
    window.sessionStorage.removeItem('primus.advanced-search.stored-query');
    window.sessionStorage.removeItem('primus.advanced-search.free-text-fields');
    window.sessionStorage.removeItem('primus.advanced-search.deleted-state');
    window.sessionStorage.removeItem('primus.advanced-search.result-artifact-type-ids');
  }

  /**
   * Update the deleted-state
   * @param {DeletedState} deletedState
   */
  public setDeletedState(deletedState?: DeletedState): void {
    this.deletedState = deletedState || 'NOT_DELETED';
  }

  /**
   * Get the currently set deleted-state
   * @return {DeletedState}
   */
  public getDeletedState(): DeletedState {
    return this.deletedState;
  }

  /**
   * Set the IDs of all artifact-types that should be allowed in the resulting dataset.
   * @param {Array<string>} types
   */
  public setAllowedArtifactTypes(types?: Array<string>): void {
    this.allowedArtifactTypesInResult = types || [];
  }

  /**
   * Get the IDs of all the allow artifact-types
   * @return {Array<string>}
   */
  public getAllowedArtifactTypes(): Array<string> {
    return this.allowedArtifactTypesInResult || [];
  }

  /**
   * Resets the query to an initial state.
   * Allowing a new query to be built.
   */
  public clearQuery(): void {
    this.restoreStoredQuery();
  }

  /**
   * Appends a new (default) group to the query
   */
  public addQueryGroup(): void {
    this.currentQuery.push({
      operand: QueryService.DEFAULT_OPERAND,
      queryFields: []
    });
  }

  /**
   * Lets the user select a group to add fields to.
   * Only applicable on mobile devices
   * @param {QueryGroup | null} group
   */
  public selectQueryGroup(group: QueryGroup | null): void {
    this.selectedQueryGroup = group && this.currentQuery.includes(group) ? group : null;
  }

  /**
   * Add a new (default) field to the given QueryGroup.
   * @param {QueryGroup} group The group to add the field to. Required
   * @param {SearchableField} field The field to add to the group
   * @return {QueryField | null} The added field, or null if the group or indexField is missing.
   */
  public addQueryField(group: QueryGroup, field: SearchableField): QueryField | null {
    if (field && this.currentQuery.includes(group)) {
      const addedField: QueryField = {
        fieldId: field.fieldId,
        operand: QueryService.DEFAULT_OPERAND,
        indexField: field.indexName,
        label: field.title.name || '',
        secondaryLabel: field.title.alternateName || '',
        searchValue: '',
        inputOptions: this.fieldService.getInputOptionsForField(field),
      };
      group.queryFields.push(addedField);
      return addedField;
    }
    return null;
  }

  /**
   * Calls 'addQueryField' with the selected group, if set.
   * Call 'selectQueryGroup' before this.
   * @see addQueryField
   * @see selectQueryGroup
   * @param {SearchableField} field
   * @return {QueryField | null}
   */
  public addQueryFieldToSelectedGroup(field: SearchableField): QueryField | null {
    if (this.selectedQueryGroup !== null) {
      return this.addQueryField(this.selectedQueryGroup, field);
    }
    return null;
  }

  /**
   * Removes a QueryGroup and all the fields associated with it
   * @param {QueryGroup} group The group to remove
   */
  public removeGroup(group: QueryGroup): void {
    const idx = this.currentQuery.indexOf(group);
    if (idx >= 0) {
      this.currentQuery.splice(idx, 1);
    }
  }

  /**
   * Removes a QueryField from the given QueryGroup.
   * @param {QueryGroup} group The group to remove the field from
   * @param {QueryField} field The field to remove
   */
  public removeField(group: QueryGroup, field: QueryField): void {
    if (group && this.currentQuery.includes(group) && group.queryFields.includes(field)) {
      const idx = group.queryFields.indexOf(field);
      if (idx >= 0) {
        group.queryFields.splice(idx, 1);
      }
    }
  }

  /**
   * Changes the operand of either a QueryGroup or QueryField.
   * @param {QueryGroup | QueryField} groupOrField The group or field to change the operand of.
   * @param {Operand} operand The operand to set. If not provided, the default will be used.
   */
  public changeOperand(groupOrField: QueryGroup | QueryField, operand?: Operand): void {
    if (groupOrField) {
      groupOrField.operand = operand || QueryService.DEFAULT_OPERAND;
    }
  }

  /**
   * Returns true if the given QueryGroup is a part of the current query.
   * @param {QueryGroup} group
   * @return {boolean}
   */
  public hasGroup(group: QueryGroup): boolean {
    return this.currentQuery.includes(group);
  }

  /**
   * Returns the current query being build
   * @return {Array<QueryGroup>}
   */
  public getCurrentQuery(): Array<QueryGroup> {
    return this.currentQuery || [];
  }

  /**
   * Returns a list of operands that can be selected and used for both QueryGroups and QueryFields,
   * along with a human-readable label for the operand.
   * @return {Array<{operand: Operand, label: string}>}
   */
  public getAvailableOperands() {
    return QueryService.AVAILABLE_OPERANDS;
  }

  /**
   * Toggles "free-text-mode" for a field in a query
   * @param {QueryField} field
   */
  public toggleFreeTextMode(field: QueryField): void {
    const idx = this.freeTextFields.indexOf(field.indexField);
    if (idx >= 0) {
      this.freeTextFields.splice(idx, 1);
    } else {
      this.freeTextFields.push(field.indexField);
    }
  }

  /**
   * Returns whether or not to search by free-text in a query-field
   * @param {QueryField} field
   * @return {boolean}
   */
  public isFreeTextMode(field: QueryField): boolean {
    return this.freeTextFields.includes(field?.indexField);
  }

  /**
   * Whether or not a field is allowed to use free-text-mode
   * @param {QueryField} field
   * @return {boolean}
   */
  public canUseFreeTextSearch(field: QueryField): boolean {
    const allowedInputTypes: Array<InputFieldType> = [
      'NUMBER',
      'SELECT',
      'SELECT_MULTIPLE',
      'UNSUPPORTED',
    ];
    return allowedInputTypes.includes(field.inputOptions.inputFieldType);
  }

  /**
   * Parses the current query into a complete SearchParameters-object
   * that can be used directly against solr.
   * (Sets both query and fq)
   * @return {SearchParameters} The parsed query and filterQuery
   */
  public buildQuery(): SearchParameters {
    const fq = this.buildFilterQuery();
    const query = this.currentQuery
      .filter(g => g.queryFields?.length > 0)
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .reduce((query, group, groupIdx) => {
        let groupQuery = '(';

        if (groupIdx > 0) {
          groupQuery = `${query}${QueryService.parseOperand(group.operand)}(`;
        }

        groupQuery += group.queryFields
          .reduce((subQuery, field, fieldIdx) => {
            let searchValue = `(${field.indexField}:${field.searchValue || '*'})`;

            if (this.isFreeTextMode(field)) {
              if (field.indexField.endsWith('_id')) {
                searchValue = `(${field.indexField}_value:${field.searchValue || '*'})`;
              } else {
                  searchValue = `${field.indexField}:${field.searchValue || '*'}`;
              }
            }

            if (fieldIdx > 0) {
              return `${subQuery}${QueryService.parseOperand(field.operand)}(${searchValue})`;
            } else {
              return searchValue;
            }
          }, '');

        return groupQuery + ')';
      }, '');

    this.storeQuery();
    return {
      query: query,
      fq: [fq]
    } as SearchParameters;
  }

  /**
   * Parses the filter-query-string (fq) for the current query.
   * @return {string}
   * @private
   */
  private buildFilterQuery(): string {
    const deletedQ = this.parseDeletedStateQuery();

    const allowedMetaTypes = [MetaTypes.ARTIFACT, MetaTypes.CONSTRUCTION, MetaTypes.NAMED, MetaTypes.PLACE];
    const metaTypeQ = `meta_type:("${allowedMetaTypes.join('","')}")`;

    let fq = `${deletedQ} AND ${metaTypeQ}`;
    if (this.allowedArtifactTypesInResult?.length > 0) {
      fq += ` AND superobject_type_id:("${this.allowedArtifactTypesInResult.join('","')}")`;
    }
    return fq;
  }

  /**
   * Parses the deletedState-query for the current query
   * @return {string}
   * @private
   */
  private parseDeletedStateQuery(): string {
    switch (this.deletedState) {
      case 'ALL': return '';
      case 'DELETED': return 'valid:false';
      case 'NOT_DELETED':
      default:
        return '-valid:false';
    }
  }

  /**
   * Stores the current query in session-storage so that the search is remembered
   * @private
   */
  private storeQuery(): void {
    window.sessionStorage.setItem(
      'primus.advanced-search.stored-query',
      JSON.stringify(this.currentQuery || [])
    );
    window.sessionStorage.setItem(
      'primus.advanced-search.free-text-fields',
      JSON.stringify(this.freeTextFields || [])
    );
    window.sessionStorage.setItem(
      'primus.advanced-search.deleted-state',
      this.deletedState
    );
    window.sessionStorage.setItem(
      'primus.advanced-search.result-artifact-type-ids',
      JSON.stringify(this.allowedArtifactTypesInResult)
    );
  }

  /**
   * Restores the service to a persisted state
   * @private
   */
  private restoreStoredQuery(): void {
    try {
      const deletedState: string | null = window.sessionStorage.getItem('primus.advanced-search.deleted-state');
      const resultArtifactTypes: string | null = window.sessionStorage.getItem('primus.advanced-search.result-artifact-type-ids');
      const query: string | null = window.sessionStorage.getItem('primus.advanced-search.stored-query');
      const fields: string | null = window.sessionStorage.getItem('primus.advanced-search.free-text-fields');
      this.deletedState = (deletedState || 'NOT_DELETED') as DeletedState;
      this.allowedArtifactTypesInResult = resultArtifactTypes ? JSON.parse(resultArtifactTypes) : [];
      this.currentQuery = !!query ? JSON.parse(query) : [];
      this.freeTextFields = !!fields ? JSON.parse(fields) : [];
      if (this.currentQuery.length === 0) {
        this.addQueryGroup();
      }
    } catch (e) {
      console.warn('Error when restoring advanced-search-query', e);
      QueryService.clearStoredQuery();
      this.freeTextFields = [];
      this.currentQuery = [];
      this.deletedState = 'NOT_DELETED';
      this.allowedArtifactTypesInResult = [];
      this.addQueryGroup();
    }
  }
}
