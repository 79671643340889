<div class="search-view">
  <mat-drawer-container class="search-view__drawer-container" [hasBackdrop]="false">
    <mat-drawer
      class="search-view__drawer"
      [mode]="isSmallScreen() ? 'over' : 'side'"
      [opened]="filterDrawerOpen"
      [disableClose]="true"
      (keydown.escape)="handleEscapeKey($event)"
    >
      <app-search-filter-menu-v2
        (closeDrawer)="filterDrawerOpen = false"
        [searchContainer]="searchContainer"
      />
    </mat-drawer>

    <mat-drawer-content id="searchViewDrawerContent" class="search-view__drawer-content" [ngClass]="{ 'search-view__drawer-content--drawer-closed' : !filterDrawerOpen }">
      <div class="search-view__header-row">
        @if (!filterDrawerOpen) {
          <button mat-button (click)="filterDrawerOpen = true">
            <mat-icon>
              menu
            </mat-icon>

            {{ 'TRANS__SEARCH_VIEW__SHOW_FILTERS' | translate }}
          </button>
        }

        <app-search-header
          class="search-view__search-header"
          [searchContainer]="searchContainer"
        />
      </div>

      @if (searchContainer.queryContainer.selectedQueryMenu?.query_type === 'advanced') {
        <app-extended-search-query-builder
          [searchContainer]="searchContainer"
        />
      }

      <app-search-filter-chiplist
        (filterRemoved)="handleRemovedFilter()"
        [searchContainer]="searchContainer"
      />

      <app-search-hierarchic-bread-crumbs
        [searchContainer]="searchContainer"
        [targetId]="searchContainer.targetId"
      />

      <div #firstSibling class="search-view__menus-container">
        <app-search-menus
          [searchContainer]="searchContainer"
        />
      </div>

      @if (!isFolder) {
        <app-search-view-full-header
          [searchContainer]="searchContainer"
          [listFieldTemplateId]="searchContainer.listFieldTemplateId"
        />
      }

      @if (isFolder && searchContainer.targetObject) {
        <app-search-view-folder-header
          [searchContainer]="searchContainer"
          [folderId]="searchContainer.targetObject.artifact_id"
        />
      }

      <div #secondSibling (scroll)="onScroll()" class="search-view__result-container">
        @if (loading()) {
          <div class="result-container__loading">
            <mat-spinner
              color="accent"
              diameter="60"
            />
            <span>
              {{ 'TRANS__SEARCH__LOADING_INFO' | translate }}
            </span>
          </div>
        }
        @else {

            <app-search-result-view-v2
              [searchContainer]="searchContainer"
            />

        }
      </div>
      <app-search-view-pagination
          [searchContainer]="searchContainer"
        />
    </mat-drawer-content>
  </mat-drawer-container>
</div>
