import {SelectorContainer} from './selector-container';
import {SectionsContainer} from './sections-container';
import {SuperObjectModel} from './super-object-model';
import {OperationDef} from './operation-def';
import {ContentInfo} from './content-info';
import {SearchContainer} from './search-container';

export class OperationContainer implements SelectorContainer {
  // Operation target is either 'object_view' or 'search_view'
  constructor(operationTarget: string) {
    this.operationTarget = operationTarget;
  }
  rootSectionsContainer: SectionsContainer;

  operationTarget: string;
  operationContextObjects: SuperObjectModel[];
  operations: OperationDef[] = [];
  currentOperation: OperationDef;
  currentSelectedGroupOperation: OperationDef;
  viewName: string;
  contentInfo: ContentInfo = new ContentInfo();
  inModal = false;
  enableSelector: Function;
  disableSelector: Function;
  selectorEnabled = false;

  art: SuperObjectModel;
  getTargetId: Function;
  searchContainer: SearchContainer;
  selectorCallback: Function;
  templateGroupId: string;
  listFieldTemplateId: string;
  openOperationDialogFn: Function;
  refreshFn: Function;
  lockObjectFn: Function;

  /**
   * hack: avoid any routing change to happen in a service, but instead let the component handle it via event emissions
   */
  $$onlyEmitStateChange: boolean;

  get currentMenu() {
    return this.currentOperation;
  }

  cancelOperation() {
    console.warn('Cancel operation not implemented');
  }
}
