<mat-expansion-panel class="job-item__expansion-panel mat-elevation-z0">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="job-item__header">
        <div class="job-item__header-title-container">
          <div>
            <span class="job-item__header-title">
              @if (message.result_object?.task_name_override) {
                {{message.result_object.task_name_override}}
              } @else {
                {{ message.task_name | translate }}
              }
            </span>

            <span>
              {{ message.fileTypeName }}
            </span>
          </div>

          <div>
            <span class="job-item__header-subtitle">
              {{ message.taskMetaData }}
            </span>
          </div>
        </div>

        <div class="job-item__header-status">
          @switch (message.statusType) {
            @case ('active') {
              <mat-spinner
                diameter="20"
              />
            }
            @case ('failed') {
              <mat-icon class="job-item__icon job-item__icon--failed">
                close
              </mat-icon>
            }
            @case ('finished') {
              <mat-icon class="job-item__icon job-item__icon--finished">
                done
              </mat-icon>
            }
          }

          @if (message.statusType === 'failed') {
            <a>
              <span>{{ 'TRANS__JOB_STATUS__FAILED' | translate }}</span>
            </a>
          } @else if (message.statusMessage === 'step complete') {
            <span>
              {{ 'TRANS__OPERATION__' + message.statusMessage.replaceAll(' ', '_').toUpperCase() | translate }}
            </span>
          } @else {
            <span>
              {{ message.statusMessage }}
            </span>
          }
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div>
    <div>
      <span class="job-item__body-id">
        ID: {{ message.queue_id }}
      </span>
    </div>

    @if (message.task_type === 'reports' && (message.status === 'finished' || message.status === 'downloaded')) {
      <button mat-stroked-button (click)="downloadReport(message.queue_id, message.filetype)">
        <span>
          {{ 'TRANS__JOB_STATUS__DOWNLOAD' | translate }}
        </span>
      </button>
    } @else if (message.progress === 100 && message.result_object?.report_id && message.result_object?.dms_id) {
      <button mat-stroked-button (click)="downloadReportV2(message.result_object.report_id, message.result_object.dms_id)">
        <span>
          {{ 'TRANS__JOB_STATUS__DOWNLOAD' | translate }}
        </span>
      </button>
    } @else if (message.ref) {
      <a
        mat-stroked-button
        color="primary"
        queryParamsHandling="merge"
        [routerLink]="primusStateMapperService.routerLinkFromState(message.ref)"
        [queryParams]="primusStateMapperService.queryParams(message.refParams)"
      >
        <span>{{ 'TRANS__JOB_STATUS__OPEN' | translate }}</span>
      </a>
    } @else if (message.status === 'failed' && message.error_message?.startsWith('TRANS__')) {
        <span>{{ message.error_message | translate }}</span>
    }
  </div>
</mat-expansion-panel>
