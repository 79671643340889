import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {SearchableField} from '../../../../core/definitions/extended-search/searchable-field';

@Component({
  selector: 'app-field-filter',
  templateUrl: './field-filter.component.html',
  styleUrls: [
    './field-filter.component.scss',
    './field-filter.component.tablet.scss',
    './field-filter.component.mobile.scss',
  ]
})
export class FieldFilterComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * References to all subscriptions used in this component.
   * Used to properly close all open subscriptions when the component is destroyed.
   * (Prevents memory-leaks)
   * @type {Array<Subscription>}
   * @private
   */
  private readonly subs: Array<Subscription>;

  /**
   * A list of all available fields
   * @type {Array<SearchableField>}
   */
  @Input() public readonly fields: Array<SearchableField>;
  /**
   * A text used as both label and placeholder for the search-field
   * @type {string}
   */
  @Input() public readonly placeholder: string;
  /**
   * The IDs of all selected object-types. (ArtifactType)
   * @type {Array<string>}
   */
  @Input() public readonly selectedObjectTypes: Array<string>;

  /**
   * Emits the filtered fields when the filter changes
   * @type {EventEmitter<Array<SearchableField>>}
   */
  @Output() public readonly fieldsFiltered: EventEmitter<Array<SearchableField>>;

  /**
   * Event emitted when the filter is cleared
   * @type {EventEmitter<void>}
   */
  @Output() public readonly filterCleared: EventEmitter<void>;

  /**
   * Controls the search-value input
   * @type {FormControl}
   */
  readonly filterValueControl: UntypedFormControl;


  /**
   * Constructor.
   */
  constructor() {
    this.subs = [];
    this.fields = [];
    this.fieldsFiltered = new EventEmitter<Array<SearchableField>>();
    this.filterCleared = new EventEmitter<void>();
    this.filterValueControl = new UntypedFormControl(null);
  }

  /**
   * Sets up an event listener for the search-input that applies the filters when the user types.
   * The event is debounced, so that the filter is not applied before the user stops typing
   */
  ngOnInit(): void {
    this.subs.push(
      this.filterValueControl.valueChanges
        .pipe(debounceTime(300))
        .subscribe(() => this.applyFilters())
    );
  }

  /**
   * Resets or reapplies the filter if input changes
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.hasOwnProperty('selectedObjectTypes') ||
        changes.hasOwnProperty('fields')) {
      this.applyFilters();
    }

  }

  /**
   * Runs cleanup. Closes any open streams
   */
  ngOnDestroy(): void {
    this.subs.filter(s => !s.closed).forEach(s => s.unsubscribe());
  }

  /**
   * Clears the filter
   */
  clearFilter(): void {
    this.filterCleared.emit();
    this.filterValueControl.setValue('', {emitEvent: false});
    this.applyFilters();
  }

  /**
   * Filters the dataset.
   * @private
   */
  private applyFilters(): void {
    const searchString = this.filterValueControl.value || '';
    let filteredFields = this.fields || [];
    filteredFields = this.filterFieldsByName(searchString, filteredFields);
    filteredFields = this.filterFieldsByArtifactType(filteredFields);
    // Add other functions here in the same fashion to further filter the dataset

    this.fieldsFiltered.emit(filteredFields);
  }

  /**
   * A filter-function that filters the dataset by name.
   * Used as a part of the filter-chain in applyFilters().
   * @param {string} searchString
   * @param {Array<SearchableField>} fields
   * @return {Array<SearchableField>}
   * @private
   */
  private filterFieldsByName(searchString: string, fields: Array<SearchableField>): Array<SearchableField> {
    if (!searchString || !fields?.length) {
      return fields;
    }
    const searchValue: string = searchString.toLowerCase();
    return fields.filter(field =>
      String(field.title.name + field.title.alternateName)
        .toLowerCase()
        .indexOf(searchValue) > -1
    );
  }

  /**
   * A filter-function that filters the dataset by Artifact type.
   * Used as a part of the filter-chain in applyFilters().
   * @param {Array<SearchableField>} fields
   * @return {Array<SearchableField>}
   * @private
   */
  private filterFieldsByArtifactType(fields: Array<SearchableField>): Array<SearchableField> {
    if (!this.selectedObjectTypes?.length) {
      return fields;
    }
    return fields
      .filter(f => f.appearsOn
        .map(a => a.artifactId)
        .some(id => this.selectedObjectTypes.includes(id))
      );
  }

}
