import {OperationTarget} from "../../core/definitions/operation-target.enum";
import {OperationExecutorType} from "../../core/definitions/operation-executor-type.enum";
import {OperationStepType} from "../../core/definitions/operation-step-type.enum";
import {OperationStepExecutionParams} from "../../core/definitions/operation-step-execution-params";
import {OperationExecutionStatus} from "../../core/definitions/operation-execution-status.enum";
import {ReportUserGenerated} from "../../core/ModelStore/models/ReportUserGenerated";
import {OperationService} from "../../operations/operation.service";
import {CmsApiService} from "../../core/cms-api.service";

export function getDefaultViewOptionsCheckboxValues(): {key: string, label: string, value: any, requiresUnchecked?: string, requiresChecked?: string}[] {
  return [
    {key: 'alle_bilder', label: 'TRANS__REPORTS_V2_SELECTIONS_ALL_IMAGES', value: false},
    {key: 'kun_bilder', label: 'TRANS__REPORTS_V2_SELECTIONS_ONLY_IMAGES', value: false},
    {
      key: 'vis_ledetekster',
      label: 'TRANS__REPORTS_V2_SELECTIONS_SHOW_LABELS',
      value: false,
      requiresUnchecked: 'kun_bilder'
    },
    {key: 'skjul_ledetekster', label: 'TRANS__REPORTS_V2_SELECTIONS_HIDE_LABELS', value: false},
    {
      key: 'ledetekst_og_verdi_på_egne_linjer',
      label: 'TRANS__REPORTS_V2_SELECTIONS_LABELS_DIFFERENT_LINE',
      value: false,
      requiresUnchecked: 'skjul_ledetekster'
    },
    {key: 'med_helbilde', label: 'TRANS__REPORTS_V2_SELECTIONS_FULL_IMAGE', value: false},
    {key: 'høyrejuster_bilder', label: 'TRANS__REPORTS_V2_SELECTIONS_RIGHT_ADJUSTED_IMAGE', value: false},
    {
      key: 'venstrejusterte_bilder',
      label: 'TRANS__REPORTS_V2_SELECTIONS_LEFT_ADJUSTED_IMAGE',
      value: false,
      requiresUnchecked: 'med_helbilde'
    },
    {key: 'ledetekst_og_verdi_på_en_linje', label: 'TRANS__REPORTS_V2_SELECTIONS_LABELS_SAME_LINE', value: false},

    {key: 'med_frimerkebilder', label: 'TRANS__REPORTS_V2_SELECTIONS_STAMP_IMAGES', value: false},
    {key: 'vis_seksjonstitler', label: "TRANS__REPORTS_V2_SELECTIONS_SECTION_TITLES", value: false},
    //{key: 'vise_fotograf_for_bilder', label: 'TRANS__REPORTS_V2_SELECTIONS_SHOW_PHOTOGRAPHER', selected: false},
    //{key: 'vis_plasseringsdetaljer', label: 'TRANS__REPORTS_V2_SELECTIONS_SHOW_PLACEMENT', selected: false},
    //{key: 'vis_søkekriterium', label: 'TRANS__REPORTS_V2_SELECTIONS_SHOW_SEARCH', selected: false},
    {key: 'vis_sidetall', label: 'TRANS__REPORTS_V2_SELECTIONS_SHOW_PAGENUM', value: false}
  ];
}


export async function generateReport(generatedReport: ReportUserGenerated, localStorageItem: any, operationService: OperationService, cms: CmsApiService) {
  let params = {
    localStorageItem: null,
    reportId: generatedReport.id,
    accessToken: localStorage.getItem('kit.access_token'),
    idToken: localStorage.getItem('kit.id_token'),
    showPagenumbers: generatedReport.reportViewOptions['vis_sidetall'] ? generatedReport.reportViewOptions['vis_sidetall'] : false
  }

  let parsedItem: any = {};

  if (localStorageItem) {
    try {
      parsedItem = JSON.parse(localStorageItem);
    } catch (e) {
      console.error('Error parsing localStorageItem:', e);
    }
  }

  params.localStorageItem = {
    name: parsedItem.name || '',
    shortName: parsedItem.shortName || '',
    api: parsedItem.api || '',
    id: parsedItem.id || '',
    online: parsedItem.online || false,
  }

  const operationContainer = await operationService.createOperationContainer(
    OperationTarget.CREATE_REPORT_PDF, []);
  await operationService.setOperations(operationContainer);
  await operationService.setCurrentOperationByType(
    operationContainer, OperationExecutorType.REPORTS_V2, OperationStepType.GENERATE_REPORT_PDF);

  let operationStepParams = new OperationStepExecutionParams();
  operationStepParams.operation_objects = [params];
  operationStepParams.operation_type_id = "6f6f6c0b-6a5d-4b6b-9e3a-3e1b8f5f5f5f";
  operationStepParams.operation_step_index = 0;

  const res = await cms.executeOperationStep(operationStepParams);
  if (res.status === OperationExecutionStatus.QUEUED) {
    operationService.handleStepResultQueued(operationContainer, res);
  }
}
