import {Injectable} from '@angular/core';

import {CmsApiService} from './cms-api.service';
import {ReferenceFilterService} from './reference-filter.service';
import {CommonsService} from './commons.service';
import {FieldParameters} from './definitions/field-parameters';
import {QueryParserService} from './query-parser.service';
import {FieldValueService} from './field-value.service';
import {HierarchicNode} from './definitions/hierarchic-objects';
import {MetaField} from './definitions/meta-field';
import {Reference} from './definitions/reference';
import {Option, OptionInfo} from './definitions/option-info';
import {SearchParameters} from './definitions/search-parameters';
import {SearchObject} from './definitions/search-object';
import {BaseModel} from './definitions/base-model';
import {UserCacheService} from './user-cache.service';
import {FieldActionParameters} from '../shared/field-action-parameters';
import {Inline} from './definitions/inline';
import {FieldActionService} from '../shared/field-action.service';
import {AConst} from './a-const.enum';
import {CurrentQuery} from './definitions/current-query';
import {ModelFactoryService} from './model-factory.service';
import {SolrFilterService} from './solr-filter.service';
import {LoggerService} from './logger.service';
import {ModelsService} from './models.service';
import {SearchParams} from './search-params';
import {ListRange} from '@angular/cdk/collections';
import {CmsQueueService} from './cms-queue.service';
import {SearchService} from "./search.service";
import {InlineViewService} from "./inline-view.service";
import {SearchReferenceService} from "./search-reference.service";
import {ValueOptionService} from "./value-option.service";
import {FieldInputType} from "./definitions/field-input-type.enum";
import {AbstractControl} from "@angular/forms";
import {
  EditFieldSelectQueryComponent
} from "../object-edit/edit-field-select/edit-field-select-query/edit-field-select-query.component";
import {CrudService} from "./crud.service";

export interface GetParentResult {
  parentNode: HierarchicNode;
  existed: boolean;
}

export class SelectFieldOptionContainer {
  fieldParameters: FieldParameters;
  fieldKey: string;
  refProp: string;
  myFormControl: AbstractControl;
  query: CurrentQuery;
  editFieldSelectQuery: EditFieldSelectQueryComponent;
  temporaryFieldValueName: string;
  setQueryTimoutId: any;
}

@Injectable({
  providedIn: 'root'
})
export class OptionsService {
  private canAddNewCache = {};

  constructor(private readonly cms: CmsApiService,
              private readonly logger: LoggerService,
              private readonly referenceFilterSvc: ReferenceFilterService,
              private readonly commons: CommonsService,
              private readonly queryParser: QueryParserService,
              private readonly fieldValueService: FieldValueService,
              private readonly userCacheService: UserCacheService,
              private readonly fieldActionService: FieldActionService,
              private readonly modelFactory: ModelFactoryService,
              private readonly modelsService: ModelsService,
              private readonly searchService: SearchService,
              private readonly solrFilter: SolrFilterService,
              private readonly cmsQueue: CmsQueueService,
              private readonly inlineViewService: InlineViewService,
              private readonly searchReferenceService: SearchReferenceService,
              private readonly valueOptionService: ValueOptionService,
              private readonly crud: CrudService) {
  }

  async searchOptions(params: SearchParams, range?: ListRange, options?: Option[]): Promise<SearchObject[]> {
    const searchParams = await this.getSearchParams(params);
    searchParams.start = (range?.start) ? range.start : 0;
    const searchRes = await this.searchService.search(searchParams);
    let res: SearchObject[];
    res = options || [];

    if (searchRes.artifacts) {
      let optionsFromSearch: SearchObject[];
      if (params.fieldParameters.field.input_type !== FieldInputType.REF_ARRAY) {
        optionsFromSearch = this.filterOptions(params.fieldParameters, searchRes.artifacts);
      } else {
        optionsFromSearch = searchRes.artifacts;
      }
      for (const [index, value] of optionsFromSearch.entries()) {
        res[searchParams.start + index] = value;
      }
    }
    return res;
  }

  filterOptions(fieldParameters: FieldParameters, options: SearchObject[]) {
    let res: SearchObject[] = this.commons.copy(options);
    const [arrayObject, arrayName] = this.getArrayObjectAndArrayName(fieldParameters);
    if (arrayObject) {
      const parentMetaField: MetaField = arrayObject.$$meta[arrayName];
      if (parentMetaField.inline) {
        const uniqueProps = parentMetaField.inline.unique_props;
        const uniqueValues = parentMetaField.inline.unique_values;
        if (uniqueProps && (uniqueProps.length === 1 || uniqueValues)) {
          res = this.filterUniqueOptions(arrayObject[arrayName], options, uniqueProps, uniqueValues);
        }
      }
    }
    return res;
  }

  async initSelect(params: SelectFieldOptionContainer, reference: Reference) {
    const metaField: MetaField = params.fieldParameters.field;
    if (metaField.field_type === AConst.ARRAY) {
      const inlineFieldName = this.getInlineFieldName(metaField);
      let [arrayObject, arrayFieldName] = this.getArrayObjectAndArrayName(params.fieldParameters);
      if (!this.fieldValueService.isSingleItemArray(params.fieldParameters)) {
        this.setQueryValue({value: ''}, params);
      } else {
        const value = this.getTextValue(params.fieldParameters);
        this.setQueryValue({value: value}, params);
      }
      params.temporaryFieldValueName = '$$' + inlineFieldName + '_value';
      if (!arrayObject[arrayFieldName]) {
        arrayObject[arrayFieldName] = [];
      }
      if (arrayObject[arrayFieldName].length > 0) {
        await this.setIconAndAuthority(arrayObject[arrayFieldName], inlineFieldName, metaField, reference);
      }
      this.removeEmptyRefArrayItems(params.fieldParameters);
    } else {
      const value = this.getTextValue(params.fieldParameters);
      this.setQueryValue({value: value}, params);
      params.temporaryFieldValueName = '$$' + metaField.name + '_value';
      const obj = params.fieldParameters.object;
      if (!obj) {
        this.logger.warn(`Missing object for ${metaField.name}`);
      } else {
        await this.setIconAndAuthority([obj], metaField.name, metaField, reference);
      }
    }
  }

  async optionsSelected(options: Option[], params: SelectFieldOptionContainer, reference: Reference) {
    let value: any;
    if (options?.length) {
      if (params.fieldParameters.field.field_type !== 'array') {
        value = await this.setFieldValueFromOption(options[0], params, reference);
      } else {
        value = await this.addFieldArrayItemsFromOptions(options, params, reference);
      }
    }
    return value;
  }

  optionUnchecked(option: Option, params: SelectFieldOptionContainer) {
    const inlineFieldName = this.getInlineFieldName(params.fieldParameters.field);
    // Unchecking items can happen when either using checkboxes or clicking the X next to selected values.
    // The "option" item will be different from either case, that's why the inlineFieldName is submitted
    // to the "getOptionId" method.
    const optionId = this.getOptionId(option, inlineFieldName);
    const array = this.getArray(params.fieldParameters);
    const foundIndex = this.findFieldArrayItemIndex(optionId, params, inlineFieldName);
    if (foundIndex !== -1) {
      this.modelFactory.deleteArrayItem(array, foundIndex, params.fieldParameters.rootObject);
      const count = this.modelFactory.countArrayElements(array);
      params.myFormControl.setValue(count || '');
      params.myFormControl.markAsDirty();
      option.$$isSelected = false;
    }
  }

  setQueryValue(queryInfo: any, params: SelectFieldOptionContainer) {
    if (params.setQueryTimoutId) {
      clearTimeout(params.setQueryTimoutId);
      params.setQueryTimoutId = 0;
    }
    params.setQueryTimoutId = setTimeout(() => {
      if (!params.myFormControl) {
        return
      }
      params.query.value = queryInfo.value;
      params.query.valueWithTag = queryInfo.value;
      if (queryInfo?.value && queryInfo.value.indexOf('<del>') !== -1) {
        params.query.value = params.query.value.replace('<del>', '').replace('</del>', '');
      }
      let setControlValue = true;
      if (params.fieldParameters.field.field_type === 'array') {
        setControlValue = false;
        const inline: Inline = params.fieldParameters.field.inline;
        if (inline && inline.inline_list && inline.inline_list.max_length === 1) {
          setControlValue = true;
        }
      }
      if (setControlValue) {
        params.myFormControl.setValue(queryInfo.value);
      }
    }, queryInfo.timeout ? queryInfo.timeout : 100);
  }

  clearField(params: SelectFieldOptionContainer) {
    this.setQueryValue({value: ''}, params);
    if (params.fieldParameters.field.field_type !== 'array') {
      this.setFieldValue(null, params.fieldParameters, params.fieldKey);
      this.setFieldTextValue('', params.fieldParameters, params.fieldKey);
    }
    if (params.temporaryFieldValueName) {
      params.fieldParameters.object[params.temporaryFieldValueName] = '';
    }
  }

  // Query based search into hierarchic objects which will attempt to build the parent nodes based on the search result
  async hierarchicQuerySearch(fieldParameters: FieldParameters, query: string, page: number, pageSize: number): Promise<HierarchicNode> {
    const reference: Reference = this.getReference(fieldParameters);
    const params = {
      rows: pageSize,
      start: pageSize * page
    } as SearchParameters;
    const nodeDisplayField = await this.getNodeDisplayField(reference, 'name.name');
    // Remove "|| 'name.name_upper'" bit when all backends are >= 10.0.231
    let queryField: string = reference.query_field || 'name.name_upper';
    let queryValue: string;
    if (queryField === 'content') {
      if (!this.validContentSearch(query)) {
        return null;
      }
      params.query = this.parseQuery(query)
    }  else if (nodeDisplayField === 'full_code_path' || nodeDisplayField === 'code') {
      queryValue = `${query}`;
      queryField = nodeDisplayField;
      this.solrFilter.addFq(params, queryField, queryValue);
    } else {
      queryValue = this.parseQuery(query);
      if (reference.object_type.startsWith('ct_')) {
        // Need to add code to concept search due to code not being part of artifact_name
        params.fq = [`(${queryField}:${queryValue} OR code:${queryValue})`];
      } else {
        this.solrFilter.addFq(params, queryField, queryValue);
      }
    }

    params.sort = reference.sort;
    this.solrFilter.addFq(params, 'object_type', reference.object_type);
    this.solrFilter.addFq(params, '-valid', false);
    if (fieldParameters.field.name === 'parent_id') {
      this.solrFilter.addFq(params, '-artifact_id', fieldParameters.object.artifact_id);
    }
    const filterData = await this.referenceFilterSvc.generateRefFilterData(
      reference.ref_filter, true, fieldParameters);
    this.solrFilter.setFqFromObject(params, filterData);
    const searchResult = await this.searchService.search(params);
    const rootNode = new HierarchicNode();
    if (searchResult.artifacts.length) {
      const searchNodes: HierarchicNode[] = searchResult.artifacts.map(searchObj => this.searchObjectToNode(
        searchObj, false, nodeDisplayField));
      this.putSearchNodesInHierarchy(rootNode, searchNodes, nodeDisplayField);
    }
    return rootNode;
  }

  async addHierarchicNodes(parentNode: HierarchicNode,
                           fieldParameters: FieldParameters,
                           reference: Reference,
                           page: number,
                           pageSize: number,
                           parentId: string,
                           level?: number): Promise<void> {
    const params = {
      sort: 'name.name_upper',
      rows: pageSize,
      start: pageSize * page
    } as SearchParameters;
    this.solrFilter.addFq(params, 'object_type', reference.object_type);
    this.solrFilter.addFq(params, '-valid', false);
    if (fieldParameters.field.name === 'parent_id') {
      this.solrFilter.addFq(params, '-artifact_id', fieldParameters.object.artifact_id);
    }
    if (parentId) {
      this.solrFilter.addFq(params, 'parent_id', parentId);
    } else {
      if (level !== undefined) {
        this.solrFilter.addFq(params, 'level', 1);
      } else {
        this.logger.warn('Level must be set when parent_id is not set');
      }
    }
    const filterData = await this.referenceFilterSvc.generateRefFilterData(
      reference.ref_filter, true, fieldParameters);
    this.solrFilter.setFqFromObject(params, filterData);
    const searchResult = await this.searchService.search(params);
    if (searchResult.artifacts.length) {
      const displayField = await this.getDisplayField(reference, 'name.name');
      const searchNodes = searchResult.artifacts.map(searchObj => this.searchObjectToNode(
        searchObj, false, displayField));
      await this.addChildren(searchNodes, displayField);
      this.appendNodesAddViewMoreNode(parentNode, searchNodes, searchResult.search_count);
    }
  }

  async addChildren(parentNodes: HierarchicNode[], displayField: string) {
    const searchParameters = {
      rows: 10000,
      sort: 'name.name_upper',
    } as SearchParameters;
    this.solrFilter.addFq(searchParameters, 'parent_id', parentNodes.map(parentNode => parentNode.artifact_id));
    this.solrFilter.addFq(searchParameters, 'object_type', parentNodes[0].object_type);
    this.solrFilter.addFq(searchParameters, '-valid', false);
    const searchRes = await this.searchService.search(searchParameters);
    for (const parentNode of parentNodes) {
      const childSearchObjects = searchRes.artifacts.filter(searchObject => searchObject.parent_id === parentNode.artifact_id);
      const childNodes = childSearchObjects.map(childSearchObj => this.searchObjectToNode(childSearchObj, true, displayField));
      this.appendNodesAddViewMoreNode(parentNode, childNodes);
    }
  }


  async setGrandChildren(node: HierarchicNode, displayField: string) {
    const childNodes = node.children.filter(child => child.$$nodeType !== 'loadMoreNode');
    await this.addChildren(childNodes, displayField);
  }

  async setIconAndAuthority(items: BaseModel[], fieldName: string, fieldInfo: MetaField, reference: Reference): Promise<void> {
    const itemIds = this.getItemIds(items, fieldName);
    if (!itemIds.length) {
      return;
    }
    const foundOptions = await this.getOptionsFromOptionIds(itemIds);
    if (!foundOptions?.length) {
      this.logger.warn('No options found' + fieldName);
      return;
    }

    for (const item of items) {
      if (['model', 'template_model'].indexOf(item.meta_type) !== -1) {
        continue;
      }
      const artifactId = item[fieldName];
      if (!artifactId) {
        continue;
      }
      let opt: any = foundOptions.find(option => option.artifact_id === artifactId);
      if (!opt) {
        this.logger.warn(`No option found for ${fieldName} with id ${artifactId}`);
        continue;
      }
      let icon: string;
      const inlineView = await this.inlineViewService.getInlineViewForField(item, fieldInfo);
      if (inlineView) {
        icon = inlineView.icon;
      }
      if (opt.authority_id_value === 'KulturNav' || icon) {
        await this.setSelectedOptionName(reference, opt, null);
        item['$$' + fieldName + '_value'] = opt.$$name;
      } else {
        item['$$' + fieldName + '_value'] = this.fieldValueService.getValueCompanion(item, fieldName);
      }
    }
  }

  private getItemIds(items: BaseModel[], fieldName: string): string[] {
    const itemIds = [];
    for (const item of items) {
      const itemId = item[fieldName];
      if (itemId) {
        itemIds.push(itemId)
      }
    }
    return itemIds;
  }

  private async getOptionsFromOptionIds(itemIds: string[]): Promise<BaseModel[]> {
    const params = {} as SearchParameters;
    this.solrFilter.addFq(params, 'artifact_id', itemIds);
    const res = await this.searchService.search(params);
    return res.artifacts;
  }

  setNodeIsSelectedById(parentNode: HierarchicNode, itemId: string, isSelected: boolean): boolean {
    let found = false;
    if (parentNode?.children?.length) {
      for (const item of parentNode.children) {
        if (item.artifact_id === itemId) {
          found = true;
          item.$$isSelected = isSelected;
          break;
        }
        found = this.setNodeIsSelectedById(item, itemId, isSelected);
        if (found) {
          break;
        }
      }
    }
    return found;
  }

  setSelectedHierarchicNodes(parentNode: HierarchicNode, fieldParameters: FieldParameters) {
    if (!parentNode) {
      return;
    }
    const array = this.fieldValueService.getFieldValueFromFieldParametersAsArray(fieldParameters);
    if (array.length) {
      const compareFieldName = this.fieldValueService.getIdFieldName(fieldParameters);
      if (compareFieldName) {
        this.setSelectedHierarchicNodesFromArray(parentNode, array, compareFieldName);
      } else {
        this.logger.warn('I have nothing to compare!');
      }
    }
  }

  async getCanAddNew(reference: Reference): Promise<boolean> {
    let res = false;
    if (!reference.add_new_params || reference.add_new_params.can_add_new !== false) {
      const objectType = reference.add_new_params?.can_add_new_object_type ||
        reference.add_new_params?.new_object_type || reference.object_type;
      if (!objectType) {
        return res;
      }
      res = this.canAddNewCache[objectType];
      if (res !== undefined) {
        return res;
      }
      try {
        const canAddNewRes = await this.cms.getCanAddNew({object_type: objectType});
        res = canAddNewRes?.can_add_new;
        if (canAddNewRes) {
          this.canAddNewCache[objectType] = res;
        }
      } catch (e) {
        this.logger.warn(`Unable to check "can add new" for object type ${objectType}: ${e}`)
      }
    }
    return res;
  }

  async getDisplayField(reference: Reference, altFieldName: string): Promise<string> {
    return this.getConfigValue(reference?.display_field, altFieldName);
  }

  async getNodeDisplayField(reference: Reference, altFieldName: string) {
    return this.getConfigValue(reference?.node_display_field, altFieldName);
  }

  getArrayObjectAndArrayName(fieldParameters: FieldParameters): [BaseModel, string] {
    const metaField: MetaField = fieldParameters.field;
    let arrayFieldName = metaField.name;
    if (!metaField.inline) {
      return [null, null];
    }
    const inlineFieldName = this.getInlineFieldName(metaField);
    let arrayObject = fieldParameters.object;
    if (inlineFieldName === metaField.name) {
      // This will happen when field is primary field
      arrayFieldName = metaField.path;
      if (fieldParameters.grandParentObject[arrayFieldName]) {
        arrayObject = fieldParameters.grandParentObject
      } else if (fieldParameters.rootObject[arrayFieldName]) {
        arrayObject = fieldParameters.rootObject;
      } else {
        this.logger.warn(`Could not find object containing array named ${arrayFieldName}`);
      }
    }
    return [arrayObject, arrayFieldName];
  }

  async getOptionName(reference: Reference, option: Option): Promise<string> {
    let name: string;
    const displayField = await this.getDisplayField(reference, 'artifact_name');
    name = (option) ? option[displayField] : undefined;
    if (!name) {
      this.logger.warn('Cannot get option name for ' + JSON.stringify(option));
      return '';
    }
    if (reference.extra_display_field) {
      name = `${name} (${option[reference.extra_display_field]})`
    }
    return name;
  }

  private async getConfigValue(fieldName: string, altFieldName: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (fieldName?.includes('config(')) {
        const configKey = fieldName.split('(')[1].split(')')[0];
        const afterEqual = fieldName.split('=')[1].split(':');
        this.cmsQueue.runCmsFnWithQueue(this.cms.getConfig, {key: configKey}, false,
          (configVal: any) => {
            fieldName = configVal.value.toString() === afterEqual[0] ? afterEqual[1] : afterEqual[3];
            resolve(fieldName || altFieldName);
          },
          (e: any) => reject(e));
      } else {
        resolve(fieldName || altFieldName);
      }
    });
  }

  private setSelectedHierarchicNodesFromArray(parentNode: HierarchicNode, array: BaseModel[], compareFieldName: string) {
    let i = 0;
    while (array.length && i < array.length && parentNode.children?.length) {
      const arrayItem = array[i];
      let matchIndex = -1;
      for (const child of parentNode.children) {
        if (child.artifact_id === arrayItem[compareFieldName]) {
          child.$$isSelected = true;
          matchIndex = i;
          break;
        }
      }
      if (matchIndex !== -1) {
        array.splice(matchIndex, 1);
      } else {
        i++;
      }
    }
    this.loopParentNodeSetSelectedNodesFromArray(parentNode, array, compareFieldName);
  }

  private loopParentNodeSetSelectedNodesFromArray(parentNode: HierarchicNode, array: BaseModel[], compareFieldName: string) {
    if (array.length && parentNode.children?.length) {
      for (const child of parentNode.children) {
        if (!child.children?.length) {
          continue;
        }
        this.setSelectedHierarchicNodesFromArray(child, array, compareFieldName);
        if (!array.length) {
          break;
        }
      }
    }
  }

  searchObjectToNode(searchObj: SearchObject, hasParent: boolean, displayField: string): HierarchicNode {
    const node = <HierarchicNode>searchObj;
    node.children = [];
    node.$$hasParent = hasParent;
    node.$$name = searchObj[displayField] || searchObj['name.name'];
    if (node.code && displayField !== 'full_code_path') {
      node.$$name = `${node.$$name} (${node.code})`
    }
    return node;
  }

  private appendNodesAddViewMoreNode(parentNode: HierarchicNode, appendNodes: HierarchicNode[], searchCount?: number) {
    if (!appendNodes.length) {
      return;
    }
    const nodes = parentNode.children;
    if (nodes.length) {
      const lastNode = nodes.pop();
      if (lastNode.$$nodeType !== 'loadMoreNode') {
        nodes.push(lastNode);
        this.logger.warn('Last node should have been type "loadMoreNode".');
      }
    }
    // Adds new nodes to nodes array
    nodes.push.apply(nodes, appendNodes);
    if (searchCount && nodes.length < searchCount) {
      // Add 'view more' node.
      const loadMoreNode = new HierarchicNode();
      loadMoreNode.$$nodeType = 'loadMoreNode';
      loadMoreNode.$$rows = nodes.length;
      loadMoreNode.$$parentNode = parentNode;
      loadMoreNode.parent_id = nodes[0].parent_id;
      loadMoreNode.level = nodes[0].level;
      nodes.push(loadMoreNode);
    }
  }

  putSearchNodesInHierarchy(rootNode: HierarchicNode, searchNodes: HierarchicNode[], displayField: string) {
    let hasMissingParent: boolean;
    let counter = 0;
    do {
      hasMissingParent = false;
      // Add level 1 search nodes to the root node
      this.loopSearchNodesSetHasParent(rootNode, searchNodes);
      // Put orphan nodes into parent nodes
      const nodesCopy = [...searchNodes];
      for (const searchNode of nodesCopy) {
        hasMissingParent = this.checkSetHasParent(searchNode, searchNodes, displayField, hasMissingParent);
      }
    } while (hasMissingParent && counter++ < 10000);
    if (counter >= 10000) {
      this.logger.warn('Had problems putting nodes in hierarchy');
    }
  }

  private loopSearchNodesSetHasParent(rootNode: HierarchicNode, searchNodes: HierarchicNode[]) {
    for (const searchNode of searchNodes) {
      if (!searchNode.$$hasParent && searchNode.level === 1) {
        rootNode.children.push(searchNode);
        searchNode.$$hasParent = true;
      }
    }
  }

  private checkSetHasParent(searchNode: HierarchicNode,
                                  searchNodes: HierarchicNode[],
                                  displayField: string,
                                  hasMissingParent: boolean) {
    if (!searchNode.$$hasParent && searchNode.parent_id) {
      hasMissingParent = true;
      // Setting $$hasParent to true even though we may not actually find a parent in order to avoid eternal loop
      searchNode.$$hasParent = true;
      const getParentResult = this.getParentNode(searchNode, searchNodes);
      if (getParentResult.parentNode) {
        if (!getParentResult.existed) {
          searchNodes.push(this.searchObjectToNode(getParentResult.parentNode, false, displayField));
        }
        getParentResult.parentNode.children.push(searchNode);
      }
    }
    return hasMissingParent;
  }

  // Will either return an existing parent node in existing result set or use path information to create parent
  // nodes
  private getParentNode(childNode: HierarchicNode, nodes: HierarchicNode[]): GetParentResult {
    let parentNode: HierarchicNode;
    let existed: boolean;
    for (const node of nodes) {
      if (node.artifact_id === childNode.parent_id) {
        parentNode = node;
        existed = true;
        break;
      }
    }
    if (!parentNode) {
      parentNode = new HierarchicNode();
      parentNode.artifact_id = childNode.parent_id;
      parentNode.artifact_name = childNode.parent_id_value;
      parentNode.level = childNode.level - 1;
      const fullPathLastSplit = childNode.full_path.lastIndexOf('»');
      const fullPathSplit = childNode.full_path.split('»');
      const name = fullPathSplit[fullPathSplit.length - 2].trim();
      parentNode['name.name'] =
      parentNode.artifact_name = name;

      if (fullPathLastSplit !== -1) {
        const parentFullPath = childNode.full_path.substring(0, fullPathLastSplit);
        parentNode.full_path = parentFullPath
        parentNode.parent_id_value = parentFullPath;
      }
      const mPathLastSplit = childNode.m_path.lastIndexOf('/');
      if (mPathLastSplit !== -1) {
        parentNode.m_path = childNode.m_path.substring(0, mPathLastSplit);
      }
      if (childNode.full_code_path) {
        const fullCodePathLastSplit = childNode.full_code_path.lastIndexOf('.');
        if (fullCodePathLastSplit !== -1) {
          parentNode.full_code_path = childNode.full_code_path.substring(0, fullCodePathLastSplit);
        }
      }
      parentNode.is_leaf = false;
      if (parentNode.level > 1) {
        const mPathSplit = childNode.m_path.split('/');
        parentNode.parent_id = mPathSplit[fullPathSplit.length - 2];
      }
      existed = false;
    }
    return {parentNode: parentNode, existed: existed};
  }

  // Unable to remove worker file due to project failing to build, thus leaving the worker accessing code commented out
  // private setHierarchyNodes(auth, searchParams: SearchParameters, level: number, callback) {
  //   if (typeof Worker !== 'undefined') {
  //     const worker = new Worker(new URL('./hierarchic-node.worker', import.meta.url), {type: 'module'});
  //     worker.onmessage = ({data}) => {
  //       callback(data);
  //     }
  //     worker.postMessage({
  //       auth: auth,
  //       url: this.cms.getApiUrl('search/json'),
  //       searchParams: searchParams,
  //       level: level
  //     });
  //   }
  // }
  //
  private buildHierarchicQuery(query: string, addWildcards: boolean) {
    let res = query;
    let lastPathSepIdx = res.lastIndexOf('»');
    if (lastPathSepIdx === -1) {
      lastPathSepIdx = res.lastIndexOf(':');
    }
    if (lastPathSepIdx !== -1) {
      res = res.substring(lastPathSepIdx + 2);
    }
    if (addWildcards) {
      res = (res || '') + '*';
    }
    return res;
  }

  private async setSelectedOptionName(reference: Reference, option: Option, query: string) {
    option.$$name = await this.getTextWithQueryHighlight(reference, option, 'name', query);
  }

  private async getTextWithQueryHighlight(
    reference: Reference, option: Option, fieldName: string, query: string): Promise<string> {
    let res: string;
    if (fieldName === 'name') {
      res = await this.getOptionName(reference, option);
    } else {
      res = option[fieldName];
    }
    if (res && query) {
      const myQuery = this.buildHierarchicQuery(query, false);
      if (myQuery.length > 1) {
        res = this.replaceRecursive(res, myQuery);
      }
    }
    if (fieldName === 'name' && option.authority_id_value === 'KulturNav') {
      const markedClass = option.authority_marked === true ? 'marked' : '';
      if (option.m_path) {
        res = '<span class=\'concept-authority-left ' + markedClass + '\'></span>' +
          '<span class=\'concept-name\'>' + res + '</span>';
      } else {
        res = '<span class=\'concept-name\'>' + res + '</span>' +
          '<span class=\' concept-authority-right' + markedClass + '\'></span>';
      }
    }
    if (option['icon']) {
      res = this.getOptionIcon(option) + '<span class=\'concept-name\'>' + res + '</span>';
    }
    return res;
  }

  private getOptionIcon(option: Option) {
    let res = '';
    if (option.icon) {
      res = '<i class="' + option.icon + ' select-options-icon">' + option.icon_frame + '</i>';
    }
    return res;
  }

  private replaceRecursive(text: string, findText: string) {
    let res = text;
    const findIndex = res.toLocaleLowerCase().indexOf(findText.toLocaleLowerCase());
    if (findIndex !== -1) {
      const endQPos = findIndex + findText.length;
      res = res.substring(0, findIndex) + '<strong>' + res.substring(findIndex, endQPos) + '</strong>' +
        this.replaceRecursive(res.substring(endQPos), findText);
    }
    return res;
  }

  private getReference(fieldParameters: FieldParameters): Reference {
    let reference: Reference;
    const parentField = this.getParentField(fieldParameters);
    if (parentField && (parentField.reference_id || parentField.reference)) {
      reference = this.getReferenceFromParentField(parentField, fieldParameters);
    }
    if (!reference) {
      reference = this.getReferenceFromField(fieldParameters);
    }
    if (reference) {
      reference = this.getSubstituteReference(fieldParameters, reference);
    }
    return reference;
  }

  private getParentField(fieldParameters: FieldParameters): MetaField {
    let parentField: MetaField;
    const parentName = fieldParameters.field.parent_name || fieldParameters.field.path;
    if (parentName) {
      if (fieldParameters.grandParentObject) {
        parentField = fieldParameters.grandParentObject.$$meta[parentName];
      }
      if (!parentField) {
        parentField = fieldParameters.rootObject.$$meta[parentName];
      }
      if (!parentField) {
        this.logger.warn('Did not find parent field ' + parentName);
      }
    }
    return parentField;
  }

  private getReferenceFromParentField(parentField: MetaField, fieldParameters: FieldParameters): Reference {
    let reference: Reference;
    const parentRef = this.searchReferenceService.getSearchReferenceFromField(parentField);

    if (parentRef && parentRef.ref_prop) {
      if (parentRef.ref_prop === fieldParameters.field.name) {
        reference = parentRef;
      } else {
        // This warning has to remain until we are sure these changes does not cause other issues
        this.logger.warn(`Parent ref prop ${parentRef.ref_prop} did not match field name ${fieldParameters.field.name}`);
      }
    } else if (parentRef) {
      // This warning has to remain until we are sure these changes does not cause other issues
      this.logger.warn(`No parent reference set for ${fieldParameters.field.name}`);
    }
    return reference;
  }

  private getReferenceFromField(fieldParameters: FieldParameters): Reference {
    const reference = this.searchReferenceService.getSearchReferenceFromField(fieldParameters.field);
    if (!reference) {
      this.logger.error('Reference info not found for field ' + fieldParameters.field.name);
    }
    return reference;
  }

  private getSubstituteReference(fieldParameters: FieldParameters, origRef: Reference): Reference {
    const reference = new Reference();
    for (let [key, value] of Object.entries(origRef)) {
      let fieldVal = value;
      const substString = this.getSubstitutionString(value);
      if (substString) {
        // Currently, a very simple method for reference value substitution is used here, may need to improve
        // on this if more complex cases occurs
        fieldVal = fieldParameters.rootObject[substString];
        if (fieldVal === undefined) {
          this.logger.warn(`Unable to get substitution value for ${value}`);
        }
      }
      reference[key] = fieldVal;
    }
    return reference;
  }

  private getSubstitutionString(value: any): string {
    let res: string;
    if (typeof value === 'string') {
      const substStart = value.indexOf('{');
      if (substStart !== -1) {
        const substEnd = value.indexOf('}');
        if (substEnd !== -1) {
          res = value.substring(substStart + 1, substEnd);
        } else {
          this.logger.warn(`Missing bracket end for substitution value ${value}`);
        }
      }
    }
    return res;
  }

  private async getSearchParams(params: SearchParams): Promise<SearchParameters> {
    let parentInfo: any;
    const options = await this.getSearchOptions(params);
    const reference: Reference = this.getReference(params.fieldParameters);
    const res = {
      'sort': reference.sort,
      'rows': 200,
      'parents_only': reference.parents_only,
      'write_collections_only': reference.write_collections_only
    } as SearchParameters;
    if (reference.group_search) {
      res.group_search = reference.group_search;
      res.group_field = reference.group_field;
    }
    let objectType = reference.object_type;
    if (reference.object_type_source_field) {
      objectType = this.getObjectTypeFromSourceField(params.fieldParameters, reference.object_type_source_field);
    }
    this.solrFilter.addFq(res, '-valid', false);
    if (objectType) {
      this.solrFilter.addFq(res, 'object_type', objectType);
    }
    if (reference.meta_type) {
      this.solrFilter.addFq(res, 'meta_type', reference.meta_type);
    }
    if (options && options.length) {
      this.solrFilter.addFq(res, 'artifact_id', options);
    }

    parentInfo = this.getParentInfo(params);
    if (parentInfo) {
      this.solrFilter.addFq(res, parentInfo.parentFilterField, parentInfo.parentId);
    }

    const refFilterData = await this.referenceFilterSvc.generateRefFilterData(
      reference.ref_filter, true, params.fieldParameters);
    if (Object.keys(refFilterData).length) {
      this.solrFilter.setFqFromObject(res, refFilterData);
    }
    if (params.query) {
      // the query_field was implemented in version 10.0.231, so the || can be removed when all backends are >= 10.0.231
      const queryField = reference.query_field || 'artifact_name_upper';
      if (queryField === 'content') {
        res.query = this.parseQuery(params.query);
      } else {
        this.solrFilter.addFq(res, queryField, this.parseQuery(params.query));
      }
    }
    return res;
  }

  private async getSearchOptions(params: SearchParams): Promise<Array<object>> {
    const metaField: MetaField = params.metaField;
    const optionsInfo: OptionInfo = this.valueOptionService.getValueOptionsForField(metaField);
    if (!optionsInfo) {
      return null;
    }
    let options = optionsInfo.options[0].value;
    if (options.includes('user.collections')) {
      const userData = await this.userCacheService.getUserData();
      if (options.includes(':w')) {
        options = [];
        for (const collectionId in userData.collections) {
          if (userData.collections[collectionId].includes('w')) {
            options.push(collectionId);
          }
        }
      } else {
        options = Object.keys(userData.collections);
      }
    }
    return options;
  }

  private getParentInfo(params: SearchParams) {
    let res = null, ref: Reference;
    const metaField: MetaField = params.metaField;
    let parentId = metaField.parent_id;
    let parentFilterField = 'parent_id';
    if (!parentId) {
      ref = this.getReference(params.fieldParameters);
      if (ref && ref.parent_field) {
        const parentField = ref.parent_field;
        parentFilterField = ref.parent_target_field || parentFilterField;
        const fieldParameters: FieldParameters = params.fieldParameters;
        if (!parentId && fieldParameters.object) {
          parentId = fieldParameters.object[parentField];
        }
        if (!parentId) {
          const rootObject = fieldParameters.rootObject || fieldParameters.sectionsContainer.rootObject;
          parentId = rootObject[parentField];
        }
      }
    }
    if (parentId) {
      res = {
        parentFilterField: parentFilterField,
        parentId: parentId
      };
    }
    return res;
  }

  private filterUniqueOptions(parentArray: any[], options: SearchObject[], uniqueProps: string[], uniqueValues: any[]) {
    // TODO: Need to support more than one unique prop
    const uniqueProp = uniqueProps[0];
    for (const item of parentArray) {
      const indexOf = this.getOptionIndex(options, item[uniqueProp]);
      if (indexOf !== -1) {
        if (uniqueValues === undefined) {
          options.splice(indexOf, 1);
        } else {
          if (uniqueValues.indexOf(item[uniqueProp]) !== -1) {
            options.splice(indexOf, 1);
          }
        }
      }
    }
    return options;
  }

  private getOptionIndex(options: SearchObject[], value: any) {
    let indexOf = options.indexOf(value);
    if (indexOf === -1) {
      for (let t = 0; t < options.length; t++) {
        if (this.getOptionCompareValue(options[t]) === value) {
          indexOf = t;
          break;
        }
      }
    }
    return indexOf;
  }

  private getOptionCompareValue(option: SearchObject) {
    let compVal = option.value;
    if (compVal === undefined) {
      compVal = this.getOptionId(option);
    }
    if (compVal === undefined) {
      this.logger.warn('Option missing compare value');
    }
    return compVal;
  }

  private async setFieldValueFromOption(option: Option, params: SelectFieldOptionContainer, reference: Reference) {
    const oldFieldValue = this.fieldValueService;
    let fieldValue = option[params.refProp];
    if (reference.get_object_type_from_superobject_type_id) {
      const optionId = this.getOptionId(option);
      const objectTypes = this.modelsService.getObjectTypesFromSuperObjectTypeIds([optionId]);
      fieldValue = objectTypes[optionId];
    }
    this.setFieldValue(fieldValue, params.fieldParameters, params.fieldKey);
    await this.setOthers(option, params.fieldParameters, reference);
    const queryValue: string = await this.getOptionName(reference, option);
    await this.setSelectedOptionName(reference, option, null);
    const temporaryFieldValueName = '$$' + params.fieldParameters.field.name + '_value';
    params.fieldParameters.object[temporaryFieldValueName] = option.$$name;
    params.fieldParameters.object['icon'] = option.icon;
    params.fieldParameters.object['icon_frame'] = option.icon_frame;
    this.setQueryValue({value: queryValue}, params);
    this.setFieldTextValue(queryValue, params.fieldParameters, params.fieldKey);
    if (!oldFieldValue || !reference.is_hierarchic || option.is_leaf) {
      setTimeout(() => {
        params.editFieldSelectQuery.toggleShowOptions(false);
      }, 300);
    }
    // Run a new search on all options so that next time user clicks the fields, all options will be displayed
    return queryValue;
  }

  private async addFieldArrayItemsFromOptions(options: Option[],
                                              params: SelectFieldOptionContainer,
                                              reference: Reference) {
    const array = this.getArray(params.fieldParameters);
    const queryValue = await this.getOptionName(reference, options[0]);
    if (this.fieldValueService.isSingleItemArray(params.fieldParameters)) {
      if (array.length) {
        this.deleteExistingArrayElements(params);
      }
      await this.createAddArrayProps(options, array, params, reference);
      this.setQueryValue({value: queryValue}, params);
      params.editFieldSelectQuery.toggleShowOptions(false);
      return queryValue;
    } else {
      await this.createAddArrayProps(options, array, params, reference);
      params.myFormControl.setValue(array.length ? array.length : '');
      return array.length;
    }
  }

  private async createAddArrayProps(options: Option[], array: any[], params: SelectFieldOptionContainer, reference: Reference) {
    const inline: Inline = params.fieldParameters.field.inline;
    const inlineFieldName = this.getInlineFieldName(params.fieldParameters.field);
    for (const option of options) {
      const data = new BaseModel();
      data[inlineFieldName] = option[params.refProp];
      this.fieldValueService.setValueCompanion(data, inlineFieldName, option.artifact_name).then();
      await this.setSelectedOptionName(reference, option, null);
      params.temporaryFieldValueName = '$$' + inlineFieldName + '_value';
      data[params.temporaryFieldValueName] = option.$$name;
      this.modelFactory.createAddArrayItem(array, inline.model, data);
      option.$$isSelected = true;
    }
  }

  private deleteExistingArrayElements(params: SelectFieldOptionContainer) {
    const array = this.getArray(params.fieldParameters);
    for (let t = array.length - 1; t >= 0; t--) {
      this.modelFactory.deleteArrayItem(array, t, params.fieldParameters.rootObject);
    }
  }

  // The reference meta attribute may contain a "set_others" attribute that allows other field values to be set based on the option data
  private async setOthers(option: SearchObject, fieldParameters: FieldParameters, reference: Reference) {
    for (const setOther of reference.set_others || []) {
      const sourceField = setOther.source_field;
      const targetField = setOther.target_field;
      fieldParameters.object[targetField] = option[sourceField];
    }
  }

  private findFieldArrayItemIndex(itemId: string, params: SelectFieldOptionContainer, inlineFieldName: string) {
    const inline = params.fieldParameters.field.inline;
    if (!inline) {
      this.logger.warn('Missing inline attribute');
      return;
    }
    const array = this.getArray(params.fieldParameters);
    let foundIndex = -1;
    array.forEach((item, index) => {
      if (item[inlineFieldName] === itemId) {
        foundIndex = index;
      }
    });
    if (foundIndex === -1) {
      this.logger.warn('Array element with id ' + itemId + ' not found');
    }
    return foundIndex;
  }

  private firstArrayItemNotDeleted(fieldParameters: FieldParameters): BaseModel {
    const fieldArray = this.getArray(fieldParameters);
    let res: BaseModel;
    for (const item of fieldArray) {
      if (!this.crud.getDestroy(item)) {
        res = item;
        break;
      }
    }
    return res;
  }

  private setFieldValue(value: any, fieldParameters: FieldParameters, fieldKey: string) {
    const sectionsContainer = fieldParameters.sectionsContainer;
    this.fieldValueService.setFieldValue(fieldParameters.rootObject, fieldKey, value);
    const actionParams = new FieldActionParameters();
    actionParams.sectionsContainer = sectionsContainer;
    actionParams.rootObject = sectionsContainer.rootObject;
    actionParams.field = fieldParameters.field;
    actionParams.object = fieldParameters.object;
    actionParams.index = fieldParameters.index;
    actionParams.parentIndex = fieldParameters.parentIndex;
    actionParams.grandParentObject = fieldParameters.grandParentObject;
    actionParams.edit = true;
    actionParams.trigger = 'after_edit';
    this.fieldActionService.runActions(actionParams).then();
  }

  private setFieldTextValue(value: any, fieldParameters: FieldParameters, fieldKey: string) {
    this.fieldValueService.setFieldValue(fieldParameters.rootObject, fieldKey, value, true);
  }

  private getTextValue(fieldParameters: FieldParameters): string {
    let res = '';
    if (!this.fieldValueService.isSingleItemArray(fieldParameters)) {
      res = this.fieldValueService.getValueCompanion(fieldParameters.object, fieldParameters.field.name);
    } else {
      const inlineFieldName = this.getInlineFieldName(fieldParameters.field);
      const item = this.firstArrayItemNotDeleted(fieldParameters);
      if (item) {
        res = this.fieldValueService.getValueCompanion(item, inlineFieldName);
      }
    }
    return res;
  }

  private parseQuery(query: string) {
    return this.queryParser.parse(query, true, ' ', '_').toLocaleUpperCase();
  }

  private getInlineFieldName(metaField: MetaField) {
    let res = null;
    if (metaField.inline?.unique_props?.length) {
      res = metaField.inline.unique_props[0];
    } else {
      this.logger.warn(`Missing inline unique props for ${metaField.name}`)
    }
    return res;
  }

  private getArray(params: FieldParameters): BaseModel[] {
    const [arrayObject, arrayName] = this.getArrayObjectAndArrayName(params);
    return arrayObject[arrayName];
  }

  // Used when ref array field is used in primary field context
  private removeEmptyRefArrayItems(fieldParameters: FieldParameters) {
    if (fieldParameters.field.input_type === FieldInputType.REF_ARRAY) {
      let deleteIndex = -1;
      const inlineFieldName = this.getInlineFieldName(fieldParameters.field);
      const array = this.getArray(fieldParameters);
      for (const [index, option] of array.entries()) {
        if (option[inlineFieldName] === null) {
          deleteIndex = index;
        }
      }
      if (deleteIndex !== -1) {
        array.splice(deleteIndex, 1);
      }
    }
  }

  private getOptionId(option: SearchObject, inlineFieldName?: string): string {
    let res = option.artifact_id;
    if (!res && inlineFieldName) {
        res = option[inlineFieldName];
    }
    if (!res) {
      this.logger.warn('No option id found for option!');
    }
    return res;
  }

  private getObjectTypeFromSourceField(fieldParameters: FieldParameters, sourceField: string): string {
    const sourceFieldSplit = sourceField.split('.');
    let obj = fieldParameters.rootObject;
    let val = '';
    for (const fieldPathItem of sourceFieldSplit) {
      val = obj[fieldPathItem];
      obj = obj[fieldPathItem];
      if (Array.isArray(obj)) {
        obj = obj[0];
      }
    }
    if (!val) {
      this.logger.warn(`Could not find object type for source field ${sourceField}`);
      return null;
    }
    return val;
  }

  private validContentSearch(searchQuery: string) {
    if (searchQuery.includes('"')) {
      if (!searchQuery.startsWith('"') || !searchQuery.endsWith('"') || searchQuery.length < 3 || searchQuery.split('"').length !== 3) {
        return false;
      }
    }
    return true;
  }
}
