@if (messages.length > 0) {
  <button 
    mat-icon-button
    (click)="setActiveMessageAsRead()"
    [matMenuTriggerFor]="messageMenu"
    [matTooltip]="'TRANS__NOTIFICATIONS__NOTIFICATIONS' | translate"
    matTooltipPosition="after"
  >
    <mat-icon
     class="messages__icon"
     [matBadge]="hasUnreadMessages"
     matBadgeSize="small"
    >
      notifications
    </mat-icon>
  </button>

  <mat-menu 
    #messageMenu="matMenu"
    class="messages__menu"
    xPosition="after"
    yPosition="above"
  >
    <div class="messages">
      <div class="messages__content">
        <span class="messages__title">
          {{ messages[currentMessageIndex].title }}
        </span>
  
        <span class="messages__message" [innerHTML]="replaceMessageUrlWithActualLink(messages[currentMessageIndex].message)">
        </span>
      </div>

      @if (messages.length > 1) {
        <div class="messages__actionbar">
          <span class="messages__counter">
            {{ 'TRANS__NOTIFICATIONS__MESSAGES' | translate }} {{ currentMessageIndex + 1 }} {{ 'TRANS__NOTIFICATIONS__OF' | translate }} {{ messages.length }}
          </span>

          <div class=messages__action-buttons>
            <button mat-stroked-button class="messages__action-button" (click)="previousMessage($event)">
              {{ 'TRANS__NOTIFICATIONS__PREVIOUS' | translate }}
            </button>
  
            <button mat-stroked-button class="messages__action-button" (click)="nextMessage($event)">
              {{ 'TRANS__NOTIFICATIONS__NEXT' | translate }}
            </button>
          </div>
        </div>
      }
    </div>
  </mat-menu>
}