import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FieldTitleComponent } from './field-title/field-title.component';
import { FieldToolTipComponent } from './field-tool-tip/field-tool-tip.component';
import { FieldActionButtonComponent } from './field-action-button/field-action-button.component';
import { ObjectIconComponent } from './object-icon/object-icon.component';
import { ObjectNameComponent } from './object-name/object-name.component';
import { CultureHubSearchDialogComponent } from './culture-hub-search-dialog/culture-hub-search-dialog.component';
import { ProgressDialogComponent } from './progress-dialog/progress-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
// eslint-disable-next-line max-len
import { TemplateGroupSelectButtonComponent } from './template-group-select-button/template-group-select-button.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { DeleteItemButtonComponent } from './delete-item-button/delete-item-button.component';
import { ObjectUsageDialogComponent } from './object-usage-dialog/object-usage-dialog.component';
import { DropdownMenuItemComponent } from './dropdown-menu/dropdown-menu-item/dropdown-menu-item.component';
import { DropdownMenuHrComponent } from './dropdown-menu/dropdown-menu-hr/dropdown-menu-hr.component';
import { ObjectUrlComponent } from './object-url/object-url.component';
import { ThumbnailComponent } from './thumbnail/thumbnail.component';
import { DownloadUrlComponent } from './download-url/download-url.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ObjectViewComponent } from './object-view/object-view.component';
import { DisplayFieldTitleComponent } from './display-field-title/display-field-title.component';
import { ObjectViewFieldValuesComponent } from './object-view-field-values/object-view-field-values.component';
import { ObjectViewFieldValueComponent } from './object-view-field-value/object-view-field-value.component';
import { ObjectViewFieldCheckboxComponent } from './object-view-field-checkbox/object-view-field-checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownMenuAnimatedComponent } from './dropdown-menu-animated/dropdown-menu-animated.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { TopHeaderComponent } from './top-header/top-header.component';
import { HeadingAndDescriptionComponent } from './heading-and-description/heading-and-description.component';
import { PrimusTableComponent } from './primus-tables/primus-table/primus-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTreeModule } from '@angular/material/tree';
import { PrimusBreadcrumbComponent } from './primus-tables/primus-breadcrumb/primus-breadcrumb.component';
import { MatMenuModule } from '@angular/material/menu';
import { PrimusTableFilterComponent } from './primus-tables/primus-table-filter/primus-table-filter.component';
import { PrimusMenuButtonComponent } from './primus-menu-button/primus-menu-button.component';
import { PrimusMenuButtonItemComponent } from './primus-menu-button/primus-menu-button-item/primus-menu-button-item.component';
import { RectoVersoButtonsComponent } from './recto-verso-buttons/recto-verso-buttons.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChooseBackendApiComponent } from './choose-backend-api/choose-backend-api.component';
import { MatListModule } from '@angular/material/list';
import { PrimusSolrDataTableComponent } from './primus-tables/primus-solr-data-table/primus-solr-data-table.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PrimusSolrDataTableFilterComponent } from './primus-tables/primus-solr-data-table-filter/primus-solr-data-table-filter.component';
import { PrimusSolrTreeViewComponent } from './primus-solr-tree-view/primus-solr-tree-view.component';
import { TreeNodeComponent } from './primus-solr-tree-view/tree-node/tree-node.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CultureHubImporterDialogComponent } from './culture-hub-importer-dialog/culture-hub-importer-dialog.component';
import { CultureHubImporterItemsComponent } from './culture-hub-importer-items/culture-hub-importer-items.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { ObjectViewTabbedSectionsComponent } from './object-view-tabbed-sections/object-view-tabbed-sections.component';
import { RouterModule } from '@angular/router';
import { ObjectUsageFieldComponent } from './object-usage-field/object-usage-field.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AutoSaveSnackBarComponent } from './auto-save-snack-bar/auto-save-snack-bar.component';
import { ImageFullScreenComponent } from './image-full-screen/image-full-screen.component';
import { MatSliderModule } from '@angular/material/slider';
import { ActiveMediaComponent } from './active-media/active-media.component';
import { ActiveAnnotateImageComponent } from './active-annotate-image/active-annotate-image.component';
import { CultureHubImporterFoldersComponent } from './culture-hub-importer-folders/culture-hub-importer-folders.component';
import { CultureHubFolderFilterPipe } from './culture-hub-importer-folders/culture-hub-folder-filter.pipe';
import { ExternalUrlDirective } from './external-url.directive';
import { ObjectViewShowMoreComponent } from './object-view-show-more/object-view-show-more.component';
import { MuseumLogoComponent } from './museum-logo/museum-logo.component';
import { NotificationSendEmailComponent } from "./notifications/notification-send-email.component";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ModelFullScreenComponent } from './model-full-screen/model-full-screen.component';
import {CdkTrapFocus} from '@angular/cdk/a11y';
import {
  AutoExpandableOverflowContainerComponent
} from '../auto-expandable-overflow-container/auto-expandable-overflow-container.component';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatRippleModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTreeModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatProgressBarModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatChipsModule,
    RouterModule,
    MatExpansionModule,
    MatSliderModule,
    MatRadioModule,
    CdkTrapFocus,
    AutoExpandableOverflowContainerComponent
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ChooseBackendApiComponent,
    FieldTitleComponent,
    FieldActionButtonComponent,
    FieldToolTipComponent,
    ObjectIconComponent,
    ObjectNameComponent,
    CultureHubSearchDialogComponent,
    AlertDialogComponent,
    ObjectUrlComponent,
    DeleteItemButtonComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    DropdownMenuHrComponent,
    DropdownMenuAnimatedComponent,
    TemplateGroupSelectButtonComponent,
    ThumbnailComponent,
    DownloadUrlComponent,
    NotificationsComponent,
    ObjectViewComponent,
    DisplayFieldTitleComponent,
    ObjectViewFieldValuesComponent,
    TopHeaderComponent,
    HeadingAndDescriptionComponent,
    PrimusTableComponent,
    PrimusBreadcrumbComponent,
    PrimusTableFilterComponent,
    PrimusMenuButtonComponent,
    RectoVersoButtonsComponent,
    PrimusSolrDataTableComponent,
    PrimusSolrDataTableFilterComponent,
    PrimusSolrTreeViewComponent,
    CultureHubImporterDialogComponent,
    CultureHubImporterItemsComponent,
    ObjectViewTabbedSectionsComponent,
    ObjectUsageFieldComponent,
    ImageFullScreenComponent,
    ActiveMediaComponent,
    ActiveAnnotateImageComponent,
    NotificationSendEmailComponent,
    ModelFullScreenComponent
  ],
  declarations: [
    ChooseBackendApiComponent,
    FieldTitleComponent,
    FieldToolTipComponent,
    FieldActionButtonComponent,
    ObjectIconComponent,
    ObjectNameComponent,
    CultureHubSearchDialogComponent,
    ProgressDialogComponent,
    AlertDialogComponent,
    TemplateGroupSelectButtonComponent,
    DropdownMenuComponent,
    DeleteItemButtonComponent,
    ObjectUsageDialogComponent,
    DropdownMenuItemComponent,
    DropdownMenuHrComponent,
    DropdownMenuAnimatedComponent,
    ObjectUrlComponent,
    ThumbnailComponent,
    DownloadUrlComponent,
    NotificationsComponent,
    ObjectViewComponent,
    DisplayFieldTitleComponent,
    ObjectViewFieldValuesComponent,
    ObjectViewFieldValueComponent,
    ObjectViewFieldCheckboxComponent,
    TopHeaderComponent,
    HeadingAndDescriptionComponent,
    PrimusTableComponent,
    PrimusBreadcrumbComponent,
    PrimusTableFilterComponent,
    PrimusMenuButtonComponent,
    PrimusMenuButtonItemComponent,
    RectoVersoButtonsComponent,
    PrimusSolrDataTableComponent,
    PrimusSolrDataTableFilterComponent,
    PrimusSolrTreeViewComponent,
    TreeNodeComponent,
    CultureHubImporterDialogComponent,
    CultureHubImporterItemsComponent,
    ObjectViewTabbedSectionsComponent,
    ObjectUsageFieldComponent,
    AutoSaveSnackBarComponent,
    ImageFullScreenComponent,
    ActiveMediaComponent,
    ActiveAnnotateImageComponent,
    CultureHubImporterFoldersComponent,
    CultureHubFolderFilterPipe,
    ExternalUrlDirective,
    ObjectViewShowMoreComponent,
    MuseumLogoComponent,
    NotificationSendEmailComponent,
    ModelFullScreenComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {
}
