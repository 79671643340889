<div>
  <div mat-dialog-title>
    <span>
      {{ data.title | translate }}
    </span>
  </div>

  <div mat-dialog-content>
    <span>
      {{ data.content | translate }}
    </span>
  </div>

  <div mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      (click)="dialogRef.close(false)"
    >
      <span>
        {{ data.cancelButtonText ?? 'TRANS__CONFIRM_DIALOG__SUBMIT' | translate }}
      </span>
    </button>

    <button
      mat-button
      color="primary"
      (click)="confirm()"
    >
      <span>
        {{ data.confirmButtonText ?? 'TRANS__CONFIRM_DIALOG__SUBMIT' | translate }}
      </span>
    </button>
  </div>
</div>
