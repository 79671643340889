<div *ngIf="conceptField.type === 'name'" class="concept-name"
     [ngStyle]="conceptsContainer.conceptType.is_hierarchic ? {'padding-left': concept.level * 20 + 'px'} : {}"
>
  <ng-container *ngIf="!concept.is_parent">
    <button *ngIf="conceptsContainer.conceptType.is_hierarchic && concept.level === 1" mat-icon-button
            [disabled]="conceptsContainer.loadingConcepts || !concept.parent_id"
            (click)="openConcept(parentId, true)">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div *ngIf="conceptsContainer.conceptType.is_hierarchic" [ngClass]="{'children': concept.level > 1}"></div>
    <span class="concept-name-text">{{value}}</span>
    <button *ngIf="conceptsContainer.conceptType.is_hierarchic"
            mat-icon-button [disabled]="conceptsContainer.loadingConcepts || concept.is_leaf"
            (click)="openConcept(concept.artifact_id, false)">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="concept.is_parent">
    <button (click)="openParent()" mat-icon-button>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <span class="concept-name-text">{{value}}</span>
  </ng-container>
</div>

<ng-container *ngIf="conceptField.type === 'select'">
  <mat-checkbox
    (click)="select($event)"
    [(ngModel)]="concept.$$selected">
  </mat-checkbox>
</ng-container>

<ng-container *ngIf="conceptField.type === 'check'">
  <mat-checkbox
    [checked]="value" [disabled]="true"></mat-checkbox>
</ng-container>

<ng-container *ngIf="conceptField.type === 'datetime'">
  <span>{{value}}</span></ng-container>

<ng-container *ngIf="conceptField.type === 'action'">
  <button class="action-icon-in-table text-blue-hover"
          mat-icon-button
          (click)="editTerm()">
    <mat-icon>edit</mat-icon>
  </button>
  <app-admin-list-item-action
    [concept]="concept"
    [conceptsContainer]="conceptsContainer"
    (addChild)="addChildConcept()"
  ></app-admin-list-item-action>
  <a *ngIf="conceptsContainer.showLinkToObject"
     [routerLink]="useNewObjectPage ? [conceptsContainer.linkToObject, concept.artifact_id] : [conceptsContainer.linkToObject]"
     [queryParams]="{artifactId: concept.artifact_id, listName: 'overview'}"
     class="action-icon-in-table" mat-icon-button>
    <mat-icon color="accent">arrow_forward</mat-icon>
  </a>
</ng-container>
