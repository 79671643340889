import { Injectable } from '@angular/core';
import {BaseModel} from "./definitions/base-model";

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor() {

  }

  getDestroyFieldName() {
    return 'destroy';
  }

  setDestroy(obj: any, destroy?: any) {
    if (destroy === undefined) {
      destroy = true;
    }
    obj.destroy = destroy;
  }

  deleteDestroy(obj: BaseModel) {
    delete obj.destroy;
  }


  getDestroy(obj: any) {
    return obj.destroy;
  }

  setCreate(obj: any, create?: boolean) {
    if (create === undefined) {
      create = true;
    }
    obj.create = create;
  }

  deleteCreate(obj: BaseModel) {
    delete obj.create;
  }

  getCreate(obj: any) {
    return obj.create;
  }

}
