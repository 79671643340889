import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {DeletedState, QueryService} from '../../services/query.service';
import {FieldFilterService} from '../../services/field-filter.service';
import {UntypedFormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {FilterOptionGroup} from '../../../../core/definitions/extended-search/filter-option-group';
import {FilterOption} from '../../../../core/definitions/extended-search/filter-option';
import {QueryGroup} from '../../../../core/definitions/extended-search/query-group';
import {QueryField} from '../../../../core/definitions/extended-search/query-field';
import {SearchableField} from '../../../../core/definitions/extended-search/searchable-field';
import {Operand} from '../../../../core/definitions/extended-search/operand';

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss']
})
export class QueryBuilderComponent implements OnDestroy {
  /**
   * Internal subscriptions stored to be destroyed to prevent memory leak
   * @type {Array<Subscription>}
   * @private
   */
  private readonly subs: Array<Subscription>;

  /**
   * The current device type being used
   * @type {"mobile" | "tablet" | "desktop"}
   */
  @Input() deviceType: 'mobile' | 'tablet' | 'desktop';

  /**
   * Emits when a group has been selected
   * @type {EventEmitter<void>}
   */
  @Output() public readonly groupSelected: EventEmitter<void>;

  /**
   * Emits when the list of selected artifact-type-ids to include
   * in the result-set has changed.
   * @type {Observable<Array<string>>}
   */
    // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('selectedArtifactIdsChanged')
  public readonly selectedArtifactIdsChanged$: Observable<Array<string>>;

  /**
   * State to maintain the what fields are in free-text-mode
   * @type {{[p: string]: boolean}}
   * @private
   */
  private readonly useFreeTextState: { [fieldId: string]: boolean };

  /**
   * controls the selected artifact type-input
   * @type {FormControl}
   */
  readonly selectedArtifactIdsControl: UntypedFormControl;

  /**
   * All artifactIds that can be selected by the user
   * @type {Array<FilterOptionGroup>}
   */
  selectableArtifactIds: Array<FilterOptionGroup>;

  /**
   * Whether or not to include deleted elements
   * @type {DeletedState}
   */
  deletedStateValue: DeletedState;

  /**
   * A list of selected artifact-names
   * @type {Observable<string>}
   */
  readonly selectedArtifactNames$: Observable<string>;

  /**
   * Constructor
   * @param {QueryService} queryService
   * @param {FieldFilterService} filterService
   */
  constructor(
    readonly queryService: QueryService,
    private readonly filterService: FieldFilterService,
  ) {
    // Init defaults
    this.subs = [];

    this.groupSelected = new EventEmitter<void>();
    this.useFreeTextState = {};
    this.selectableArtifactIds = [];
    this.selectedArtifactIdsControl = new UntypedFormControl([]);

    // Create observable stream of the selected artifact ids
    this.selectedArtifactIdsChanged$ = this.selectedArtifactIdsControl.valueChanges.pipe(
      map((v: Array<FilterOption>) => (v || []).map(opt => opt.id))
    );
    // Create observable stream of the selected artifact names
    this.selectedArtifactNames$ = this.selectedArtifactIdsControl.valueChanges.pipe(
      map((v: Array<FilterOption>) => (v || []).map(opt => opt.name).join(', '))
    );

    // Load options for filter
    this.filterService.getFilterOptions()
      .then(g => {
        this.selectableArtifactIds = g;
        const selectedIds = this.queryService.getAllowedArtifactTypes();
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const selectedOptions = g.map(g => g.options.filter(opt => selectedIds.includes(opt.id)));
        this.selectedArtifactIdsControl.setValue(selectedOptions.reduce((acc, val) => [...acc, ...val], []));
      })
      .catch(err => {
        console.error(err);
        this.selectableArtifactIds = [];
        this.selectedArtifactIdsControl.setValue([]);
      });
    // Set default or stored value for deleted state filter
    this.deletedStateValue = this.queryService.getDeletedState();

    // Sync selected artifact-types with query-service
    this.subs.push(
      this.selectedArtifactIdsChanged$.subscribe(s => this.queryService.setAllowedArtifactTypes(s))
    );

  }

  /**
   * Callback that is run when a field is dropped into a group
   * @param {CdkDragDrop<QueryGroup>} dropEvent
   */
  public onDrop(dropEvent: CdkDragDrop<QueryGroup>): void {
    if (dropEvent.container !== dropEvent.previousContainer) {
      // The item is fetched from outside the current group
      if (this.queryService.hasGroup(dropEvent.container.data) &&
        this.queryService.hasGroup(dropEvent.previousContainer.data)) {
        // The element is moved from one group to another: move item:
        const currentGroup: Array<QueryField> = dropEvent.previousContainer.data.queryFields;
        const targetGroup: Array<QueryField> = dropEvent.container.data.queryFields;
        transferArrayItem(currentGroup, targetGroup, dropEvent.previousIndex, dropEvent.currentIndex);
      } else {
        // The element is moved from the field-list to the current group: Add field:
        const group: QueryGroup = dropEvent.container.data;
        const field: SearchableField = dropEvent.item.data;
        this.queryService.addQueryField(group, field);
      }
    } else {
      // source and target container is the same. Reorder elements in container:
      moveItemInArray(dropEvent.container.data.queryFields, dropEvent.previousIndex, dropEvent.currentIndex);
    }
  }

  /**
   * Cleanup
   */
  public ngOnDestroy(): void {
    // Close any open subscriptions to prevent memory leaks
    this.subs.filter(s => !s.closed).forEach(s => s.unsubscribe());
  }

  /**
   * Sets the provided group as selected.
   * This wil cause selected fields to be added to this group.
   * Only relevant on mobile devices.
   * @param {Event} event
   * @param {QueryGroup} grp
   */
  selectGroup(event: Event, grp: QueryGroup): void {
    if (this.deviceType === 'mobile') {
      event.stopPropagation();
      this.queryService.selectQueryGroup(grp);
      this.groupSelected.emit();
    }
  }

  /**
   * Removes a group and all the fields in it from the query
   * @param {Event} event
   * @param {QueryGroup} group
   */
  removeGroup(event: Event, group: QueryGroup): void {
    event.stopPropagation();
    this.queryService.removeGroup(group);
  }

  /**
   * Removes a field from the query
   * @param {Event} event
   * @param {QueryGroup} group
   * @param {QueryField} field
   */
  removeField(event: Event, group: QueryGroup, field: QueryField): void {
    event.stopPropagation();
    this.queryService.removeField(group, field);
  }

  /**
   * Whether or not removing groups should be allowed.
   * (false will disable the remove-button)
   * @return {boolean}
   */
  enableRemoveGroupButton(): boolean {
    return this.queryService.getCurrentQuery().length > 1;
  }

  /**
   * Sets a new search-value
   * @param {QueryField} field
   * @param {string} newValue
   */
  handleSearchValueChanged(field: QueryField, newValue: string): void {
    field.searchValue = newValue;
  }

  /**
   * Changes the operand for a field or group
   * @param {QueryGroup | QueryField} groupOrField
   * @param {Operand} operand
   */
  changeOperand(groupOrField: QueryGroup | QueryField, operand: Operand): void {
    this.queryService.changeOperand(groupOrField, operand);
  }

  /**
   * Changes the deleted-state.
   */
  handleDeletedStateChanged() {
    this.queryService.setDeletedState(this.deletedStateValue);
  }

}
