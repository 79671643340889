<div class="extended-search-query">
  <span class="extended-search-query__title">
    {{ getHeaderText() }}
  </span>

  <button
    mat-icon-button
    class="extended-search-query__help-button"
    [matMenuTriggerFor]="extendedSearchTooltip"
  >
    <mat-icon class="extended-search-query__help-icon">
      help_outlined
    </mat-icon>
  </button>

  <div class="extended-search-query__search-suggestion-container">
    <button
      mat-button
      class="extended-search-query__search-suggestion-button"
      [matMenuTriggerFor]="searchSuggestionsMenu"
    >
      <span class="extended-search-query__search-suggestion-label">
        {{ 'TRANS__ADVANCED_SEARCH__SEARCH_SUGGESTIONS' | translate }}
      </span>

      <mat-icon
        iconPositionEnd
      >
        arrow_drop_down
      </mat-icon>
    </button>
  </div>
</div>


<mat-menu #extendedSearchTooltip="matMenu" xPosition="after" yPosition="below" class="extended-search-tooltip">
  <div class="extended-search-tooltip__inner-container">
    <div class="extended-search-tooltip__title">
      <span class="extended-search-tooltip__title-text">
        {{ 'TRANS__ADVANCED_SEARCH__HELP__TITLE' | translate }}
      </span>
    </div>

    <div class="extended-search-tooltip__text">
      {{ 'TRANS__ADVANCED_SEARCH__HELP__CONTENT' | translate }}
    </div>
  </div>
</mat-menu>

<mat-menu #searchSuggestionsMenu="matMenu" xPosition="after" yPosition="below">
  @for(suggestion of extendedSearchService.extendedSearchSuggestions(); track $index) {
    <button mat-menu-item (click)="setSearchSuggestion(suggestion)">
      {{ suggestion.label }}
    </button>
  }
</mat-menu>
