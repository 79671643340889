import {Component, Input, OnChanges, Output, EventEmitter, OnDestroy} from '@angular/core';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {FieldValueService} from '../../core/field-value.service';
import {ChangeTrackerService} from '../../core/change-tracker.service';
import {CommonsService} from '../../core/commons.service';
import {OptionsService} from '../../core/options.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {AnnotationService} from '../../image-annotation/annotation.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {AnnotationHandler} from '../../image-annotation/annotation-handler';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {MetaField} from '../../core/definitions/meta-field';
import {UploadMediaContainer} from '../../core/definitions/upload-media-container';
import {ImageItem} from '../../core/definitions/image-item';
import {
  AnnotationDialogComponent,
  AnnotationDialogComponentData
} from '../../image-annotation/annotation-dialog/annotation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SearchReferenceService} from "../../core/search-reference.service";
import {MatExpansionPanel} from "@angular/material/expansion";
import {SearchObject} from "../../core/definitions/search-object";
import {BaseModel} from "../../core/definitions/base-model";
import {CrudService} from "../../core/crud.service";
import {System} from "../../core/definitions/system";

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss']
})
export class MediaListComponent implements OnChanges, OnDestroy {

  @Input() uploadMediaContainer: UploadMediaContainer;
  @Input() parentObject: SuperObjectModel;
  @Input() isDialog: boolean;
  @Output() deleteMedia = new EventEmitter<BaseModel>();

  confirm = false;
  fileContainers = {};
  commonRegVisible = false;
  commonContainer: SectionsContainer;
  allChecked = false;
  files: BaseModel[] = [];

  private promise: any;
  private editFields: string[];
  private commonObj: SuperObjectModel;
  private oldValues: any;
  private modName: string;
  private pageSize = 4;
  private pageNo = 0;
  private settingFileContainers: boolean;
  private fileContainersSet: boolean;
  private setChangedTimeout:any = -1;
  private oldFileLength = 0;

  constructor(private fieldValueHandler: FieldValueService,
              private changeTracker: ChangeTrackerService,
              private commons: CommonsService,
              private optionsService: OptionsService,
              private currentObjectService: CurrentObjectService,
              private annotationService: AnnotationService,
              private objectEditService: ObjectEditService,
              private modalService: MatDialog,
              private searchReferenceService: SearchReferenceService,
              public crud: CrudService) {
  }

  ngOnChanges() {
    this.files = this.uploadMediaContainer.files;
    this.settingFileContainers = false;
    this.fileContainersSet = false;
    this.modName = this.uploadMediaContainer.uploadInfo.object_type;
    this.objectEditService.createModelItemGetSectionsContainerForPrimeFields(this.modName, null, true).then(sectionsContainer => {
      this.commonContainer = sectionsContainer;
      this.commonContainer.isDialog = this.isDialog;
      this.commonObj = sectionsContainer.rootObject;
      this.editFields = [];
      this.fieldValueHandler.setObjectEditFields(this.editFields, this.commonObj);
    });
  }

  ngOnDestroy() {
    if (this.promise) {
      clearTimeout(this.promise)
    }
  }

  getPageFiles(): BaseModel[] {
    if (this.uploadMediaContainer.changesOccurred) {
      this.fileContainersSet = false;
      this.checkClearChangesOccurred();
      return [];
    }
    if (this.files.length && ((!this.settingFileContainers && !this.fileContainersSet)
      || this.files.length !== this.oldFileLength)) {
      this.oldFileLength = this.files.length;
      this.setFileContainers().then();
    }
    const rowStart = this.pageNo * this.pageSize;
    return this.files.slice(rowStart, rowStart + this.pageSize);
  }

  getPages() {
    const res = [];
    const pageLen = Math.floor(this.files.length / this.pageSize);
    for (let t = 0 ; t < pageLen ; t++) {
      res.push(t);
    }
    return res;
  }

  setPage(pageNo: number) {
    this.pageNo = pageNo;
  }

  checkAll(checked: boolean, panel: MatExpansionPanel) {
    for (const file of this.files) {
      file['checked'] = checked;
    }
    if (checked) {
      this.promise = setTimeout(() => {
        this.copyFields().then();
      }, 500);
      panel.open();
    } else {
      if (this.promise) {
        clearTimeout(this.promise);
      }
      this.allChecked = false;
      panel.close();
    }
  }

  checkIfFileIsSelected() {
    return this.files.filter(file => file['checked']).length > 0 && !this.allChecked;
  }

  disableCommonReg() {
    return this.files.filter(file => file['checked']).length === 0;
  }

  fileChecked(file: BaseModel) {
    file.$$singleSelect = !file.$$singleSelect;
    if (file.$$singleSelect) {
      this.promise = setTimeout(() => {
        this.copyFields().then();
      }, 500);
    } else {
      if (this.promise) {
        clearTimeout(this.promise);
      }
    }
  }

  getObjectIdField(item: BaseModel) {
    return this.commons.getObjectIdField(item);
  }

  canAnnotate(file: BaseModel) {
    if (file.$$canAnnotate === undefined) {
      if (file.object_type === 'Image') {
        file.$$canAnnotate = false; // This will prevent repeating calls to canAnnotate
        this.annotationService.canAnnotate(this.parentObject).then(res => {
          file.$$canAnnotate = res;
        });
      } else {
        file.$$canAnnotate = false;
      }
    }
    return file.$$canAnnotate;
  }

  get curAnn(): AnnotationHandler {
    return this.currentObjectService.curAnn;
  }

  annotateImage(file: BaseModel) {
    for (const item of this.files) {
      if (item.artifact_id !== file.artifact_id) {
        item.$$annotateImage = false;
      } else {
        file.$$annotateImage = true;
      }
    }

    if (!this.parentObject.$$annotateImage && file.$$annotateImage) {
      this.curAnn.closeCallback = (curAnn: AnnotationHandler) => {
        if (curAnn.annotations && curAnn.annotations.length) {
          this.annotationService.saveAnnotations(curAnn).then(() => {
            this.parentObject.$$annotateImage = false;
          });
        }
        modalRef.close();
      };
      const image = new ImageItem();
      image.image_id = file.artifact_id;
      this.annotationService.setCurAnnotation(
        this.curAnn,
        this.parentObject,
        image,
        this.parentObject,
        null,
        false).then(() => {
        this.parentObject.$$annotateImage = true;
      });

      const modalRef = this.modalService.open(AnnotationDialogComponent, {
        panelClass: 'annotation-dialog-container',
        disableClose: true,
        restoreFocus: true,
        data: {
          file: file,
          parentObject: this.parentObject,
          parentId: this.parentObject.artifact_id,
          curAnn: this.curAnn
        } as AnnotationDialogComponentData
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    moveItemInArray(this.uploadMediaContainer.fileObjectSectionsContainers, event.previousIndex, event.currentIndex);
    for (const [index, item] of this.files.entries()) {
      item['order_number'] = index;
    }
  }

  deleteFile(pageIndex: number) {
    const index = this.pageNo * this.pageSize + pageIndex;
    const file = this.files[index];
    if (this.crud.getCreate(file)) {
      this.files.splice(index, 1);
    } else {
      this.crud.setDestroy(file);
    }
    this.deleteMedia.emit(file);
  }

  getFileContainer(file: BaseModel) {
    const objectId = this.getObjectId(file);
    return this.fileContainers[objectId];
  }

  private async setFileContainers() {
    let index = 0;
    this.settingFileContainers = true;
    for (const file of this.files) {
      if (this.getFileContainer(file)) {
        continue;
      }
      const sectionsContainer = await this.objectEditService.createModelItemGetSectionsContainerForPrimeFields(
        this.modName, <SuperObjectModel>file);
      sectionsContainer.isDialog = this.isDialog;
      const item = sectionsContainer.rootObject;
      this.crud.deleteCreate(item);
      this.files[index].order_number = index++;
      this.setItemMapping(item, file, sectionsContainer);
      const objectId = this.getObjectId(file);
      file.$$id = objectId;
      this.fileContainers[objectId] = sectionsContainer;
      this.uploadMediaContainer.fileObjects.push(sectionsContainer.rootObject);
      this.uploadMediaContainer.fileObjectSectionsContainers.push(sectionsContainer);
    }
    this.settingFileContainers = false;
    this.fileContainersSet = true;
  }

  private getObjectId(item: BaseModel) {
    const idField = this.commons.getObjectIdField(item);
    return item[idField];
  }


  private async copyFields() {
    const newValues = this.getNewCommonValues();
    if (newValues) {
      await this.copyNewCommonValuesToFileObjects(newValues);
      this.allChecked = this.files.filter(file => file['checked']).length === this.files.length;
      this.oldValues = newValues;
    }
    this.promise = setTimeout(() => {
      this.copyFields();
    }, 100);
  }

  private getNewCommonValues() {
    const values = {};
    let hasChanges = false;
    for (const editField of this.editFields) {
      values[editField] = this.fieldValueHandler.getFieldValue(this.commonObj, editField);
      if (!this.oldValues || values[editField] !== this.oldValues[editField]) {
        hasChanges = true;
        break;
      }
    }
    return hasChanges ? values : null;
  }

  private async copyNewCommonValuesToFileObjects(newValues: any) {
    let hadChanges = false;
    for (const [$index, file] of this.files.entries()) {
      if (!file['checked']) {
        continue;
      }
      const fileContainer: SectionsContainer = this.fileContainers[file.$$id];
      const changesOccurred = await this.setFieldValuesOnFileObject(fileContainer, newValues, $index);
      if (changesOccurred) {
        hadChanges = true;
      }
    }
    if (hadChanges) {
      this.uploadMediaContainer.changesOccurred = true;
    }
  }

  private async setFieldValuesOnFileObject(fileContainer: SectionsContainer, values: {}, $index: number): Promise<boolean> {
    if (!fileContainer) {
      return;
    }
    const fileObj = fileContainer.rootObject;
    let changesOccurred = false;
    for (const [name, value] of Object.entries(values)) {
      let origVal: any;
      if (value === null) {
        continue;
      }
      if (this.commonRegVisible) {
        const changed = await this.setFieldValue(fileContainer, name, value);
        if (!changed) {
          continue;
        }
        changesOccurred = true;
        await this.checkSetIconAndAuthority(fileContainer, name);
        this.highlightFieldElement(name, $index);
      } else {
        origVal = this.changeTracker.getOriginalFieldValue(fileObj, name);
        const changed = this.fieldValueHandler.setFieldValue(fileObj, name, origVal);
        if (changed) {
          this.highlightFieldElement(name, $index);
        }
      }
    }
    return changesOccurred;
  }

  private async checkSetIconAndAuthority(fileContainer: SectionsContainer, name: string) {
    const fieldParameters = this.getFieldParameters(fileContainer, name);
    if (fieldParameters.field.reference_id || fieldParameters.field.reference) {
      const reference = this.searchReferenceService.getSearchReferenceFromField(fieldParameters.field);
      await this.optionsService.setIconAndAuthority([fileContainer.rootObject], name, fieldParameters.field, reference);
    }

  }

  private getFieldParameters(sectionsContainer: SectionsContainer, fieldName: string): FieldParameters {
    const fieldParameters = new FieldParameters();
    fieldParameters.sectionsContainer = sectionsContainer;
    for (const primeField of sectionsContainer.primeFields) {
      if (fieldName.indexOf(primeField.name) !== -1) {
        fieldParameters.field = primeField;
        fieldParameters.object = sectionsContainer.rootObject;
        const pathSplit = fieldName.split(System.keySep);
        for (const [index, pathName] of pathSplit.entries()) {
          if (index < pathSplit.length - 1) {
            fieldParameters.object = fieldParameters.object[pathName];
          }
        }
      }
    }
    return fieldParameters;
  }


  private setFieldValue(sectionsContainer: SectionsContainer, fieldName: string, fieldValue: any): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const fieldParameters = this.getFieldParameters(sectionsContainer, fieldName);
      if (fieldParameters.field) {
        this.fieldValueHandler.setFieldValueAndControlValue(
          fieldParameters, fieldParameters.object, fieldParameters.field, fieldValue).then(
          changed => {
            resolve(changed);
          }
        );
      } else {
        console.warn('No prime field named ' + fieldName + ' found');
        resolve(false);
      }
    });
  }

  private highlightFieldElement(fieldName: string, index: number) {
    const fileIndex = index + 2;
    const fieldElement = document.getElementsByName(fieldName);
    if (fieldElement[fileIndex]) {
      fieldElement[fileIndex]['classList'].add('field-highlighted');
    }
  }

  private setItemMapping(item: SuperObjectModel, file: BaseModel, sectionsContainer: SectionsContainer) {
    if (!this.uploadMediaContainer.uploadInfo.item_mapping) {
      return;
    }
    let separator = '';
    for (const mapping of this.uploadMediaContainer.uploadInfo.item_mapping) {
      let targetVal = '';
      const sourceFields = mapping.source_fields || [];
      for (const sourceFieldInfo of sourceFields) {
        const sourceType = sourceFieldInfo.source_type;
        const sourceField = sourceFieldInfo.source_field;
        let source = {};
        switch (sourceType) {
          case 'art':
            source = this.parentObject;
            break;
          case 'file':
            source = file;
            break;
        }
        const sourceVal = source[sourceField];
        if (sourceVal) {
          targetVal += separator + sourceVal;
          separator = mapping.separator;
        }
      }
      const targetField = mapping.target_field;
      const fieldMeta: MetaField = item.$$meta[targetField];
      const maxLength = fieldMeta.validation ? fieldMeta.validation.max_length : fieldMeta.max_length;
      if (maxLength) {
        targetVal = targetVal.slice(0, maxLength);
      }
      this.setFieldValue(sectionsContainer, targetField, targetVal).then();
    }
  }

  private checkClearChangesOccurred() {
    if (this.setChangedTimeout === -1) {
      this.setChangedTimeout = setTimeout(() => {
        this.uploadMediaContainer.changesOccurred = false;
        this.setChangedTimeout = -1;
      }, 100);
    }
  }

  protected readonly SearchObject = SearchObject;
}
