import { Component, effect, input, output } from '@angular/core';
import { ExtendedSearchField } from '../../../core/definitions/extended-search-field';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTree,
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeNode,
  MatTreeNodeDef,
  MatTreeNodePadding, MatTreeNodeToggle
} from '@angular/material/tree';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ExtendedSearchService } from '../../../core/extended-search.service';
import { ExtendedFieldQuery, ExtendedFieldQueryGroup } from '../../../core/definitions/extended-search-params';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRipple } from '@angular/material/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-extended-search-query-builder-value-display',
  standalone: true,
  imports: [
    MatTree,
    MatTreeNode,
    MatTreeNodeDef,
    MatTreeNodePadding,
    MatIconButton,
    MatIcon,
    MatTreeNodeToggle,
    MatButton,
    MatTooltip,
    NgClass,
    TranslateModule,
    MatCheckbox,
    MatRipple
  ],
  templateUrl: './extended-search-query-builder-value-display.component.html',
  styleUrl: './extended-search-query-builder-value-display.component.scss'
})
export class ExtendedSearchQueryBuilderValueDisplayComponent {
  canSelectParentNode = input<boolean>(false);
  expandNodes = input<boolean>(false);
  fields = input<ExtendedSearchField[]>([]);
  filterQuery = input<string>('');
  inputId = input<string>('');
  internalFiltering = input<boolean>(false);
  multiSelect = input<boolean>(false);
  parentField = input.required<ExtendedFieldQuery>();
  parentGroup = input<ExtendedFieldQueryGroup>();
  rootGroup = input<ExtendedFieldQueryGroup>();
  values = input([]);

  // I know I said no outputs, but I promise, these two are necessary and only here to close the dropdown
  selectedNode = output<string>();
  selectedArrayNode = output<void>();

  treeController = new FlatTreeControl(
    this.getNodeLevel,
    this.isExpandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    this.getNodeLevel,
    this.isExpandable,
    this.getNodeChildren
  )

  treeFieldDataSource = new MatTreeFlatDataSource(
    this.treeController,
    this.treeFlattener
  )

  constructor (
    private readonly extendedSearchService: ExtendedSearchService,
    private readonly platform: Platform
  ) {

    effect(() => {
      this.treeFieldDataSource.data = this.fields().length ? this.fields() : this.values();
    });

    effect(() => {
      if (this.fields().length && this.values().length) {
        throw new Error('Cannot display both fieldtypes and valuetypes');
      }

      if (this.fields().length) {
        for (const field of this.fields()) {
          this.setNodeVisibility(field, this.filterQuery());
        }

        this.treeFieldDataSource.data = this.fields();

        if (this.filterQuery() !== '') {
          this.treeController.expandAll();
        }
      }

      if (this.values().length) {
        for (const field of this.values()) {
          this.setNodeVisibility(field, this.filterQuery());
        }

        this.treeFieldDataSource.data = this.values();

        if (this.expandNodes()) {
          this.treeController.expandAll();
        }
        else {
          this.treeController.collapseAll();
        }
      }
    });
  }

  nodeIsUsed(node): boolean {
    if (this.isExpandable(node)) {
      if (node.used && this.parentGroup().field_logical_operator !== 'OR') {
        this.treeController.collapse(node);
      }
    }
    return node.used && this.parentGroup().field_logical_operator !== 'OR'
  }

  requiresNewGroup(node): 'no' | 'newGroup' | 'newSubgroup' | 'moveExistingToSubgroup' {
    // Prevents any chance of marking and marking new group in the valueselector
    if (!this.fields().length || node.input_type === 'marker' || node.input_type === 'divider') {
      return 'no';
    }

    const relationInfo = this.extendedSearchService.getRelationInfoFromGroup(this.parentGroup(), this.rootGroup());
    const relationId = relationInfo?.superobjectTypeId;

    const groupHasFields = this.extendedSearchService.groupHasOneOrMoreFieldsSelected(this.parentGroup());

    const relationInfoMatchesField = (!!relationInfo?.childDocumentType && relationInfo?.childDocumentType === node.child_document_type) ||
                                     (!!relationInfo?.contextField && relationInfo?.contextField === node.context_field) ||
                                     (!!relationId && relationId === node.superobject_type_id);

    

    // Is there more than one field in this group? OR is there relationInfo on the group or parent group?
    if (groupHasFields || relationInfo !== null) {
      // Does this field match the relation info?
      if (relationInfoMatchesField) {
        return 'no';
      }

      // If "root" is NOT set as restriction name and also the nodes parent restriction
      if (this.parentGroup().restriction_name !== 'root' && this.parentGroup().restriction_name === node.restriction?.parent) {
        return 'no';
      }
      // If group has restriction name set
      if (this.parentGroup().restriction_name) {
        
        // If restriction name is root and nodes only parent is root
        if (this.parentGroup().restriction_name === 'root' && 
        // @ts-ignore
            this.parentGroup().restriction_parent_name === 'root' && 
            node.restriction?.parents.length === 1 && 
            node.restriction?.parents[0] === 'root') {
          return 'no';
        }
        // If group has restriction set and nodes new_group is "no"
        else if (node.restriction.new_group === 'no') {
          return 'newGroup';
        }
        else {
          for (const parent of node.restriction?.parents ?? []) {
            if (parent === this.parentGroup().restriction_name) {
              if (node.restriction?.new_group === 'relationSubgroup' || node.restriction?.new_group === 'subgroup') {
                return 'newSubgroup';
              }
            }
            // @ts-expect-error restriction_parent_name is not defined in interface, but exists
            if (parent === this.parentGroup().restriction_parent_name) {
              // @ts-ignore
              if (node.restriction?.new_group === 'relationGroup' && this.parentGroup().field_queries[0].restriction?.new_group === 'relationSubgroup') {
                return 'moveExistingToSubgroup';
              }
            }
          }
          // At this point, it has checked that there IS field restrictions AND group restrictions and that these does not match
          // thus needing to put the new field in a new group of its own.
          return 'newGroup';
        } 
      } 
      else if (node.restriction.new_group !== 'no') {
        return 'newGroup';
      }
    }

    return 'no';
  }

  requiresSqlMode(node): boolean {
    return node.restriction?.sql_mode;
  }

  valueIsInSelectedArray(node): boolean {
    if (Array.isArray(this.parentField().valueDisplay) && this.parentField().valueDisplay.length) {
      return this.parentField().valueDisplay.includes(node.artifact_name);
    }
    return false;
  }

  clickedNode(node, expandClick: boolean = true) {
    // Ignore marker and divider nodes
    if (node.input_type === 'marker' || node.input_type === 'divider') {
      return;
    }

    if (node.used && this.parentGroup().field_logical_operator !== 'OR') {
      return;
    }
    
    if (expandClick) {
      this.treeController.toggle(node);
    }
    else {
      if (this.fields().length) {
        const needsNewGroup = this.requiresNewGroup(node);
        if (needsNewGroup !== 'no') {
          if (needsNewGroup === 'newGroup') {
            this.extendedSearchService.setSelectedFieldInNewSiblingGroup(node, this.parentField());
          }

          if (needsNewGroup === 'newSubgroup') {
            this.extendedSearchService.setSelectedfieldInNewSubGroup(node, this.parentField());
          }

          if (needsNewGroup === 'moveExistingToSubgroup') {
            this.extendedSearchService.setSelectedFieldAndMoveExistingToSubGroup(node, this.parentField());
          }
        }
        else {
          this.extendedSearchService.setSelectedField(node, this.parentField());
        }
      }

      if (this.values().length) {
        if (this.multiSelect()) {
          if (this.valueIsInSelectedArray(node)) {
            this.extendedSearchService.removeArrayValueFromField(this.parentField(), node);
          }
          else {
            this.extendedSearchService.addArrayValueToField(this.parentField(), node);
          }
        }
        else {
          this.extendedSearchService.setSelectedFieldValue(this.parentField(), node);
        }
      }

      if (this.multiSelect()) {
        this.selectedArrayNode.emit();
      }
      else {
        this.selectedNode.emit(this.fields().length ? node.field_title : node.artifact_name);

        // Focus the input element using the inputId
        setTimeout(() => {
          const inputElement = document.getElementById(this.inputId());
          if (inputElement) {
            inputElement.focus();
          }
        });
      }
    }
  }

  focusNextNode(event) {
    event.preventDefault();
    let elements = document.querySelectorAll('.value-tree__node:not(.value-tree__node--hidden) > .value-tree__node-button');

    for (let i = 0; i < elements.length; i++) {
      if (elements[i] === document.activeElement) {
        if (i !== elements.length - 1) {
          // @ts-ignore
          elements[i + 1].focus();
        }

        return;
      }
    }
  }

  focusPreviousNode(event) {
    event.preventDefault();
    let elements = document.querySelectorAll('.value-tree__node:not(.value-tree__node--hidden) > .value-tree__node-button');

    // Check if the active element is the first visible node
    if (document.activeElement === elements[0]) {
      document.getElementById(this.inputId()).focus();
      return; // Exit the function early to avoid further processing
    }

    // Existing logic for navigating to the previous node
    for (let i = 0; i < elements.length; i++) {
      if (elements[i] === document.activeElement) {
        if (i > 0) {
          // @ts-ignore
          elements[i - 1].focus();
        }
        return;
      }
    }
    }

    toggleNodeExpand(node, event, expand) {
    event.preventDefault();
    if(expand) {
      this.treeController.expand(node);
    }
    else {
      this.treeController.collapse(node);
    }
  }

  hasSiblingFields(): string {
    if (this.values().length || !!this.parentField().field_title) {
      return '';
    }
    
    if (this.parentGroup()?.header !== 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION') {
      return this.parentGroup()?.header ?? 'N/A';
    }

    return '';
  }

  getNodeNameWithHighlights(node: ExtendedSearchField): string {
    const nodeName = node.hasOwnProperty('field_title') 
      ? node.field_title.split(' / ')[node.field_title.split(' / ').length -1] 
      // @ts-ignore
      : node.artifact_name;

    if (this.filterQuery() === '') {
      return nodeName;
    }
    
    return nodeName.replace(new RegExp(this.filterQuery(), 'gi'), (match: string) => {
      return `<span class="highlighted-extended-search-tree-view">${match}</span>`;
    });
  }

  nodeTabbed(event) {
    event.preventDefault();
    document.getElementById(this.inputId()).focus();
  }

  nodeHasChildren(_: number, node: ExtendedSearchField): boolean {
    return !!node.children && node.children.length > 0;
  }

  private getNodeChildren(node: ExtendedSearchField): ExtendedSearchField[] {
    return node.children;
  }

  private getNodeLevel(node: ExtendedSearchField): number {
    return node.level;
  }

  private isExpandable(node: ExtendedSearchField): boolean {
    return !!node.children && node.children.length > 0;
  }

  private setNodeVisibility(node, query: string, parent?): void {
    if (this.internalFiltering()) {
      if (query === '') {
        node.visible = true;
      }
      else {
        if (this.fields().length) {
          node.visible = node.field_title.toLowerCase().indexOf(query.toLowerCase()) !== -1;

          if (parent && node.visible) {
            parent.visible = true;
          }
        }

        if(this.values().length) {
          node.visible = node.artifact_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;

          if (parent && node.visible) {
            parent.visible = true;
          }
        }
      }
    }
    else {
      node.visible = true;
    }

    if (!!node.children && node.children.length > 0) {
      for (const child of node.children) {
        this.setNodeVisibility(child, query, node);
      }
    }
  }

  private transformer(node, level: number) {
    const supplemental = {
      expandable: this.isExpandable(node),
      name: node.hasOwnProperty('field_title') ? node.field_title.split(' / ')[node.field_title.split(' / ').length -1] : node.artifact_name,
      level,
    }

    return {
      ...node,
      ...supplemental
    }
  }
}
