@if (hasSiblingFields() !== '') {
  <h4 class="sibling-fields-heading">
    @if (hasSiblingFields() === 'N/A') {
      {{ 'TRANS__EXTENDED_SEARCH__SIBLING_HEADER_GENERIC' | translate}}
    }
    @else {
      {{ hasSiblingFields() | translate }} {{ 'TRANS__EXTENDED_SEARCH__FIELDS' | translate }}
    }
  </h4>
}

@if (values().length && filterQuery()?.length < 2) {
  <span class="limit-descriptor">
    {{'TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__SHOWING_MOST_USED' | translate}}
  </span>
}

<mat-tree
  class="value-tree"
  [dataSource]="treeFieldDataSource"
  [treeControl]="treeController"
>
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodePadding
    class="value-tree__node"
    matTreeNodePaddingIndent="16"
    [disabled]="node.input_type === 'marker' || node.input_type === 'divider'"
    [ngClass]="{
      'value-tree__node--hidden' : !node.visible,
      'value-tree__node--used' : nodeIsUsed(node),
      'value-tree__node--divider' : node.input_type === 'divider'
      }"
    (keydown.arrowUp)="focusPreviousNode($event)"
    (keydown.arrowDown)="focusNextNode($event)"
    (keydown.shift.tab)="nodeTabbed($event)"
    (keydown.tab)="nodeTabbed($event)"
  >
    <!-- Disabled button to preserve indent in leaf-nodes -->
    @if (node.input_type !== 'divider') {
      <button
        disabled
        mat-icon-button
        class="value-tree__node-icon-button"
        tabindex="-1"></button>
    }

    @if (node.input_type !== 'divider') {
      @if (multiSelect()) {
        <mat-checkbox
          (click)="clickedNode(node, false)"
          (keyDown)="null"
          [checked]="valueIsInSelectedArray(node)"
        />
      }

      <button
        mat-button
        class="value-tree__node-button"
        [ngClass]="{'value-tree__node-button--used': nodeIsUsed(node)}"
        (click)="clickedNode(node, false)"
        (mousedown)="$event.preventDefault()"

      >
        <span class="value-tree__node-button-text" [innerHTML]="getNodeNameWithHighlights(node)"></span>
        @if (node.used) {
          <span class="value-tree__node-postfix">I bruk</span>
        }
      </button>
      @if (requiresSqlMode(node)) {
        <span class="value-tree__node-postfix">
          {{ 'TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__REQUIRES_SQL_MODE' | translate }}
        </span>
      }
      @if (requiresNewGroup(node) === 'newGroup') {
        <span class="value-tree__node-postfix">
          {{ 'TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__REQUIRES_NEW_GROUP' | translate }}
        </span>
      }

      @if (requiresNewGroup(node) === 'newSubgroup') {
        <span class="value-tree__node-postfix">
          {{ 'TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__REQUIRES_NEW_SUBGROUP' | translate }}
        </span>
      }
    } @else {
      <hr class="value-tree__divider" />
    }
  </mat-tree-node>

  <mat-tree-node
    matRipple
    *matTreeNodeDef="let node; when nodeHasChildren"
    matTreeNodePadding
    matTreeNodePaddingIndent="16"
    class="value-tree__node"
    [disabled]="node.input_type === 'marker' || node.input_type === 'divider'"
    [ngClass]="{
      'value-tree__node--hidden' : !node.visible,
      'value-tree__node--used' : nodeIsUsed(node),
      'value-tree__node--divider' : node.input_type === 'divider'
      }"
    [matTooltip]="'TRANS__EXTENDED_SEARCH__DISABLED_PARENT_TOOLTIP' | translate"
    [matTooltipDisabled]="!nodeIsUsed(node)"
    (keydown.arrowUp)="focusPreviousNode($event)"
    (keydown.arrowDown)="focusNextNode($event)"
    (keydown.arrowLeft)="toggleNodeExpand(node, $event, false)"
    (keydown.arrowRight)="toggleNodeExpand(node, $event, true)"
    (keydown.shift.tab)="nodeTabbed($event)"
    (keydown.tab)="nodeTabbed($event)"
  >
    @if (node.input_type !== 'divider') {
      <button
        mat-icon-button
        disableRipple
        matTreeNodeToggle
        class="value-tree__node-icon-button"
        tabindex="-1"
        [disabled]="nodeIsUsed(node)"
      >
        <mat-icon>
          {{ treeController.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>

      @if (multiSelect() && canSelectParentNode()) {
        <mat-checkbox
          (click)="clickedNode(node, false)"
          (keyDown)="null"
          [checked]="valueIsInSelectedArray(node)"
        />
      }

      <button
        mat-button
        disableRipple
        class="value-tree__node-button"
        [ngClass]="{'value-tree__node-button--used': nodeIsUsed(node)}"
        (click)="clickedNode(node, !canSelectParentNode())"
        (mousedown)="$event.preventDefault()"
      >
        <span class="value-tree__node-button-text" [innerHTML]="getNodeNameWithHighlights(node)"></span>
      </button>
    } @else {
      <hr class="value-tree__divider" />
    }
  </mat-tree-node>

</mat-tree>

@if (values().length && filterQuery()?.length < 2) {
  <span class="limit-descriptor">
    {{'TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__TYPE_TO_SHOW_MORE' | translate}}
  </span>
}
