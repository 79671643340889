import {UploadInfo} from './upload-info';
import {SuperObjectModel} from './super-object-model';
import {SectionsContainer} from './sections-container';
import {MediaItem} from "./media-item";

export class UploadMediaContainer {
  uploadInfo: UploadInfo;
  files: Array<MediaItem> = [];
  fileObjects: Array<any> = [];
  parentObject: SuperObjectModel;
  name: string;
  fileObjectSectionsContainers: SectionsContainer[] = [];
  changesOccurred = false;
}

