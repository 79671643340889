import { Component, isDevMode, OnInit } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { AConst } from 'src/app/core/a-const.enum';
import { CmsApiService } from 'src/app/core/cms-api.service';
import { LoggerService } from 'src/app/core/logger.service';
import { PrimusBackendInstanceService } from 'src/app/core/primus-backend-instance.service';
import {TranslateModule} from "@ngx-translate/core";
import { IdleService } from 'src/app/shared/idle.service';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-messages-and-notification-v2',
  standalone: true,
  imports: [
    MatBadge,
    MatButton,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuTrigger,
    MatTooltip,
    TranslateModule
  ],
  templateUrl: './messages-and-notification-v2.component.html',
  styleUrl: './messages-and-notification-v2.component.scss',
})
export class MessagesAndNotificationV2Component implements OnInit { 
  currentMessageIndex: number = 0;
  localStorageKey: string = 'primus.readMessages';
  messages: any[] = [];
  waitTime: number = 600000;

  constructor(
    private readonly cmsApiService: CmsApiService,
    private readonly idleService: IdleService,
    private readonly logger: LoggerService,
    private readonly primusBackendInstanceService: PrimusBackendInstanceService
  ) {}

  ngOnInit(): void {
    setInterval(() => {
      if (this.idleService.idle()) {
        if (isDevMode()) {
          this.logger.debug('Idle, skipping notifications check');
        }
        return;
      }

      this.getMessage().then(() => {
        return this.getUpcomingVersion();
      });

      // Resets index if it's out of bounds after refresh of messages
      if (this.currentMessageIndex > this.messages.length - 1) {
        this.currentMessageIndex = 0;
      }
    }, this.waitTime);

    this.getMessage().then(() => {
      return this.getUpcomingVersion();
    });
  }

  async getMessage(): Promise<void> {
    try {
      let data: any;
      data = await this.cmsApiService.getMessage();
      if (data[AConst.MESSAGES]) {
        this.messages = data[AConst.MESSAGES]
      }
    }
    catch (response) {
      this.logger.info('Getting messages failed');
      if (response.status === 500) {
        this.logger.info('Trying refresh token');
        this.primusBackendInstanceService.setRefreshToken().then();
      }
    }
  }

  async getUpcomingVersion(): Promise<void> {
    const data = await this.cmsApiService.getUpcomingVersion();
    let id, index, exist;

    if (data[AConst.MESSAGE]) {
      id = data[AConst.MESSAGE].id;
      index = this.messages.findIndex((message) => message.id === id);
      exist = this.messages[index];

      if (!exist) {
        this.messages.push(data[AConst.MESSAGE]);
      } else {
        if (JSON.stringify(data[AConst.MESSAGE].description) !== JSON.stringify(exist.description)) {
          this.messages[index].description = data[AConst.MESSAGE].description;
        }
        if (JSON.stringify(data[AConst.MESSAGE].title) !== JSON.stringify(exist.title)) {
          this.messages[index].title = data[AConst.MESSAGE].title;
        }
      }
    }
  }

  get hasUnreadMessages(): number {
    let readItems = localStorage.getItem(this.localStorageKey)?.split(',') ?? [];

    for (const message of this.messages) {
      if (!readItems.includes(message.id.toString())) {
        return 1;
      }
    }

    return null
  }

  nextMessage(event) {
    event.stopPropagation();

    if (this.currentMessageIndex < this.messages.length - 1) {
      this.currentMessageIndex++;
    }
    else {
      this.currentMessageIndex = 0;
    }

    this.setActiveMessageAsRead();
  }

  previousMessage(event) {
    event.stopPropagation();

    if (this.currentMessageIndex > 0) {
      this.currentMessageIndex--;
    }
    else {
      this.currentMessageIndex = this.messages.length - 1;
    }

    this.setActiveMessageAsRead();
  }

  replaceMessageUrlWithActualLink(message: string) {
    // Retain new-lines in the message before adding URLS
    const messageWithLineBreaks = message.replace(/\n/g, '<br>');
    
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    return messageWithLineBreaks.replace(urlRegex, (match) => `<a class="messages__link" href="${match}" target="_blank">${match}</a>`);
  }

  setActiveMessageAsRead() {
    let readItems = localStorage.getItem(this.localStorageKey)?.split(',') ?? [];

    if (!readItems.includes(this.messages[this.currentMessageIndex].id.toString())) {
      readItems.push(this.messages[this.currentMessageIndex].id);
      localStorage.setItem(this.localStorageKey, readItems.join(','));
    }
  }
}