import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import { JobStatusItem } from 'src/app/core/definitions/job-status-item';
import {PrimusStateMapperService} from '../../../core/primus-state-mapper.service';
import {saveAs} from "file-saver";

@Component({
  selector: 'app-job-status-message-list',
  templateUrl: './job-status-message-list.component.html',
  styleUrls: ['./job-status-message-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobStatusMessageListComponent implements OnInit {

  @Input() message: JobStatusItem;
  @Input() afterExpand: any;
  @Input() afterCollapse: any;
  @Input() downloadReport: any;
  @Input() downloadReportV2: any;

  @Output() showError = new EventEmitter<JobStatusItem>();
  @Output() closePanel = new EventEmitter<void>();

  constructor(public primusStateMapper: PrimusStateMapperService) { }

  ngOnInit(): void {

  }
  onShowError(m) {
    this.showError.emit(m);
  }

  onClosePanel() {
    this.closePanel.emit();
  }
}
