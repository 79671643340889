import {Injectable} from '@angular/core';
import {SearchResult} from '../core/definitions/search-result';
import {SearchObject} from '../core/definitions/search-object';
import {SearchParameterService} from './search-parameter.service';
import {SearchContainer} from '../core/definitions/search-container';
import {ContentListSourceContainer} from '../core/definitions/object-content-tab/content-list-source-container';
import {BaseModel} from '../core/definitions/base-model';
import {SearchService} from "../core/search.service";
import {FeatureFlagsService} from "../core/feature-flags.service";
import {AppNotification, NotificationService} from "../shared/notification.service";
import {ResultViewService} from "./result-view.service";
import {SearchResultViewType} from "../core/definitions/search-result-view-type.enum";

@Injectable({
  providedIn: 'root'
})
export class SearchResultSelectionsService {
  private readonly SELECTION_LIMIT = 2000;

  constructor(private searchParameterService: SearchParameterService,
              private searchService: SearchService,
              private featureFlagService: FeatureFlagsService,
              private notificationService: NotificationService,
              private resultViewService: ResultViewService) {
  }

  selectionsChanged(searchContainer: SearchContainer) {
    let res = false;
    const originalItems = searchContainer.selections.originalItems;
    const selected = searchContainer.selections.selectedItems;

    if (searchContainer.selections.singleSelect) {
      res = selected.length !== 0;
    } else {
      if (originalItems.length !== selected.length) {
        res = true;
      } else {
        for (const origItem of originalItems) {
          if (selected.indexOf(origItem) === -1) {
            res = true;
            break;
          }
        }
      }
    }
    return res;
  }

  async selectAll(searchContainer: SearchContainer, contentListSource?: ContentListSourceContainer) {
    let newItems: any[];
    if (!contentListSource) {
      // Getting "all" artifacts was originally used in connection with scroll based search in order to obtain
      // all the artifacts that matches the search criteria (limited to 1000 objects).
      // Now, with page-based scroll, only the artifacts within the search page are obtained.
      // It may not even be necessary to do the "get all artifacts" call when finally switching to new search views
      newItems = await this.getAllArtifactsFromSearch(searchContainer);
      searchContainer.searchResult.artifacts = newItems;
    } else {
      newItems = contentListSource.items.map(item => item.object);
    }
    let addOk = true;
    if (searchContainer.selections.allSelected) {
      for (const newItem of newItems) {
        if (this.selectedIndexOf(searchContainer, newItem) === -1) {
          if (!this.addItem(searchContainer, newItem)) {
            addOk = false;
            break;
          }
        }
      }
      if (addOk && this.featureFlagService.getFeatureFlags().experimental.useNewMainMenu) {
        searchContainer.selections.allSelectedForPage[searchContainer.searchPage] = true;
      }
    } else {
      for (const newItem of newItems) {
        this.removeItemIdById(searchContainer, newItem, true);
      }
      if (this.featureFlagService.getFeatureFlags().experimental.useNewMainMenu) {
        searchContainer.selections.allSelectedForPage[searchContainer.searchPage] = false;
      }
    }
    if (!contentListSource) {
      this.toggleSelectedItems(searchContainer);
    } else {
      this.toggleSelectedItemsContentListSource(searchContainer, contentListSource);
    }
    if (searchContainer.selections.selectItemCallback) {
      searchContainer.selections.selectItemCallback();
    }
    if (!addOk) {
      this.warnTooMany();
    }
    return addOk;
  }

  selectSearchResultItem(obj: SearchObject, searchContainer: SearchContainer, shiftKey: boolean): { unselectedItems: SearchObject[] } {
    if (shiftKey && searchContainer.selections.firstSelectedObject) {
      const result = this.checkSelectRange(obj, searchContainer, shiftKey);
      if (result.success) {
        if (searchContainer.selections.selectItemCallback) {
          searchContainer.selections.selectItemCallback();
        }
        return { unselectedItems: result.unselectedItems };
      }
    }

    const existPos = this.selectedIndexOf(searchContainer, obj);
    let selected: boolean;
    if (!searchContainer.selections.singleSelect) {
      selected = existPos === -1;
    } else {
      selected = !(searchContainer.selections.selectedItems.length &&
        this.getItemId(obj) === this.getItemId(searchContainer.selections.selectedItems[0]));
    }
    const addOk = this.setSelected(searchContainer, obj, selected, existPos);
    if (!shiftKey) {
      searchContainer.selections.firstSelectedObject = obj;
    }
    if (searchContainer.selections.selectItemCallback) {
      searchContainer.selections.selectItemCallback();
    }
    return { unselectedItems: !addOk ? [obj] : [] };
  }

  setSelectedUsed(searchContainer: SearchContainer, searchRes: SearchResult) {
    const sel = searchContainer.selections.selectedItems.map(item => item.artifact_id);
    const used = searchContainer.used;
    if (sel.length > 0 || used.length > 0) {
      for (const art of searchRes.artifacts) {
        this.checkItemInArray(searchContainer, sel, art, 'selected');
        this.checkItemInArray(searchContainer, used, art, 'used');
      }
    }
  }

  toggleSelectedItems(searchContainer: SearchContainer) {
    const sc = searchContainer;
    const itemIds = sc.selections.selectedItems.map(selectedItem => this.getItemId(selectedItem));
    for (const item of sc.searchResult.artifacts) {
      const itemId = this.getItemId(item);
      searchContainer.selections.selected[itemId] = false;
      if (itemIds.length) {
        const index = itemIds.indexOf(itemId);
        if (index !== -1) {
          searchContainer.selections.selected[itemId] = true;
          itemIds.splice(index, 1);
        }
      }
    }
  }

  toggleSelectedItemsContentListSource(searchContainer: SearchContainer, contentListSource: ContentListSourceContainer) {
    const itemIds = this.getSelectedItemIds(searchContainer);
    for (const pageItem of contentListSource.pageItems) {
      pageItem.checked = false;
      if (itemIds.length) {
        const index = itemIds.indexOf(this.getItemId(pageItem.listItem.object));
        if (index !== -1) {
          pageItem.checked = true;
          itemIds.splice(index, 1);
        }
      }
    }
  }

  clearSelections(searchContainer: SearchContainer) {
    this.clearAllSelections(searchContainer);
    if (searchContainer.selections.selectItemCallback) {
      searchContainer.selections.selectItemCallback();
    }
  }

  setSelections(searchContainer: SearchContainer, items: SearchObject[]) {
    searchContainer.selections.originalItems = [...items];
    if (items && items.length) {
      for (const item of items) {
        if (!this.addItem(searchContainer, item)) {
          return false;
        }
      }
    }
    return true;
  }

  removeItemIdById(searchContainer: SearchContainer, deleteItem: SearchObject, skipCallback?: boolean) {
    let deleteIndex = -1;
    const deleteItemId = this.getItemId(deleteItem);
    searchContainer.selections.selectedItems.forEach((selectedItem, index) => {
      if (deleteItemId === this.getItemId(selectedItem)) {
        deleteIndex = index;
      }
    });
    this.removeItemIdByIndex(searchContainer, deleteIndex);
    if (searchContainer.selections.selectItemCallback && !skipCallback) {
      searchContainer.selections.selectItemCallback();
    }
  }

  removeItemIdByIndex(searchContainer: SearchContainer, deleteIndex: number) {
    if (deleteIndex !== -1) {
      searchContainer.selections.selectedItems.splice(deleteIndex, 1);
    }
  }

  getItemId(obj: BaseModel): string {
    let res: string;
    const relationIdFields = ['relation_id', 'image_id', 'video_id', 'attachment_id', 'text_block_id'];
    if (obj.artifact_id) {
      res = obj.artifact_id;
    } else {
      for (const relationIdField of relationIdFields) {
        res = obj[relationIdField];
        if (res) {
          break;
        }
      }
    }
    if (!res) {
      console.warn('Unable to get an item id from ' + JSON.stringify(obj));
    }
    return res;
  }

  getSelectedItemIds(searchContainer: SearchContainer) {
    return searchContainer.selections.selectedItems.map(selectedItem => this.getItemId(selectedItem));
  }

  // Get items without $$ props
  getCleanItems(searchContainer: SearchContainer): SearchObject[] {
    return searchContainer.selections.selectedItems.map(item => this.cleanItem(item));
  }

  private setSelected(searchContainer: SearchContainer, searchObject: SearchObject, selected: boolean, existPos: number) {
    let addOk = true;
    if (selected) {
      if (searchContainer.selections.singleSelect) {
        this.clearAllSelections(searchContainer);
        this.addItem(searchContainer, searchObject);
        this.setSelectedUsed(searchContainer, searchContainer.searchResult);
      } else {
        addOk = this.addItem(searchContainer, searchObject);
      }
      if (addOk) {
        this.setSelectedDelayed(searchContainer, searchObject, true);
      }
    } else {
      this.removeItemIdByIndex(searchContainer, existPos);
      this.setSelectedDelayed(searchContainer, searchObject, false);
    }
    if (!addOk) {
      this.warnTooMany();
    }
    return addOk;
  }

  private setSelectedDelayed(searchContainer: SearchContainer, searchObject: SearchObject, selected: boolean) {
    setTimeout(() => {
      // Delay required for setting selected, else GUI will not register the change
      searchContainer.selections.selected[this.getItemId(searchObject)] = selected;
    }, 100);
  }

  private checkSelectRange(obj: SearchObject, searchContainer: SearchContainer, shiftKey: boolean): { success: boolean; unselectedItems: SearchObject[] } {
    let searchObjects: SearchObject[] = [];
    if (searchContainer.cachedScrollDataColumns?.length) {
      const rows = searchContainer.cachedScrollDataColumns.filter(row => row !== undefined);
      for (const row of rows) {
        searchObjects = searchObjects.concat(row.columns.filter(column => column !== undefined));
      }
    } else if (searchContainer.cachedScrollDataList?.length && searchContainer.cachedScrollDataList[0]) {
      searchObjects = searchContainer.cachedScrollDataList.filter(item => item !== undefined);
    } else {
      searchObjects = searchContainer.searchResult.artifacts;
    }

    return this.selectRange(obj, searchContainer, searchObjects);
  }

  private selectRange(obj: SearchObject, searchContainer: SearchContainer, searchObjects: SearchObject[]): { success: boolean; unselectedItems: SearchObject[] } {
    let loopStart: number, loopEnd: number;
    const searchIndex = searchObjects.findIndex(item => this.getItemId(item) === this.getItemId(obj));
    const firstSelectedIndex = searchObjects.findIndex(
      item => this.getItemId(item) === this.getItemId(searchContainer.selections.firstSelectedObject));
    if (searchIndex === -1 || firstSelectedIndex === -1) {
      // Add item if searchIndex !== -1, to prevent empty marking.
      if (searchIndex !== -1) {
        const existPos = this.selectedIndexOf(searchContainer, searchObjects[searchIndex]);
        if (existPos === -1) {
          this.setSelected(searchContainer, searchObjects[searchIndex], true, -1);
        }
      }
      console.warn('Unable to find item');
      return { success: false, unselectedItems: [] };
    }

    // Always start from the first selected item and work outwards
    loopStart = firstSelectedIndex;
    const direction = searchIndex < firstSelectedIndex ? -1 : 1;
    let currentIndex = loopStart;
    let addedCount = 0;
    let addOk = true;
    const itemsToSelect: SearchObject[] = [];
    const unselectedItems: SearchObject[] = [];

    // First check which items we can add, starting from the first selected item
    while (currentIndex !== searchIndex + direction && addOk) {
      const searchObj = searchObjects[currentIndex];
      const existPos = this.selectedIndexOf(searchContainer, searchObj);
      if (existPos === -1) {
        if (searchContainer.selections.selectedItems.length + itemsToSelect.length >= this.SELECTION_LIMIT) {
          addOk = false;
          // Add remaining items to unselected
          let remainingIndex = currentIndex;
          while (remainingIndex !== searchIndex + direction) {
            const remainingObj = searchObjects[remainingIndex];
            if (this.selectedIndexOf(searchContainer, remainingObj) === -1) {
              unselectedItems.push(remainingObj);
            }
            remainingIndex += direction;
          }
          break;
        }
        itemsToSelect.push(searchObj);
      }
      currentIndex += direction;
    }

    // Then add them and update their selected state
    for (const searchObj of itemsToSelect) {
      this.addItem(searchContainer, searchObj);
      searchContainer.selections.selected[this.getItemId(searchObj)] = true;
      addedCount++;
    }

    if (!addOk) {
      this.warnTooMany();
    }
    return { success: addedCount > 0, unselectedItems };
  }

  private clearAllSelections(searchContainer: SearchContainer) {
    searchContainer.selections.allSelected = false;
    searchContainer.selections.selected = {};
    searchContainer.selections.selectedItems = [];
  }

  public isSelectionLimitReached(searchContainer: SearchContainer): boolean {
    return searchContainer.selections.selectedItems.length >= this.SELECTION_LIMIT;
  }

  private addItem(searchContainer: SearchContainer, item: SearchObject) {
    let addOk = !this.isSelectionLimitReached(searchContainer);
    if (addOk) {
      searchContainer.selections.selectedItems.push(item);
    }
    return addOk;
  }

  private async getAllArtifactsFromSearch(searchContainer: SearchContainer): Promise<Array<SearchObject>> {
    const searchParams = await this.searchParameterService.getSearchParams(
      searchContainer, false, true);
    const useNewMainMenu = this.featureFlagService.getFeatureFlags().experimental.useNewMainMenu &&
      searchContainer.searchResultViewName !== SearchResultViewType.CONTENT_LIST;
    if (!useNewMainMenu) {
      searchParams.rows = searchContainer.searchResult.search_count || searchParams.rows;
      searchParams.start = 0;
    } else {
      searchParams.rows = searchContainer.rows;
      searchParams.start = (searchContainer.searchPage - 1) * searchParams.rows;
    }

    const searchRes = await this.searchService.searchWithOverview(searchParams);
    if (useNewMainMenu) {
      // Since new search views uses search result artifact directly, we must set some props e.g. media url
      await this.resultViewService.setSearchResultItemProps(searchContainer, searchRes.artifacts);
    }
    // filter to just artifact ids
    return searchRes.artifacts;
  }

  private checkItemInArray(searchContainer: SearchContainer, arr: string[], art: SearchObject, setProp: string) {
    const itemId = this.getItemId(art);
    const index = arr.indexOf(itemId);
    const found = index !== -1;
    switch (setProp) {
      case 'selected':
        searchContainer.selections.selected[itemId] = found;
        break;
      case 'used':
        if (found) {
          searchContainer.selections.selected[itemId] = true;
        }
        art.$$used = found;
        break;
      default:
        console.warn('Unknown prop ' + setProp);
    }
  }

  private selectedIndexOf(searchContainer: SearchContainer, item: SearchObject): number {
    let res = -1;
    const itemId = this.getItemId(item);
    for (let index = 0; index < searchContainer.selections.selectedItems.length; index++) {
      const selectedItem = searchContainer.selections.selectedItems[index];
      if (this.getItemId(selectedItem) === itemId) {
        res = index;
        break;
      }
    }
    return res;
  }

  private cleanItem(item: SearchObject): SearchObject {
    const res = {} as SearchObject;
    for (const itemProp in item) {
      if (itemProp.indexOf('$$') !== 0) {
        res[itemProp] = item[itemProp];
      }
    }
    return res;
  }

  private warnTooMany() {
    this.notificationService.addNotification(new AppNotification(['TRANS__SEARCH_SELECTIONS__TOO_MANY_SELECTED'], 'info'));
  }
}
