import { Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton, MatMiniFabButton} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {CurrentSearchContext} from '../../../../object-search/current-search.context';
import {MatSuffix} from '@angular/material/form-field';
import { ObjectPageNavigationStack } from 'src/app/object-page-v2/object-page-navigation-stack';
import { Router } from '@angular/router';

@Component({
  selector: 'app-object-page-search-navigation',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    TranslateModule,
    MatSuffix,
    MatButton,
    MatMiniFabButton
  ],
  templateUrl: './object-page-search-navigation.component.html',
  styleUrl: './object-page-search-navigation.component.scss'
})
export class ObjectPageSearchNavigationComponent {
  @Input() artifactId: string;
  @Input() searchContext?: CurrentSearchContext;

  @Output() backClick = new EventEmitter();
  @Output() forwardClick = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  constructor(
    private readonly objectPageNavigationStack: ObjectPageNavigationStack,
    private readonly router: Router,
  ) {}

  get currentResultIndex() {
    return this.searchContext?.findResultIndex(this.artifactId);
  }

  get hasPreviousArtifact() {
    return this.objectPageNavigationStack.artifactHistoryExists;
  }

  get previousArtifactName() {
    return this.objectPageNavigationStack.previousArtifactName
  }

  get totalResults() {
    return this.searchContext?.resultSize;
  }

  get canNavigateBack() {
    return this.currentResultIndex > 0;
  }

  get canNavigateForward() {
    return this.currentResultIndex < this.totalResults - 1;
  }

  onBackClick() {
    this.backClick.emit();
  }

  onForwardClick() {
    this.forwardClick.emit();
  }

  onCloseClick() {
    this.closeClick.emit();
  }

  onPreviousArtifactClick() {
    let object = this.objectPageNavigationStack.removeArtifactFromHistory();

    this.router.navigate([object.url], {queryParams: object.queryParams, queryParamsHandling: 'merge'});
  }
}
