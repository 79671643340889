import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {ConceptType, ConceptTypes} from './definitions/concept-types';
import {
  Concept,
  ConceptListItem,
  ConceptsParams,
  SortDir,
  ConceptHierarchic
} from './definitions/concepts';
import {ConceptsContainer} from './definitions/concepts-container';
import {ModelFactoryService} from './model-factory.service';
import {ConceptDataSource} from '../administration/admin-concept-lists/concept-data-source';
import {CheckFilterGroup} from './definitions/search-objects';

export interface ConceptDialogData {
  concept: Concept;
  concept_id: string;
  conceptType: ConceptType;
  conceptsParams: ConceptsParams;
  conceptList: ConceptListItem[];
  conceptDataSource: ConceptDataSource;
  index: number;
}

export interface AdminConceptJoinConceptsData {
  conceptsContainer: ConceptsContainer;
  callback: any;
}

@Injectable({
  providedIn: 'root'
})
export class ConceptService {

  private conceptFieldUsage: any;

  constructor(private cms: CmsApiService,
              private modelFactoryService: ModelFactoryService) {
  }

  async getConceptTypes(nonSystemOnly?: boolean): Promise<ConceptTypes> {
    return new Promise<ConceptTypes>(resolve => {
      this.cms.getConceptTypes({non_system_only: nonSystemOnly}).then(conceptTypes => {
        resolve(conceptTypes);
      });
    });
  }

  async getConceptType(conceptTypeId: string): Promise<ConceptType> {
    return new Promise<ConceptType>(resolve => {
      this.cms.getConceptType({concept_type_id: conceptTypeId}).then(conceptType => {
        resolve(conceptType);
      });
    });
  }

  async setConceptsContainer(conceptType: ConceptType): Promise<ConceptsContainer> {
    const conceptsContainer = new ConceptsContainer(this.cms);
    const conceptsParams = new ConceptsParams();
    conceptsParams.concept_type_id = conceptType.concept_type_id;
    conceptsParams.meta_type = conceptType.meta_type;
    conceptsParams.rows = 20;
    conceptsParams.query = '';
    conceptsParams.sort = 'artifact_name';
    conceptsParams.sort_dir = SortDir.Asc;
    if (conceptsContainer.conceptDataSource.sort) {
      conceptsContainer.conceptDataSource.sort.active = 'name';
      conceptsContainer.conceptDataSource.sort.start = 'asc';
    }
    conceptsContainer.conceptsParams = conceptsParams;
    conceptsContainer.conceptType = conceptType;
    conceptsContainer.conceptType.minLevel = -1;
    conceptsContainer.reload = Math.random();
    conceptsContainer.filterGroups = await this.getFilterGroupsForType(conceptsContainer.conceptType);
    this.setConceptParamsFilters(conceptsContainer);
    return conceptsContainer;
  }

  async setConceptList(conceptsContainer: ConceptsContainer, resetStart: boolean, goBack?: boolean, pageIndex?: number) {
    conceptsContainer.firstSelectedConcept = null;
    if (resetStart) {
      this.setPageIndex(conceptsContainer, goBack, pageIndex);
      this.setPaginatorIndex(conceptsContainer, goBack, pageIndex);
    }
    await conceptsContainer.conceptDataSource.loadConcepts(conceptsContainer);

    this.checkSetMinLevel(conceptsContainer);
  }

  private setPageIndex(conceptsContainer: ConceptsContainer, goBack?: boolean, pageIndex?: number) {
    if (goBack) {
      conceptsContainer.pageIndex = pageIndex || 0;
    } else {
      conceptsContainer.pageIndex = 0;
    }
  }

  private setPaginatorIndex(conceptsContainer: ConceptsContainer, goBack?: boolean, pageIndex?: number) {
    if (conceptsContainer.conceptDataSource.paginator) {
      if (goBack) {
        conceptsContainer.conceptDataSource.paginator.pageIndex = pageIndex || 0;
      } else {
        conceptsContainer.conceptDataSource.paginator.pageIndex = 0;
      }
    }
  }

  private async getFilterGroupsForType(conceptType: ConceptType): Promise<CheckFilterGroup[]> {
    const filterGroupData = await this.cms.getObjectSearchFilters(conceptType.meta_type, conceptType.concept_type_id);
    if (filterGroupData.error_message) {
      console.error(
        `An error occurred getting filter groups for ${conceptType.concept_type_id}: ${filterGroupData.error_message}`);
    }
    return filterGroupData.filter_groups;
  }

  private setConceptParamsFilters(conceptsContainer: ConceptsContainer) {
    for (const filterGroup of conceptsContainer.filterGroups) {
      for (const filter of filterGroup.filters) {
        if (filter.checked_value) {
          conceptsContainer.conceptsParams[filter.name] = filter.value;
          filter.checked = true;
        }
      }
    }
  }

  private checkSetMinLevel(conceptsContainer: ConceptsContainer) {
    if (conceptsContainer.conceptType.is_hierarchic &&
      conceptsContainer.conceptType.minLevel === -1 &&
      conceptsContainer.conceptDataSource.data.length) {
      conceptsContainer.conceptType.minLevel = conceptsContainer.conceptDataSource.data[0].level;
    }
  }

  async setConceptUsage(concepts: Array<any>) {
    const conceptIds = concepts.map(concept => concept.artifact_id);
    const conceptUsages = await this.cms.getConceptUsage({
      artifact_ids: conceptIds, writable_usages_only: false});
    concepts.forEach(concept => {
      concept.$$usage = conceptUsages[concept.artifact_id];
    });
  }

  async getConceptFieldUsage(conceptTypeId?: string) {
    if (!this.conceptFieldUsage) {
      this.conceptFieldUsage = this.cms.getConceptFieldUsage({writable_usages_only: 'false'});
    }
    return conceptTypeId ? this.conceptFieldUsage[conceptTypeId] : this.conceptFieldUsage;
  }

  getParentConcept(conceptsContainer: ConceptsContainer, offset?: number): ConceptListItem {
    let parentConcept: ConceptListItem;
    offset = offset || 0;
    const parentConcepts = conceptsContainer.parentConcepts;
    if (parentConcepts.length > offset) {
      parentConcept = parentConcepts[parentConcepts.length - (1 + offset)];
    }
    return parentConcept;
  }

  async createChildConcept(conceptsContainer: ConceptsContainer, parentConcept?: ConceptListItem): Promise<Concept> {
    const conceptData = new ConceptHierarchic();
    if (!parentConcept) {
      parentConcept = this.getParentConcept(conceptsContainer);
    }
    if (parentConcept) {
      conceptData.level = parentConcept.level + 1;
      conceptData.parent_id = parentConcept.artifact_id;
      conceptData.parent_id_value = parentConcept.artifact_name;
      conceptData.parent_path = parentConcept.parent_path ? parentConcept.parent_path + ' > ...' : '';
      parentConcept.is_leaf = false;
    } else {
      conceptData.level = 1;
    }
    return <Concept>await this.modelFactoryService.createModelItemAsync(
      conceptsContainer.conceptType.edit_model || conceptsContainer.conceptType.concept_type_id, conceptData);
  }

  selectAll(conceptsContainer: ConceptsContainer) {
    this.clearSelected(conceptsContainer);
    conceptsContainer.conceptDataSource.data.forEach(concept => {
      concept.$$selected = conceptsContainer.allSelected;
      if (conceptsContainer.allSelected) {
        conceptsContainer.selected.push(concept);
      }
    });
  }

  clearSelected(conceptsContainer: ConceptsContainer) {
    conceptsContainer.conceptDataSource.setSelected(conceptsContainer, false);
    conceptsContainer.selected = [];
  }

  selectConcept(concept: ConceptListItem, conceptsContainer: ConceptsContainer, shiftKey: boolean = false) {
    if (this.checkSelectRange(concept, conceptsContainer, shiftKey)) {
      return;
    }
    if (concept.$$selected) {
      conceptsContainer.selected.push(concept);
    } else {
      this.unselectConcept(concept, conceptsContainer);
    }
  }

  unselectConcept(concept: ConceptListItem, conceptsContainer: ConceptsContainer) {
    const existingIndex = conceptsContainer.selected.indexOf(concept);
    if (existingIndex > -1) {
      conceptsContainer.selected.splice(existingIndex, 1);
    }
  }

  private checkSelectRange(concept: ConceptListItem, conceptsContainer: ConceptsContainer, shiftKey: boolean): boolean {
    if (!shiftKey || !conceptsContainer.firstSelectedConcept) {
      conceptsContainer.firstSelectedConcept = concept;
      return false;
    }
    this.selectRange(concept, conceptsContainer);
    return true;
  }

  private selectRange(concept: ConceptListItem, conceptsContainer: ConceptsContainer) {
    let loopStart: number, loopEnd: number;
    let concepts: ConceptListItem[] = conceptsContainer.concepts.concepts;
    const searchIndex = concepts.findIndex(item => item.artifact_id === concept.artifact_id);
    const firstSelectedIndex = concepts.findIndex(
      item => item.artifact_id === conceptsContainer.firstSelectedConcept.artifact_id);
    if (searchIndex === -1 || firstSelectedIndex === -1) {
      console.warn('Unable to find index for selected')
      return;
    }
    if (firstSelectedIndex < searchIndex) {
      loopStart = firstSelectedIndex;
      loopEnd = searchIndex;
    } else {
      loopStart = searchIndex;
      loopEnd = firstSelectedIndex;
    }
    for (let t = loopStart; t <= loopEnd; t++) {
      const conceptItem = concepts[t];
      if (this.selectedIndexOf(conceptsContainer, conceptItem) === -1) {
        conceptsContainer.selected.push(conceptItem);
        if (searchIndex !== t) {
          conceptItem.$$selected = true;
        }
      }
    }
  }

  private selectedIndexOf(conceptsContainer: ConceptsContainer, item: ConceptListItem): number {
    let res = -1;
    const itemId = item.artifact_id;
    for (let index = 0; index < conceptsContainer.selected.length; index++) {
      const selectedItem = conceptsContainer.selected[index];
      if (selectedItem.artifact_id === itemId) {
        res = index;
        break;
      }
    }
    return res;
  }

}
