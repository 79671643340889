<div class="search-navigation">
  @if(hasPreviousArtifact) {
    <div class="search-navigation__back">
      <button
        mat-button
        class="search-navigation__button"
        color="primary"
        (click)="onPreviousArtifactClick()"
      >
        <mat-icon>
          arrow_left
        </mat-icon>
        {{ previousArtifactName }}
      </button>
    </div>
  }
  @if (searchContext && !hasPreviousArtifact) {
    <div class="search-navigation__navigation">
      @if (backClick.observed) {
        <button
          mat-icon-button
          class="search-navigation__icon-button density--2"
          (click)="onBackClick()"
          [disabled]="!canNavigateBack"
        >
          <mat-icon matButtonIcon>
            chevron_left
          </mat-icon>
        </button>
      }

      <div class="search-navigation__page-info">
        {{ 'TRANS__OBJECT_PAGE_V2__HEADER__SEARCH_CONTEXT_RESULTS' | translate: { x: searchContext.findResultIndex(artifactId) >= 0 ? searchContext.findResultIndex(artifactId) + 1 : 1, y: searchContext.resultSize } }}
      </div>

      @if(forwardClick.observed) {
        <button
          mat-icon-button
          class="search-navigation__icon-button density--2"
          (click)="onForwardClick()"
          [disabled]="!canNavigateForward"
        >
          <mat-icon matButtonIcon>
            chevron_right
          </mat-icon>
        </button>
      }
    </div>
  }

  <div class="search-navigation__close">
    <button
      mat-button
      class="search-navigation__button"
      color="primary"
      (click)="onCloseClick()"
    >
      <mat-icon>
        @if (searchContext) {
          search
        }
        @else {
          close
        }
      </mat-icon>
      @if (searchContext) {
        {{ 'TRANS__OBJECT_PAGE_V2__HEADER__BACK_TO_SEARCH' | translate }}
      }
      @else {
        {{ 'TRANS__OBJECT_PAGE_V2__HEADER__CLOSE' | translate }}
      }
    </button>
  </div>
</div>
