

<div>
  <a routerLink="/service" class="back-link">
    <mat-icon class="material-symbols-outlined">arrow_back</mat-icon>
    Tilbake til tjenester
  </a>
</div>

<h1 class="heading">Teste notifikasjoner</h1>
<p class="sub-heading">Her kan du teste komponenten for å vise feilmeldinger.</p>

<input type="text" placeholder="Skriv en melding" [formControl]="notificationMessageControl">
<input type="text" placeholder="Type melding (info, warning, error, success)" [formControl]="notificationTypeControl">
<button type="submit" mat-button (click)="sendNotification()">Send melding</button>
