<div class="report-preview-area"
     [ngClass]="{'report-preview-area-three-col' : numColumnsInReport === 3, 'report-preview-area-four-col' : numColumnsInReport === 4}">
  @for (artifact of extractedArtifacts; track artifact.artifactId; let idx = $index) {
    <div class="report-image-artifact-object">
      <div class="report-image-artifact-image">
        <div class="report-picture-thumbnail">
          @if (artifact.thumbnailImage) {
            <app-thumbnail
              [item]="artifact.thumbnailImage"
              [stylingClass]="'report-picture-thumbnail-img'"></app-thumbnail>
          } @else {
            <div class="default-image report-picture-missing">
              <span>{{'TRANS__HAS_IMAGES__NONE' | translate}}</span>
            </div>
          }
        </div>
      </div>

      @if (!isOnlyPhotosSelected()) {
        <h1>{{ artifact.artifactName }}</h1>

        @for (section of artifact.sections; track section.id) {
          @for (field of section.fields; track $index; let isEven = $even; let isFirst = $first) {
            <div class="report-image-value-area" [ngClass]="{'report-image-value-area-one-col' : numColumnsInReport > 2}">
              @for (value of field.value; track $index; let isEven2 = $even) {
                @if(isLedeteksterSelected()) {
                  <div class="report-artifact-field-title" [ngClass]="{'report-artifact-field-title-medium' : numColumnsInReport > 2}">
                    <span>{{ field.key }}</span>
                  </div>
                }
                <div class="report-artifact-field-values-grid-right">
                  {{ value.values }}
                </div>
              }
            </div>
          }
        }
      }
    </div>
  }
</div>
