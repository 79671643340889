import { effect, Injectable, signal, WritableSignal } from '@angular/core';
import {
  ExtendedFieldQuery,
  ExtendedFieldQueryGroup, ExtendedFieldQueryLogicalOperator, ExtendedFieldQueryOperator,
  ExtendedSearchParams,
  SearchSuggestion
} from './definitions/extended-search-params';
import { CmsApiService } from './cms-api.service';
import { SearchContainer } from './definitions/search-container';
import { ExtendedSearchField, GetExtendedSearchFieldParams } from './definitions/extended-search-field';
import { SearchObject } from './definitions/search-object';
import { HierarchicNode } from './definitions/hierarchic-objects';
import { SearchReferenceService } from './search-reference.service';
import { OptionsService } from './options.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../shared/confirmation-modal/confirmation-modal.component';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ExtendedSearchService {
  extendedSearchFieldOperators: WritableSignal<ExtendedFieldQueryOperator[]> = signal([]);
  extendedSearchParameters: WritableSignal<ExtendedSearchParams> = signal(null);
  extendedSearchSuggestions: WritableSignal<SearchSuggestion[]> = signal([]);

  updateFieldSelectorListFlag: WritableSignal<boolean> = signal(false);

  searchContainer: SearchContainer;

  constructor(
    private readonly cmsApi: CmsApiService,
    private readonly modal: MatDialog,
    private readonly optionsService: OptionsService,
    private readonly referenceService: SearchReferenceService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
    this.initializeSearchSuggestions();

    effect(() => {
      if (this.extendedSearchParameters() !== null) {
        if (!this.extendedSearchParameters().query_groups.length) {
          this.extendedSearchParameters.set({ ...this._getInitialParamState() });
          this.searchContainer.extendedSearchParams = this.extendedSearchParameters();
        }
        else {
          this.searchContainer.extendedSearchParams = this.extendedSearchParameters();
        }

        console.debug('Update searchContainer for extended search!', this.extendedSearchParameters());
      }
    }, {
      allowSignalWrites: true
    });
  }

  public addNewFieldToGroup(group: ExtendedFieldQueryGroup): void {
    let temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(group.field_queries[0]);

    this.addNewFieldToNestedGroup(group, indexArray.length === 2 ? indexArray : indexArray.slice(1));

    this.extendedSearchParameters.set(temp);
  }

  public addNewGroupToRoot(): void {
    let temp = { ...this.extendedSearchParameters() };

    temp.query_groups.push({
      child_document_type: '',
      field_logical_operator: 'AND',
      field_queries: [
        {
          parent_field_ids: [],
          relation_superobject_type_id: '',
          field_title: '',
          field_name: '',
          path: '',
          input_type: '',
          reference_id: '',
          is_array_field: false,
          context_field: '',
          is_array_context_field: false,
          child_document_type: '',
          operators: [],
          operator_selected: '=',
          value: undefined,
          valueDisplay: '',
          superobject_type_id: '',
          valid: false
        }
      ],
      header: 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION',
      level: 0,
      logical_operator: 'AND',
      relation_superobject_type_id: '',
      sub_groups: [],
      superobject_type_id: ''
    })
  }

  public addArrayValueToField(field: ExtendedFieldQuery, value: SearchObject): void {
    let temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    this.updateNestedFieldValueArray(field, temp.query_groups[indexArray[0]], indexArray.slice(1), value);

    this.extendedSearchParameters.set(temp);
  }

  public addNewSubGroupToGroup(group: ExtendedFieldQueryGroup): void {
    let temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(group.field_queries[0]);

    this.addNewSubGroupToNestedGroup(group, indexArray.length === 2 ? indexArray : indexArray.slice(1));

    this.extendedSearchParameters.set(temp);
  }

  public checkSetSortFieldsGroup(selectedField: ExtendedSearchField, field: ExtendedFieldQuery): void {
    let temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    this.updateNestedFieldSortOrder(selectedField, temp.query_groups[indexArray[0]], indexArray.slice(1));

    this.extendedSearchParameters.set(temp);
  }

  public clearSelectedField(field: ExtendedFieldQuery): void {
    let temp = { ...this.extendedSearchParameters() };
    const indexArray = this.getFieldIndex(field);

    this.updateNestedField(null, temp.query_groups[indexArray[0]], indexArray.slice(1))

    this.extendedSearchParameters.set(temp);
  }

  public clearSelectedValue(field: ExtendedFieldQuery): void {
    let temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    this.updateNestedFieldValue(null, temp.query_groups[indexArray[0]], indexArray.slice(1), null);

    this.extendedSearchParameters.set(temp);
  }

  public getExtendedSearchSuggestion(id: string): ExtendedSearchParams {
    for (const suggestion of this.extendedSearchSuggestions()) {
      if (suggestion.id === id) {
        return suggestion.advancedSearchParams;
      }
    }

    return this._getInitialParamState();
  }

  public getExtendedSearchSuggestions(): SearchSuggestion[] {
    return this.extendedSearchSuggestions();
  }

  public initializeOrCreateSearchParametersFromSearchContainer(searchContainer: SearchContainer): void {
    const stateParams = this.route.snapshot.queryParamMap['params'];

    this.searchContainer = searchContainer;

    if (stateParams.searchSuggestionId) {
      this.initializeSearchSuggestions(() => {
        this.extendedSearchParameters.set( {...this.getExtendedSearchSuggestion(stateParams.searchSuggestionId)});

        this.router.navigate([], {
          queryParams: {
            searchSuggestionId: null
          },
          queryParamsHandling: 'merge'
        })
      });
    }
    else if (searchContainer.extendedSearchParams) {
      this.extendedSearchParameters.set({ ...searchContainer.extendedSearchParams });
    }
    else {
      this.extendedSearchParameters.set({ ...this._getInitialParamState() });
    }

    searchContainer.extendedSearchParams = this.extendedSearchParameters();

    console.debug('[ExtendedSearchService] Search parameters initialized');
  }

  public getRelationInfoFromGroup(group: ExtendedFieldQueryGroup, rootGroup: ExtendedFieldQueryGroup) {
    let res: {
      superobjectTypeId: string,
      contextField: string,
      isArrayContextField: boolean,
      childDocumentType: string
    } = null;
    let id = rootGroup?.relation_superobject_type_id ?? group?.superobject_type_id;
    let childDocumentType = group?.child_document_type;
    let contextField: string;
    let isArrayContextField: boolean;
    if (rootGroup?.field_queries?.length) {
      contextField = rootGroup.field_queries[0].context_field;
      isArrayContextField = rootGroup.field_queries[0].is_array_context_field;
      if (!isArrayContextField && group.field_queries?.length && group.field_queries[0].is_array_field && group.field_queries[0].child_document_type) {
        isArrayContextField = true;
      }
    }
    if (!id && rootGroup?.field_queries?.length) {
      id = rootGroup.field_queries[0].relation_superobject_type_id;
    }
    if (!childDocumentType && group?.field_queries?.length) {
      childDocumentType = group.field_queries[0].child_document_type;
    }
    if (id || childDocumentType) {
      res = {
        superobjectTypeId: id,
        contextField: contextField,
        isArrayContextField: isArrayContextField,
        childDocumentType: childDocumentType
      };
    } else if (group?.sub_groups?.length) {
      res = this.getRelationInfoFromGroup(group.sub_groups[0], rootGroup);
    }

    return res || null;
  }

  public initializeSearchSuggestions(fn?: () => void): void {
    Promise.all([
      this.fetchAndSetExtendedSearchFieldOperators(),
      this.fetchAndSetExtendedSearchSuggestions()
    ]).then(() => {
      if (fn) {
        fn();
      }

      console.debug('[ExtendedSearchService] Searchsuggestions and operators initialized from constructor');
    });
  }

  public getRulesHasValues(): boolean {
    let hasValues = false;

    for (const group of this.extendedSearchParameters().query_groups) {
      hasValues = this.nestedFieldHasValuesSet(group);

      if (hasValues) {
        return hasValues;
      }
    }

    return hasValues;
  }

  public groupHasOneOrMoreFieldsSelected(group: ExtendedFieldQueryGroup): boolean {
    let rulesWithFields = 0;
    let subGroupsHasFields = false;

    if (group.field_queries.length) {
      for (const field of group.field_queries) {
        if (field.field_title) {
          rulesWithFields++;
        }
      }
    }

    if (rulesWithFields === 0 && group.sub_groups.length) {
      for (const subGroup of group.sub_groups) {
        if (this.groupHasOneOrMoreFieldsSelected(subGroup)) {
          subGroupsHasFields = true;
        }
      }
    }

    return rulesWithFields >= 1 || subGroupsHasFields;
  }

  public groupHasMoreThanOneFieldsSelected(group: ExtendedFieldQueryGroup): boolean {
    let rulesWithFields = 0;
    let subGroupsHasFields = false;

    if (group.field_queries.length) {
      for (const field of group.field_queries) {
        if (field.field_title) {
          rulesWithFields++;
        }
      }
    }

    if (rulesWithFields === 0 && group.sub_groups.length) {
      for (const subGroup of group.sub_groups) {
        if (this.groupHasMoreThanOneFieldsSelected(subGroup)) {
          subGroupsHasFields = true;
        }
      }
    }

    return rulesWithFields > 1 || subGroupsHasFields;
  }

  public removeArrayValueFromField(field: ExtendedFieldQuery, value: SearchObject): void {
    const temp = {...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    this.removeArrayValueFromNestedField(field, temp.query_groups[indexArray[0]], indexArray.slice(1), value);

    this.extendedSearchParameters.set(temp);
  }

  public removeField(field: ExtendedFieldQuery): void {
    let temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    this.removeNestedField(indexArray);

    if (!this.sqlModeShouldRemainActive()) {
      temp.db_search = false;
    }

    this.extendedSearchParameters.set(temp);
  }

  public removeGroup(group: ExtendedFieldQueryGroup): void {
    let temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(group.field_queries[0]);

    this.removeNestedGroup(indexArray.splice(0, indexArray.length - 1));

    if (!this.sqlModeShouldRemainActive()) {
      temp.db_search = false;
    }

    this.extendedSearchParameters.set(temp);
  }

  public resetExtendedSearch(): void {
    this.extendedSearchParameters.set({...this._getInitialParamState()});

    this.triggerFieldSelectorUpdate();
  }

  public resetExtendedSearchToSuggestion(searchSuggestion: SearchSuggestion): void {
    this.extendedSearchParameters.set({ ...searchSuggestion.advancedSearchParams })
  }

  public setFieldOperator(field: ExtendedFieldQuery, operator: "=" | "<>" | ">=" | "<=" | "like" | "not like" | "empty" | "not empty" | "range" | "not range" | "in" | "not in" | "true" | "false"): void {
    const temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    field.operator_selected = operator;

    this.updateNestedFieldOperator(field, temp.query_groups[indexArray[0]], indexArray.slice(1));

    this.extendedSearchParameters.set(temp);
  }

  public setGroupOperator(group: ExtendedFieldQueryGroup, operator: ExtendedFieldQueryLogicalOperator): void {
    const temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(group.field_queries[0]);

    this.updateNestedGroupOperator(indexArray.splice(0, indexArray.length - 1), operator);

    this.extendedSearchParameters.set(temp);
  }

  public setSelectedField(selectedField: ExtendedSearchField, field: ExtendedFieldQuery): void {
    // Using a copy to trigger onChange event for signals
    const temp = { ...this.extendedSearchParameters() };

    // @ts-ignore
    if (selectedField.restriction?.sql_mode) {
      temp.db_search = true;
    }

    const indexArray = this.getFieldIndex(field);

    this.updateNestedField(selectedField, temp.query_groups[indexArray[0]], indexArray.slice(1))

    this.extendedSearchParameters.set(temp);

    this.triggerFieldSelectorUpdate();
  }

  public setSelectedFieldInNewSiblingGroup(selectedField: ExtendedSearchField, field: ExtendedFieldQuery): void {
    const temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    // @ts-ignore
    if (selectedField.restriction?.sql_mode) {
      temp.db_search = true;
    }

    if(indexArray.length > 2) {
      this.addFieldToSiblingGroup(selectedField, indexArray.slice(1, indexArray.length), temp.query_groups[indexArray[0]]);
    }

    if (indexArray.length === 2) {
      let header = 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION';
      let relation_superobject_type_id = '';
      let child_document_type = '';
      let restriction_parent_name = '';
      let restriction_name = '';

      if (selectedField.superobject_type_id || selectedField.child_document_type) {
        if (header === 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION') {
          header = selectedField.field_title.split(' / ')[0];
        }
        //@ts-ignore
        relation_superobject_type_id = selectedField.relation_superobject_type_id ?? selectedField.superobject_type_id;
        child_document_type = selectedField.child_document_type;
      }

      if (selectedField.restriction?.parents) {
        // @ts-ignore
        restriction_parent_name = selectedField.restriction.parents[0];
        restriction_name = selectedField.restriction.parents[selectedField.restriction.parents.length - 1];
      }

      temp.query_groups.push({
        child_document_type: child_document_type,
        field_logical_operator: 'AND',
        field_queries: [
          {
            child_document_type: selectedField.child_document_type,
            context_field: selectedField.context_field,
            field_name: selectedField.field_name,
            field_title: selectedField.field_title,
            input_type: selectedField.input_type,
            is_array_context_field: selectedField.is_array_context_field,
            is_array_field: selectedField.is_array_field,
            operator_selected: '=',
            operators: this.getFieldOperatorsForFieldType(selectedField.input_type),
            parent_field_ids: selectedField.parent_field_ids,
            path: selectedField.path,
            reference_id: selectedField.reference_id,
            relation_superobject_type_id: null,
            // @ts-ignore
            restriction: selectedField.restriction,
            superobject_type_id: selectedField.superobject_type_id,
            valid: selectedField.valid,
            value: '',
            valueDisplay: ''
          }
        ],
        header: header,
        level: 0,
        logical_operator: 'AND',
        relation_superobject_type_id: relation_superobject_type_id,
        restriction: selectedField.restriction,
        restriction_name: restriction_name,
        restriction_parent_name: restriction_parent_name,
        sub_groups: [],
        superobject_type_id: ''
      });
    }
    this.extendedSearchParameters.set(temp);

    this.removeNestedField(indexArray);

    this.triggerFieldSelectorUpdate();
  }

  public setSelectedfieldInNewSubGroup(selectedField: ExtendedSearchField, field: ExtendedFieldQuery): void {
    const temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    // @ts-ignore
    if (selectedField.restriction?.sql_mode) {
      temp.db_search = true;
    }

    if(indexArray.length > 2) {
      this.addFieldToSubGroup(selectedField, indexArray.slice(1, indexArray.length), temp.query_groups[indexArray[0]]);
    }

    if (indexArray.length === 2) {
      temp.query_groups[indexArray[0]].sub_groups.push({
        child_document_type: '',
        field_logical_operator: 'AND',
        field_queries: [
          {
            child_document_type: selectedField.child_document_type,
            context_field: selectedField.context_field,
            field_name: selectedField.field_name,
            field_title: selectedField.field_title,
            input_type: selectedField.input_type,
            is_array_context_field: selectedField.is_array_context_field,
            is_array_field: selectedField.is_array_field,
            operator_selected: '=',
            operators: this.getFieldOperatorsForFieldType(selectedField.input_type),
            parent_field_ids: selectedField.parent_field_ids,
            path: selectedField.path,
            reference_id: selectedField.reference_id,
            // @ts-ignore
            relation_superobject_type_id: selectedField.relation_superobject_type_id ?? selectedField.superobject_type_id,
            // @ts-ignore
            restriction: selectedField.restriction,
            superobject_type_id: selectedField.superobject_type_id,
            valid: selectedField.valid,
            value: '',
            valueDisplay: ''
          }
        ],
        header: temp.query_groups[indexArray[0]].header,
        level: temp.query_groups[indexArray[0]].level + 1,
        logical_operator: 'AND',
        restriction: selectedField.restriction,
        restriction_name: temp.query_groups[indexArray[0]].restriction_name,
        //@ts-ignore
        restriction_parent_name: temp.query_groups[indexArray[0]].restriction_parent_name,
        // @ts-ignore
        relation_superobject_type_id: temp.query_groups[indexArray[0]].relation_superobject_type_id,
        sub_groups: [],
        superobject_type_id: ''
      })
    }

    this.extendedSearchParameters.set(temp);

    this.removeNestedField(indexArray);

    this.triggerFieldSelectorUpdate();
  }

  public setSelectedFieldAndMoveExistingToSubGroup(selectedField: ExtendedSearchField, field: ExtendedFieldQuery): void {
    const temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    this.updateNestedFieldWithMovedField(temp.query_groups[indexArray[0]], indexArray.slice(1), selectedField);

    this.extendedSearchParameters.set(temp);

    this.triggerFieldSelectorUpdate();
  }

  public setSelectedFieldValue(field: ExtendedFieldQuery, value: SearchObject): void {
    const temp = {...this.extendedSearchParameters() }

    const indexArray = this.getFieldIndex(field);

    this.updateNestedFieldValue(field, temp.query_groups[indexArray[0]], indexArray.slice(1), value);

    this.extendedSearchParameters.set(temp);
  }

  public setSelectedFieldValueFromArray(field: ExtendedFieldQuery, value: any[]): void {
    const temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    this.updateNestedFieldValueArrayFromAny(field, temp.query_groups[indexArray[0]], indexArray.slice(1), value);

    this.extendedSearchParameters.set(temp);
  }

  public setSelectedFieldValueFromString(field: ExtendedFieldQuery, value: string): void {
    const temp = { ...this.extendedSearchParameters() };

    const indexArray = this.getFieldIndex(field);

    this.updateNestedFieldValueFromString(field, temp.query_groups[indexArray[0]], indexArray.slice(1), value);

    this.extendedSearchParameters.set(temp);
  }

  public triggerFieldSelectorUpdate(): void {
    this.updateFieldSelectorListFlag.set(!this.updateFieldSelectorListFlag());
  }

  private addFieldToSiblingGroup(selectedField: ExtendedSearchField, indexes: number[], group?: ExtendedFieldQueryGroup): void {
    if (indexes.length === 2) {
      let header = 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION';
      let relation_superobject_type_id = '';
      let child_document_type = '';
      let restriction_parent_name = '';
      let restriction_name = '';

      if (selectedField.superobject_type_id || selectedField.child_document_type) {
        if (header === 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION') {
          header = selectedField.field_title.split(' / ')[0];
        }
        //@ts-ignore
        relation_superobject_type_id = selectedField.relation_superobject_type_id ?? selectedField.superobject_type_id;
        child_document_type = selectedField.child_document_type;
      }

      if (selectedField.restriction?.parents) {
        // @ts-ignore
        restriction_parent_name = selectedField.restriction.parents[0];
        restriction_name = selectedField.restriction.parents[selectedField.restriction.parents.length - 1];
      }

      group.sub_groups.push({
        child_document_type: child_document_type,
        field_logical_operator: 'AND',
        field_queries: [
          {
            child_document_type: selectedField.child_document_type,
            context_field: selectedField.context_field,
            field_name: selectedField.field_name,
            field_title: selectedField.field_title,
            input_type: selectedField.input_type,
            is_array_context_field: selectedField.is_array_context_field,
            is_array_field: selectedField.is_array_field,
            operator_selected: '=',
            operators: this.getFieldOperatorsForFieldType(selectedField.input_type),
            parent_field_ids: selectedField.parent_field_ids,
            path: selectedField.path,
            reference_id: selectedField.reference_id,
            // @ts-ignore
            relation_superobject_type_id: selectedField.relation_superobject_type_id ?? selectedField.superobject_type_id,
            // @ts-ignore
            restriction: selectedField.restriction,
            superobject_type_id: selectedField.superobject_type_id,
            valid: selectedField.valid,
            value: '',
            valueDisplay: ''
          }
        ],
        header: header,
        level: 0,
        logical_operator: 'AND',
        restriction_name: restriction_name,
        restriction_parent_name: restriction_parent_name,
        // @ts-ignore
        relation_superobject_type_id: relation_superobject_type_id,
        sub_groups: [],
        superobject_type_id: ''
      })
    }
    else {
      this.addFieldToSiblingGroup(selectedField, indexes.slice(1), group.sub_groups[indexes[0]]);
    }
  }

  private addFieldToSubGroup(selectedField: ExtendedSearchField, indexes: number[], group?: ExtendedFieldQueryGroup): void {
    if (indexes.length === 2) {
      group.sub_groups[indexes[0]].sub_groups.push({
        child_document_type: selectedField.child_document_type ?? '',
        field_logical_operator: 'AND',
        field_queries: [
          {
            child_document_type: selectedField.child_document_type,
            context_field: selectedField.context_field,
            field_name: selectedField.field_name,
            field_title: selectedField.field_title,
            input_type: selectedField.input_type,
            is_array_context_field: selectedField.is_array_context_field,
            is_array_field: selectedField.is_array_field,
            operator_selected: '=',
            operators: this.getFieldOperatorsForFieldType(selectedField.input_type),
            parent_field_ids: selectedField.parent_field_ids,
            path: selectedField.path,
            reference_id: selectedField.reference_id,
            // @ts-ignore
            relation_superobject_type_id: selectedField.relation_superobject_type_id ?? selectedField.superobject_type_id,
            // @ts-ignore
            restriction: selectedField.restriction,
            superobject_type_id: selectedField.superobject_type_id,
            valid: selectedField.valid,
            value: '',
            valueDisplay: ''
          }
        ],
        header: group.header,
        level: group.sub_groups[indexes[0]].level + 1,
        logical_operator: 'AND',
        relation_superobject_type_id: group.sub_groups[indexes[0]].relation_superobject_type_id,
        restriction: selectedField.restriction,
        restriction_name: group.restriction_name,
        // @ts-ignore
        restriction_parent_name: group.restriction_parent_name,
        sub_groups: [],
        superobject_type_id: ''
      })
    }
    else {
      this.addFieldToSubGroup(selectedField, indexes.slice(1), group.sub_groups[indexes[0]]);
    }
  }

  private addNewFieldToNestedGroup(group: ExtendedFieldQueryGroup, indexes: number[]): void {
    // Indexes length 2 because last index is always field, and we want to upgrade the group this time
    if (indexes.length === 2) {
      group.field_queries.push({
        parent_field_ids: [],
        relation_superobject_type_id: group.relation_superobject_type_id ?? '',
        field_title: '',
        field_name: '',
        path: '',
        input_type: '',
        reference_id: '',
        is_array_field: false,
        context_field: '',
        is_array_context_field: false,
        child_document_type: '',
        operators: [],
        operator_selected: '=',
        value: undefined,
        valueDisplay: '',
        superobject_type_id: '',
        valid: false
      })
    }
    else {
      this.addNewFieldToNestedGroup(group, indexes.slice(1));
    }
  }

  private addNewSubGroupToNestedGroup(group: ExtendedFieldQueryGroup, indexes: number[]): void {
    // Indexes length 2 because last index is always field, and we want to upgrade the group this time
    if (indexes.length === 2) {
      group.sub_groups.push({
        child_document_type: group.child_document_type ?? null,
        field_logical_operator: 'AND',
        field_queries: [
          {
            parent_field_ids: [],
            relation_superobject_type_id: '',
            field_title: '',
            field_name: '',
            path: '',
            input_type: '',
            reference_id: '',
            is_array_field: false,
            context_field: '',
            is_array_context_field: false,
            child_document_type: '',
            operators: [],
            operator_selected: '=',
            value: undefined,
            valueDisplay: '',
            superobject_type_id: '',
            valid: false
          }
        ],
        header: group.header,
        level: group.level + 1,
        logical_operator: 'AND',
        restriction_name: group.restriction_name,
        //@ts-ignore
        restriction_parent_name: group.restriction_parent_name,
        //@ts-ignore
        restriction: group.restriction,
        relation_superobject_type_id: group.relation_superobject_type_id ?? null,
        sub_groups: [],
        superobject_type_id: group.superobject_type_id ?? null
      })
    }
    else {
      this.addNewFieldToNestedGroup(group, indexes.slice(1));
    }
  }

  private hasDuplicateFields(group: ExtendedFieldQueryGroup): boolean {
    let hasDuplicates = false;

    for (const field of group.field_queries) {
      let duplicateFields = [...group.field_queries.filter(f => f.field_title === field.field_title)];

      if (duplicateFields.length > 1) {
        hasDuplicates = true;
      }
    }

    return hasDuplicates
  }

  private getFieldIndex(fieldToFind: ExtendedFieldQuery): number[] {
    for (let i = 0; i < this.extendedSearchParameters().query_groups.length; i++) {
      for (let j = 0; j < this.extendedSearchParameters().query_groups[i].field_queries.length; j++) {
        if (this.extendedSearchParameters().query_groups[i].field_queries[j] === fieldToFind) {
          return [i, j];
        }
      }

      if (this.extendedSearchParameters().query_groups[i].sub_groups.length) {
        let subGroupIndex = this.getFieldIndexFromGroup(fieldToFind, this.extendedSearchParameters().query_groups[i].sub_groups);

        if (subGroupIndex.length) {
          return [i, ...subGroupIndex];
        }
      }
    }
    return [];
  }

  private getFieldIndexFromGroup(fieldToFind: ExtendedFieldQuery, groupsToTraverse: ExtendedFieldQueryGroup[]): number[] {
    for (let i = 0; i < groupsToTraverse.length; i++) {
      for (let j = 0; j < groupsToTraverse[i].field_queries.length; j++) {
        if (groupsToTraverse[i].field_queries[j] === fieldToFind) {
          return [i, j];
        }
      }

      if (groupsToTraverse[i].sub_groups.length) {
        let subGroupIndex = this.getFieldIndexFromGroup(fieldToFind, groupsToTraverse[i].sub_groups);

        if (subGroupIndex.length) {
          return [i, ...subGroupIndex];
        }
      }
    }

    return [];
  }

  private getFieldOperatorsForFieldType(fieldType: string): ExtendedFieldQueryOperator[] {
    return this.extendedSearchFieldOperators().filter((operator: ExtendedFieldQueryOperator): boolean => {
      return operator.forFieldTypes.indexOf(fieldType) !== -1;
    })
  }

  private nestedFieldHasValuesSet(group: ExtendedFieldQueryGroup): boolean {
    for (const field of group.field_queries) {
      if ((field.value !== null && field.value !== undefined && field.value !== '') || (field.operator_selected === 'not empty' || field.operator_selected === 'empty') || (field.operator_selected === 'true' || field.operator_selected === 'false')) {
        return true;
      }
    }

    if (group.sub_groups.length) {
      let hasValue = false;
      for (const subGroup of group.sub_groups) {
        hasValue = this.nestedFieldHasValuesSet(subGroup);
      }

      return hasValue;
    }

    return false;
  }

  private removeArrayValueFromNestedField(field: ExtendedFieldQuery, group: ExtendedFieldQueryGroup, indexes: number[], value: SearchObject): void {
    if (indexes.length === 1) {
      group.field_queries[indexes[0]] = {
        ...group.field_queries[indexes[0]],
        value: (group.field_queries[indexes[0]].value as string[]).filter((item: string) => item !== value.artifact_id),
        valueDisplay: (group.field_queries[indexes[0]].valueDisplay as string[]).filter((item: string) => item !== value.artifact_name)
      }
    }
    else {
      this.removeArrayValueFromNestedField(field, group.sub_groups[indexes[0]], indexes.slice(1), value);
    }
  }

  private removeNestedField(indexes: number[], group?: ExtendedFieldQueryGroup): void {
    if (indexes.length === 1) {
      if (group) {
        if (group.field_queries.length === 1 && !group.sub_groups.length) {
          this.removeGroup(group);
        }
        else if (group.field_queries.length === 1 && group.sub_groups.length) {
          group.field_queries[0] ={
            is_array_context_field: false,
            is_array_field: false,
            relation_superobject_type_id: '',
            valid: false,
            value: '',
            valueDisplay: '',
            parent_field_ids: [],
            field_title: '',
            field_name: '',
            path: '',
            input_type: '',
            reference_id: '',
            operator_selected: undefined,
            operators: [],
            superobject_type_id: null,
            context_field: null,
            child_document_type: null
          }
        }
        else {
          group.field_queries.splice(indexes[0], 1);
        }
      }
    }
    else {
      if (group) {
        this.removeNestedField(indexes.slice(1), group.sub_groups[indexes[0]]);
      }
      else {
        this.removeNestedField(indexes.slice(1), this.extendedSearchParameters().query_groups[indexes[0]]);
      }
    }
  }

  private removeNestedGroup(indexes: number[], group?: ExtendedFieldQueryGroup): void {
    if (indexes.length === 1) {
      if (group) {
        if (group.sub_groups.length === 1 && !group.field_queries.length) {
          this.removeGroup(group);
        }
        else {
          group.sub_groups.splice(indexes[0], 1);
        }
      }
      else {
        this.extendedSearchParameters().query_groups.splice(indexes[0], 1);
      }
    }
    else {
      if (group) {
        this.removeNestedGroup(indexes.slice(1), group.sub_groups[indexes[0]]);
      }
      else {
        this.removeNestedGroup(indexes.slice(1), this.extendedSearchParameters().query_groups[indexes[0]]);
      }
    }
  }

  private sqlModeShouldRemainActive(group?: ExtendedFieldQueryGroup): boolean {
    let sqlMode = false;

    if (group) {
      for (const subGroup of group.sub_groups) {
        // @ts-ignore
        if (subGroup.restriction?.sql_mode) {
          sqlMode = true;
        }

        if (subGroup.sub_groups.length && !sqlMode) {
          sqlMode = this.sqlModeShouldRemainActive(subGroup);
        }
      }
    }
    else {
      for (const group of this.extendedSearchParameters().query_groups) {
        for (const field of group.field_queries) {
          // @ts-ignore
          if (field.restriction?.sql_mode) {
            sqlMode = true;
          }
        }

        if (group.sub_groups.length && !sqlMode) {
          sqlMode = this.sqlModeShouldRemainActive(group);
        }
      }
    }

    return sqlMode;
  }

  private updateNestedField(selectedField: ExtendedSearchField, group: ExtendedFieldQueryGroup, indexes: number[]): void {
    if (indexes.length === 1) {
      if (selectedField !== null) {

        if (selectedField.superobject_type_id || selectedField.child_document_type) {
          if (group.header === 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION') {
            group.header = selectedField.field_title.split(' / ')[0];
          }
          //@ts-ignore
          group.relation_superobject_type_id = !!group.relation_superobject_type_id ? group.relation_superobject_type_id : selectedField.relation_superobject_type_id ?? selectedField.superobject_type_id;
          group.child_document_type = selectedField.child_document_type;
        }

        if (selectedField.restriction?.parents) {
          // @ts-ignore
          group.restriction_parent_name = selectedField.restriction.parents[0];
          group.restriction_name = selectedField.restriction.parents[selectedField.restriction.parents.length - 1];
        }

        group.field_queries[indexes[0]] = {
          child_document_type: selectedField.child_document_type,
          context_field: selectedField.context_field,
          field_name: selectedField.field_name,
          field_title: selectedField.field_title,
          input_type: selectedField.input_type,
          is_array_context_field: selectedField.is_array_context_field,
          is_array_field: selectedField.is_array_field,
          operator_selected: '=',
          operators: this.getFieldOperatorsForFieldType(selectedField.input_type),
          parent_field_ids: selectedField.parent_field_ids,
          path: selectedField.path,
          reference_id: selectedField.reference_id,
          relation_superobject_type_id: group.relation_superobject_type_id,
          // @ts-ignore
          restriction: selectedField.restriction,
          superobject_type_id: selectedField.superobject_type_id,
          valid: selectedField.valid,
          value: '',
          valueDisplay: ''
        }
      }
      else {
        if (!this.groupHasMoreThanOneFieldsSelected(group) && !this._parentGroupHasRestrictions(group)) {
          group.relation_superobject_type_id = null;
          group.child_document_type = null;

          group.header = 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION';
        }

        group.field_queries[indexes[0]] = {
          is_array_context_field: false,
          is_array_field: false,
          relation_superobject_type_id: '',
          valid: false,
          value: '',
          valueDisplay: '',
          parent_field_ids: [],
          field_title: '',
          field_name: '',
          path: '',
          input_type: '',
          reference_id: '',
          operator_selected: undefined,
          operators: [],
          superobject_type_id: null,
          context_field: null,
          child_document_type: null
        }
      }
    }
    else {
      this.updateNestedField(selectedField, group.sub_groups[indexes[0]], indexes.slice(1));
    }
  }

  private _parentGroupHasRestrictions(group: ExtendedFieldQueryGroup, indexes?: number[]): boolean {
    if (indexes) {
      if (indexes.length === 2) {
        return !!group.superobject_type_id || !!group.child_document_type || !!group.restriction_name;
      }
      else {
        return this._parentGroupHasRestrictions(group.sub_groups[indexes[0]], indexes.slice(1));
      }
    }
    else {
      const i = this.getFieldIndex(group.field_queries[0]);

      // If indexes length is 1 or 2, it is a root group and cant have parents
      if (i.length === 1 || i.length === 2) {
        return false;
      }

      return this._parentGroupHasRestrictions(this.extendedSearchParameters().query_groups[i[0]], i.slice(1));
    }
  }

  private updateNestedFieldSortOrder(selectedField: ExtendedSearchField, group: ExtendedFieldQueryGroup, indexes: number[]): void {
    if (indexes.length === 1) {
      // @ts-ignore
      if (group.field_queries[indexes[0]].restriction?.new_group === 'relationSubgroup') {
        group.sub_groups.push({
          relation_superobject_type_id: '',
          superobject_type_id: '',
          child_document_type: '',
          header: 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION',
          field_queries: [
            {...group.field_queries[indexes[0]]}
          ],
          logical_operator: 'AND',
          field_logical_operator: 'AND',
          sub_groups: [],
          level: 0
        });
      }
      else {
        group.field_queries.push({
          child_document_type: selectedField.child_document_type,
          context_field: selectedField.context_field,
          field_name: selectedField.field_name,
          field_title: selectedField.field_title,
          input_type: selectedField.input_type,
          is_array_context_field: selectedField.is_array_context_field,
          is_array_field: selectedField.is_array_field,
          operator_selected: '=',
          operators: this.getFieldOperatorsForFieldType(selectedField.input_type),
          parent_field_ids: selectedField.parent_field_ids,
          path: selectedField.path,
          reference_id: selectedField.reference_id,
          relation_superobject_type_id: group.relation_superobject_type_id,
          // @ts-ignore
          restriction: selectedField.restriction,
          superobject_type_id: selectedField.superobject_type_id,
          valid: selectedField.valid,
          value: '',
          valueDisplay: ''
        })
      }
    }
    else {
      this.updateNestedFieldSortOrder(selectedField, group.sub_groups[indexes[0]], indexes.slice(1));
    }
  }

  private updateNestedFieldOperator(field: ExtendedFieldQuery, group: ExtendedFieldQueryGroup, indexes: number[]): void {
    if (indexes.length === 1) {
      group.field_queries[indexes[0]] = {
        ...group.field_queries[indexes[0]],
        operator_selected: field.operator_selected,
        value: '',
        valueDisplay: ''
      }
    }
    else {
      this.updateNestedFieldOperator(field, group.sub_groups[indexes[0]], indexes.slice(1));
    }
  }

  private updateNestedFieldValue(field: ExtendedFieldQuery, group: ExtendedFieldQueryGroup, indexes: number[], value: SearchObject): void {
    if (indexes.length === 1) {
      group.field_queries[indexes[0]] = {
        ...group.field_queries[indexes[0]],
        value: value !== null ? value.artifact_id : null,
        valueDisplay: value !== null ? value.artifact_name: null
      }
    }
    else {
      this.updateNestedFieldValue(field, group.sub_groups[indexes[0]], indexes.slice(1), value);
    }
  }

  private updateNestedFieldValueArray(field: ExtendedFieldQuery, group: ExtendedFieldQueryGroup, indexes: number[], value: SearchObject): void {
    if (indexes.length === 1) {
      group.field_queries[indexes[0]] = {
        ...group.field_queries[indexes[0]],
        value: !Array.isArray(group.field_queries[indexes[0]].value) ? [value.artifact_id] : [...group.field_queries[indexes[0]].value, value.artifact_id],
        // @ts-ignore
        valueDisplay: !Array.isArray(group.field_queries[indexes[0]].valueDisplay) ? [value.artifact_name] : [...group.field_queries[indexes[0]].valueDisplay, value.artifact_name],
      }
    }
    else {
      this.updateNestedFieldValueArray(field, group.sub_groups[indexes[0]], indexes.slice(1), value);
    }
  }

  private updateNestedFieldValueArrayFromAny(field: ExtendedFieldQuery, group: ExtendedFieldQueryGroup, indexes: number[], value: any[]): void {
    if (indexes.length === 1) {
      group.field_queries[indexes[0]] = {
        ...group.field_queries[indexes[0]],
        value: value,
        valueDisplay: value
      }
    }
    else {
      this.updateNestedFieldValueArrayFromAny(field, group.sub_groups[indexes[0]], indexes.slice(1), value);
    }
  }

  private updateNestedFieldValueFromString(field: ExtendedFieldQuery, group: ExtendedFieldQueryGroup, indexes: number[], value: string): void {
    if (indexes.length === 1) {
      group.field_queries[indexes[0]] = {
        ...group.field_queries[indexes[0]],
        value: value,
        valueDisplay: value
      }
    }
    else {
      this.updateNestedFieldValueFromString(field, group.sub_groups[indexes[0]], indexes.slice(1), value);
    }
  }

  private updateNestedFieldWithMovedField(group: ExtendedFieldQueryGroup, indexes: number[], selectedField: ExtendedSearchField): void {
    if (indexes.length === 1) {
      group.sub_groups.push({
        relation_superobject_type_id: group.relation_superobject_type_id,
        superobject_type_id: group.superobject_type_id,
        child_document_type: group.child_document_type,
        header: group.header,
        // @ts-ignore
        field_queries: [ ...group.field_queries.filter(f => f.restriction?.new_group === 'relationSubgroup') ],
        logical_operator: 'AND',
        field_logical_operator: 'AND',
        sub_groups: [],
        level: 0
      });

      group.field_queries = [
        // @ts-ignore
        ...group.field_queries.filter(f => f.restriction?.new_group !== 'relationSubgroup' && f.field_title !== ''),
        {
          child_document_type: selectedField.child_document_type,
          context_field: selectedField.context_field,
          field_name: selectedField.field_name,
          field_title: selectedField.field_title,
          input_type: selectedField.input_type,
          is_array_context_field: selectedField.is_array_context_field,
          is_array_field: selectedField.is_array_field,
          operator_selected: '=',
          operators: this.getFieldOperatorsForFieldType(selectedField.input_type),
          parent_field_ids: selectedField.parent_field_ids,
          path: selectedField.path,
          reference_id: selectedField.reference_id,
          relation_superobject_type_id: group.relation_superobject_type_id,
          // @ts-ignore
          restriction: selectedField.restriction,
          superobject_type_id: selectedField.superobject_type_id,
          valid: selectedField.valid,
          value: '',
          valueDisplay: ''
        }
      ]
    }
    else {
      this.updateNestedFieldWithMovedField(group.sub_groups[indexes[0]], indexes.slice(1), selectedField);
    }
  }

  private updateNestedGroupOperator(indexes: number[], operator: ExtendedFieldQueryLogicalOperator, group?: ExtendedFieldQueryGroup): void {
    if (indexes.length === 1) {
      if (group) {
        if (operator !== 'OR' && this.hasDuplicateFields(group)) {
          const modalref = this.modal.open(ConfirmationModalComponent, {
            data: {
              title: 'TRANS__EXTENDED_SEARCH__DUPLICATE_CONFIRMATION_TITLE',
              content: 'TRANS__EXTENDED_SEARCH__DUPLICATE_CONFIRMATION_CONTENT',
              confirmButtonText: 'TRANS__EXTENDED_SEARCH__DUPLICATE_CONFIRMATION_CONFIRM',
              cancelButtonText: 'TRANS__EXTENDED_SEARCH__DUPLICATE_CONFIRMATION_CANCEL'
            }
          });

          modalref.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
              this._removeDuplicateFields(group);

              group.sub_groups[indexes[0]].field_logical_operator = operator;
            }
          })
        }
        else {
          group.sub_groups[indexes[0]].field_logical_operator = operator;
        }
      }
      else {
        if (operator !== 'OR' && this.hasDuplicateFields(this.extendedSearchParameters().query_groups[indexes[0]])) {
          const modalref = this.modal.open(ConfirmationModalComponent, {
            data: {
              title: 'TRANS__EXTENDED_SEARCH__DUPLICATE_CONFIRMATION_TITLE',
              content: 'TRANS__EXTENDED_SEARCH__DUPLICATE_CONFIRMATION_CONTENT',
              confirmButtonText: 'TRANS__EXTENDED_SEARCH__DUPLICATE_CONFIRMATION_CONFIRM',
              cancelButtonText: 'TRANS__EXTENDED_SEARCH__DUPLICATE_CONFIRMATION_CANCEL'
            }
          });

          modalref.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
              this._removeDuplicateFields(this.extendedSearchParameters().query_groups[indexes[0]]);

              this.extendedSearchParameters().query_groups[indexes[0]].field_logical_operator = operator;
            }
          })
        }
        else {
          this.extendedSearchParameters().query_groups[indexes[0]].field_logical_operator = operator;
        }
      }
    }
    else {
      if (!group) {
        this.updateNestedGroupOperator(indexes.slice(1), operator, this.extendedSearchParameters().query_groups[indexes[0]]);
      }
      else {
        this.updateNestedGroupOperator(indexes.slice(1), operator, group.sub_groups[indexes[0]]);
      }
    }
  }

  private _removeDuplicateFields(group: ExtendedFieldQueryGroup) {
    const newFields: ExtendedFieldQuery[] = [];

    for (const field of group.field_queries) {
      if (newFields.findIndex(f => f.field_title.split(' / ')[f.field_title.split(' / ').length - 1] === field.field_title.split(' / ')[field.field_title.split(' / ').length - 1]) === -1) {
        newFields.push(field);
      }
    }

    group.field_queries = newFields;
  }

  private _filterUsedFields(fields: ExtendedSearchField[], usedFieldIds: string[]): ExtendedSearchField[] {
    let res = fields;
    if (usedFieldIds.length > 0) {
      res = [];
      for (const field of fields) {
        if (!usedFieldIds.includes(field.artifact_id)) {
          res.push(field);
        }
      }
    }
    return res;
  }

  private markUsedFields(fields: ExtendedSearchField[], usedFieldIds: string[], currentGroup?: ExtendedFieldQueryGroup): void {
    for (const field of fields || []) {
      if (currentGroup) {
        field.used = usedFieldIds.includes(field.artifact_id) && currentGroup.field_queries.findIndex(f => f.field_title === field.field_title) !== -1;
      }
      else {
        field.used = usedFieldIds.includes(field.artifact_id);
      }

      this.markUsedFields(field.children, usedFieldIds);
    }
  }

  private _filterUsedRelations(fields: ExtendedSearchField[], usedRelationIds: string[]): ExtendedSearchField[] {
    let res = fields;
    if (usedRelationIds.length > 0) {
      res = [];
      for (const field of fields) {
        if (!usedRelationIds.includes(field.superobject_type_id)) {
          res.push(field);
        }
      }
    }
    return res;
  }

  private _markUsedRelations(fields: ExtendedSearchField[], currentGroup: ExtendedFieldQueryGroup, usedRelationIds: string[]) {
    for (const field of fields || []) {
      if (usedRelationIds.includes(field.superobject_type_id)) {
        if (currentGroup.field_queries.findIndex(f => f.superobject_type_id === field.superobject_type_id) === -1) {
          field.used = true;
        }

        if (field.children?.length) {
          this._markUsedRelations(field.children, currentGroup, usedRelationIds);
        }
      }
    }
  }

  private _markUsed(fields: ExtendedSearchField[], currentGroup: ExtendedFieldQueryGroup, usedRelationIds: string[], usedFields): void {
    for (const field of fields || []) {
      for (const usedField of usedFields) {
        if (field.artifact_id === usedField.parent_field_id && field.restriction?.parent === usedField.restriction?.parent) {
          field.used = true;
        }

        if (usedRelationIds.includes(field.superobject_type_id) && currentGroup.field_queries.findIndex(f => f.field_title === field.field_title) === -1) {
          field.used = true;
        }
      }

      if (field.children?.length) {
        this._markUsed(field.children, currentGroup, usedRelationIds, usedFields);
      }
    }
  }

  private _getInitialParamState(): ExtendedSearchParams {
    return {
      db_search: false,
      field_queries: [],
      query_groups: [
        {
          relation_superobject_type_id: '',
          superobject_type_id: '',
          child_document_type: '',
          header: 'TRANS__ADVANCED_SEARCH__HEADER__OBJECTINFORMATION',
          field_queries: [
            {
              parent_field_ids: [],
              relation_superobject_type_id: '',
              field_title: '',
              field_name: '',
              path: '',
              input_type: '',
              reference_id: '',
              is_array_field: false,
              context_field: '',
              is_array_context_field: false,
              child_document_type: '',
              operators: [],
              operator_selected: '=',
              value: undefined,
              valueDisplay: '',
              superobject_type_id: '',
              valid: false
            }
          ],
          logical_operator: 'AND',
          field_logical_operator: 'AND',
          sub_groups: [],
          level: 0
        }
      ],
      superobject_type_ids: this.searchContainer.currentPathView.search_view.superobject_types
    }
  }

  private _getUsedFieldInfo(groups?: ExtendedFieldQueryGroup[], currentGroup?: ExtendedFieldQueryGroup, isSubGroup?: boolean) {
    let usedFieldIds = [];
    let usedRelationIds = [];

    for (const group of groups ? groups : this.extendedSearchParameters().query_groups) {
      const [usedFieldIdsForGroup, usedRelationIdsForGroup] = this._getUsedInfoForGroup(group);
      usedFieldIds = usedFieldIds.concat(usedFieldIdsForGroup);
      usedRelationIds = usedRelationIds.concat(usedRelationIdsForGroup);

      if (group.sub_groups?.length) {
        const [usedFieldIdsSub, usedRelationIdsSub] = this._getUsedFieldInfo(group.sub_groups, null, false);
        usedFieldIds = usedFieldIds.concat(usedFieldIdsSub);
        usedRelationIds = usedRelationIds.concat(usedRelationIdsSub);
      }
    }


    return [usedFieldIds, usedRelationIds];
  }

  private _getUsedInfoForGroup(group: ExtendedFieldQueryGroup) {
    let usedFields = [];
    let usedRelationIds = [];

    for (const field of group.field_queries) {
      if (field.parent_field_ids?.length) {
        for (const parentField of field.parent_field_ids) {
          usedFields.push({
            parent_field_id: parentField,
            //@ts-ignore
            restriction: field.restriction
          });
        }
      }

      if (field.relation_superobject_type_id) {
        usedRelationIds.push(field.relation_superobject_type_id);
      }
    }

    return [usedFields, usedRelationIds];
  }

  private _getUsedFieldInfoForGroup(group: ExtendedFieldQueryGroup): [string[], string[]] {
    let usedFieldIds = [];
    let usedRelationIds = [];
    for (const fieldQuery of group.field_queries) {
      if (fieldQuery.parent_field_ids?.length) {
        usedFieldIds = usedFieldIds.concat(fieldQuery.parent_field_ids);
      }
      if (fieldQuery.relation_superobject_type_id) {
        usedRelationIds.push(fieldQuery.relation_superobject_type_id);
      }
    }
    return [usedFieldIds, usedRelationIds];
  }

  public async getExtendedSearchFields(params: GetExtendedSearchFieldParams, queryGroups?: ExtendedFieldQueryGroup[], currentGroup?: ExtendedFieldQueryGroup): Promise<ExtendedSearchField[]> {
    const fieldRes = await this.cmsApi.getAdvancedSearchFields(params);

    let fields = fieldRes.fields;

    let [usedFieldIds, usedRelationIds] = this._getUsedFieldInfo(queryGroups, currentGroup, params.is_sub_group);

    this._markUsed(fields, currentGroup, usedRelationIds, usedFieldIds);

    // if (usedFieldIds.length) {
    //   fields = this._filterUsedFields(fields, usedFieldIds);
    // }
    // Comment out this line to show groups with only solr-only children
    fields = fields.filter(field => !this.fieldHasOnlySolrOnlyChildren(field));

    return fields;
  }

  private fieldHasOnlySolrOnlyChildren(field: ExtendedSearchField): boolean {
    if (field.children?.length) {
      for (const child of field.children) {
        // @ts-ignore
        if (!child.restriction?.solr_mode_only) {
          return false
        }

        if (child.children?.length) {
          let hasSolrOnlyChildren = this.fieldHasOnlySolrOnlyChildren(child);

          if (!hasSolrOnlyChildren) {
            return false;
          }
        }
      }
    }
    else {
      return false;
    }

    return true;
  }

  public async getExtendedSearchFieldValues(fieldQuery: ExtendedFieldQuery, query: string, superObjectTypeIds: string[], max_value_count: number, folderId: string): Promise<SearchObject[]> {
    let fieldName = fieldQuery.path ? `${fieldQuery.path}.${fieldQuery.field_name}` : fieldQuery.field_name;
    if (folderId && (fieldQuery.relation_superobject_type_id || fieldQuery.child_document_type)) {
      folderId = ''
    }
    let params = {
      query: query,
      superobject_type_ids: superObjectTypeIds.join(','),
      child_document_type: fieldQuery.child_document_type,
      facet_limit: max_value_count,
      folder_id: folderId
    };
    const searchObjects = await this.cmsApi.getAdvancedSearchFieldValues(fieldName, params);
    return this.setHierarchicFieldValues(searchObjects, fieldQuery)
  }

  private async fetchAndSetExtendedSearchFieldOperators(): Promise<void> {
    this.extendedSearchFieldOperators.set(await this.cmsApi.getFieldQueryOperators());
  }

  private async fetchAndSetExtendedSearchSuggestions(): Promise<void> {
    this.extendedSearchSuggestions.set(await this.cmsApi.getSearchSuggestions());
  }

  private async setHierarchicFieldValues(searchObjects: SearchObject[], fieldQuery: ExtendedFieldQuery): Promise<SearchObject[]> {
    const reference = this.referenceService.getSearchReferenceFromReferenceId(fieldQuery.reference_id);
    if (searchObjects.length && reference?.is_hierarchic) {
      const nodeDisplayField = await this.optionsService.getNodeDisplayField(reference, 'name.name');
      const rootNode = new HierarchicNode();
      const searchNodes: HierarchicNode[] = searchObjects.map(searchObj => this.optionsService.searchObjectToNode(
        searchObj, false, nodeDisplayField));
      this.optionsService.putSearchNodesInHierarchy(rootNode, searchNodes, nodeDisplayField);
      return rootNode.children;
    } else {
      return searchObjects;
    }
  }
}
