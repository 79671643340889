import {Component, OnInit} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {EventHarvesterService} from '../../core/event-harvester.service';
import {LoginService} from '../../core/login.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {FeatureFlagsService} from "../../core/feature-flags.service";
import {PrimusBackendInstanceService, PrimusInstanceDetails} from "../../core/primus-backend-instance.service";

export class UserManual {
  manuals: UserManualItem[];
}

export class UserManualItem {
  href: string;
  title: string;
}

@Component({
  selector: 'app-about-primus',
  templateUrl: './about-primus.component.html',
  styleUrls: ['./about-primus.component.scss']
})
export class AboutPrimusComponent implements OnInit {

  museum: PrimusInstanceDetails;
  parent = {
    adminType: 'about',
    hasSubMenus: false,
    currentState: ''
  };

  edition = {
    title: ''
  };
  version: string;
  userManual: UserManual;

  constructor(private readonly loginService: LoginService,
              private readonly primusRouter: PrimusRouterService,
              private readonly cms: CmsApiService,
              private readonly commons: CommonsService,
              private readonly eventHarvester: EventHarvesterService,
              private readonly featureFlagsService: FeatureFlagsService) {
  }

  ngOnInit() {
    this.setEditionAndVersion((edition: any, version: string) => {
      this.edition = edition;
      this.version = version;
    });

    this.userManual = {
      manuals: [{
        href: this.cms.getApiUrl('translation/manual/', true),
        title: 'TRANS__ABOUT_PRIMUS__USER_MANUAL'
      }],
    }
    this.parent = {
      adminType: 'about',
      hasSubMenus: false,
      currentState: this.primusRouter.currentState()
    };

    this.museum = PrimusBackendInstanceService.getInstanceDetails();

  }

  logDownloadManual(name: string) {
    this.eventHarvester.fileDownload('manual', name);
  }

  private setEditionAndVersion(fn: any) {
    this.loginService.currentUser.subscribe(user => {
      if (user) {
        const edition = this.commons.setEditionTitle(user);
        const version = user['version'];
        fn(edition, version);
      }
    });
  }

  readonly useNewLayout = this.featureFlagsService.getFeatureFlags()?.experimental?.useNewMainMenu;
}
