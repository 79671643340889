import {AfterViewInit, Component, Input} from '@angular/core';
import {SearchContainer} from "../../../core/definitions/search-container";
import {SearchObject} from "../../../core/definitions/search-object";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatIcon} from "@angular/material/icon";
import {SearchResultSelectionsService} from "../../search-result-selections.service";
import {NgClass} from "@angular/common";
import {FeatureFlagsService} from "../../../core/feature-flags.service";
import {RouterLink} from "@angular/router";
import {objectTypeToMatSymbol} from "../../../shared/material-icons";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-search-result-view-thumbnail-v2',
  standalone: true,
  imports: [
    MatCheckbox,
    MatIcon,
    NgClass,
    RouterLink,
    MatTooltipModule,
    TranslateModule
  ],
  templateUrl: './search-result-view-thumbnail-v2.component.html',
  styleUrl: './search-result-view-thumbnail-v2.component.scss'
})
export class SearchResultViewThumbnailV2Component implements AfterViewInit {
  @Input() isRootObject: boolean;
  @Input() item: SearchObject;
  @Input() searchContainer: SearchContainer;

  constructor(
    private readonly featureFlagService: FeatureFlagsService,
    private readonly searchResultSelectionService: SearchResultSelectionsService
  ) {}

  ngAfterViewInit(): void {
    if (this.isRootObject) {
      const element = document.getElementById(this.item.artifact_id);
      if (element) {
        element.scrollIntoView({ behavior: 'instant' });

        // Set a small timeout to ensure the element is in view before focusing
        setTimeout(() => {
          // Make the element focusable if it isn't already
          if (!element.hasAttribute('tabindex')) {
            element.setAttribute('tabindex', '-1');
          }

          element.focus({ preventScroll: true });
        }, 100);
      }
    }
  }

  getMaterialSymbol(objectType: string) {
    return objectTypeToMatSymbol(objectType);
  }

  getRouterLink(item: SearchObject) {
    if (item.$$refData?.routerLink?.join('/')?.includes('/artifact') && this.objectPageV2) {
      return [...item.$$refData.routerLink, item.artifact_id];
    }
    return item.$$refData?.routerLink
  }

  select(item: SearchObject, event: MouseEvent) {
    event.stopPropagation();
    this.searchResultSelectionService.selectSearchResultItem(item, this.searchContainer, event.shiftKey);
  }

  isCheckboxDisabled(item: SearchObject): boolean {
    return item.$$used || (this.searchResultSelectionService.isSelectionLimitReached(this.searchContainer) && !this.searchContainer.selections.selected[item.artifact_id]);
  }

  readonly objectPageV2 = this.featureFlagService.getFeatureFlags().experimental.useNewObjectPage;
}
