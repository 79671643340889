import {SearchObject} from './search-object';
import {Facet, RangeGroup} from './search-objects';

interface StatsField {
  min: number;
  max: number;
  count: number;
  missing: number;
  sum: number;
  sumOfSquares: number;
  mean: number;
  stddev: number;
}
interface Stats {
  stats_fields: {[name: string]: StatsField}
}

export class SearchResult {
  artifacts: Array<SearchObject>;
  correlation_id: string;
  facets: Array<Facet>;
  facet_range_groups: Array<RangeGroup>;
  total_count: number;
  search_count: number;
  start: number;
  end: number;
  stats: Stats;
  status: string;
  status_msg: string;
  status_code: any;

  // client only
  $$searchResId: number;
}
