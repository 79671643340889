import {Injectable} from '@angular/core';
import {CmsApiService} from "./cms-api.service";
import {MetaField} from "./definitions/meta-field";
import {OptionInfo} from "./definitions/option-info";

@Injectable({
  providedIn: 'root'
})
export class ValueOptionService {
  private allValueOptionInformation: {[id: string]: OptionInfo};

  constructor(private cms: CmsApiService) {
  }

  setAllValueOptions() {
    this.cms.getValueOptions().then(allValueOptionInformation => {
      this.allValueOptionInformation = allValueOptionInformation;
    })
  }

  getValueOptionsForField(field: MetaField): OptionInfo {
    let res: OptionInfo;
    if (field.option_info) {
      res = field.option_info
    } else if (field.value_option_id) {
      if (!this.allValueOptionInformation) {
        this.setAllValueOptions();
        res = null;
      } else {
        res = this.allValueOptionInformation[field.value_option_id];
      }
    }
    return res;
  }
}
