import {MediaItem} from './media-item';

export class ImageItem extends MediaItem {
  object_type = 'ImageItem';
  image_id: string;
  dms_identifier: string;
  order_number: number;
  thumbnail_id: string;

  $$uploading: boolean;
}
