<div class="section-panel">
  <ng-container *ngIf="fieldParameterList">
    <div *ngFor="let fieldParameters of fieldParameterList" class="section-panel__field">
      <div class="section-panel-edit-field {{styleClass}}">
        <div class="section-panel-edit-field__view">
          <div class="section-panel-edit-field__view-title">
            <i *ngIf="fieldParameters.field.$$inlineView?.icon &&
            fieldParameters.field.$$inlineView?.icon.startsWith('icon-')"
               [ngClass]="fieldParameters.field.$$inlineView?.icon"></i>
            <mat-icon *ngIf="fieldParameters.field.$$inlineView?.icon &&
            !fieldParameters.field.$$inlineView?.icon.startsWith('icon-')">{{ fieldParameters.field.$$inlineView?.icon }}</mat-icon>
            <span class="bold">{{fieldParameters.field.title | translate}}</span>
          </div>
          <div class="section-panel-edit-field__view-content">
            <app-edit-field-input
              [fieldParameters]="fieldParameters"
              [disabled]="!edit"
            ></app-edit-field-input>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>
