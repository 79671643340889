<div class="report-preview-area"
     [ngClass]="{'report-preview-area-three-col' : numColumnsInReport == 3, 'report-preview-area-four-col' : numColumnsInReport == 4, 'report-preview-area-five-col' : numColumnsInReport == 5, 'report-preview-area-six-col' : numColumnsInReport == 6}">
  @for (artifact of extractedArtifacts; track artifact.artifactId; let idx = $index) {
    <div class="report-image-artifact-object">
      <div class="report-image-artifact-image">
        <div class="report-artifact-field-image-box">
          @if (artifact.thumbnailImage) {
            <app-thumbnail
              [item]="artifact.thumbnailImage"
              [stylingClass]="'report-artifact-image-img'"></app-thumbnail>
          } @else {
            <div class="report-picture-missing">
              <span>{{'TRANS__HAS_IMAGES__NONE' | translate}}</span>
            </div>
          }
        </div>
      </div>
      @if (isLedeteksterSelected()) {
        <h1>{{ artifact.summaryIdentifier }}</h1>
        <h2>{{ artifact.summaryTitle }}</h2>
      }
    </div>
  }
</div>
