import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

export interface ConfirmationModalData {
  title: string;
  content: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmAction?: () => void;
}

@Component({
  selector: 'app-confirmation-modal',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    TranslateModule
  ],
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss'
})
export class ConfirmationModalComponent {
  constructor (
    @Inject(MAT_DIALOG_DATA) public readonly data: ConfirmationModalData,

    public readonly dialogRef: MatDialogRef<ConfirmationModalComponent>,
  ) {}

  confirm() {
    if (this.data.confirmAction) {
      this.data.confirmAction();
    }

    this.dialogRef.close(true);
  }
}
