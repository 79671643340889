import {Component, Input, OnInit} from '@angular/core';
import { SearchContainer } from '../../../core/definitions/search-container';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ExtendedSearchService } from '../../../core/extended-search.service';
import { SearchSuggestion } from '../../../core/definitions/extended-search-params';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../../../shared/confirmation-modal/confirmation-modal.component';
import { SearchFilterService } from '../../search-filter.service';
import { FilterGroupHierarchyNode } from 'src/app/core/definitions/search-objects';
import { SearchExecutorService } from '../../search-executor.service';

@Component({
  selector: 'app-search-query-extended-search',
  standalone: true,
  imports: [
    MatMenu,
    TranslateModule,
    MatIconButton,
    MatIcon,
    MatMenuTrigger,
    MatButton,
    MatMenuItem
  ],
  templateUrl: './search-query-extended-search.component.html',
  styleUrl: './search-query-extended-search.component.scss'
})
export class SearchQueryExtendedSearchComponent implements OnInit {
  @Input() searchContainer: SearchContainer;

  constructor (
    public readonly extendedSearchService: ExtendedSearchService,

    private readonly modal: MatDialog,
    private readonly searchExecutorService: SearchExecutorService,
    private readonly searchFilterService: SearchFilterService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.searchFilterService.resetFilter(this.searchContainer);

    if (this.searchContainer.filtersFacets?.hierarchicFilterGroup?.childrenArray?.length > 0) {
      this.checkHierarchicFiltersChecked(this.searchContainer.filtersFacets?.hierarchicFilterGroup?.childrenArray, true);
    }

    this.searchExecutorService.runSearch(this.searchContainer).then();
  }

  getHeaderText() {
    if (this.searchContainer.currentPathView.path_name === 'home') {
      return this.translateService.instant('TRANS__QUERY_MENU__ADVANCED_SEARCH');
    }
    else {
      return `${this.translateService.instant('TRANS__QUERY_MENU__ADVANCED_SEARCH')} ${this.translateService.instant('TRANS__SEPARATOR__IN')} ${this.translateService.instant(this.searchContainer.currentPathView.search_view.title)}`;
    }
  }

  setSearchSuggestion(searchSuggestion: SearchSuggestion) {
    if (this.extendedSearchService.getRulesHasValues()) {
      const modalRef = this.modal.open(ConfirmationModalComponent, {
        data: {
          title: 'TRANS__ADVANCED_SEARCH__MODAL__CONTENT',
          content: 'TRANS__ADVANCED_SEARCH__MODAL__CONTENT',
          confirmButtonText: 'TRANS__ADVANCED_SEARCH__MODAL__CONFIRM',
          cancelButtonText: 'TRANS__ADVANCED_SEARCH__MODAL__CANCEL'
        }
      });

      modalRef.afterClosed().subscribe((clear) => {
        if (clear) {
          this.extendedSearchService.initializeSearchSuggestions(() => {
            this.extendedSearchService.resetExtendedSearchToSuggestion(searchSuggestion);
          });
        }
      })
    }
    else {
      this.extendedSearchService.initializeSearchSuggestions(() => {
        this.extendedSearchService.resetExtendedSearchToSuggestion(searchSuggestion);
      });
    }
  }

  private checkHierarchicFiltersChecked(filterGroup: FilterGroupHierarchyNode[], deselect: boolean = false): boolean {
    let isChecked = false;

    for (const filter of filterGroup) {
      if (filter.facet?.selected) {
        if (deselect) {
          filter.facet.selected = false;
          filter.open = false;
        }
        isChecked = true;
      }

      if(filter.childrenArray?.length) {
        isChecked = this.checkHierarchicFiltersChecked(filter.childrenArray);
      }
    }

    return isChecked;
  }
}
