import {Injectable} from '@angular/core';

export class AppNotification {
  constructor(messages: Array<string>, type: string, values?: object, status?: number) {
    this.messages = messages;
    this.type = type;
    this.values = values;
    this.status = status;
  }

  messages: Array<string>;
  type: string;
  values: object;
  status: number;
  correlationId: string;
  _id: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private appNotifications: Array<AppNotification> = [];
  private notificationTime = 0;

  // Wait 3 seconds before auto-dismissing notification.
  private autoDismissDelay = 6 * 1000;

  // Auto-dismiss notifications of these types.
  private autoDismissTypes = ['success', 'info'];

  constructor() {
  }

  get notifications(): Array<AppNotification> {
    return this.appNotifications;
  }

  addNotification(notification: AppNotification) {
    const mxLen = 3;
    const notTime = new Date().getTime();
    let mustDelay = false, delay = 1000;
    if (this.appNotifications.length > 0) {
      mustDelay = notTime < this.notificationTime;
      if (mustDelay) {
        delay += this.notificationTime - notTime; // updated delay calculation remains the same
      }
    }
    this.notificationTime = notTime + delay;
    setTimeout(() => {
      if (this.appNotifications.length > mxLen) {
        this.appNotifications.splice(0, this.appNotifications.length - mxLen); // removed extra notifications if any
      }
      notification._id = this.appNotifications.length; // assigning an id based on current length
      this.appNotifications.push(notification); // notification is now visible

      // Start auto-dismiss timer after notification is visible
      if (this.autoDismissTypes.includes(notification.type)) { // updated to use includes instead of in
        setTimeout(() => {
          this.appNotifications = this.autoDismissSuccess(this.appNotifications, notification._id); // auto-dismiss after autoDismissDelay
        }, this.autoDismissDelay); // delay now starts after the notification is shown
      }
    }, mustDelay ? delay : 0); // delay before showing the notification remains unchanged
  }

  removeNotification(index) {
    this.appNotifications.splice(index, 1);
  }

  private autoDismissSuccess(notifications, id) {
    const ix = notifications.findIndex(function (n) {
      return (n._id === id);
    });

    notifications.splice(ix, 1);
    return notifications;
  }

}
