import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

export interface InformationModalData {
  title: string;
  content: string;
  buttonText?: string;
}

@Component({
  selector: 'app-information-modal',
  standalone: true,
  imports: [
    MatDialogTitle,
    TranslateModule,
    MatDialogContent,
    MatDialogActions,
    MatButton
  ],
  templateUrl: './information-modal.component.html',
  styleUrl: './information-modal.component.scss'
})
export class InformationModalComponent {
  constructor (
    @Inject(MAT_DIALOG_DATA) public readonly data: InformationModalData,

    public readonly dialogRef: MatDialogRef<InformationModalComponent>,
  ) {}
}
