import {AfterContentInit, Component, ContentChildren, Input, OnInit} from "@angular/core";
import {AdminSelectionActionComponent} from "./admin-selection-action/admin-selection-action.component";

@Component({
  selector: 'app-admin-selection-action-menu',
  templateUrl: './admin-selection-action-menu.component.html',
  styleUrls: ['./admin-selection-action-menu.component.scss']
})
export class AdminSelectionActionMenuComponent implements OnInit, AfterContentInit {
  showActions: boolean = false;

  @ContentChildren(AdminSelectionActionComponent) actions: AdminSelectionActionComponent[];
  private _selectedItems: any[] = [];

  @Input()
  set selectedItems(value: any[]) {
    this._selectedItems = value;
    if (value.length === 0) {
      this.closeActionPopup();
    }
  }

  get selectedItems(): any[] {
    return this._selectedItems;
  }

  constructor() {
  }


  ngAfterContentInit(): void {
    if (this.actions) {
      this.actions.forEach(action => {
        action.actionComplete.subscribe(() => {
          this.closeActionPopup();
        });
      });
    }
  }

  ngOnInit(): void {
  }


  toggleActions(): void {
    if (this.selectedItems.length > 0) {
      this.showActions = !this.showActions;
    } else {
      this.showActions = false;
    }

  }

  closeActionPopup() {
    this.showActions = false;
  }
}
