<div class="object-page object-and-media-page" id="artifactCtrl"
     [ngClass]="{'edit': isEditing,
     'imageFullScreenOpen': imageFullScreen}">
  <div class="object-page__container object-and-media-page__container">
    <div class="object-page__content object-and-media-page__content row" id="artCon">
      <app-section-menu
        [sectionMenuContainer]="sectionMenuContainer"
      ></app-section-menu>

      <app-object-search-menu
        [object]="art"
        [isEditing]="isEditing"
        [stylingClass]="'object-page'"
      ></app-object-search-menu>

      <div id="artifactImgContainer"
           class="small-12 medium-12 large-4 large-push-8 columns object-page__media-carousel
           object-and-media-page__media-carousel"
           [ngClass]="{'artifactImgContainerEdit': isEditing}">

          <div class="object-page__media-carousel-template object-and-media-page__template">
            <div class="object-page__media-carousel-template-content">
              @if (art) {
                <app-recto-verso-buttons [object]="art"></app-recto-verso-buttons>
              }
              @if (contentInfo.artifact) {
                <app-template-group-select-button
                  [object]="contentInfo.artifact"
                  [collapseOnSmallScreen]="false"
                  [templateGroupId]="contentInfo.templateGroupId"
                  (templateSelectorCreated)="onTemplateSelectorCreated($event)"
                  (changeTemplateGroup)="onChangeTemplateGroup($event)"
                  [disabled]="isEditing"
                ></app-template-group-select-button>
              }
            </div>
          </div>

        <div class="object-page__media-carousel__content object-and-media-page__media-carousel-content">
          @if (art?.$$mediaContainer?.mediaTypes.length) {
            <div class="showImages"
                 [ngClass]="{'selector-enabled': operationContainer?.selectorEnabled}">
              <app-media-carousel
                [startMediaId]="art.$$mediaContainer.mediaTypes[0].mediaElements[0].mediaObject.artifact_id"
                [mediaContainer]="art.$$mediaContainer"
                [sectionsContainer]="sectionsContainer"
                [curAnn]="currentObjectService.curAnn"
                [isEditing]="isEditing"
                (openImageFullScreen)="openImageFullScreen($event)"
                (openModelFullScreen)="openModelFullscreen()"
                (objectRefresh)="objectRefresh($event)"
              ></app-media-carousel>
            </div>
          }
          @if (operationContainer?.selectorEnabled) {
            <app-search-view-selector
              [selectorContainer]="operationContainer"
            ></app-search-view-selector>
          }
          @if (templateSelectorContainer.selectorEnabled) {
            <app-search-view-selector
              [selectorContainer]="templateSelectorContainer"
            ></app-search-view-selector>
          }
        </div>
      </div>
      <div class="small-12 medium-12 large-5 large-pull-1 columns
                  object-menus-container object-page__object-menus landing-page-top landing-page-top__action-menu">
        @if (operationContainer?.art) {
          <div class="object-page__object-menus__content">
            @if (!smallObjectMenus) {
              <app-operation-menus-object
                [operationContainer]="operationContainer"
              ></app-operation-menus-object>
            }
            @else {
              <app-operation-menus-object-small
                [operationContainer]="operationContainer"
              ></app-operation-menus-object-small>
            }
          </div>
        }
      </div>
      <div id="scrollToThisFromCopyCallback"
           class="small-12 medium-12 large-8 large-pull-4 columns landing-page-main-content">
        @if (operationContainer?.selectorEnabled) {
          <div class="overlay"
               (click)="selectorOverlay()"></div>
        }
        <div class="landing-page-main-content__content" id="innerCon">
          @if (operationContainer?.currentOperation) {
            <div class="object-page__object-menu-current"
                 [ngClass]="{'isEditing': isEditing}">
              <app-operation-view-object
                [operationContainer]="operationContainer"
              ></app-operation-view-object>
            </div>
          }

          <div class="landing-page-main-content__overview-fields">
            @if (!sectionsContainer) {
              <div class="loading-object-overview-fields"
                   [ngClass]="{'isEditing': isEditing}">
                <div class="loading-object-overview-fields__header"></div>
                <div class="loading-object-overview-fields__line"></div>
                <div class="loading-object-overview-fields__line"></div>
                <div class="loading-object-overview-fields__line"></div>
                <div class="loading-object-overview-fields__line"></div></div>
            }
            @else {
              <app-object-overview-fields
                [objectView]="sectionsContainer.objectView"
                class="landing-page-main-content__overview-fields-item"
                [isEditing]="isEditing"
              ></app-object-overview-fields>
            }
          </div>

          <app-section-menu-scroll
            [sectionMenuContainer]="sectionMenuContainer"
            class="landing-page-lower"
            id="artifactLower">
            <div class="landing-page-lower__container">
              <div class="landing-page-lower__content-tabs-container"
                   [ngClass]="{'isEditing': isEditing}">

                @if (!finishedLoading) {
                  <div class="loading-object-content-tabs">
                    <div class="loading-object-content-tabs__selected-first-menu"
                         [ngClass]="{'isEditing': isEditing}">
                      <span class="bold text-blue" translate="TRANS__OVERVIEW_MENU__OVERVIEW"></span>
                      <mat-icon class="text-blue">expand_more</mat-icon></div></div>
                }

                @if (contentInfo?.artifact && !isNew) {
                  <app-object-content-tabs
                    class="landing-page-lower__content-tabs"
                    [isEditing]="isEditing"
                    (finishedLoading)="isFinishedLoading()"
                    [contentInfo]="contentInfo"
                  ></app-object-content-tabs>
                }
              </div>

              <div class="landing-page-lower__content">
                @if (loadFailed) {
                  <div class="landing-page-lower__failed" translate="TRANS__OBJECT_PAGE__LOAD_FAILED"></div>
                }

                @if (contentInfo.curListName === 'overview' && sectionsContainer) {
                  <div class="landing-page-lower__overview">
                    <app-object-view-and-edit
                      [isEditing]="isEditing"
                      [sectionsContainer]="sectionsContainer"
                      [templateGroupId]="contentInfo.templateGroupId"
                    ></app-object-view-and-edit>

                    @if (isEditing && isNew) {
                      <app-media-selectors
                        [object]="sectionsContainer.rootObject"
                        [isDialog]="false"
                        [operationUploadContainer]="operationContainer?.currentOperation?.$$operationUploadContainer"
                        [annotateDashboardClass]="'annotate-dashboard-admin-event-create'"
                      ></app-media-selectors>
                    }

                  </div>
                }

                @if (!isEditing && contentInfo.curListName === 'overview' && art) {
                  <div class="object-page__information-tabs">
                    <app-object-information-tabs [object]="art"></app-object-information-tabs>
                  </div>
                }

                @if (contentInfo.curListName !== 'overview') {
                  <div class="landing-page-lower__content-list">
                    <div class="large-12 columns landing-page-lower__content-list__container">
                      <app-object-content-list
                        [contentInfo]="contentInfo"
                        (objectRefresh)="objectRefresh($event)"
                      ></app-object-content-list>
                    </div>
                  </div>
                }

              </div>
            </div>
          </app-section-menu-scroll>
        </div>

      </div>
    </div>

    @if (operationContainer?.currentOperation?.$$currentStep) {
      <div class="object-page__action-toolbar">
        <app-operation-toolbar
          [operationContainer]="operationContainer"
          [operationStep]="operationContainer.currentOperation.$$currentStep"
          [selectorEnabled]="operationContainer.selectorEnabled || templateSelectorContainer.selectorEnabled"
          [stylingClass]="'object-page'"
        ></app-operation-toolbar>
      </div>
    }
  </div>
  <app-floating-feature-toggle-switch
    *ngIf="isArtifact"
    fixed="true"
    bottom="3rem"
    left=".5rem"
    feature="experimental.useNewObjectPage"
    [featureLabel]="'TRANS__FEATURE__USE_NEW_OBJECT_PAGE' | translate"
    (afterToggle)="navigateToNewDesign()"
    [triggerLabel]="'TRANS__OBJECT_PAGE__TRY_NEW_DESIGN_TRIGGER_LABEL' | translate"
  >
    <span>{{ 'TRANS__OBJECT_PAGE__TRY_NEW_DESIGN' | translate }}</span>
  </app-floating-feature-toggle-switch>
</div>

@if (imageFullScreen) {
  <app-image-full-screen
    class="image-full-screen-container"
    [mediaContainer]="art.$$mediaContainer"
    (closeImageFullScreen)="closeImageFullScreen()"
    [curAnn]="currentObjectService.curAnn"
    [toggleAnnotations]="toggleAnnotations"></app-image-full-screen>
}

@if (modelFullscreen) {
  <app-model-full-screen
    class="image-full-screen-container"
    [mediaContainer]="art.$$mediaContainer"
    (closeModelFullScreen)="closeModelFullscreen()"
  ></app-model-full-screen>
}
