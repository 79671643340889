<div class="notifications-component">
    <ul>
        @for (n of notifications; track n._id; let index = $index) {
            <li [ngClass]="notType(n)">
                <span>
                  <span *ngFor="let m of n.messages" [translate]="m" [translateParams]="n.values"></span>
                </span>

                @if (featureFlagsService.getFeatureFlags().temporary?.displaySendErrorViaEmailOption === true && n.type === 'error' || n.type === 'warning') {
                    <button (click)="openSendEmailDialog(n)" translate="TRANS__NOTIFICATION_EMAIL__POPUP_LABEL"></button>
                }
                <button (click)="remove(index)"><mat-icon>close</mat-icon></button>
              </li>
        }
    </ul>
</div>
