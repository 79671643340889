import {Injectable, isDevMode} from '@angular/core';

import {CmsApiService} from './cms-api.service';
import {ModelsService} from './models.service';
import {EventHarvesterService} from './event-harvester.service';
import {JobStatusSubscriberService} from './jobstatussubcriber/job-status-subscriber.service';
import {UserData} from './definitions/user-data';
import {logout, login} from '@ekultur/authentication';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {UserCacheService} from './user-cache.service';
import { PrimusBackendInstanceService } from './primus-backend-instance.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  debug = undefined;
  processing = false;
  progress = 0;

  public currentUser: BehaviorSubject<UserData | null> = new BehaviorSubject(null);

  constructor(private cms: CmsApiService,
              private userCacheService: UserCacheService,
              private models: ModelsService,
              private eventHarvester: EventHarvesterService,
              private jobStatusSubscriberService: JobStatusSubscriberService) {

  }

  logoutUser() {
    const eCultureLogoutCallback = () => {
      this.currentUser.next(null);
      PrimusBackendInstanceService.cleanInstanceDetails();

      if (isDevMode()) {
        /* eslint-disable no-console */
        console.debug('[LOGIN-SERVICE] -- performed logout housekeeping');
        console.debug('[LOGIN-SERVICE] -- redirecting to login');
        /* eslint-enable no-console */
      }

      setTimeout(() => {
        login({
          locationPostLogin: null,
          redirectUri: null,
          loginHost: environment.ekulturLoginGatewayURL
        });
      }, 500);
    };

    this.eventHarvester.loggedOff();
    this.jobStatusSubscriberService.stopPolling();

    this.cms.logout().then(() => {
      logout({
        signal: null,
        apiGateway: environment.ekulturAPIGatewayURL,
        onLogout: eCultureLogoutCallback
      });
    });
  }

  async getUserData() {
    // eslint-disable-next-line no-console
    if (isDevMode()) { console.debug('[LOGIN-SERVICE] -- getUserData()'); }
    const userData = await this.userCacheService.getUserData(true);
    if (userData) {
      this.currentUser.next(userData);
    }
  }
}
