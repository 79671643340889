import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchContainer} from "../../../core/definitions/search-container";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {TranslateModule} from "@ngx-translate/core";
import {SearchResultViewType} from "../../../core/definitions/search-result-view-type.enum";
import {
  SearchResultViewThumbnailV2Component
} from "../search-result-view-thumbnail-v2/search-result-view-thumbnail-v2.component";
import {NgClass} from "@angular/common";
import {
  SearchResultViewThumbnailListV2Component
} from "../search-result-view-thumbnail-list-v2/search-result-view-thumbnail-list-v2.component";
import {
  SearchResultViewGalleryV2Component
} from "../search-result-view-gallery-v2/search-result-view-gallery-v2.component";
import {SearchResultViewListV2Component} from "../search-result-view-list-v2/search-result-view-list-v2.component";
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { MatCard, MatCardContent, MatCardSubtitle, MatCardHeader } from '@angular/material/card';

@Component({
  selector: 'app-search-result-view-v2',
  standalone: true,
  imports: [
    MatProgressSpinner,
    MatIcon,
    TranslateModule,
    SearchResultViewThumbnailV2Component,
    NgClass,
    SearchResultViewThumbnailListV2Component,
    SearchResultViewGalleryV2Component,
    SearchResultViewListV2Component,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardSubtitle,
  ],
  templateUrl: './search-result-view-v2.component.html',
  styleUrl: './search-result-view-v2.component.scss'
})
export class SearchResultViewV2Component implements OnInit{
  @Input() searchContainer: SearchContainer;

  rootObjectId: string;
  viewNames = SearchResultViewType;

  constructor(
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.rootObjectId = this.activatedRoute.snapshot.queryParamMap.get('rootObjId') || '';
  }

  isRootObject(artifactId: string) {
    return artifactId === this.rootObjectId;
  }
}
