import {Component, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";
import {ConceptsContainer} from "../../core/definitions/concepts-container";
import {ConceptService} from "../../core/concept.service";
import {PrimusRouterService} from "../../core/primus-router.service";
import {AdministrationModule} from "../administration.module";
import {TopHeaderParentInfo} from "../../core/definitions/top-header-parent-info";

@Component({
  selector: 'app-admin-user',
  standalone: true,
  imports: [
    NgIf,
    SharedModule,
    AdministrationModule,
  ],
  templateUrl: './admin-user.component.html',
  styleUrl: './admin-user.component.scss'
})
export class AdminUserComponent implements OnInit {

  constructor(private conceptService: ConceptService,
              private primusRouter: PrimusRouterService) { }

  conceptsContainer: ConceptsContainer;
  parent: TopHeaderParentInfo;

  ngOnInit(): void {
    this.setUserConceptsContainer().then();
    this.parent = {
      path: 'admin',
      adminType: 'user',
      hasSubMenus: true,
      target_params: {admin_type: 'user'},
      currentState: this.primusRouter.currentState()
    };
  }

  async setUserConceptsContainer() {
    const conceptType = await this.conceptService.getConceptType('user');
    this.conceptService.setConceptsContainer(conceptType).then(conceptsContainer => {
      this.conceptsContainer = conceptsContainer;
      this.conceptsContainer.showLinkToObject = true;
      this.conceptsContainer.linkToObject = '/profile';
    });

  }

}
