import {Injectable} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import User, {UserPrivilege} from './User';
import {SearchParameters} from '../../core/definitions/search-parameters';
import {SearchObject} from '../../core/definitions/search-object';
import {LoginService} from '../../core/login.service';
import {ObjectStorageService} from '../../core/object-storage.service';
import {BehaviorSubject} from 'rxjs';
import {UserCollectionItems} from '../../core/definitions/user-collection-items';
import {AccessTokenService} from '../../core/access-token.service';
import {SettingsService} from '../../core/settings.service';
import {TranslateService} from '@ngx-translate/core';
import {SearchService} from "../../core/search.service";
import {CrudService} from "../../core/crud.service";

export interface RightsLevel {
  id: string;
  name: string;
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService {
  private currentUserPrivilege: UserPrivilege;

  public editSection: BehaviorSubject<any> = new BehaviorSubject(null);
  public cancelEditSection: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private readonly translate: TranslateService,
              private readonly cmsApi: CmsApiService,
              private readonly loginService: LoginService,
              private readonly accessTokenService: AccessTokenService,
              private readonly objectStorageService: ObjectStorageService,
              private readonly settings: SettingsService,
              private readonly searchService: SearchService,
              private readonly crud: CrudService) {
    this.loginService.currentUser.subscribe(async user => {
      if (user) {
        this.currentUserPrivilege = user['rights_level']
          ? user['rights_level'] as UserPrivilege : UserPrivilege.GUEST;
      } else {
        await this.reloadUserData();
      }
    });
  }

  public get isAdmin(): boolean {
    return this.hasPrivilege(UserPrivilege.ADMIN) ||
      this.hasPrivilege(UserPrivilege.SUPER_USER);
  }

  /**
   * Broadcasts the name of the user-profile section currently in edit-mode.
   */
  public setEditMode(sectionName: string) {
    this.editSection.next(sectionName);
  }

  /**
   * Broadcasts the name of the user-profile section to cancel the edit-mode for.
   */
  public cancelEditMode(sectionName: string) {
    this.cancelEditSection.next(sectionName);
  }

  public hasPrivilege(privilege: UserPrivilege): boolean {
    return this.currentUserPrivilege === privilege;
  }

  public async getOneUser(userId: string): Promise<User | null> {
    const res = await this.objectStorageService.loadObject(userId);
    return res ? new User(res) : null;
  }


  public async activateUser(user: User): Promise<User> {
    user.deactivated = false;
    await this.saveUser(user);
    return user;
  }

  public async saveUser(user: User): Promise<string> {
    if (user) {
      if (typeof (user.deactivated) === 'undefined' || user.deactivated === null) {
        user.deactivated = false;
      }
      await this.controlChangesToMainCollection(user);
      const res = await this.checkCollectionRightsSaveUser({
        artifact: user,
        jwt: this.accessTokenService.getToken()
      } as any);
      return res['artifact_id'];
    }
  }

  public async getCollectionRightTypes(collectionType: string): Promise<SearchObject[]> {
    const clientConfig = this.settings.getClientConfig();
    const conceptTypeCollectionRights = collectionType === 'collections' ?
      clientConfig.CONCEPT_TYPE_USER_COLLECTION_RIGHTS : clientConfig.CONCEPT_TYPE_VIRTUAL_COLLECTION_RIGHTS;
    const searchRes = await this.searchService.search({
      query: `object_type:${conceptTypeCollectionRights}`,
      fl: ['artifact_id', 'artifact_name'],
      getAll: true,
      sort: 'artifact_name asc'
    } as SearchParameters);
    return searchRes.artifacts;
  }

  public async getCollections(collectionType: string): Promise<SearchObject[]> {
    const collectionObjectType = collectionType === 'collections' ?
      'Collection' : this.settings.getClientConfig().CONCEPT_TYPE_VIRTUAL_COLLECTION;
    let allCollections = [{
      artifact_id: 'all',
      artifact_name: this.translate.instant('TRANS__USER_COLLECTIONS_ADDER__SELECT_ALL')
    } as SearchObject];
    const searchRes = await this.searchService.search({
      query: `object_type:${collectionObjectType}`,
      fl: ['artifact_id', 'artifact_name'],
      getAll: true,
      sort: 'artifact_name asc'
    } as SearchParameters);
    // Need to skip procedure collections that are not available to the instance
    let collections = searchRes.artifacts;
    if (searchRes.artifacts.length && collectionType === 'virtual_collections') {
      collections = [];
      const availableVirtualCollections = await this.cmsApi.getAvailableVirtualCollections();
      for (const foundColl of searchRes.artifacts) {
        if (availableVirtualCollections.indexOf(foundColl.artifact_id) !== -1) {
          collections.push(foundColl);
        }
      }
    }
    allCollections = allCollections.concat(collections);
    return allCollections;
  }

  private async checkCollectionRightsSaveUser(params: any) {
    const addedCollection: UserCollectionItems = await this.cmsApi.createArtifact({object_type: 'UserCollectionItems'}) as UserCollectionItems;
    // Make sure the user's main collection is set
    if (params.artifact.main_collection_id) {
      if (!params.artifact.collections) {
        params.artifact.collections = [];
      }
      params.artifact.collections.forEach((c: any) => c.is_main_collection = false);
      const existingCollectionIdx = params.artifact.collections.findIndex((c:any) => c.collection_id === params.artifact.main_collection_id);
      if (existingCollectionIdx >= 0) {
        params.artifact.collections[existingCollectionIdx].is_main_collection = true;
      } else {
        // const addedCollection: Collection = await this.createArtifact({object_type: 'UserCollectionItems'}) as Collection;
        addedCollection.is_main_collection = true;
        addedCollection.collection_id = params.artifact.main_collection_id;
        this.crud.setCreate(addedCollection);
        params.artifact.collections.push(addedCollection);
      }
    }

    if (typeof (params.artifact.deactivated) === 'undefined' || params.artifact.deactivated === null) {
      params.artifact.deactivated = false;
    }

    if (typeof (params.artifact.support) === 'undefined' || params.artifact.support === null) {
      params.artifact.support = false;
    }
    return await this.cmsApi.saveUser(params);
  }

  private async controlChangesToMainCollection(user: User): Promise<void> {
    if (user.main_collection_id) {
      if (!user.collections) {
        user.collections = [];
      }
      user.collections.forEach(c => c.is_main_collection = false);
      const existingCollectionIdx = user.collections.findIndex(c => c.collection_id === user.main_collection_id);
      if (existingCollectionIdx >= 0) {
        user.collections[existingCollectionIdx].is_main_collection = true;
      } else {
        await this.assignMainCollectionToUser(user);
      }
    }
  }

  private async assignMainCollectionToUser(user: User) {
    const addedCollection: UserCollectionItems =
      await this.cmsApi.createArtifact({object_type: 'UserCollectionItems'}) as UserCollectionItems;
    addedCollection.is_main_collection = true;
    addedCollection.collection_id = user.main_collection_id;
    this.crud.setCreate(addedCollection);
    user.collections.push(addedCollection);
  }

  private async reloadUserData() {
    await this.loginService.getUserData();
  }

}
