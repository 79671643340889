import {Component, OnDestroy, OnInit} from '@angular/core';
import {SearchHandlerService} from '../search-handler.service';
import {AConst} from '../../core/a-const.enum';
import {SearchPageService} from '../search-page.service';
import {SearchScrollService} from '../search-scroll.service';
import {SearchStateService} from '../search-state.service';
import {SearchExecutorService} from '../search-executor.service';
import {SearchContainer} from '../../core/definitions/search-container';
import {ActivatedRoute, Router} from '@angular/router';
import {PrimusRouterService} from '../../core/primus-router.service';
import { Observable, Subscription } from 'rxjs';
import {SearchTemplateGroupService} from '../search-template-group.service';
import {SearchFocusService} from '../search-focus.service';
import {CurrentSearchContext} from '../current-search.context';
import {QueryFieldMenuService} from "../query-field-menu.service";
import {FeatureFlagsService} from "../../core/feature-flags.service";
import {
  ConfirmationModalComponent,
  ConfirmationModalData
} from '../../shared/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '../../core/search.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  providers: [
    CurrentSearchContext
  ]
})
export class SearchPageComponent implements OnInit, OnDestroy {

  searchContainer: SearchContainer;
  AConst = AConst;
  private routerSubscription: Subscription;

  constructor(protected route: ActivatedRoute,
              private router: Router,
              private primusRouter: PrimusRouterService,
              private searchHandler: SearchHandlerService,
              private searchPageService: SearchPageService,
              private searchScrollService: SearchScrollService,
              private searchStateService: SearchStateService,
              private searchExecutorService: SearchExecutorService,
              private searchTemplateGroupService: SearchTemplateGroupService,
              private searchFocusService: SearchFocusService,
              private currentSearchContext: CurrentSearchContext,
              private queryFieldMenuService: QueryFieldMenuService,
              private featureFlagService: FeatureFlagsService
              ) {
  }

  async ngOnInit() {
    const shouldClearCurrentSearchState = this.route.snapshot.queryParamMap['params'].clear;
    if (shouldClearCurrentSearchState) {
      await this.router.navigate([], {queryParams: {clear: null}, queryParamsHandling: 'merge'});
      this.searchPageService.clearSearchContainer();
      await this.searchPageService.checkCreateSearchContainer();
    }
    this.searchPageService.addSearchContainerCreationListener(this.searchContainerCreationCallback.bind(this));
    this.routerSubscription = this.primusRouter.navigationHandler(() => {
      if (this.searchContainer) {
        this.searchContainerCreationCallback(this.searchContainer).then();
      }
    });

    if (this.route.snapshot.queryParamMap['params'].editSearchFocus) {
      await this.router.navigate([], {queryParams: {editSearchFocus: null}, queryParamsHandling: 'merge'});
      setTimeout(() => {
        this.searchFocusService.openFocusEditPanel();
      }, 2000)
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
  private async searchContainerCreationCallback(searchContainer: SearchContainer): Promise<void> {
    // this.hideSearchView = this.primusStateMapper.isObjectState(this.primusRouter.currentState());
    const stateParams = this.route.snapshot.queryParamMap['params'];
    const stateParamsPath = this.searchStateService.getDecodedStateParameter(stateParams.path);
    this.searchContainer = searchContainer;
    this.currentSearchContext.searchContainer = searchContainer;
    const stateTargetId = stateParams.targetId || 'none';
    const searchContainerTargetId = searchContainer.targetId || 'none';
    const oldQuery = searchContainer.queryContainer.query;
    searchContainer.queryContainer.query = this.searchStateService.getDecodedStateParameter(stateParams.query);

    if (stateParams.cur_focus_id) {
      const focus = this.searchContainer.focus.focuses.find(f => f.focusId === stateParams.cur_focus_id);

      if (focus) {
        this.searchContainer.focus.curFocus = focus;
        this.searchContainer.focus.curFocusId = focus.focusId;
      }
    }

    let advancedSearch = Boolean(stateParams.advanced_search);

    if (!stateParamsPath || searchContainer.path !== stateParamsPath || stateTargetId !== searchContainerTargetId) {
      await this.searchHandler.goSearchMenu(
        {
          path: stateParamsPath || searchContainer.path,
          targetId: stateParams.targetId || searchContainer.targetId,
          objectCount: Number(stateParams.objectCount) || searchContainer.objectCount
        },
        searchContainer);
    } else {
      if (!searchContainer.searchResult || oldQuery !== searchContainer.queryContainer.query) {
        await this.searchExecutorService.runSearch(searchContainer);
      }
      this.searchScrollService.scrollToYPos(searchContainer);
    }

    if (advancedSearch) {
      const queryMenus = await this.queryFieldMenuService.getQueryMenusForCurrentPathView(this.searchContainer);
      const advancedQueryMenu = queryMenus.menus.find(menu => menu.query_type === 'advanced');
      if (advancedQueryMenu) {
        this.searchContainer.queryContainer.selectedQueryMenu.selected = false;
        this.searchContainer.queryContainer.selectedQueryMenu = advancedQueryMenu;
        advancedQueryMenu.selected = true;
      }
      // reset query state so we don't mess up any other routing later
      await this.router.navigate([], {queryParams: {advanced_search: null}, queryParamsHandling: 'merge'});
    }

    if (searchContainer.listFieldTemplateId && searchContainer.listFieldTemplateId !== stateParams['list_field_template_id']) {
      await this.searchTemplateGroupService.setListFieldTemplateGroup(searchContainer, stateParams['list_field_template_id']);
      await this.searchExecutorService.runSearch(searchContainer);
    }
    if (stateParamsPath !== 'home/folders/folder') {
      searchContainer.breadcrumbs = [];
    }
  }

  readonly newLayout = this.featureFlagService.getFeatureFlags().experimental.useNewMainMenu;
}
