{
  "name": "primus-web",
  "version": "10.2.8",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --proxy-config proxy.conf.dev.json",
    "start-local": "ng serve --proxy-config proxy.conf.local.json",
    "start-local-import": "ng serve --proxy-config proxy.conf.local-import.json",
    "start-local-prod": "npm run build-prod && npx http-server -p 4200 -c-1 -P http://localhost:5000 ./dist/browser",
    "start-local-unsecure": "ng serve --host 0.0.0.0 --proxy-config proxy.conf.local.json",
    "build": "ng build",
    "build-prod": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --configuration production",
    "build-sw": "./node_modules/.bin/ngsw-config ./dist ./ngsw-config.json",
    "test": "jest",
    "test:watch": "jest --watch",
    "test:coverage": "jest --coverage",
    "test-web-runner": "web-test-runner test/**/*.test.js --node-resolve",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "browser": {
    "fs": false,
    "path": false,
    "os": false
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.1.2",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/localize": "^18.2.13",
    "@angular/material": "^18.1.2",
    "@angular/material-moment-adapter": "^18.1.2",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@angular/service-worker": "^18.2.13",
    "@ekultur/authentication": "^6.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular": "^8.55.0",
    "@sentry/browser": "^8.55.0",
    "@web-components/dms": "^1.0.3",
    "file-saver": "^2.0.5",
    "jest": "^29.7.0",
    "jest-preset-angular": "^14.1.0",
    "jszip": "^3.10.1",
    "moment": "^2.30.1",
    "ng2-dragula": "^5.1.0",
    "ngx-filesaver": "^17.0.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.1.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-eslint/builder": "^18.1.0",
    "@angular-eslint/eslint-plugin": "^18.1.0",
    "@angular-eslint/eslint-plugin-template": "^18.1.0",
    "@angular-eslint/schematics": "^18.1.0",
    "@angular-eslint/template-parser": "^18.1.0",
    "@angular/build": "^18.1.2",
    "@angular/cli": "^18.2.17",
    "@angular/compiler-cli": "^18.2.13",
    "@angular/language-service": "^18.2.13",
    "@types/jest": "29.5.12",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "@web/test-runner": "^0.18.0",
    "eslint": "^8.57.0",
    "ts-node": "^10.9.1",
    "typescript": "^5.4.5"
  },
  "overrides": {
    "ng2-dragula": {
      "@angular/common": "$@angular/common",
      "@angular/core": "$@angular/core",
      "@angular/animations": "$@angular/animations"
    }
  },
  "packageManager": "yarn@1.22.22+sha512.a6b2f7906b721bba3d67d4aff083df04dad64c399707841b7acf00f6b133b7ac24255f2652fa22ae3534329dc6180534e98d17432037ff6fd140556e2bb3137e"
}
