import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FieldFilterService} from '../../services/field-filter.service';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions} from '@angular/material/checkbox';
import {FilterOptionGroup} from '../../../../core/definitions/extended-search/filter-option-group';
import TreeSelectionState from '../../../../core/definitions/extended-search/tree-selection-state';
import {FilterOption} from '../../../../core/definitions/extended-search/filter-option';

@Component({
  selector: 'app-select-object-type-button',
  templateUrl: './select-object-type-button.component.html',
  styleUrls: ['./select-object-type-button.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        // Setting this to 'noop' ensures that clicking a checkbox in the filter
        // is not overriding the checked-state that is correctly kept externally
        clickAction: 'noop'
      } as MatCheckboxDefaultOptions
    }
  ]
})
export class SelectObjectTypeButtonComponent implements OnInit {

  @Input() buttonLabel: string;

  /**
   * Emits the ID (superobject type id) of all selected object-types
   * @type {EventEmitter<Array<string>>}
   */
  @Output() public readonly selectionChanged: EventEmitter<Array<string>>;

  /**
   * All object-types that can be used to filter the dataset
   * @type {Array<FilterOptionGroup>}
   */
  objectTypes: Array<FilterOptionGroup>;

  readonly objectTypeSelectionState: TreeSelectionState<FilterOptionGroup, FilterOption>;

  constructor(private readonly filterService: FieldFilterService) {
    this.selectionChanged = new EventEmitter<Array<string>>();
    this.objectTypes = [];
    this.objectTypeSelectionState = new TreeSelectionState(g => g.groupId, g => g.options, o => o.id);
  }

  ngOnInit(): void {
    this.filterService.getFilterOptions()
      .then(groups => {
        this.objectTypes = groups;
        this.objectTypeSelectionState.setGroups(groups);
      });
  }

  toggleGroupSelection(group: FilterOptionGroup, event?: Event): void {
    if (!!event) {
      // Prevents the menu from being closed when selecting an option or group
      event.stopPropagation();
    }
    this.objectTypeSelectionState.toggleGroupSelection(group.groupId);
    this.emitSelectionChange();
  }

  toggleOptionSelection(option: FilterOption, event?: Event): void {
    if (!!event) {
      // Prevents the menu from being closed when selecting an option
      event.stopPropagation();
    }
    this.objectTypeSelectionState.toggleOptionSelection(option.parentId, option.id);
    this.emitSelectionChange();
  }

  emitSelectionChange(): void {
    const selectedIds = this.objectTypeSelectionState.getSelectedOptions().map(opt => opt.id);
    this.selectionChanged.emit(selectedIds);
  }

}
