<div class="object-view-field-values {{stylingClass}}">
  <span *ngIf="fieldValues.title_values"
        class="object-view-field-values__title-values">
    <app-object-view-field-value
      *ngFor="let titleValue of fieldValues.title_values"
      [edit]="edit"
      [fieldValue]="titleValue">
    </app-object-view-field-value>
  </span>
  <p class="object-view-field-values__content-values"
     [ngClass]="{'content-has-title': fieldValues.title_values,
     'content-has-icon': fieldValues.field_icon}">
    <i *ngIf="fieldValues.field_icon"
       class="object-view-field-values__icon {{fieldValues.field_icon.icon}}">{{fieldValues.field_icon.icon_frame}}</i>

    <app-object-view-field-value
      class="object-view-field-values__field-value"
      *ngFor="let fieldValue of fieldValuesValues"
      [edit]="edit"
      [fieldValue]="fieldValue"
      [fieldValueClass]="fieldValueClass"
      [sectionsContainer]="sectionsContainer">
    </app-object-view-field-value>
  </p>
</div>

