@if (isRange) {
  <div
    class="value-field__range-container"
  >
    <mat-form-field
      appearance="outline"
      class="value-field"
    >
      <mat-label>
        {{'TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__FROM' | translate}}
      </mat-label>

      <input
        matInput
        class="value-field__input"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        (input)="setInternalValue($event, null)"
        [disabled]="disabled() || ongoingSearch"
        [type]="inputType"
        [value]="getInternalDisplayValue(0)"
      />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="value-field"
    >
      <mat-label>
        {{'TRANS__ADVANCED_SEARCH__QUERY_BUILDER_VALUE__TO' | translate}}
      </mat-label>

      <input
        matInput
        class="value-field__input"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        (input)="setInternalValue(null, $event)"
        [disabled]="disabled() || ongoingSearch"
        [type]="inputType"
        [value]="getInternalDisplayValue(1)"
      />
    </mat-form-field>
  </div>
}
@else {
  <mat-form-field
  #valueFormField
  appearance="outline"
  cdkOverlayOrigin
  #valueTrigger="cdkOverlayOrigin"
  class="value-field"
  (click)="dropdownOpen = hasDropdown"
  (keydown)="null"
>
  <div class="value-field__flex-container">
    @if (selfField().operator_selected === 'in' || selfField().operator_selected === 'not in') {
      <span class="value-field__value-display">
        {{ arraySelectedValues }}
      </span>
    }

    <input
      class="value-field__input"

      matInput
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      id="query-builder-value-input-{{selfField().field_title}}"
      (input)="filterInput($event)"
      (keydown.arrowDown)="focusFirstNodeInDropdown($event)"
      [disabled]="disabled() || ongoingSearch"
      [type]="inputType"
      [value]="filterQuery()"
    />


    @if (hasValue) {
      <mat-icon matSuffix (click)="clearValueInput($event)" class="value-field__clear-icon">
        close
      </mat-icon>
    }

    @if (hasDropdown) {
      <mat-icon matSuffix class="value-field__dropdown-icon">
        arrow_drop_down
      </mat-icon>
    }
  </div>
</mat-form-field>

@if (hasDropdown) {
  <ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayMinWidth]="valueFormField.getConnectedOverlayOrigin().nativeElement.offsetWidth"
    [cdkConnectedOverlayOrigin]="valueTrigger"
    [cdkConnectedOverlayOpen]="dropdownOpen"
    (backdropClick)="dropdownOpen = false"
    (detach)="dropdownOpen = false"
  >
    <div class="value-dropdown">
      <app-extended-search-query-builder-value-display
        inputId="query-builder-value-input-{{selfField().field_title}}"
        [canSelectParentNode]="true"
        [expandNodes]="expandValueNodes()"
        [filterQuery]="filterQuery()"
        [internalFiltering]="false"
        [multiSelect]="selfField().operator_selected === 'in' || selfField().operator_selected === 'not in'"
        [parentField]="selfField()"
        [values]="values()"
        (selectedNode)="valueSelected($event)"
        (selectedArrayNode)="arrayValueSelected()"
      />
    </div>
  </ng-template>
}


}
