<div class="report-preview-area">
  @if (showReportLoadingInfo()) {
    <mat-spinner id="report-preview-spinner" [ngClass]='"report-preview-area-spinner"'></mat-spinner>
    <h4 class="report-preview-area-spinner-header">{{'TRANS__REPORTS_V2_LOADING_PREVIEW' | translate}}...</h4>
  } @else {
    @if(reportTemplate.reportViewTypeId === katalograpportUuid) {
      <report-preview-catalogue
        [reportTemplate]="reportTemplate"
        [extractedArtifacts]="extractedArtifacts"
        [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
        [preview]="preview"></report-preview-catalogue>
    } @else if (reportTemplate.reportViewTypeId === gridReportUuid) {
      <grid-report
        [reportTemplate]="reportTemplate"
        [extractedArtifacts]="extractedArtifacts"
        [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
        [numColumnsInReport]="numColumnsInReport"
        [preview]="preview">
      </grid-report>
    } @else if (reportTemplate.reportViewTypeId === stampReportUuid) {
      <stamp-report
        [reportTemplate]="reportTemplate"
        [extractedArtifacts]="extractedArtifacts"
        [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
        [numColumnsInReport]="numColumnsInReport"
        [preview]="preview">
      </stamp-report>
    } @else if (reportTemplate.reportViewTypeId === prosedyrerapportUuid) {
      <procedure-report
        [reportTemplate]="reportTemplate"
        [extractedArtifacts]="extractedArtifacts"
        [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
        [preview]="preview">
      </procedure-report>
    } @else if (reportTemplate.reportViewTypeId === rapportlisteMedBildeUuid) {
      <report-list
        [reportTemplate]="reportTemplate"
        [extractedArtifacts]="extractedArtifacts"
        [viewOptionsCheckboxValues]="viewOptionsCheckboxValues"
        [numPhotographsInReport]="numPhotographsInReport"
        [selectedPhotographSize]="selectedPhotographSize"
        [preview]="preview">
      </report-list>
    } @else if (reportTemplate.reportViewTypeId) {
      <div>{{'TRANS__REPORTS_V2_NOT_YET_IMPLEMENTED' | translate}}</div>
    } @else {
      <div>{{'TRANS__REPORTS_V2_CHOOSE_A_REPORT_VIEW_TEXT' | translate}}. </div>
    }
  }




</div>
