import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MediaUploadCameraComponent} from './media-upload-camera/media-upload-camera.component';
import { MediaSelectorsComponent } from './media-selectors/media-selectors.component';
import {ImageAnnotationModule} from '../image-annotation/image-annotation.module';
import {SharedModule} from '../shared/shared.module';
import { MediaSelectorGeneralComponent } from './media-selector-general/media-selector-general.component';
import {MediaListComponent} from './media-list/media-list.component';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MediaSelectorSelectedFilesComponent} from './media-selector-selected-files/media-selector-selected-files.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    MediaUploadCameraComponent,
    MediaSelectorsComponent,
    MediaSelectorGeneralComponent,
    MediaSelectorSelectedFilesComponent,
    MediaListComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        DragDropModule,
        SharedModule,
        ImageAnnotationModule,
        ObjectEditModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTabsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatExpansionModule,
        MatTooltipModule
    ],
  exports: [
    MediaSelectorGeneralComponent,
    MediaSelectorSelectedFilesComponent,
    MediaSelectorsComponent,
    MediaListComponent
  ]
})
export class MediaSelectorModule {
}
