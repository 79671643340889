import { Injectable } from '@angular/core';
import {PrimusBackendInstanceService} from "./primus-backend-instance.service";
import {CmsApiService} from "./cms-api.service";
import {LoggerService} from "./logger.service";

export interface DamsSearchRequest {
  q?: string;
  sort: string;
  rows: number;
  start: number;
  document_type?: string[];
  museums: string;
  uploaded_by?: number;
  copyright?: string;
  subjects?: string[];
  persons?: string[];
  producers?: string[];
  licenses?: string[];
}

export interface DamsTypeItem {
  count: number;
  value: string;
  label: string;
  icon: string;
}

@Injectable({
  providedIn: 'root'
})
export class DamsService {

  allowedTypes: string[] = [
    'StillImage',
    'Audio',
    'Video',
    'Modell',
    'Dokument'
  ]
  availableTypes: DamsTypeItem[] = [];

  constructor(
    private readonly cms: CmsApiService,
    private readonly logger: LoggerService
  ) {
    this.initialize();
  }

  getAvailableTypes(): DamsTypeItem[] {
    return this.availableTypes;
  }

  initialize() {
    this.getUserDamsAccess().then(userAccess => {
      if (userAccess.dams_access) {
        this.getAvailableTypesFromDams().then().catch((reason: any) => {
          this.logger.error(`Error loading available types: ${reason.message}`);
        });
      }
    });
  }

  static getIconFromType(type: string): string {
    switch(type) {
      case 'StillImage':
        return 'photo_size_select_actual';

      case 'Video':
        return 'smart_display';

      case 'Audio':
        return 'volume_up';

      case 'Modell':
        return '3d_rotation';

      case 'Dokument':
        return 'file_present';
    }
  }

  async getUserDamsAccess() {
    return this.cms.getUserDamsAccess();
  }

  async getMetaData(damsId: string) {
    return this.cms.getDamsMetadata(damsId);
  }

  async search(params: DamsSearchRequest) {
    return this.cms.searchDams({...params});
  }

  private async getAvailableTypesFromDams() {
    let response = await this.cms.getDamsStatistics(PrimusBackendInstanceService.getInstanceDetails().id);

    if (response) {
      this.updateAvailableTypes(response);
    }
  }

  private getIconFromName(name: string): string {
    return 'photo_size_select_actual';
  }

  private updateAvailableTypes(data: any) {
    for (let index = 0; index < data.length; index += 2) {
      if(this.allowedTypes.includes(data[index]) && data[index + 1] > 0) {
        this.availableTypes.push({
          count: data[index + 1],
          value: data[index],
          label: `TRANS__DAMS_TYPE__${data[index].toUpperCase()}`,
          icon: this.getIconFromName(data[index])
        })
      }
    }
  }
}
