@import "../../../../primus-assets/scss/variables";
@import "../../../../primus-assets/scss/vendor-prefixes";

.search-query-advanced {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-left: $searchPageHeaderHeight;
  width: 100%;

  input[type="text"] {
    @include border-radius-mixin(0);
    @include box-shadow-mixin(none);
    border: none;
    flex-shrink: 1;
    flex-grow: 1;
    font-size: $normal-font-size;
    height: 100%;
    margin: 0;
    padding-right: $searchPageHeaderHeight;
  }

  &__clear-input-field {
    color: $textColorPlaceholderInput;
    font-size: $normal-font-size;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: auto;
    @include display-flex();
    @include align-flex-items(center);
    padding-right: $S;
  }

  &__chip-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 5px;
    height: 100%;
  }

  &__suggestion-menu-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px;
    max-height: 200px;
  }
}

.mat-mdc-menu-content {
  max-height: 300px !important;
}
