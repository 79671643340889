import {Component, Input, OnInit} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {SearchContainer} from "../../../core/definitions/search-container";
import {SearchExecutorService} from "../../search-executor.service";
import {SearchResultSelectionsService} from "../../search-result-selections.service";
import { SearchResultViewType } from 'src/app/core/definitions/search-result-view-type.enum';

@Component({
  selector: 'app-search-view-pagination',
  standalone: true,
  imports: [
    MatPaginator
  ],
  templateUrl: './search-view-pagination.component.html',
  styleUrl: './search-view-pagination.component.scss'
})
export class SearchViewPaginationComponent implements OnInit {
  @Input() searchContainer: SearchContainer;
  viewNames = SearchResultViewType;

  constructor(
    private readonly searchExecutorService: SearchExecutorService,
    private readonly searchResultSelectionsService: SearchResultSelectionsService
  ) {}

  ngOnInit() {
    if(this.searchContainer) {
      this.searchContainer.virtualScrollEnabled = false;
      this.searchContainer.searchPage = this.searchContainer.searchPage ?? 1;
      this.searchContainer.rows = typeof this.searchContainer.rows === 'number' ? this.searchContainer.rows  : DEFAULT_SEARCH_ROWS;
      this.searchExecutorService.runSearch(this.searchContainer, true).then();
    }
  }

  paginationChange(event: PageEvent) {
    if (event.pageIndex + 1 !== this.searchContainer.searchPage) {
      this.searchContainer.searchPage = event.pageIndex + 1;
    }

    if (event.pageSize !== this.searchContainer.rows) {
      this.searchContainer.searchPage = 1;
      this.searchContainer.rows = event.pageSize;
      this.searchResultSelectionsService.clearSelections(this.searchContainer);
      this.searchContainer.selections.allSelectedForPage = {};
    }
    this.searchExecutorService.runSearch(this.searchContainer, true).then(() => {
      setTimeout(() => {
        this.searchContainer.selections.allSelected = !!this.searchContainer.selections.allSelectedForPage[
          this.searchContainer.searchPage]
      }, 500);
    });
  }
}

const DEFAULT_SEARCH_ROWS = 50;
