<div
  class="rule-group"
  [matTooltip]="'TRANS__EXTENDED_SEARCH__ONGOING_SEARCH__TOOLTIP' | translate"
  [matTooltipPositionAtOrigin]="true"
  [matTooltipDisabled]="!ongoingSearch"
  [ngClass]="{'rule-group--ongoing' : ongoingSearch}"
>
  <div class="rule-group__header-container">
    <div class="rule-group__title-container">
      <span class="rule-group__title">
          {{ getSubgroupTitleText() | translate }}
      </span>

      <button
        mat-button
        class="rule-group__operator-button"
        [disabled]="ongoingSearch"
        [ngClass]="{'operator-button--subgroup': selfGroup().level > 0}"
        color="primary"
        [matMenuTriggerFor]="operatorMenu"
      >
        <span class="rule-group__operator-label">{{ getGroupLogicalOperatorDisplay() | translate }}</span>
        <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
      </button>

      <span>
        @if (selfGroup().level > 0) {
          {{ 'TRANS__ADVANCED_SEARCH__THESE_RULES_SUBGROUP' | translate }}
        } @else {
          {{ 'TRANS__ADVANCED_SEARCH__THESE_RULES' | translate }}
        }
      </span>
    </div>

    <div class="rule-group__group-control-container">
      <mat-icon
        class="rule-group__help-icon"
        matTooltipPosition="below"
        [matTooltip]="'TRANS__ADVANCED_SEARCH__GROUP_HELP_TEXT' | translate"
      >
        help_outline
      </mat-icon>

      <button mat-icon-button class="rule-group__control-button" (click)="removeSelf()" [disabled]="ongoingSearch">
        <mat-icon>
          close
        </mat-icon>
      </button>
    </div>
  </div>

  <div class="rule-group__field-container">
    @for(field of selfGroup().field_queries; track $index) {
      <app-extended-search-query-builder-field
        [parentGroup]="selfGroup()"
        [rootGroup]="rootGroup()"
        [searchContainer]="searchContainer()"
        [selfField]="field"
      />
    }
  </div>

  <div class="rule-group__add-button-container">
    <button mat-stroked-button [matMenuTriggerFor]="addMenu" [disabled]="ongoingSearch">
      <span class="rule-group__add-button-label">
      {{ 'TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__ADD' | translate }}
      </span>
      <mat-icon>
        expand_more
      </mat-icon>
    </button>
  </div>

    @for (group of selfGroup().sub_groups; track $index) {
      <div class="rule-group__sub-group-container">
        <app-extended-search-query-builder-group
          [rootGroup]="rootGroup()"
          [searchContainer]="searchContainer()"
          [selfGroup]="group"
          [parentGroup]="selfGroup()"
        />
      </div>
    }

</div>

<mat-menu #operatorMenu=matMenu xPosition="after" yPosition="below" class="rule-group__operator-menu">
  <button mat-menu-item (click)="updateGroupLogicalOperator('AND')">
    {{'TRANS__OPERATOR__AND__DISPLAY' | translate}}
  </button>

  <button
    mat-menu-item
    [disabled]="selfGroup().level < 1"
    [matTooltip]="'TRANS__EXTENDED_SEARCH__OPERATOR_DISABLED_TOOLTIP' | translate"
    [matTooltipPosition]="'right'"
    [matTooltipDisabled]="selfGroup().level >= 1"
    [ngClass]="{'rule-group__operator-button--disabled': selfGroup().level < 1}"
    (click)="selfGroup().level < 1 ? null : updateGroupLogicalOperator('OR')"
  >
    {{'TRANS__OPERATOR__OR__DISPLAY' | translate}}
  </button>

  <button mat-menu-item (click)="updateGroupLogicalOperator('NOT')">
    {{'TRANS__OPERATOR__NOT__DISPLAY' | translate}}
  </button>
</mat-menu>

<mat-menu #addMenu="matMenu" xPosition="after" yPosition="below">
  <button mat-menu-item (click)="addNewField()">
    {{ 'TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__RULE' | translate}}
  </button>

  @if (selfGroup().level < 2) {
    <button mat-menu-item (click)="addNewGroup()">
      {{ 'TRANS__SEARCH__ADVANCED_SEARCH__QUERY_BUILDER__SUBGROUP' | translate}}
    </button>
  }
</mat-menu>
