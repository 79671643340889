import {inject, Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, Router} from '@angular/router';

const MAX_ITEMS = 10;

type PathConfigAndUrl = {
  path: string;
  url: string;
}

type ArtifactHistoryObject = {
  url: string;
  artifactName: string;
  queryParams: any;
}

const blacklist = [
  '',
  '/new',
]

/**
 * Service that helps trace the different routes for maing back-navigation in object routes easier.
 * The stack only gets a new item once the route changes to a new route-config (e.g. from /artifact/:id to /gallery)
 */
@Injectable({
  providedIn: 'root'
})
export class ObjectPageNavigationStack {
  private artifactHistory: ArtifactHistoryObject[] = [];
  private stack: PathConfigAndUrl[] = [];
  private router = inject(Router);

  constructor() {
    this.addCurrentRouteToStack();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.addCurrentRouteToStack();
      }
    })
  }

  private addCurrentRouteToStack() {
    // Reasoning for this is that we want to track the navigation between the object pages, and
    // those are both behind '/search' and '/' paths.
    // We don't want to track the /artifact/:artifact/:subpath paths.
    const isOnSearchPage = this.router.routerState.snapshot.root.firstChild?.routeConfig?.path === 'search';
    const uniquePathKey = getDeepRoutePath(this.router.routerState.snapshot.root, isOnSearchPage ? 2 : 1);
    if (blacklist.includes(uniquePathKey)) {
      return;
    }
    if (this.stack[this.stack.length - 1]?.path !== uniquePathKey) {
      this.clearArtifactHistory();
      if (this.stack.length >= MAX_ITEMS) {
        this.stack.shift();
      }
      this.stack.push({
        path: uniquePathKey,
        url: this.router.url
      });
    } else {
      this.stack[this.stack.length - 1].url = this.router.url;
    }
    if (isDevMode()) {
      console.debug('[ObjectPageNavigationStack] Pushed navigation. Current stack: ', this.stack);
    }
  }

  /**
   * Pop the top item, and returns the next url to navigate to.
   */
  pop(): string | undefined {
    this.stack.pop();
    if (isDevMode()) {
      console.debug('[ObjectPageNavigationStack] Popped navigation. Current stack: ', this.stack);
    }
    return this.stack[this.stack.length - 1]?.url;
  }

  addArtifactToHistory(artifactName: string, url: string, queryParams: any) {
    this.artifactHistory.push({
      url,
      artifactName,
      queryParams
    });
  }

  clearArtifactHistory() {
    this.artifactHistory = [];
  }

  removeArtifactFromHistory(): ArtifactHistoryObject {
    return this.artifactHistory.pop();
  }

  get artifactHistoryExists() {
    return this.artifactHistory.length > 0;
  }

  get previousArtifactName() {
    return this.artifactHistory[this.artifactHistory.length - 1]?.artifactName;
  }
}

function getDeepRoutePath(route: ActivatedRouteSnapshot, maxLevels: number): string {
  let path = route.routeConfig?.path ?? '';
  if (route.firstChild) {
    if (maxLevels > 0) {
      path += '/' + getDeepRoutePath(route.firstChild, maxLevels - 1);
    }
  }
  return path;
}
