<div class="db-dialog">
  @if (!data.failed()) {
    <mat-progress-spinner mode="indeterminate" diameter="48" />
  }

  <div class="db-dialog__text-container">
    <span class="db-dialog__title">
      @if (!data.failed()) {
        {{ 'TRANS__EXTENDED_SEARCH__DB_DIALOG__TITLE' | translate }}
      }
      @else {
        {{ 'TRANS__EXTENDED_SEARCH__SEARCH_FAILED__TITLE' | translate }}
      }
    </span>

    <span class="db-dialog__description">
      @if (!data.failed()){
        {{ 'TRANS__EXTENDED_SEARCH__DB_DIALOG__DESCRIPTION' | translate }}
      }
      @else {
        {{ 'TRANS__EXTENDED_SEARCH__SEARCH_FAILED__CONTENT' | translate }}
      }
    </span>
  </div>

  <div class="db-dialog__button-container">
    @if (data.failed()) {
      <button mat-flat-button (click)="dialogRef.close(true)">
        {{ 'TRANS__EXTENDED_SEARCH__SEARCH_FAILED__BUTTON' | translate }}
      </button>
    }
    @else {
      <button mat-stroked-button (click)="dialogRef.close(true)">
        {{ 'TRANS__EXTENDED_SEARCH__DB_DIALOG__CANCEL' | translate }}
      </button>
    }
  </div>
</div>
