<div class="rule-field">
  <div class="rule-field__fields-container">
    <mat-form-field
      #fieldFormField
      cdkOverlayOrigin
      #fieldTrigger="cdkOverlayOrigin"
      appearance="outline"
      class="rule-field__selector"
      (click)="fieldSelectorOpen = true"
      (keydown)="null"
    >
      <mat-label>
        {{ 'TRANS__ADVANCED_SEARCH__QUERY_BUILDER_FIELDS' | translate }}
      </mat-label>

      <div class="rule-field__flex-container">
        <input
          matInput
          class="rule-field__field-input"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          id="query-builder-field-input-{{selfField().field_title}}"
          type="text"
          (input)="fieldInput($event)"
          (keydown.arrowDown)="focusFirstNodeInDropdown($event)"
          (focusout)="onFocusOut($event)"
          [disabled]="ongoingSearch"
          [value]="fieldQuery()"
        />

        @if (selfField().field_title !== '' && !ongoingSearch) {
          <mat-icon
            matSuffix
            (click)="clearField($event)"
            (keydown)="null"
            class="rule-field__clear-icon"
          >
            close
          </mat-icon>
        }

        @if (!ongoingSearch) {
          <mat-icon matSuffix class="rule-field__dropdown-icon">
            arrow_drop_down
          </mat-icon>
        }
      </div>

    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="rule-field__selector rule-field__selector--operator"
    >
      <mat-label>
        {{ 'TRANS__ADVANCED_SEARCH__QUERY_BUILDER_OPERATOR' | translate }}
      </mat-label>

      <mat-select
        (selectionChange)="updateFieldOperator($event)"
        [disabled]="selfField().operators.length === 0 || ongoingSearch"
        [value]="selfField().operator_selected"
        panelClass="rule-field__select"
      >
        @for(operator of selfField().operators; track $index) {
          <mat-option [value]="operator.operator">
            {{ operator.operatorTitle | translate }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <app-extended-search-query-builder-field-value
      [parentGroup]="parentGroup()"
      [rootGroup]="rootGroup()"
      [searchContainer]="searchContainer()"
      [disabled]="hasNoFieldSelected || !hasValueField"
      [selfField]="selfField()"
    />

  <button mat-icon-button (click)="removeSelf()" [disabled]="ongoingSearch">
    <mat-icon>
      remove_circle
    </mat-icon>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayMinWidth]="fieldFormField.getConnectedOverlayOrigin().nativeElement.offsetWidth"
  [cdkConnectedOverlayOrigin]="fieldTrigger"
  [cdkConnectedOverlayOpen]="fieldSelectorOpen"
  (backdropClick)="closeFieldDropdown()"
  (detach)="closeFieldDropdown()"
>
  <div class="field-dropdown">
    <app-extended-search-query-builder-value-display
      inputId="query-builder-field-input-{{selfField().field_title}}"
      [canSelectParentNode]="false"
      [fields]="availableFields()"
      [filterQuery]="fieldQuery()"
      [internalFiltering]="true"
      [parentField]="selfField()"
      [parentGroup]="parentGroup()"
      [rootGroup]="rootGroup()"
      (selectedNode)="selectedNode($event)"
    />
  </div>
</ng-template>
</div>
