<div class="search-hierarchic-bread-crumbs-wrapper">
  <ng-container *ngFor="let breadcrumb of searchContainer.breadcrumbs; index as i">
    <div *ngIf="finishedLoading && i < searchContainer.breadcrumbs.length - 1"
      class="search-hierarchic-bread-crumbs__items">
      <button mat-button [routerLink]="breadcrumb.$$refData?.routerLink"
        [queryParams]="breadcrumb.$$refData?.queryParams" [title]="breadcrumb.artifact_name" class="parent-folder"
        [ngClass]="{'hide-items': showMoreButton &&
         (i !== 0 && i !== searchContainer.breadcrumbs.length - 2) }">
        <mat-icon class="folder-icon">arrow_back</mat-icon>
        {{breadcrumb.artifact_name}}
      </button>

      <button *ngIf="showMoreButton && i === 1" (click)="showAllItems()" mat-button>...</button>
    </div>

    <button *ngIf="finishedLoading && i === searchContainer.breadcrumbs.length - 1 && i === 0" mat-button
      (click)="goToMenuPath()" class="search-hierarchic-bread-crumbs__back-to-folders">
      <mat-icon>arrow_back</mat-icon>
      <span>{{'TRANS__SEARCH_HIERARCHIC_BREAD_CRUMBS__BACK_TO_FOLDERS' | translate}}</span>
    </button>
  </ng-container>
  @if (searchContainer.currentPathView.search_view.search_view_type === 'media_folder' && targetId) {
    <button
            mat-button
            (click)="goToMenuPath()"
            class="search-hierarchic-bread-crumbs__back-to-folders">
      <mat-icon>arrow_back</mat-icon>
      <span>{{'TRANS__SEARCH_HIERARCHIC_BREAD_CRUMBS__BACK_TO_FOLDERS' | translate}}</span>
    </button>
  }
</div>
