import {effect, Injectable, isDevMode, OnDestroy} from '@angular/core';
import {Observable, of, Subscription, throwError, timer} from 'rxjs';
import {catchError, delay, mergeMap, switchMap} from 'rxjs/operators';
import {ResultParser} from './result-parser';
import {LoggerService} from '../logger.service';
import {AccessTokenService} from '../access-token.service';
import {CmsApiService} from '../cms-api.service';
import {JobStatusItem} from "../definitions/job-status-item";
import {OperationExecutionStatus} from "../definitions/operation-execution-status.enum";
import {IdleService} from '../../shared/idle.service';
import {HibernationService} from '../../shared/hibernation.service';

@Injectable({
  providedIn: 'root'
})
export class JobStatusSubscriberService implements OnDestroy {
  public readonly autoStart: boolean = false;
  public parser = new ResultParser();

  private readonly refreshMs: number = 10000;
  private readonly maxRetries: number = 10000;
  private readonly retryDelayMs: number = 2000;

  private data$: Observable<any>; // Holds data fetched from the request.
  private waitForMessages = false;

  // Store all subscribed observables in this array,
  // in order to clean up correctly later!
  private subscriptions: Subscription[] = [];

  // All statuses that indicate that an operation is finished must be defined here
  private finishedTypes = [
    OperationExecutionStatus.FINISHED.toString(),
    OperationExecutionStatus.STEP_COMPLETE.toString(),
    OperationExecutionStatus.OPERATION_COMPLETE.toString(),
    OperationExecutionStatus.DOWNLOADED.toString()
  ];

  constructor(
    private cms: CmsApiService,
    private accessTokenService: AccessTokenService,
    private logger: LoggerService,
    private idleService: IdleService,
    private hibernationService: HibernationService
  ) {
    this.monitorIdleStatus();
    this.monitorHibernationStatus();
  }

  ngOnDestroy(): void {
    if (this.autoStart) {
      this.stopPolling();
    }
  }

  private handleReqError(error: any): void {
    if (error.status && error.IMessage) {
      const errorMessage = `Error Code: ${error.status}\nMessage: ${error.IMessage}`;
      this.logger.error(errorMessage);
    }
  }

  private delayedRetry() {
    let retries = this.maxRetries;
    return (src: Observable<any>) =>
      src.pipe(
        mergeMap(error => retries-- > 0
          ? of(error).pipe(delay(this.retryDelayMs))
          : throwError('Max retries exceeded - giving up.'))
      );
  }

  private monitorIdleStatus() {
    effect(() => {
      this.idleService.idle() ? this.stopPolling() : this.startPolling();
    });
  }

  private monitorHibernationStatus() {
    effect(() => {
      this.hibernationService.isSleeping() ? this.stopPolling() : this.startPolling();
    });
  }

  isWaitingForMessages(): boolean {
    return this.waitForMessages;
  }

  setWaitForMessages(waitForMessages: boolean) {
    this.waitForMessages = waitForMessages;
  }

  startPolling(waitForMessages: boolean = false): void {
    this.setWaitForMessages(waitForMessages);
    const token = this.accessTokenService.getToken();

    if (!token) {
      this.stopPolling();
      return;
    }

    if (isDevMode()) {
      console.debug('[JOBSTATUS] -- start polling');
    }

    const fetchJobStatus$ = this.cms.getJobStatus().pipe(
      this.delayedRetry(),
      catchError(async (error) => {
        await this.hibernationService.healthCheck();
        this.handleReqError(error);
        return throwError(error);
      })
    );

    this.data$ = timer(0, this.refreshMs).pipe(
      switchMap(() => fetchJobStatus$)
    );

    this.startMessageSubscription();
  }

  stopPolling(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
    this.data$ = null;

    if (isDevMode()) {
      console.debug('[JOBSTATUS] -- no active jobs, stopped polling');
    }
  }

  convertUTCDates(messages: JobStatusItem[]) {
    const dateStrToDateInts = (dateStr: string) => {
      if (!dateStr) return [];
      const [date, time] = dateStr.split(' ');
      const dateParts = date.split('.').map(Number);
      const timeParts = time.split(':').map(Number);
      return [...dateParts.reverse(), ...timeParts];
    };

    const dateTimeToUtc = (dateInts: number[]) => {
      return new Date(Date.UTC(dateInts[0], dateInts[1] - 1, dateInts[2], dateInts[3], dateInts[4], dateInts[5]));
    };

    const dateTimeToLocal = (dateStr: string) => {
      if (!dateStr) return '';
      const dateInts = dateStrToDateInts(dateStr);
      const utc = dateTimeToUtc(dateInts);
      return `${dateInts[2]}.${dateInts[1]}.${dateInts[0]} ${utc.toLocaleTimeString()}`;
    };

    return messages.map(m => ({
      ...m,
      started: dateTimeToLocal(m.started),
      endedDate: dateTimeToUtc(dateStrToDateInts(m.ended)),
      ended: dateTimeToLocal(m.ended),
      registered: dateTimeToLocal(m.registered)
    }));
  }

  hasStatus(statuses: string[], messages: JobStatusItem[], adminUser: boolean, currentUser: any): JobStatusItem {
    if (!messages || messages.length === 0) return null;

    let relevantMessages = messages;

    if (!adminUser && currentUser) {
      relevantMessages = messages.filter(message => message.created_by_id.replace('USER-', '') === currentUser.artifact_id.replace('USER-', ''));
    }

    return relevantMessages.find(m => statuses.includes(m.status)) || null;
  }

  getFinished(messages: JobStatusItem[], adminUser: boolean, currentUser: any): JobStatusItem {
    return this.hasStatus(this.finishedTypes, messages, adminUser, currentUser);
  }

  hasFinishedJobs(messages: JobStatusItem[], adminUser: boolean, currentUser: any) {
    return this.getFinished(messages, adminUser, currentUser) !== null;
  }

  setStatusType(jobStatusItem: JobStatusItem) {
    if (this.finishedTypes.includes(jobStatusItem.status)) {
      jobStatusItem.statusType = 'finished';
    } else {
      jobStatusItem.statusType = jobStatusItem.status === OperationExecutionStatus.FAILED || jobStatusItem.status === OperationExecutionStatus.NO_DATA ? 'failed' : 'active';
    }
  }

  private startMessageSubscription() {
    this.subscriptions.push(
      this.data$.subscribe(d => {
        if (!d || !d.messages?.length) {
          if (!this.waitForMessages) {
            this.stopPolling();
          }
        } else {
          d.messages = this.convertUTCDates(d.messages);
          this.parser.parse(d);

          if (d.messages.some((m: any) => this.finishedTypes.includes(m.status))) {
            if (!this.waitForMessages) {
              this.stopPolling();
            }
          }
        }
      })
    );
  }
}
