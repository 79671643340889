import {ChangeDetectorRef, Component, OnDestroy, OnInit, signal} from "@angular/core";
import {CmsApiService} from "../../../../core/cms-api.service";
import {ModelStore} from "../../../../core/ModelStore/ModelStore";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {FieldSection, ReportUserGenerated} from "../../../../core/ModelStore/models/ReportUserGenerated";
import {DragulaService} from "ng2-dragula";
import {
  stampReportUuid,
  gridReportUuid,
  rapportlisteMedBildeUuid,
  ReportViewType
} from "../../../../core/ModelStore/models/ReportViewType";
import { getDefaultViewOptionsCheckboxValues } from '../../generate-report-util'
import {ReportUserTemplate} from "../../../../core/ModelStore/models/ReportUserTemplate";
import {MatSelectChange} from "@angular/material/select";
import {saveAs} from "file-saver";
import {OperationService} from "../../../../operations/operation.service";
import {OperationModel} from "../../../../core/ModelStore/models/Operation";
import {PrimusRouterService} from "../../../../core/primus-router.service";
import {generateReport} from "../../generate-report-util";
import {UrlQueryParam} from "../../../../core/ModelStore/UrlQueryParam";

@Component({
  selector: 'edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.scss']
})
export class EditReportComponent implements OnInit, OnDestroy {
  reportId: string;
  generatedReport: ReportUserGenerated;
  reportUserTemplates: ReportUserTemplate[];
  selectedReportUserTemplateId: string;
  reportViewTypes: ReportViewType[];
  selectedReportViewType: ReportViewType;
  showCreateNewReportSettingsArea: boolean = false;
  newReportSettings: ReportUserTemplate = null;
  showDeleteReportSettingsArea: boolean = false;
  showViewOptions = true;
  showFieldSelections = signal(true);
  dragulaId: string = 'HANDLES';
  operation : OperationModel = null
  subs = new Subscription();
  reportGenerationInterval: any = null;
  numPhotographsInReport: number = 1;
  numColumnsInReport: number = 2;
  selectedPhotographSize: string = 'small';
  previousRouteName: any = null;

  downloadPdfStarted = signal(false);
  reportGenerationInProgress = signal(false);

  viewOptionsCheckboxValues = getDefaultViewOptionsCheckboxValues();
  private paramMapSubscription: Subscription;

  constructor(private readonly cms: CmsApiService,
              private modelStore: ModelStore,
              private route: ActivatedRoute,
              private cdRef:ChangeDetectorRef,
              private operationService: OperationService,
              private dragulaService: DragulaService,
              private readonly primusrouter: PrimusRouterService,
              private router: Router) {
    dragulaService.createGroup(this.dragulaId, {
      invalid: (el) => el.classList.contains('report-collapsible-list-child-item')
    });

    this.subs.add(dragulaService.dropModel(this.dragulaId)
      .subscribe(({ name, el, target, source, sourceModel, targetModel, item }) => {
        const targetPosition = targetModel.findIndex((field: any) => field.name === item.name);
        const sourcePosition = this.generatedReport.fieldSections.findIndex((field: any) => field.name === item.name);

        //When item is dropped, move the source item to the target position in this.availableFieldsKeyList
        this.generatedReport.fieldSections.splice(targetPosition, 0, this.generatedReport.fieldSections.splice(sourcePosition, 1)[0]);

        this.triggerUIUpdate();
      }));
  }

  ngOnDestroy() {
    this.dragulaService.destroy(this.dragulaId);
    this.subs.unsubscribe();
    this.paramMapSubscription.unsubscribe();

    if (this.reportGenerationInterval) {
      clearInterval(this.reportGenerationInterval);
      this.reportGenerationInterval = null;
    }
  }

  async ngOnInit(): Promise<void> {
    console.log('-------> ', this.primusrouter.prevStateName);
    if (this.primusrouter.prevStateName) {
      this.previousRouteName = this.primusrouter.prevStateName;
    }

    this.operation = this.modelStore.findModel('operation', 'reports_v2');
    await this.operation.loading;

    this.reportUserTemplates = this.modelStore.findAllModels('report_user_template');
    // @ts-ignore
    await this.reportUserTemplates.loading;

    this.reportViewTypes = this.modelStore.findAllModels('report_view_type');
    // @ts-ignore
    await this.reportViewTypes.loading;

    this.paramMapSubscription = this.route.paramMap.subscribe(params => {
      this.reportId = params.get('report_id');
    });

    if (this.reportId)  {
      this.generatedReport = this.modelStore.findModel('report_user_generated', this.reportId, new UrlQueryParam('timezone_offset', '' + new Date().getTimezoneOffset()));
      await this.generatedReport.loading;

      if (this.generatedReport.reportStatus === 'generating' || this.generatedReport.reportStatus === 'generated') {
        this.pollForFinishedPdf(this.reportId);
      }
      if (this.generatedReport.reportViewTypeId) {
        this.selectedReportViewType = this.reportViewTypes.find(viewType => viewType.id === this.generatedReport.reportViewTypeId);
      }

      let storedReportNumColumns = null;
      if (this.generatedReport.reportViewOptions) {
        Object.keys(this.generatedReport.reportViewOptions).forEach(option => {
          if (option === 'numPhotosPerObject') {
            this.numPhotographsInReport = this.generatedReport.reportViewOptions[option];
          }

          if (option === 'photographSize') {
            this.selectedPhotographSize = this.generatedReport.reportViewOptions[option];
          }

          if (option === 'numColumnsInReport') {
            this.numColumnsInReport = this.generatedReport.reportViewOptions[option];
            storedReportNumColumns =  this.generatedReport.reportViewOptions[option];
          }

          let checkbox = this.viewOptionsCheckboxValues.find(viewOption => viewOption.key === option);
          if (checkbox) {
            checkbox.value = this.generatedReport.reportViewOptions[option];
          }
        });
      }

      if (this.generatedReport.reportViewTypeId === stampReportUuid && storedReportNumColumns === null) {
        this.numColumnsInReport = 5;
      }

      this.showFieldSelections.set(this.generatedReport.reportViewTypeId !== stampReportUuid);
    }

    await this.fetchFieldSections();
  }

  private async fetchFieldSections() {

    let objectTypes = this.generatedReport.getObjectTypes();
    let fieldSections: FieldSection[] = [];
    if (objectTypes.length > 0) {
      //For each object type fetch fieldSearchResult and join the array returned via the name property
      for (const objectType of objectTypes) {
        let fieldSearchResult = await this.cms.getModelSections({object_type: objectType, usage: 'reports'});
        fieldSearchResult.forEach(section => {
          //If fieldSearchResult does not contain a section with the same name-property, push it to the fieldSections array
          if (!fieldSections.find(fieldSection => fieldSection['name'] === section['name'] && fieldSection['path'] === section['path'])) {
            // @ts-ignore
            fieldSections.push(section);
          }
        });
      }

      this.generatedReport.applyFieldSectionsFromBackend(fieldSections);
      this.triggerUIUpdate();
    }
  }
  async navigateAwayFromPreview() {
    if (this.previousRouteName && this.previousRouteName === 'home.primus.search.artifact' && this.generatedReport.artifactList[0]['artifact_id'] !== null) {
      let artifactId = this.generatedReport.artifactList[0]['artifact_id'];
      await this.primusrouter.navigateState(this.previousRouteName, {artifactId: artifactId});
    } else if (this.previousRouteName) {
      await this.primusrouter.navigateState(this.previousRouteName)
    } else if (this.generatedReport.reportStatus === 'generated') {
      await this.primusrouter.navigateState('home.primus.reports.generatedReports')
    } else {
      await this.primusrouter.navigateState('home.primus.reports.reportDrafts')
    }
  }

  isAllImagesSelected() {
    return this.viewOptionsCheckboxValues.find((option) => option.key === 'alle_bilder').value;
  }

  isViewOptionChecked(optionKey: string) {
    if (!optionKey) {
      return false;
    }

    return this.viewOptionsCheckboxValues.find((option) => option.key === optionKey).value;
  }

  decrementNumPhotographsInReport() {
    if (this.numPhotographsInReport > 1) {
      this.numPhotographsInReport--;
    }
  }

  incrementNumPhotographsInReport() {
    this.numPhotographsInReport++
  }

  decrementNumColumnsInReport(minColumns = 2) {
    if (this.numColumnsInReport > minColumns) {
      this.numColumnsInReport--;
    }
  }

  incrementNumColumnsInReport(maxColumns = 4) {
    if (this.numColumnsInReport < maxColumns) {
      this.numColumnsInReport++;
    }
  }

  doShowCreateNewReportSettingsArea() {
    this.newReportSettings = new ReportUserTemplate();
    this.showCreateNewReportSettingsArea = true;
  }

  async doPersistNewReportSettings() {
    if (this.newReportSettings != null && this.newReportSettings.name != null && this.generatedReport != null) {
      this.newReportSettings.reportViewTypeId = this.generatedReport.reportViewTypeId;
      this.newReportSettings.reportViewOptions = this.generatedReport.reportViewOptions;
      this.newReportSettings.fieldSections = this.generatedReport.fieldSections;

      let modelCreated = this.modelStore.createModel({modelSingularKey: 'report_user_template', record: this.newReportSettings});
      await modelCreated.loading;

      this.newReportSettings = null;
      this.showCreateNewReportSettingsArea = false;
      this.reportUserTemplates = this.modelStore.findAllModels('report_user_template');
      await this.reportUserTemplates;

      //select the newly created user template
      this.selectedReportUserTemplateId = modelCreated.id;
      this.triggerUIUpdate();
    }
  }

  async persistSelectedUserTemplate() {
    if (this.selectedReportUserTemplateId) {
      this.reportUserTemplates.forEach(template => {
        if (template.id === this.selectedReportUserTemplateId) {
          template.reportViewTypeId = this.generatedReport.reportViewTypeId;
          template.reportViewOptions = this.generatedReport.reportViewOptions;
          template.fieldSections = this.generatedReport.fieldSections;
          this.modelStore.updateModel({modelSingularKey: 'report_user_template', record: template});
        }
      });
    }
  }

  doCancelPersistingNewReportSettings() {
    this.newReportSettings = null;
    this.showCreateNewReportSettingsArea = false;
  }

  doShowDeleteReportSettingsArea() {
    this.showDeleteReportSettingsArea = true;
  }

  async doDeleteReportSettings() {
    if (this.selectedReportUserTemplateId) {
      this.reportUserTemplates.forEach(async template => {
        if (template.id === this.selectedReportUserTemplateId) {
          let deleteStatus = this.modelStore.deleteModel('report_user_template', template.id);
          await deleteStatus.loading;
          this.selectedReportUserTemplateId = null;
          this.reportUserTemplates = this.modelStore.findAllModels('report_user_template');
        }
      });
    }
    this.showDeleteReportSettingsArea = false
  }

  onUserTemplateSelected(event: MatSelectChange) {
    this.reportUserTemplates.forEach(template => {
      if (template.id === event.value) {
        //Set reportUserTemplate ID to the selected value in the selectbox
        this.selectedReportUserTemplateId = template.id;

        //Set the templates report View type to the selected report
        this.selectedReportViewType = this.reportViewTypes.find(viewType => viewType.id === template.reportViewTypeId);

        //reset all checkboxes
        this.viewOptionsCheckboxValues.forEach(viewOption => {
          viewOption.value = false;
        })

        //Check the correct boxes
        Object.keys(template.reportViewOptions).forEach(option => {
          let checkbox = this.viewOptionsCheckboxValues.find(viewOption => viewOption.key === option);
          if (checkbox) {
            checkbox.value = template.reportViewOptions[option];
          }
        });

        let options = {}
        this.viewOptionsCheckboxValues.forEach(option => {
          if (template.reportViewOptions[option.key]) {
            options[option.key] = template.reportViewOptions[option.key]
          }
        });

        //Update the report preview with the checked values
        this.generatedReport.reportViewOptions = options;

        this.reportViewTypeChanged();

        //Set the fieldSections to the selected template fieldSections
        this.generatedReport.fieldSections = template.fieldSections;

        //Trigger UI update
        this.triggerUIUpdate();
      }
    });
  }

  async persistUserReport() {
    if (!this.generatedReport.reportViewOptions) {
      this.generatedReport.reportViewOptions = {};
    }

    if (this.numPhotographsInReport > 1) {
      let viewOptions = this.generatedReport.reportViewOptions;
      viewOptions['numPhotosPerObject'] = this.numPhotographsInReport;
    } else {
      let viewOptions = this.generatedReport.reportViewOptions;
      delete viewOptions['numPhotosPerObject'];
    }

    if (this.selectedPhotographSize !== 'small') {
      let viewOptions = this.generatedReport.reportViewOptions;
      viewOptions['photographSize'] = this.selectedPhotographSize;
    } else {
      let viewOptions = this.generatedReport.reportViewOptions;
      delete viewOptions['photographSize'];
    }

    if ((this.generatedReport.reportViewTypeId === gridReportUuid || this.generatedReport.reportViewTypeId === stampReportUuid) && this.numColumnsInReport) {
      let viewOptions = this.generatedReport.reportViewOptions;
      viewOptions['numColumnsInReport'] = this.numColumnsInReport;
    } else {
      let viewOptions = this.generatedReport.reportViewOptions;
      delete viewOptions['numColumnsInReport'];
    }

    let newReportTemplate = this.modelStore.updateModel({modelSingularKey: 'report_user_generated', record: this.generatedReport});
    this.generatedReport = null;
    await newReportTemplate.loading;
    this.generatedReport = this.modelStore.findModel('report_user_generated', newReportTemplate.id);
  }

  async requestReportPdf(reportId) {
    this.reportGenerationInProgress.set(true);
    await this.persistUserReport();

    let newReportTemplate = this.modelStore.updateModel({modelSingularKey: 'report_user_generated', record: this.generatedReport});
    await newReportTemplate.loading;
    this.generatedReport = this.modelStore.findModel('report_user_generated', newReportTemplate.id, new UrlQueryParam('timezone_offset', '' + new Date().getTimezoneOffset()));

    const localStorageItem = localStorage.getItem('primus.client.main');

    await generateReport(this.generatedReport, localStorageItem, this.operationService, this.cms);
    this.generatedReport.reportStatus = 'generating';
    this.pollForFinishedPdf(reportId);

    this.triggerUIUpdate();
  }

  private pollForFinishedPdf(reportId) {
    let self = this;
    this.reportGenerationInterval = setInterval(async () => {
      let tempReport = this.modelStore.reloadModel('report_user_generated', reportId, new UrlQueryParam('timezone_offset', '' + new Date().getTimezoneOffset()));
      await tempReport.loading;
      if (tempReport.reportStatus === 'generated') {
        this.reportGenerationInProgress.set(false);
        self.generatedReport = tempReport;

        await this.fetchFieldSections();
        this.triggerUIUpdate();
        clearInterval(this.reportGenerationInterval);
      }
    }, 15000);
  }

  async downloadSinglePdf() {
    this.downloadPdfStarted.set(true);
    let downloadUrl = await this.cms.requestReportPdfFromDms(this.generatedReport.id);
    if (downloadUrl.url) {
      let pdfFile = await fetch(downloadUrl.url);
      let pdfFileBlob = await pdfFile.blob();
      saveAs(pdfFileBlob, this.generatedReport.id + '.pdf');
    }
    this.downloadPdfStarted .set(false);
  }

  getRefreshToken() {
    const name = 'refresh_token';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length + 1, c.length);
      }
    }
    return '';
  }

  doShowViewOptions() {
    this.showViewOptions = true;
    this.triggerUIUpdate();
  }

  doHideViewOptions() {
    this.showViewOptions = false;
    this.triggerUIUpdate();
  }

  toggleFieldExpanded(field: any) {
    field.isExpanded = !field.isExpanded;
  }

  toggleTopLevelField(section: any) {
    section.fields.forEach((field: any) => {
      field.isSelected = section.isSelected;
    });

    this.triggerUIUpdate();
  }

  private triggerUIUpdate() {
    // @ts-ignore
    this.generatedReport = {...this.generatedReport};

    // Manually trigger change detection
    this.cdRef.detectChanges();
  }

  toggleSubField(field: any, section: any) {
    if (!field.isSelected) {
      field.isSelected = true;
    }

    //If all sub-fields are deselected, deselect the parent field
    if (field.fields.every((field: any) => !field.isSelected)) {
      field.isSelected = false;
    }

    this.triggerUIUpdate();
  }

  markAllTopLevelFields(checked: boolean) {
    this.generatedReport.fieldSections.forEach((value) => {
      // @ts-ignore
      value.isSelected = checked;
      // @ts-ignore
      value.fields.forEach((field: any) => {
        field.isSelected = checked;
      });
    });

    this.triggerUIUpdate();
  }

  reportViewTypeChanged() {
    let storedReportNumColumns = null;

    this.generatedReport.reportViewTypeId = this.selectedReportViewType.id;
    this.viewOptionsCheckboxValues.forEach(option => {
      if (option.value && !this.selectedReportViewType.viewChoices.includes(option.key)) {
        option.value = false;
      }
    });

    let options = {}
    this.viewOptionsCheckboxValues.forEach(option => {
      if (option.value) {
        options[option.key] = option.value
      }
    });

    if (this.generatedReport.reportViewOptions) {
      Object.keys(this.generatedReport.reportViewOptions).forEach(option => {
        if (option === 'numPhotosPerObject') {
          this.numPhotographsInReport = this.generatedReport.reportViewOptions[option];
        }

        if (option === 'photographSize') {
          this.selectedPhotographSize = this.generatedReport.reportViewOptions[option];
        }

        if (option === 'numColumnsInReport') {
          this.numColumnsInReport = this.generatedReport.reportViewOptions[option];
          storedReportNumColumns =  this.generatedReport.reportViewOptions[option];
        }
      });
    }

    if (this.generatedReport.reportViewTypeId === stampReportUuid && storedReportNumColumns === null) {
      this.numColumnsInReport = 5;
    } else if (this.generatedReport.reportViewTypeId === gridReportUuid && storedReportNumColumns === null) {
      this.numColumnsInReport = 2;
    }

    this.generatedReport.reportViewOptions = options;

    this.showFieldSelections.set(this.generatedReport.reportViewTypeId !== stampReportUuid);
    this.triggerUIUpdate();
  }

  toggleViewChoice(viewChoice: any) {
    viewChoice.value = !viewChoice.value
    //find all viewChoices that are selected in a comma separated string
    let options = {}
    this.viewOptionsCheckboxValues.forEach(option => {
      if (option.value) {
        options[option.key] = option.value
      }
    });

    this.generatedReport.reportViewOptions = options;

    this.triggerUIUpdate();
  }

  createTree(objects: any[]) {
    // Sort the objects alphabetically based on the artifact_name property
    objects.sort((a, b) => a.artifact_name.localeCompare(b.artifact_name));

    // Create a map to store the hierarchy
    const hierarchy = new Map();

    objects.forEach(obj => {
      // Split the name property to get the hierarchy levels
      const levels = obj.name.split(' / ');
      // Start at the top level of the hierarchy
      let currentLevel = hierarchy;

      levels.forEach((level, i) => {
        // If the current level doesn't exist yet, create it
        if (!currentLevel.has(level)) {
          if (i === levels.length - 1) {
            // If this is the last level, add the complete JSON structure
            currentLevel.set(level, { ...obj, children: new Map() });
          } else {
            // Otherwise, create a new object with a children property
            // and keep the original name
            currentLevel.set(level, { name: obj.name, isSelected: false, isExpanded: false, children: new Map() });
          }
        }

        // Move to the next level
        currentLevel = currentLevel.get(level).children;
      });
    });

    //Iterate over keys and delete all keys that have no children
    hierarchy.forEach((value, key) => {
      if (value.children.size === 0) {
        hierarchy.delete(key);
      }
    });

    return hierarchy;
  }

  updateReportPreview() {
    this.cdRef.detectChanges();
  }

  protected readonly rapportlisteMedBildeUuid = rapportlisteMedBildeUuid;
  protected readonly bilderapportMedOversiktsfelterUuid = gridReportUuid;
  protected readonly bilderapportId = stampReportUuid;
}
