import {Component, ElementRef, EventEmitter, Input, Output, ViewChild, WritableSignal} from '@angular/core';
import {ImageItem} from '../../core/definitions/image-item';
import {MediaItem} from '../../core/definitions/media-item';
import {CrudService} from "../../core/crud.service";
import {BaseModel} from "../../core/definitions/base-model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {snackbarObject} from "../media-selector-general/media-selector-general.component";

@Component({
  selector: 'app-media-selector-selected-files',
  templateUrl: './media-selector-selected-files.component.html',
  styleUrls: ['./media-selector-selected-files.component.scss']
})
export class MediaSelectorSelectedFilesComponent {

  @ViewChild('selectedImgContainer', { static: true }) selectedImgContainer: ElementRef;
  @Input() files: MediaItem[];
  @Input() selectedFiles: MediaItem[];
  @Input() text: string;
  @Input() imageIdField: string;
  @Input() showSelected: boolean;
  @Input() _snackBar: WritableSignal<snackbarObject>;
  @Output() itemSelected = new EventEmitter<MediaItem>();
  @Output() fileDeleted = new EventEmitter<MediaItem>();

  chosenItems: Array<ImageItem> = [];

  constructor(private crud: CrudService) { }


  selectFile() {
    if (this.showSelected) {
      this.chosenItems.forEach((item) => {
        this.itemSelected.emit(item);
      });
      this.chosenItems = [];
    }

  }

  isChosen(img: ImageItem) {
    if (this.showSelected) {
      let res = false;
      for (const item of this.selectedFiles) {
        if (img.image_id === item.artifact_id && !this.crud.getDestroy(item)) {
          this.crud.setCreate(item);
          res = true;
        } else {
          this.crud.setDestroy(item, false);
        }
      }
      return res;
    }
  }

  isUploading(): boolean {
    let files = this.getFiles();
    for (const file in files) {
      if (files[file].$$uploading) {
        return true;
      }
    }

    return false;
  }

  public setFile(file: ImageItem) {
    if (!this.isChosen(file)) {
      const index: number = this.chosenItems.indexOf(file);
      if (index !== -1) {
        this.chosenItems.splice(index, 1);
      } else {
        this.chosenItems.push(file);
      }
    }
  }

  public getFiles() {
    return this.files.filter(f => !this.crud.getDestroy(f));
  }

  isSelectedFile(index: number) {
    const file = this.files[index];
    let res = false;
    this.chosenItems.forEach((item) => {
      if (file['image_id'] === item.image_id && !this.crud.getDestroy(item)) {
        res = true;
      }
    });
    return res;
  }


  deleteFile(selectedFile: BaseModel) {
    // Get file in array.
    const file = this.files.find(f => {
      return f.thumbnail_id === selectedFile.thumbnail_id;
    });
    if (this.crud.getCreate(file)) {
      // Get file index in array.
      const index = this.files.findIndex( f => {
        return f.thumbnail_id === selectedFile.thumbnail_id;
      });
      this.files.splice(index, 1);
    } else {
      this.crud.setDestroy(file);
    }
    this.fileDeleted.emit(file);
  }
}
