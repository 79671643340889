import { AfterViewInit, Component, Input } from '@angular/core';
import {SearchObject} from "../../../core/definitions/search-object";
import {SearchContainer} from "../../../core/definitions/search-container";
import {MatCheckbox} from "@angular/material/checkbox";
import {NgClass} from "@angular/common";
import {SearchResultSelectionsService} from "../../search-result-selections.service";
import {objectTypeToMatSymbol} from "../../../shared/material-icons";
import {MatIcon} from "@angular/material/icon";
import {FeatureFlagsService} from "../../../core/feature-flags.service";
import {RouterLink} from "@angular/router";
import {OverviewFieldsModule} from "../../../overview-fields/overview-fields.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-search-result-view-thumbnail-list-v2',
  standalone: true,
  imports: [
    MatCheckbox,
    NgClass,
    MatIcon,
    RouterLink,
    OverviewFieldsModule,
    MatTooltipModule,
    TranslateModule
  ],
  templateUrl: './search-result-view-thumbnail-list-v2.component.html',
  styleUrl: './search-result-view-thumbnail-list-v2.component.scss'
})
export class SearchResultViewThumbnailListV2Component implements AfterViewInit {
  @Input() isRootObject: boolean;
  @Input() item: SearchObject;
  @Input() searchContainer: SearchContainer;
  needsDisableRefresh = new Set<string>();

  constructor(
    private readonly featureFlagService: FeatureFlagsService,
    private readonly searchResultSelectionService: SearchResultSelectionsService
  ) {}

  ngAfterViewInit() {
    if (this.isRootObject) {
      const element = document.getElementById(this.item.artifact_id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        element.focus();

        element.classList.add('thumbnail-list-item--highlighted');
        setTimeout(() => {
          element.classList.remove('thumbnail-list-item--highlighted');
        }, 3000);
      }
    }
  }

  isCheckboxDisabled(item: SearchObject): boolean {
    const itemId = item.artifact_id;
    const isSelected = this.searchContainer.selections.selected[itemId];

    if (this.needsDisableRefresh.has(itemId)) {
      this.needsDisableRefresh.delete(itemId);
      return item.$$used || (!isSelected && this.searchResultSelectionService.isSelectionLimitReached(this.searchContainer));
    }

    return item.$$used || (!isSelected && this.searchResultSelectionService.isSelectionLimitReached(this.searchContainer));
  }

  isCheckboxSelected(item: SearchObject): boolean {
    const itemId = item.artifact_id;
    if (this.isCheckboxDisabled(item) && !this.searchContainer.selections.selected[itemId]) {
      return false;
    }
    return this.searchContainer.selections.selected[itemId] || false;
  }

  getDisplayFields(item: SearchObject) {
    if (item.$$columns) {
      return item.$$columns;
    }

    const data = [];
    const overviewFields = item.overview;

    if (overviewFields) {
      for(const field of overviewFields) {
        data.push(field);
      }

      item.$$columns = data;

      if (data.length === 0) {
        console.warn('No columns set');
      }
    }

    return data;
  }

  getRouterLink(item: SearchObject) {
    if (item.$$refData?.routerLink?.join('/')?.includes('/artifact') && this.objectPageV2) {
      return [...item.$$refData.routerLink, item.artifact_id];
    }
    return item.$$refData?.routerLink
  }

  getSymbolFromObjectType(objectType: string) {
    return objectTypeToMatSymbol(objectType);
  }

  select(item: SearchObject, event: MouseEvent) {
    event.stopPropagation();
    const result = this.searchResultSelectionService.selectSearchResultItem(item, this.searchContainer, event.shiftKey);

    // Only prevent default if we have unselected items (hit the limit)
    if (event.shiftKey && result.unselectedItems.length > 0) {
      event.preventDefault();
    }

    // Mark all unselected items for refresh
    result.unselectedItems.forEach(unselectedItem => {
      this.needsDisableRefresh.add(unselectedItem.artifact_id);
    });
  }

  readonly objectPageV2 = this.featureFlagService.getFeatureFlags()?.experimental.useNewObjectPage;
}
