import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SearchObject} from '../../../core/definitions/search-object';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchHandlerService} from '../../search-handler.service';
import {VirtualScrollSearchDataSourceColumns} from '../../virtual-scroll-search-data-source-columns';
import {SearchExecutorService} from '../../search-executor.service';
import {ResultViewService} from '../../result-view.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {SearchContainer} from '../../../core/definitions/search-container';
import {FeatureFlagsService} from '../../../core/feature-flags.service';

@Component({
  selector: 'app-search-result-view-gallery',
  templateUrl: './search-result-view-gallery.component.html',
  styleUrls: ['./search-result-view-gallery.component.scss']
})
export class SearchResultViewGalleryComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @Input() searchContainer: SearchContainer;
  @Input() width: string;

  ds: VirtualScrollSearchDataSourceColumns;
  needsDisableRefresh = new Set<string>();

  readonly objectPageV2 = this.featureFlags.getFeatureFlags().experimental.useNewObjectPage;
  constructor(private searchResultSelectionsService: SearchResultSelectionsService,
              private searchHandler: SearchHandlerService,
              private searchExecutorService: SearchExecutorService,
              public resultViewService: ResultViewService,
              private featureFlags: FeatureFlagsService) {
  }

  ngOnInit() {
    this.ds = new VirtualScrollSearchDataSourceColumns(this.searchExecutorService, this.searchContainer);
    if (this.searchContainer.virtualScrollViewOffset) {
      setTimeout(() => {
        this.virtualScroll.scrollToOffset(this.searchContainer.virtualScrollViewOffset);
      }, 2000);
    }
  }

  select(obj: SearchObject, event: any) {
    const result = this.searchResultSelectionsService.selectSearchResultItem(obj, this.searchContainer, event.shiftKey);
    
    // Only prevent default if we have unselected items (hit the limit)
    if (event.shiftKey && result.unselectedItems.length > 0) {
      event.preventDefault();
    }

    // Mark all unselected items for refresh
    result.unselectedItems.forEach(unselectedItem => {
      this.needsDisableRefresh.add(unselectedItem.artifact_id);
    });
  }

  isCheckboxDisabled(art: SearchObject): boolean {
    const itemId = art.artifact_id;
    const isSelected = this.searchContainer.selections.selected[itemId];
    
    if (this.needsDisableRefresh.has(itemId)) {
      this.needsDisableRefresh.delete(itemId);
      return art.$$used || (!isSelected && this.searchResultSelectionsService.isSelectionLimitReached(this.searchContainer));
    }

    return art.$$used || (!isSelected && this.searchResultSelectionsService.isSelectionLimitReached(this.searchContainer));
  }

  isCheckboxSelected(art: SearchObject): boolean {
    const itemId = art.artifact_id;
    if (this.isCheckboxDisabled(art) && !this.searchContainer.selections.selected[itemId]) {
      return false;
    }
    return this.searchContainer.selections.selected[itemId] || false;
  }

  setSearchItemIndex(searchItemIndex: number) {
    if (this.searchContainer && searchItemIndex !== undefined) {
      this.searchContainer.virtualScrollViewOffset = this.virtualScroll.getOffsetToRenderedContentStart();
      this.searchHandler.setSearchItemIndex(this.searchContainer, searchItemIndex);
    }
  }

  getRouterLink(art: SearchObject) {
    if (art.$$refData?.routerLink?.join('/')?.includes('/artifact') && this.objectPageV2) {
      return [...art.$$refData.routerLink, art.artifact_id];
    }
    return art.$$refData?.routerLink
  }
}
