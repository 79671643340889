import { Component } from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {AppNotification, NotificationService} from "../../../shared/notification.service";

@Component({
  selector: 'app-admin-service-test-notifications',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatIcon,
    ReactiveFormsModule,
    RouterLink,
    TranslateModule
  ],
  templateUrl: './admin-service-test-notifications.component.html',
  styleUrl: './admin-service-test-notifications.component.scss'
})
export class AdminServiceTestNotificationsComponent {
  constructor(private readonly notificationService: NotificationService) {
  }

  notificationMessageControl = new FormControl<string>('');
  notificationTypeControl = new FormControl<string>('');

  sendNotification() {
    console.log('Sending notification');
    const notification = new AppNotification(
      [this.notificationMessageControl.value],
      this.notificationTypeControl.value,
      null,
      null
    );

    this.notificationService.addNotification(notification)
  }
}
