import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CmsApiService} from './cms-api.service';
import {ProgressDialogComponent} from '../shared/progress-dialog/progress-dialog.component';
import {AppNotification, NotificationService} from '../shared/notification.service';
import {Image} from './definitions/image';
import User from '../administration/admin-user/User';
import {ImageItem} from './definitions/image-item';
import {ContextList} from './definitions/context-list';
import {SuperObjectModel} from './definitions/super-object-model';
import {UrlData} from './definitions/url-data';
import {GetArtifactParams} from './definitions/get-artifact-params';
import {AccessTokenService} from "./access-token.service";
import {LoggerService} from "./logger.service";
import {TranslateService} from "@ngx-translate/core";
import {CrudService} from "./crud.service";
import {ObjectTypes} from "./definitions/object-types";
import {MediaItem} from "./definitions/media-item";
import {HttpClient} from "@angular/common/http";

export interface UploadWorkerMessage {
  ok: boolean;
  uploadStatus: string;
  statusText: string;
  progress: number;
  images: UploadImageResult[];
}

export interface UploadImageResult {
  imageId: string;
  fileName: string
  ok: boolean;
  status: number;
  statusText: string;
  uploadStatus: string;
  uploadStatusText: string;
  uploadStatusCounter: number;
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private uploadWorker: Worker;
  private uploadWorkerCallback: any;
  private lastUploadWorkerResult: UploadWorkerMessage;
  private lastMediaFolder: SuperObjectModel;

  constructor(private logger: LoggerService,
              private translate: TranslateService,
              private cms: CmsApiService,
              private modalService: MatDialog,
              private notificationService: NotificationService,
              private accessTokenService: AccessTokenService,
              private crud: CrudService,
              private http: HttpClient) {
  }

  async uploadFiles(files: File[], uploadType: string, parentObject: SuperObjectModel): Promise<MediaItem[]> {
    const res = [];
    if (files) {
      const progressModal = this.modalService.open(ProgressDialogComponent, {
        data: {
          descriptorText: files.length > 1 ? 'TRANS__IMAGE_SELECTOR__PREPARING_UPLOAD_COUNT' : 'TRANS__IMAGE_SELECTOR__PREPARING_UPLOAD',
          count: files.length
        },
        disableClose: true,
        panelClass: 'progress-modal'
      });
      for (const file of files) {
        let urlInfo: UrlData;
        try {
          urlInfo = await this.getUploadURl(uploadType, file.name);
        } catch (e) {
          this.logger.error(`Getting url info failed: ${e}`);
        }
        if (!urlInfo) {
          break;
        }
        const mediaObject = await this.uploadFile(file, urlInfo, parentObject);
        if (mediaObject) {
          res.push(mediaObject);
        }
      }
      progressModal.close();
    }
    return res;
  }

  private async uploadFile(file: File,
                           urlInfo: UrlData,
                           parentObject: SuperObjectModel): Promise<MediaItem> {
    let retry = false;
    let uploadFailCounter = 0;
    let mediaObject: any;
    do {
      retry = false;
      try {
        await this.putFile(file, urlInfo.url);
        mediaObject = await this.getMediaObject(urlInfo.artifact_id, parentObject);
      } catch (e) {
        retry = await this.handleUploadError(e, uploadFailCounter, file);
        uploadFailCounter++;
      }
    } while (retry);
    return mediaObject;
  }

  private async getMediaObject(mediaId: string, parentObject: SuperObjectModel): Promise<MediaItem> {
    let mediaObject: any;
    mediaObject = await this.cms.getArtifact({artifact_id: mediaId} as GetArtifactParams);
    if (mediaObject.object_type === ObjectTypes.IMAGE) {
      mediaObject.media_publish_flag = true;
    }
    if (parentObject?.object_type === 'user' && mediaObject) {
      await this.handleUploadProfileImage(mediaObject as Image, parentObject as User);
    }
    mediaObject.$$uploading = true;
    return mediaObject;
  }

  // This new file uploader should be able to upload files using multipart upload¸ but needs to be triggered from a form
  async uploadFilesNew(files: File[], uploadType: string, parentObject: SuperObjectModel): Promise<MediaItem[]> {
    return new Promise((resolve, reject) => {
      // Form-data to store files to send it
      // as a multipart/form-data post request
      const mediaObjects: MediaItem[] = [];
      // Inserting every file to formData
      let countDown = files.length;
      for (const file of files) {
        // Use the name as file
        // as mock backend configured
        // to get file as input in the name of file
        const formData = new FormData();
        formData.append('file', file);
        try {
          this.getUploadURl(uploadType, file.name).then(urlInfo => {
            // Post method is returned for subscribing in the component
            this.http.put(urlInfo.url, formData).subscribe(res => {
              console.log('Response: ', res);
              this.getMediaObject(urlInfo.artifact_id, parentObject).then(mediaObject => {
                mediaObjects.push(mediaObject)
                countDown--;
                if (countDown === 0) {
                  resolve(mediaObjects);
                }
              })
            });
          })
        } catch (e) {
          reject(e);
        }
      }
    })
  }

  private async putFile(file: File, uploadUrl: string): Promise<Response> {
    return await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      },
      body: file
    });
  }

  private async handleUploadError(e: any, uploadFailCounter: number, file: File) {
    this.logger.error(`Upload failed ${e}`);
    const retry = uploadFailCounter < 10;
    if (!retry) {
      this.logger.warn('Tried uploading 10 times, giving up');
      this.notificationService.addNotification(new AppNotification(
        ['TRANS__UPLOAD_SERVICE__UPLOAD_ERROR', ': ', file.name], 'error'));
    } else {
      this.logger.warn(`Retrying upload for the ${uploadFailCounter} time`);
      // Wait a second before retrying
      await new Promise(resolve => setTimeout(resolve, 3000));
    }
    return retry;
  }

  hasActiveUploadWorker(): boolean {
    return !!this.uploadWorker;
  }

  getLastUploadWorkerResult(): UploadWorkerMessage {
    return this.lastUploadWorkerResult;
  }

  setUploadWorkerCallback(callback: any) {
    this.uploadWorkerCallback = callback;
  }

  getLastMediaFolder(): SuperObjectModel {
    return this.lastMediaFolder;
  }

  uploadFilesWithWorker(files: any, mediaFolder: SuperObjectModel, callback: any) {
    this.lastMediaFolder = mediaFolder;
    const uploadContainer = {
      files: files,
      folderId: mediaFolder.artifact_id,
      imageUploadUrlApi: this.cms.getApiUrl(this.cms.API_IMAGE_ORDER_UPLOAD_URL, true),
      authorization: 'Bearer ' + this.accessTokenService.getToken(),
      idToken: this.accessTokenService.getIdToken(),
      texts: {
        statusOrderingImageUrl: this.translate.instant('TRANS__UPLOAD_STATUS__ORDERING_IMAGE_URL'),
        statusRetryOrderingImageUrl: this.translate.instant('TRANS__UPLOAD_STATUS__RETRY_ORDERING_IMAGE_URL'),
        statusOrderingImageUrlFailed: this.translate.instant('TRANS__UPLOAD_STATUS__ORDERING_IMAGE_URL_FAILED'),
        statusUploading: this.translate.instant('TRANS__UPLOAD_STATUS__UPLOADING'),
        statusUploadingFailed: this.translate.instant('TRANS__UPLOAD_STATUS__UPLOAD_FAILED'),
        statusRetryUploading: this.translate.instant('TRANS__UPLOAD_STATUS__RETRY_UPLOADING'),
        statusTooManyUploadsFailed: this.translate.instant('TRANS__UPLOAD_STATUS__TOO_MANY_UPLOADS_FAILED')
      }
    }
    this.uploadWorker = new Worker(new URL('upload.worker.ts', import.meta.url));
    this.uploadWorker.postMessage(uploadContainer);
    this.uploadWorkerCallback = callback;
    this.uploadWorker.onmessage = ({data}) => {
      this.uploadWorkerCallback(data);
      this.checkFinishedAndTerminateWorker(data);
    }
    window.addEventListener('beforeunload', this.warnUnload);
    return this.uploadWorker;
  }

  private warnUnload = () => {
    return 'If you leave, the upload process will be terminated. Are you sure?';
  }

  private checkFinishedAndTerminateWorker(data: any) {
    this.lastUploadWorkerResult = data;
    if (data.uploadStatus === 'uploadFinished' || data.uploadStatus === 'failed') {
      this.logger.info('Terminating the upload worker')
      this.uploadWorker.terminate();
      this.uploadWorker = null;
      window.removeEventListener('beforeunload', this.warnUnload)
    }
  }

  private async getUploadURl(uploadType: string, fileName: string): Promise<UrlData> {
    let res: UrlData;
    switch (uploadType) {
      case 'image':
        res = await this.cms.getImageUploadUrl({fileName: fileName});
        break;
      case 'video':
        res = await this.cms.getVideoUploadUrl({fileName: fileName});
        break;
      case 'attachment':
        res = await this.cms.getAttachmentUploadUrl({fileName: fileName});
        break;
      case 'audio':
        res = await this.cms.getAudioUploadUrl({fileName: fileName});
        break;
      case 'model_3d':
        res = await this.cms.get3dModelUploadUrl({fileName: fileName})
        break;
      default:
        console.warn(`Unsupported upload type ${uploadType}`);
    }
    return res;
  }

  private async handleUploadProfileImage(image: Image, user: User): Promise<void> {
    const userImageRelationItem: ImageItem = await this.cms.createArtifact({object_type: 'ImageItem'}) as ImageItem;
    if (!userImageRelationItem) {
      this.logger.error('Unable to create Image-User-relation:', userImageRelationItem);
      return;
    }

    const subArtifactList = new ContextList();

    if (user?.images?.length > 0) {
      user.images.filter(i => !!i.image_id).forEach(i => this.crud.setDestroy(i, true));
      subArtifactList.contexts.push(...user.images);
    }
    this.crud.setCreate(userImageRelationItem);
    userImageRelationItem.context_id = user.artifact_id;
    userImageRelationItem.image_id = image.artifact_id;

    subArtifactList.contexts.push(userImageRelationItem);

    await this.cms.saveSubArtifacts(subArtifactList);
  }

}
