import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {SearchObject} from '../../../core/definitions/search-object';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchExecutorService} from '../../search-executor.service';
import {SearchHandlerService} from '../../search-handler.service';
import {VirtualScrollSearchDataSourceList} from '../../virtual-scroll-search-data-source-list';
import {OverviewField} from '../../../core/definitions/object-view';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {SearchContainer} from '../../../core/definitions/search-container';
import {MetaTypes} from '../../../core/definitions/meta-types';
import {SettingsService} from '../../../core/settings.service';
import {ClientConfig} from "../../../core/definitions/client-config";
import {FeatureFlagsService} from '../../../core/feature-flags.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-search-result-view-list-thumbnail',
  templateUrl: './search-result-view-list-thumbnail.component.html',
  styleUrls: ['./search-result-view-list-thumbnail.component.scss']
})
export class SearchResultViewListThumbnailComponent implements OnInit, OnChanges{
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @Input() searchContainer: SearchContainer;
  @Input() width: string;

  MetaTypes = MetaTypes;
  ds: VirtualScrollSearchDataSourceList;
  clientConfig: ClientConfig;
  private containerWidth: number;
  needsDisableRefresh = new Set<string>();

  readonly objectPageV2 = this.featureFlags.getFeatureFlags().experimental.useNewObjectPage;
  constructor(private searchResultSelectionsService: SearchResultSelectionsService,
              private searchExecutorService: SearchExecutorService,
              private searchHandler: SearchHandlerService,
              private settingsService: SettingsService,
              private featureFlags: FeatureFlagsService) {
  }

  ngOnInit() {
    this.clientConfig = this.settingsService.getClientConfig();
    this.ds = new VirtualScrollSearchDataSourceList(this.searchExecutorService, this.searchContainer);
    if (this.searchContainer.virtualScrollViewOffset) {
      setTimeout(() => {
        this.virtualScroll.scrollToOffset(this.searchContainer.virtualScrollViewOffset);
      }, 2000);
    }
  }

  ngOnChanges() {
    if (this.width !== undefined) {
      this.containerWidth = Number(this.width.substring(0, this.width.length - 2));
    }
  }

  select(obj: SearchObject, event: any) {
    const result = this.searchResultSelectionsService.selectSearchResultItem(obj, this.searchContainer, event.shiftKey);
    
    // Only prevent default if we have unselected items (hit the limit)
    if (event.shiftKey && result.unselectedItems.length > 0) {
      event.preventDefault();
    }

    // Mark all unselected items for refresh
    result.unselectedItems.forEach(unselectedItem => {
      this.needsDisableRefresh.add(unselectedItem.artifact_id);
    });
  }

  setSearchItemIndex(searchItemIndex: number) {
    if (this.searchContainer && searchItemIndex !== undefined) {
      this.searchContainer.virtualScrollViewOffset = this.virtualScroll.getOffsetToRenderedContentStart();
      this.searchHandler.setSearchItemIndex(this.searchContainer, searchItemIndex);
    }
  }

  getColumns(searchObject: SearchObject): Array<OverviewField[]> {
    if (searchObject.$$columns) {
      return searchObject.$$columns;
    }
    const columns = [];
    const overviewFields = searchObject.overview;
    if (overviewFields && this.containerWidth !== undefined) {
      overviewFields.forEach((ovf, index) => {
        const colIndex = Math.floor(index / 4);
        if (index < 12) {
          if (columns.length <= colIndex) {
            columns.push([]);
          }
          columns[colIndex].push(ovf);
        }
      });
      searchObject.$$columns = columns;
      if (columns.length === 0) {
        console.warn('No columns set!');
      }
    }
    return columns;
  }

  getRouterLink(art: SearchObject) {
    if (art.$$refData?.routerLink?.join('/')?.includes('/artifact') && this.objectPageV2) {
      return [...art.$$refData.routerLink, art.artifact_id];
    }
    return art.$$refData?.routerLink
  }

  isCheckboxDisabled(art: SearchObject): boolean {
    const itemId = art.artifact_id;
    const isSelected = this.searchContainer.selections.selected[itemId];
    
    if (this.needsDisableRefresh.has(itemId)) {
      this.needsDisableRefresh.delete(itemId);
      return art.$$used || (!isSelected && this.searchResultSelectionsService.isSelectionLimitReached(this.searchContainer));
    }

    return art.$$used || (!isSelected && this.searchResultSelectionsService.isSelectionLimitReached(this.searchContainer));
  }

  isCheckboxSelected(art: SearchObject): boolean {
    const itemId = art.artifact_id;
    if (this.isCheckboxDisabled(art) && !this.searchContainer.selections.selected[itemId]) {
      return false;
    }
    return this.searchContainer.selections.selected[itemId] || false;
  }
}
