import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, DoCheck} from '@angular/core';
import {SearchResultSelectionsService} from '../search-result-selections.service';
import {SearchContainer} from '../../core/definitions/search-container';
import {AfterCloseOperation} from '../../operations/operation.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CmsApiService} from '../../core/cms-api.service';
import {GetArtifactParams} from '../../core/definitions/get-artifact-params';
import {Folder} from '../../core/definitions/folder';

@Component({
  selector: 'app-search-menus',
  templateUrl: './search-menus.component.html',
  styleUrls: ['./search-menus.component.scss']
})
export class SearchMenusComponent implements OnInit, OnChanges, DoCheck {

  private _searchContainer: SearchContainer;
  private previousTargetId: string = '';

  @Input()
  set searchContainer(value: SearchContainer) {
    this._searchContainer = value;
    this.loadFolderInfo();
  }

  get searchContainer(): SearchContainer {
    return this._searchContainer;
  }

  @Output() operationPerformed = new EventEmitter<AfterCloseOperation>();
  currentResultView = {description: '', icon: '', name: ''};
  openSelection = false;
  folderTitle: string = '';

  constructor(
    private searchResultSelectionsService: SearchResultSelectionsService,
    private cms: CmsApiService
  ) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchContainer && !changes.searchContainer.firstChange) {
      this.loadFolderInfo();
    }
  }

  ngDoCheck() {

    if (this._searchContainer &&
        this._searchContainer.targetId &&
        this._searchContainer.targetId !== this.previousTargetId &&
        this.isInsideFolderView()) {

      this.previousTargetId = this._searchContainer.targetId;
      this.loadFolderInfo();
    }
  }

  private isInsideFolderView(): boolean {
    return this._searchContainer?.currentPathView?.search_view?.search_view_type === 'folder' ||
           this._searchContainer?.currentPathView?.search_view?.search_view_type === 'media_folder';
  }

  private async loadFolderInfo() {
    if (!this._searchContainer) {
      return;
    }

    if (this.isInsideFolderView() && this._searchContainer.targetId) {
      try {
        const params = new GetArtifactParams();
        params.artifact_id = this._searchContainer.targetId;
        const folder = <Folder>await this.cms.getArtifact(params);

        if (folder) {
          this.folderTitle = folder.artifact_name || folder.artifact_id_value || '';
        }
      } catch (error) {
        console.error('Error loading folder information:', error);
      }
    }
  }

  get actionMenuDisabled() {
    let res = true;
    let operationsLength = 0;
    if (this._searchContainer.operationContainer) {
      operationsLength = this._searchContainer.operationContainer.operations ? this._searchContainer.operationContainer.operations.length : 0;
    }
    if (operationsLength) {
      res = this._searchContainer.selections.selectedItems.length === 0 && !this._searchContainer.selections.allSelected;
    }
    return res;
  }

  get selectedCount() {
    return this._searchContainer.selections.selectedItems.length;
  }

  toggleSelection() {
    this.openSelection = !this.openSelection;
  }

  remove(art: any) {
    const sc = this._searchContainer;
    const artifactId = this.searchResultSelectionsService.getItemId(art);
    sc.selections.selectedItems.forEach(item => {
      if (this.searchResultSelectionsService.getItemId(item) === artifactId) {
        this.searchResultSelectionsService.removeItemIdById(this._searchContainer, item);
        this.searchResultSelectionsService.toggleSelectedItems(this._searchContainer);
      }
    });
  }

  removeAll() {
    this.searchResultSelectionsService.clearSelections(this._searchContainer);
    this.searchResultSelectionsService.toggleSelectedItems(this._searchContainer);
  }

  onOperationPerformed(afterCloseOperation: AfterCloseOperation) {
    this.operationPerformed.emit(afterCloseOperation);
  }
}
