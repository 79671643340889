import {Component, inject, WritableSignal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";

export interface DbDialogData {
  failed: WritableSignal<boolean>
}

@Component({
  selector: 'app-extended-search-db-dialog',
  standalone: true,
  imports: [
    MatProgressSpinner,
    MatButton,
    TranslateModule
  ],
  templateUrl: './extended-search-db-dialog.component.html',
  styleUrl: './extended-search-db-dialog.component.scss'
})
export class ExtendedSearchDbDialogComponent {
  readonly data = inject<DbDialogData>(MAT_DIALOG_DATA)
  readonly dialogRef = inject(MatDialogRef<ExtendedSearchDbDialogComponent>);
}
