import {Injectable} from '@angular/core';
import {InlineArrayItemService} from './inline-array-item.service';
import {BaseModel} from "./definitions/base-model";

@Injectable({
  providedIn: 'root'
})
export class UiToolsService {
  windowSizeData = {
    width: window.innerWidth,
    height: window.innerHeight,
    // This is a bad idea, should get the side menu width from element
    sideMenuWidth: 300
  };
  windowListeners = [];
  clickListeners = [];
  clickIgnoreList = [];
  scrollToOpenArrayItemTimeout;

  constructor(private inlineArrayItemService: InlineArrayItemService) {
  }

  public getClientType() {
    return {
      isComputer: !/Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  }

  public get windowSize() {
    return this.windowSizeData;
  }

  public setMediumScreenSizeOnLoad(mediumScreen: boolean) {
    const windowSize = this.windowSize;
    if (mediumScreen === undefined) {
      mediumScreen = windowSize.width < 1025;
    }
    return mediumScreen;
  }

  public setWindowSize() {
    const oldData = {width: this.windowSize.width, height: this.windowSize.height};
    this.windowSizeData.width = window.innerWidth;
    this.windowSizeData.height = window.innerHeight;
    this.runListenerCallbacks(this.windowListeners, [], this.windowSizeData, oldData);
  }

  public addWindowSizeListener(callback: any, source?: string) {
    return this.addListener(this.windowListeners, callback, source || 'ID');
  }

  public removeWindowSizeListener(id: string) {
    this.removeListener(this.windowListeners, id);
  }

  public addDocumentClickListener(callback: any, sourceName: string) {
    return this.addListener(this.clickListeners, callback, sourceName);
  }

  public removeDocumentClickListener(id: string) {
    this.removeListener(this.clickListeners, id);
  }

  public registerDocumentClick(event: any) {
    this.runListenerCallbacks(this.clickListeners, this.clickIgnoreList, event);
  }

  public ignoreNextDocumentClick(id: string) {
    this.clickIgnoreList.push(id);
  }

  public scrollTo(elementId: string) {
    let res = false;
    try {
      const scrollElement = document.getElementById(elementId);

      if (scrollElement) {
        res = true;

        setTimeout(() => {
          scrollElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }, 111);
      }
    } catch (error) {
      console.error('Error in scrollTo:', error);
    }
    return res;
  }

  public scrollToOpenArrayItem(idString: string, idIndexStart: number, rootObject: BaseModel) {
    const bracketEnd = idString.indexOf(']', idIndexStart);
    if (bracketEnd !== -1) {
      const arrayId = idString.substring(0, bracketEnd + 1);
      this.inlineArrayItemService.setOpenArrayItemIndexFromKey(rootObject, arrayId);
      this.scrollToOpenArrayItemTimeout = setTimeout(() => {
        if (this.scrollTo(arrayId)) {
          this.scrollToOpenArrayItem(idString, bracketEnd + 1, rootObject);
        } else {
          console.warn('Unable to find element to scroll to ' + arrayId);
        }
      }, 200);
    }
  }

  public clearScrollToArrayItemTimeout() {
    if (this.scrollToOpenArrayItemTimeout) {
      clearTimeout(this.scrollToOpenArrayItemTimeout);
    }
  }

  public findClassNameRecursively(nodeIn: any, className: string) {
    let node = nodeIn, found = false;
    if (node) {
      while (node.parentNode) {
        node = node.parentNode;
        if (this.hasClass(node, className)) {
          found = true;
        }
      }
    } else {
      console.warn('Node not set!');
    }
    return found;
  }

  private hasClass(el: Element, class_to_match: string) {
    if (el && el.className &&
      typeof class_to_match === 'string') {
      let c = el.getAttribute('class');
      c = ' ' + c + ' ';
      return c.indexOf(' ' + class_to_match + ' ') > -1;
    } else {
      return false;
    }
  }


  private addListener(listenerList: any[], callback: any, sourceName: string) {
    const id = sourceName + ':' + Math.random();
    listenerList.push({id: id, callback: callback});
    return id;
  }

  private removeListener(listenerList: any[], id: string) {
    let index = null;
    listenerList.forEach((clickListener, ndx) => {
      if (clickListener.id === id) {
        index = ndx;
      }
    });
    if (index !== null) {
      listenerList.splice(index, 1);
    }
  }

  private runListenerCallbacks(listenerList: any[], ignoreList: any[], data?: any, oldData?: any) {
    listenerList.forEach(listener => {
      if (!this.ignoreCallback(listener, ignoreList)) {
        listener.callback(data, oldData);
      }
    });
  }

  private ignoreCallback(listener: any, ignoreList: any[]) {
    let res = false, ignoreIndex = 0;
    if (ignoreList.length) {
      ignoreList.forEach((ignoreId, index) => {
        if (ignoreId === listener.id) {
          ignoreIndex = index;
          res = true;
        }
      });
    }
    if (res) {
      this.clickIgnoreList.splice(ignoreIndex, 1);
    }
    return res;
  }
}
