import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-admin-selection-action',
  templateUrl: './admin-selection-action.component.html',
  styleUrls: ['./admin-selection-action.component.scss']
})
export class AdminSelectionActionComponent {

  @Input() label: string;
  @Input() action: () => void;
  @Output() actionComplete = new EventEmitter<void>();

  constructor() {}

  executeAction(): void {
    if (this.action) {
      this.action();
      this.actionComplete.emit();
    }
  }
}
