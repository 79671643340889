import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {VirtualScrollSearchDataSourceColumns} from '../../virtual-scroll-search-data-source-columns';
import {SearchExecutorService} from '../../search-executor.service';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchHandlerService} from '../../search-handler.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {SearchContainer} from '../../../core/definitions/search-container';
import {MetaTypes} from '../../../core/definitions/meta-types';
import {SettingsService} from '../../../core/settings.service';
import {ClientConfig} from "../../../core/definitions/client-config";
import {SearchObject} from "../../../core/definitions/search-object";
import {ResultViewService} from "../../result-view.service";
import {SearchResultViewType} from "../../../core/definitions/search-result-view-type.enum";
import {FeatureFlagsService} from '../../../core/feature-flags.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-search-result-view-thumbnail',
  templateUrl: './search-result-view-thumbnail.component.html',
  styleUrls: ['./search-result-view-thumbnail.component.scss']
})
export class SearchResultViewThumbnailComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @Input() searchContainer: SearchContainer;
  @Input() width: string;

  MetaTypes = MetaTypes;
  columns: number;
  ds: VirtualScrollSearchDataSourceColumns;
  clientConfig: ClientConfig;
  needsDisableRefresh = new Set<string>();

  readonly objectPageV2 = this.featureFlags.getFeatureFlags().experimental.useNewObjectPage;

  constructor(private searchExecutorService: SearchExecutorService,
              private searchResultSelectionsService: SearchResultSelectionsService,
              private searchHandler: SearchHandlerService,
              private settingsService: SettingsService,
              private resultViewService: ResultViewService,
              private featureFlags: FeatureFlagsService) {
  }

  ngOnInit(): void {
    this.clientConfig = this.settingsService.getClientConfig();
    this.ds = new VirtualScrollSearchDataSourceColumns(this.searchExecutorService, this.searchContainer);
    this.getColumns();
    if (this.searchContainer.virtualScrollViewOffset) {
      setTimeout(() => {
        this.virtualScroll.scrollToOffset(this.searchContainer.virtualScrollViewOffset);
      }, 2000);
    }
  }

  async select(obj: SearchObject, event: any) {
    const result = this.searchResultSelectionsService.selectSearchResultItem(obj, this.searchContainer, event.shiftKey);
    
    // Only prevent default if we have unselected items (hit the limit)
    if (event.shiftKey && result.unselectedItems.length > 0) {
      event.preventDefault();
    }

    // Mark all unselected items for refresh
    result.unselectedItems.forEach(unselectedItem => {
      this.needsDisableRefresh.add(unselectedItem.artifact_id);
    });
  }

  setSearchItemIndex(searchItemIndex: number) {
    if (this.searchContainer && searchItemIndex !== undefined) {
      this.searchContainer.virtualScrollViewOffset = this.virtualScroll.getOffsetToRenderedContentStart();
      this.searchHandler.setSearchItemIndex(this.searchContainer, searchItemIndex);
    }
  }

  getColumns() {
    const sizes = this.resultViewService.calcSearchRowsAndColumns(SearchResultViewType.THUMBNAIL);
    this.columns = sizes.columns;
  }

  getRouterLink(art: SearchObject) {
    if (art.$$refData?.routerLink?.join('/')?.includes('/artifact') && this.objectPageV2) {
      return [...art.$$refData.routerLink, art.artifact_id];
    }
    return art.$$refData?.routerLink
  }

  isCheckboxDisabled(art: SearchObject): boolean {
    const itemId = art.artifact_id;
    const isSelected = this.searchContainer.selections.selected[itemId];
    
    if (this.needsDisableRefresh.has(itemId)) {
      this.needsDisableRefresh.delete(itemId);
      return art.$$used || (!isSelected && this.searchResultSelectionsService.isSelectionLimitReached(this.searchContainer));
    }

    return art.$$used || (!isSelected && this.searchResultSelectionsService.isSelectionLimitReached(this.searchContainer));
  }

  isCheckboxSelected(art: SearchObject): boolean {
    const itemId = art.artifact_id;
    if (this.isCheckboxDisabled(art) && !this.searchContainer.selections.selected[itemId]) {
      return false;
    }
    return this.searchContainer.selections.selected[itemId] || false;
  }
}
