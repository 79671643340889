import {TwoWayMap} from "../TwoWayMap";
import {DefaultModelAdapter} from "../DefaultModelAdapter";
import {ReportUserTemplate} from "../models/ReportUserTemplate";

export class ReportUserTemplateAdapter<T extends ReportUserTemplate> extends DefaultModelAdapter<ReportUserTemplate> {
  keyConversionMap: TwoWayMap = new TwoWayMap();

  constructor(singularKey: string, pluralKey: string) {
    super(singularKey, pluralKey);
    this.singularKey = singularKey;
    this.pluralKey = pluralKey;
    this.urlPrefix = '/cms_api/v1.0/report';
    this.urlForId = null;
    this.urlForList = null;

    this.keyConversionMap.set("reportViewTypeId", "report_view_type_id");
    this.keyConversionMap.set("reportViewOptions", "report_view_options");
    this.keyConversionMap.set("fieldSections", "field_sections");
  }

  /*
    Override the fromJsonObject method to parse the report_data_json field and set the artifactList property
  */
  fromJsonObject(jsonString: any): ReportUserTemplate {
    let standardAdapterObject: ReportUserTemplate = super.fromJsonObject(jsonString);

    this.parseReportUserTemplate(jsonString[this.singularKey], standardAdapterObject);

    return standardAdapterObject;
  }

  /*
    Parse the report_data_json field and set the artifactList property
   */
  private parseReportUserTemplate(jsonString: any, standardAdapterObject: ReportUserTemplate) {
    if (jsonString.reportViewOptions) {
      standardAdapterObject.reportViewOptions = JSON.parse(jsonString.reportViewOptions);
    }
  }

  /*
      Override the toJsonList method to convert the artifactList property to a JSON string and set the report_data_json field
     */
  fromJsonList(jsonString: string): ReportUserTemplate[] {
    let returnList: ReportUserTemplate[] = [];
    // @ts-ignore
    let models = jsonString[this.pluralKey];
    if (models) {
      models.forEach((jsonModel: any) => {
        let modelObject: ReportUserTemplate = this.parseJSON(jsonModel);
        this.parseReportUserTemplate(jsonModel, modelObject);
        returnList.push(modelObject);
      });
    }

    return returnList;
  }

  toJsonObject(objectmodel: ReportUserTemplate): string {
    if (objectmodel.reportViewOptions) {
      // @ts-ignore
      objectmodel.reportViewOptions = JSON.stringify(objectmodel.reportViewOptions);
    }

    return super.toJsonObject(objectmodel)
  }
}
