import {Component, Input, OnChanges, AfterViewInit, ElementRef, NgZone} from '@angular/core';
import {SearchContainer} from "../../../core/definitions/search-container";
import {
  MatCell, MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable
} from "@angular/material/table";
import {MatCheckbox} from "@angular/material/checkbox";
import {OverviewFieldsModule} from "../../../overview-fields/overview-fields.module";
import {FlatTreeControl} from "@angular/cdk/tree";
import {
  ListDatabase,
  ListDataSource,
  ListFlatNode
} from "../../search-result-view/search-result-view-list/search-result-view-list.component";
import {FeatureFlagsService} from "../../../core/feature-flags.service";
import {SearchObject} from "../../../core/definitions/search-object";
import {MatTreeFlattener} from "@angular/material/tree";
import {ResultViewService} from "../../result-view.service";
import {OverviewField} from "../../../core/definitions/object-view";
import {SearchViewListService} from "../../search-view-list.service";
import {AConst} from "../../../core/a-const.enum";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {RouterLink} from "@angular/router";
import {iconToMaterialSymbol} from "../../../shared/material-icons";
import {SearchResultSelectionsService} from "../../search-result-selections.service";
import {TranslateModule} from "@ngx-translate/core";
import {OperationsModule} from "../../../operations/operations.module";
import {FormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import { NgClass } from '@angular/common';
import { SearchHandlerService } from '../../search-handler.service';
import { MetaField } from 'src/app/core/definitions/meta-field';

@Component({
  selector: 'app-search-result-view-list-v2',
  standalone: true,
  imports: [
    MatTable,
    MatColumnDef,
    MatCheckbox,
    MatHeaderCell,
    MatHeaderCellDef,
    MatCell,
    MatCellDef,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRow,
    MatRowDef,
    OverviewFieldsModule,
    MatIconButton,
    MatIcon,
    MatProgressSpinner,
    RouterLink,
    TranslateModule,
    OperationsModule,
    FormsModule,
    MatTooltipModule,
    NgClass
  ],
  providers: [ListDatabase],
  templateUrl: './search-result-view-list-v2.component.html',
  styleUrl: './search-result-view-list-v2.component.scss'
})
export class SearchResultViewListV2Component implements OnChanges, AfterViewInit {
  @Input() rootObjectId: string;
  @Input() searchContainer: SearchContainer;

  artifactNameField = {field_name: AConst.ARTIFACT_NAME} as MetaField;
  dataSource: ListDataSource;
  displayedColumns: string[] = [];
  objectFields: {[name: string]: OverviewField[]};
  subtitleCounter = {count: 0};
  treeControl: FlatTreeControl<ListFlatNode>;
  private needsDisableRefresh = new Set<string>();

  constructor(
    private readonly database: ListDatabase,
    private readonly featureFlagService: FeatureFlagsService,
    private readonly resultViewService: ResultViewService,
    private readonly searchHandlerService: SearchHandlerService,
    private readonly searchViewListService: SearchViewListService,
    private readonly searchViewSelectionService: SearchResultSelectionsService,
  ) {}

  ngOnChanges() {
    this.initialize().then();
  }

  checkedStatus() {
    let selected = [];

    for (const key of Object.keys(this.searchContainer.selections.selected)) {
      if (this.searchContainer.selections.selected[key] === true) {
        selected.push(key);
      }
    }

    if (selected.length === 0) {
      return 'none';
    }
    if (selected.length < this.searchContainer.searchResult.artifacts.length) {
      return 'some';
    }

    return 'all';
  }

  isCheckboxDisabled(row: any): boolean {
    const itemId = row[AConst.ARTIFACT_ID];
    const isSelected = this.searchContainer.selections.selected[itemId];

    if (this.needsDisableRefresh.has(itemId)) {
      this.needsDisableRefresh.delete(itemId);
      return row.$$used || (!isSelected && this.searchViewSelectionService.isSelectionLimitReached(this.searchContainer));
    }

    return row.$$used || (!isSelected && this.searchViewSelectionService.isSelectionLimitReached(this.searchContainer));
  }

  isCheckboxSelected(row: any): boolean {
    const itemId = row[AConst.ARTIFACT_ID];
    // If the checkbox should be disabled and isn't already selected, don't show it as selected
    if (this.isCheckboxDisabled(row) && !this.searchContainer.selections.selected[itemId]) {
      return false;
    }
    return this.searchContainer.selections.selected[itemId] || false;
  }

  isRootObject(row: any) {
    return row.item.artifact_id === this.rootObjectId;
  }

  getLevel(node: ListFlatNode) {
    return node.level;
  }

  getObjectFields(element: ListFlatNode): Array<OverviewField> {
    const art = element.item;
    const objectId = art.artifact_id;
    let res: OverviewField[] = this.objectFields[objectId];
    if (!res) {
      /* In some cases, the search result is changed after objectFields is set, causing some issues*/
      this.objectFields[objectId] = art.overview;
      res = this.objectFields[objectId];
    }
    for (const field of res) {
      this.searchViewListService.setFieldColumnName(field);
    }
    return res;
  }

  getRouterLink(art: ListFlatNode) {
    if (art.refData?.routerLink?.join('/')?.includes('/artifact') && this.objectPageV2) {
      return [...art.refData.routerLink, art.artifact_id];
    }
    return art.refData?.routerLink
  }

  isExpandable(node: ListFlatNode) {
    if (!!node.is_leaf) {
      return false;
    }
    else {
      this.database.isExpandable(node.item);
    }
  }

  select(item: ListFlatNode, event: MouseEvent) {
    const result = this.searchViewSelectionService.selectSearchResultItem(item.item, this.searchContainer, event.shiftKey);

    // Only prevent default if we have unselected items (hit the limit)
    if (event.shiftKey && result.unselectedItems.length > 0) {
      event.preventDefault();
    }

    // Mark all unselected items for refresh
    result.unselectedItems.forEach(unselectedItem => {
      this.needsDisableRefresh.add(unselectedItem[AConst.ARTIFACT_ID]);
    });
  }

  selectAll() {
    this.searchViewSelectionService.selectAll(this.searchContainer).then();
  }

  setTitles() {
    for (const field of this.searchContainer.currentPathView.search_view.overview_fields) {
      field.$$fieldTitle = this.searchViewListService.getTitle(field, this.subtitleCounter);
    }
  }

  sort(fieldInfo: MetaField) {
    console.log(this.searchContainer.getOrder(), fieldInfo);
    if (fieldInfo.field_name === 'artifact_name' || fieldInfo.isSortable) {
      this.searchHandlerService.setOrder(this.searchContainer, fieldInfo.field_name, fieldInfo);
    }
  }

  private async checkSetSearchResultItemProps() {
    const searchObjects = this.searchContainer.searchResult.artifacts;

    if (searchObjects.length && !searchObjects[0].$$refData) {
      await this.resultViewService.setSearchResultItemProps(this.searchContainer, searchObjects);
    }
  }

  private async initialize() {
    this.treeControl = new FlatTreeControl<ListFlatNode>(this.getLevel, this.isExpandable);

    const treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      () => []
    );

    this.dataSource = new ListDataSource(this.treeControl, treeFlattener, this.database, this.searchContainer);

    await this.checkSetSearchResultItemProps();

    this.dataSource.data = this.database.initialData(this.searchContainer.searchResult.artifacts);

    this.objectFields = {};

    this.searchViewListService.initObjectFields(this.searchContainer, this.objectFields);
    this.searchViewListService.setDisplayedColumns(this.searchContainer, this.displayedColumns);

    this.setTitles();

    this.displayedColumns = ['artifact_name'];
    this.searchContainer.currentPathView.search_view.overview_fields.forEach((field) => {
      this.displayedColumns.push(field.$$fieldColumnName);
    });
    this.displayedColumns.push('setting');

    this.dataSource.data = [];

    for (const item of this.searchContainer.searchResult.artifacts) {
      this.dataSource.data.push(new ListFlatNode(item));
    }
  }

  private transformer(node: SearchObject, level: number) {
    return {
      expandable: node.$$hasChildren === undefined || node.$$hasChildren,
      level: level,
      item: node,
      name: node.$$name,
      artifact_id: node.artifact_id,
      used: node.$$used,
      refData: node.$$refData,
      icon: node.$$icon,
      meta_type: node.meta_type,
      is_leaf: node.is_leaf
    };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.rootObjectId) {
        let element = document.getElementById(this.rootObjectId);
        if (element) {
          element.scrollIntoView({ block: "center", behavior: "instant" });

          element.focus();

          element.classList.add('rootObjectCell');
          setTimeout(() => {
            element.classList.remove('rootObjectCell');
          }, 6000);
        }
      }
    }, 400)
  }

  readonly objectPageV2 = this.featureFlagService.getFeatureFlags().experimental.useNewObjectPage;
  protected readonly AConst = AConst;
  protected readonly iconToMaterialSymbol = iconToMaterialSymbol;
}
