<app-object-url
  *ngIf="urlObject"
  [object]="urlObject"
  [currentObjectName]="sectionsContainer?.rootObject?.artifact_name ?? ''"
  [disable]="edit"
  [aClass]="'object-view-field-value-link'">
  <span class="object-url-content" [innerHTML]="fieldValue.value"></span>
</app-object-url>
<app-thumbnail
  *ngIf="imageObject"
  class="object-view-field-value__thumbnail"
  [item]="imageObject"></app-thumbnail>
<a *ngIf="url"
   class="object-view-field-value__link"
   [attr.href]="fieldValue.value"
   target="_blank">{{ fieldValue.value }}</a>
<app-auto-expandable-overflow-container
  *ngIf="!urlObject && fieldValue.is_text"
  [showMoreText]="'TRANS__OBJECT_VIEW_FIELD_VALUE__SHOW_MORE' | translate"
  [showLessText]="'TRANS__OBJECT_VIEW_FIELD_VALUE__SHOW_LESS' | translate"
>
  <p
            [matTooltip]="'TRANS__OBJECT_VIEW_FIELD_VALUE__CONTAINS_DELETED_TOOLTIP' | translate"
            [matTooltipDisabled]="fieldValueClass !== 'contains-deleted'"
            matTooltipPosition="above"
            class="text-area-field-view {{fieldValueClass}}" readonly
  >{{ fieldValue.value }}</p>
</app-auto-expandable-overflow-container>
<span *ngIf="!urlObject && !imageObject && !url && !fieldValue.is_text" [innerHTML]="fieldValue.value"></span>
