import {ConceptListItem, SearchConcepts, ConceptsParams} from './concepts';
import {ConceptType} from './concept-types';
import {MetaOperationContainer} from './meta-operation-def';
import {ConceptDataSource} from '../../administration/admin-concept-lists/concept-data-source';
import {CmsApiService} from '../cms-api.service';
import {CheckFilterGroup} from './search-objects';

export class ConceptsContainer {

  constructor(private cms: CmsApiService) {
  }
  allSelected = false;
  concepts: SearchConcepts;
  conceptsParams: ConceptsParams;
  conceptType: ConceptType;
  cutConcepts: Array<ConceptListItem> = [];
  firstSelectedConcept: ConceptListItem;
  loadingConcepts = false;
  showLinkToObject = false;
  linkToObject: string = '/artifact';
  parentConcepts: Array<ConceptListItem> = [];
  selected: Array<ConceptListItem> = [];
  conceptDataSource: ConceptDataSource = new ConceptDataSource(this.cms);
  operationContainers: Array<MetaOperationContainer> = [];
  pageIndex = 0;
  reload = 0.1;

  filterGroups: CheckFilterGroup[] = [];
}
