/**
 * This service is responsible for generating and modifying model objects to be stored on the server
 */
import {Injectable} from '@angular/core';
import {
  AbstractControlOptions,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

import {CmsApiService} from './cms-api.service';
import {FieldValidators} from './field-validators';
import {DateToolsService} from './date-tools.service';
import {FieldStateService} from './field-state.service';
import {ObjectFieldTraverseService} from './object-field-traverse.service';
import {CommonsService} from './commons.service';
import {ModelSectionsService} from './model-sections.service';
import {Section, SectionsContainer} from './definitions/sections-container';
import {UserSettingsService} from './user-settings.service';
import {FieldValueService} from './field-value.service';
import {MetaField} from './definitions/meta-field';
import {SuperObjectModel} from './definitions/super-object-model';
import {BaseModel} from './definitions/base-model';
import {FieldInputType} from './definitions/field-input-type.enum';
import {FieldType} from './definitions/field-type.enum';
import {IfType} from './definitions/field-if';
import {FieldConditionService} from './field-condition.service';
import {FieldParameters} from './definitions/field-parameters';
import {ValidationType} from './definitions/field-validation';
import {LoggerService} from './logger.service';
import {FieldDateInfoService} from "./field-date-info.service";
import {CrudService} from "./crud.service";

export interface FormGroupParams {
  field: MetaField;
  fields: MetaField[];
  parentKey: string;
  object: BaseModel;
  rootObject: BaseModel;
  group: UntypedFormGroup;
  index1: number;
  index2: number;
}

@Injectable({
  providedIn: 'root'
})
export class PrimusFormGroupService {
  copyOptions: any;
  nonSettableInputTypes = [
    FieldInputType.DATE_ISO,
    FieldInputType.DATE_TIME_ISO,
    FieldInputType.CHECK_ARRAY,
    FieldInputType.MAP_ID,
    FieldInputType.REF_ARRAY,
    FieldInputType.SEARCH_SELECTOR,
    FieldInputType.SEARCH_SELECTOR_MULTIPLE,
    FieldInputType.IMAGE,
    FieldInputType.COMPARE_VALUE,
    FieldInputType.INLINE_ARRAY,
    FieldInputType.RADIO_INLINE_ARRAY,
  ];

  constructor(private logger: LoggerService,
              private fieldState: FieldStateService,
              private cms: CmsApiService,
              private dateTools: DateToolsService,
              private objectFieldTraverse: ObjectFieldTraverseService,
              private commons: CommonsService,
              private modelSections: ModelSectionsService,
              private userSettings: UserSettingsService,
              private fieldValueService: FieldValueService,
              private fieldConditionService: FieldConditionService,
              private fieldDateInfoService: FieldDateInfoService,
              private crud: CrudService) {

  }

  async getSectionsAndFormGroup(object: SuperObjectModel, templateGroupId?: string, isCopy?: boolean): Promise<SectionsContainer> {
    const sectionsContainer = await this.modelSections.getCreateSectionsContainer(object, templateGroupId);
    this.setSectionsContainerFormGroup(sectionsContainer);
    if (isCopy) {
      await this.setCopyOptions(sectionsContainer);
    }
    return sectionsContainer;
  }

  setSectionsContainerFormGroup(sectionsContainer: SectionsContainer) {
    sectionsContainer.formGroup = this.getFormGroup(sectionsContainer.rootObject,
      this.getFormFieldsForSections(sectionsContainer.sections));
  }

  setObjectValuesFromForm(object: SuperObjectModel, form: UntypedFormGroup) {
    for (const fieldPath in form.value) {
      if (form.value.hasOwnProperty(fieldPath)) {
        const metaAndObject = this.objectFieldTraverse.getFieldMetaAndSubObjectFromPath(object, fieldPath);
        const subObject = metaAndObject.subObject;
        const fieldMeta = metaAndObject.fieldMeta;
        const fieldValue = form.value[fieldPath];
        if (fieldMeta) {
          this.setFieldValueFromControlValue(subObject, fieldMeta, fieldValue);
        }
      }
    }
  }

  // Create form controls for an array item of an inline array field element
  setFormGroupFieldInlineArrayItem(params: FormGroupParams, item: BaseModel, index: number) {
    const indexes = this.getIndexesFromParams(params, index);
    this.setFormGroupFields({
      rootObject: params.rootObject,
      object: item,
      fields: params.field.inline_fields,
      group: params.group,
      parentKey: indexes.indexKey,
      index1: indexes.index1,
      index2: indexes.index2
    } as FormGroupParams);
  }

  markFormGroupFieldInlineArrayItemAsDeleted(params: FormGroupParams, index: number) {
    const indexes = this.getIndexesFromParams(params, index);
    const metaField = {} as MetaField;
    const destroyFieldName = this.crud.getDestroyFieldName();
    metaField.name = `${params.field.name}.${destroyFieldName}`;
    metaField.key = `${indexes.indexKey}->${destroyFieldName}`;
    const controlKey = this.fieldState.getFieldKey(metaField, indexes.index1, indexes.index2);
    const formControl = params.group.controls[controlKey];
    if (formControl) {
      formControl.markAsDirty();
    } else {
      this.logger.warn(`${destroyFieldName} must be defined as a model field for field ${params.field.name}`);
    }
  }

  // Remove form controls for an inline array element, which is necessary in order to avoid prevailing errors on deleted elements
  removeFormGroupFieldInlineArrayItem(params: FormGroupParams, item: BaseModel, index: number) {
    const indexes = this.getIndexesFromParams(params, index);
    const metaField = params.field;
    this.removeFormGroupFields({
      object: item,
      fields: metaField.inline_fields,
      group: params.group,
      parentKey: indexes.indexKey,
      index1: indexes.index1,
      index2: indexes.index2
    } as FormGroupParams);
  }

  getFormGroup(object: BaseModel, fields: Array<MetaField>, createArrayElement?: boolean): UntypedFormGroup {
    const group: any = {};
    for (const field of fields) {
      const traverseRes = this.objectFieldTraverse.traverseObjectByPath(object, field.path, 0, createArrayElement);
      const o = traverseRes.subObject;
      if (o === undefined || o === null) {
        this.logger.warn('Unable to find sub object for ' + field.path);
      }
      let index1 = traverseRes.parentIndex
      if (!index1 && createArrayElement === true) {
        index1 = 0;
      }
      this.setFormGroupField({
        rootObject: object,
        object: o,
        field: field,
        group: group,
        parentKey: traverseRes.parentKey,
        index1: index1
      } as FormGroupParams);
    }
    return new UntypedFormGroup(group);
  }

  setFormGroupField(params: FormGroupParams) {
    const metaField: MetaField = params.field;
    this.fieldState.generateFieldKey(metaField, params.parentKey);
    if (metaField.input_type !== FieldInputType.INLINE && !this.isInlineArray(metaField)) {
      this.addFormGroupControl(params);
    } else if (metaField.input_type === FieldInputType.INLINE) {
      this.setFormGroupFieldInline(params);
    } else if (this.isInlineArray(metaField)) {
      this.addInlineArrayFormGroupControl(params);
      const array = params.object[metaField.name];
      if (array.length) {
        for (let index = 0; index < array.length; index++) {
          const item = array[index];
          this.setFormGroupFieldInlineArrayItem(params, item, index);
          if (index === array.length - 1) {
            return;
          }
        }
      }
    }
  }

  private getFormFieldsForSections(sections: Array<Section>): Array<MetaField> {
    const formFields: Array<MetaField> = [];
    for (const section of sections) {
      section.editFields = [];
      for (const field of section.fields) {
        if (this.isEditField(field)) {
          formFields.push(field);
          section.editFields.push(field);
        }
      }
    }
    return formFields;
  }

  private getIndexesFromParams(params: FormGroupParams, index: number) {
    const fieldKey = this.fieldState.generateFieldKey(params.field, params.parentKey);
    let indexKey: string;
    let index1: number
    let index2: number;
    if (params.index1 === undefined || params.index1 === null) {
      indexKey = fieldKey + '[{index1}]';
      index1 = index;
    } else {
      indexKey = fieldKey + '[{index2}]';
      index1 = params.index1;
      index2 = index;
    }
    return {
      indexKey: indexKey,
      index1: index1,
      index2: index2
    };
  }

  private setFieldValueFromControlValue(object: BaseModel | BaseModel[], metaField: MetaField, fieldValue: any) {
    const inputType = metaField.input_type;
    const fieldType = metaField.field_type;
    let setFieldValue = true;
    // @ts-ignore
    if (this.nonSettableInputTypes.indexOf(inputType) !== -1 || fieldType === FieldType.MAP_ID) {
      setFieldValue = false;
    } else if (inputType === 'number') {
      if (fieldValue === '' || fieldValue === null) {
        fieldValue = null;
      } else {
        fieldValue = Number(fieldValue);
      }
    } else if (inputType === FieldInputType.CHECKBOX) {
      fieldValue = fieldValue === true || fieldValue === 'true';
    }
    if (setFieldValue) {
      if (object && object[metaField.name] !== undefined) {
        object[metaField.name] = fieldValue;
      } else {
        this.logger.error(`Field "${metaField.name}" does not exist in object or object empty!`);
      }
    }
  }

  private isEditField(field: MetaField) {
    let res = false;
    const forceEditFieldTypes = [FieldType.ACTION_BUTTON.toString(), FieldType.OBJECT_USAGE.toString()];
    if ((field.edit && (field.title || field.admin_title)) || forceEditFieldTypes.includes(field.field_type)) {
      res = true;
    }
    return res;
  }

  private setFormGroupFields(params: FormGroupParams) {
    let fieldCounter = params.fields.length;
    if (fieldCounter) {
      for (const field of params.fields) {
        this.setFormGroupField({
          rootObject: params.rootObject,
          object: this.getFieldObject(params.object, field),
          field: field,
          group: params.group,
          parentKey: params.parentKey,
          index1: params.index1,
          index2: params.index2
        } as FormGroupParams);
        fieldCounter--;
        if (!fieldCounter) {
          return;
        }
      }
    }
  }

  private getFieldObject(object: BaseModel, metaField: MetaField) {
    if (object[metaField.name] === undefined) {
      if (metaField.parent_name) {
        object = object[metaField.parent_name];
        if (object === undefined) {
          this.logger.warn('No object found for parent field: ' + metaField.parent_name);
        }
      } else {
        this.logger.warn('No parent object found for: ' + metaField.key);
      }
    }
    return object;
  }

  private addInlineArrayFormGroupControl(params: FormGroupParams) {
    const metaField: MetaField = params.field;
    const controlKey = this.fieldState.getFieldKey(metaField, params.index1, params.index2);
    params.group[controlKey] = this.createFormControl(params.rootObject, params.object, metaField);
  }

  private addFormGroupControl(params: FormGroupParams): UntypedFormControl {
    let control: UntypedFormControl;
    const metaField: MetaField = params.field;
    // The controlKey contains the key with correct inline array indexes
    const controlKey = this.fieldState.getFieldKey(metaField, params.index1, params.index2);
    if (params.group.addControl) {
      // Used when adding new inline array elements
      control = this.createFormControl(params.rootObject, params.object, metaField);
      params.group.addControl(controlKey, control);
    } else {
      // Used when generating a form group the first time
      let object = params.object || params.rootObject;
      const parentName = metaField.parent_name;
      if (parentName && object[metaField.name] === undefined && object[parentName] !== undefined) {
        // This condition is triggered for a few fields, like the field "history_events[n].timespan_historic.from_date"
        object = object[parentName];
      }
      control = this.createFormControl(params.rootObject, object, metaField);
      params.group[controlKey] = control;
    }
    return control;
  }

  // Create form controls for an inline field element
  private setFormGroupFieldInline(params: FormGroupParams) {
    const metaField: MetaField = params.field;
    // Setting the static field.key to "raw" key without indexes as the key will be replaced with indexes later on
    const fieldKey = this.fieldState.generateFieldKey(metaField, params.parentKey);
    this.setFormGroupFields({
      rootObject: params.rootObject,
      object: params.object[metaField.name],
      fields: metaField.sub_fields || [],
      group: params.group,
      parentKey: fieldKey,
      index1: params.index1,
      index2: params.index2
    } as FormGroupParams);
  }

  private removeFormGroupFields(params: FormGroupParams) {
    for (const field of params.fields) {
      this.removeFormGroupField({
        object: params.object,
        field: field,
        group: params.group,
        parentKey: params.parentKey,
        index1: params.index1,
        index2: params.index2
      } as FormGroupParams);
    }
  }

  private removeFormGroupField(params: FormGroupParams) {
    const metaField = params.field;
    if (metaField.input_type !== FieldInputType.INLINE && !this.isInlineArray(metaField)) {
      this.removeFormGroupControl(params);
    } else if (metaField.input_type === FieldInputType.INLINE) {
      this.removeFormGroupFieldInline(params);
    } else if (this.isInlineArray(metaField)) {
      const array = params.object[metaField.name];
      for (const [index, item] of array.entries()) {
        this.removeFormGroupFieldInlineArrayItem(params, item, index);
      }
    }
  }

  // Used when removing inline array elements
  private removeFormGroupControl(params: FormGroupParams) {
    // The controlKey contains the key with correct inline array indexes
    const controlKey = this.fieldState.getFieldKey(params.field, params.index1, params.index2);
    params.group.removeControl(controlKey);
  }

  // Remove form controls for an inline field element
  private removeFormGroupFieldInline(params: FormGroupParams) {
    const metaField: MetaField = params.field;
    // Setting the static field.key to "raw" key without indexes as the key will be replaced with indexes later on
    const fieldKey = this.fieldState.generateFieldKey(metaField, params.parentKey);
    this.removeFormGroupFields({
      object: params.object[metaField.name],
      fields: metaField.sub_fields || [],
      group: params.group,
      parentKey: fieldKey,
      index1: params.index1,
      index2: params.index2
    } as FormGroupParams);
  }

  private createFormControl(rootObject: BaseModel, object: BaseModel, field: MetaField): UntypedFormControl {
    const controlValue = this.fieldValueService.getControlValueFromObjectField(rootObject, object, field);
    const options: AbstractControlOptions = {
      asyncValidators: [],
      validators: [],
      updateOn: this.getUpdateOn(field)
    };
    this.setValidators(options, field, rootObject, object, controlValue);
    const disabled = field.name === 'auto_generated';
    return new UntypedFormControl({value: controlValue, disabled: disabled}, options);
  }

  private getUpdateOn(field: MetaField) {
    return field.field_type === FieldType.MAP_ID ? 'submit' : 'blur';
  }

  private setValidators(options: any, field: MetaField, rootObject: BaseModel, object: BaseModel, controlValue: any) {
    if (!this.fieldState.checkEditOnce(field, rootObject, object)) {
      if (this.checkRequiredShowIf(field, object)) {
        options.validators.push(Validators.required);
      }
      if (field.validation) {
        this.setLegacyValidators(options, field, object);
      } else {
        this.setFieldValidators(options, field, object);
      }
      if (field.input_type === FieldInputType.DATE_ISO || field.input_type === FieldInputType.DATE_TIME_ISO) {
        options.validators.push(FieldValidators.validatePrecisionDate(this.dateTools));
      }
      if (field.input_type === 'identifier') {
        options.asyncValidators.push(FieldValidators.validateIdentifier(this.cms, controlValue));
      }
    }
  }

  private setFieldValidators(options: any, field: MetaField, object: BaseModel) {
    const dateInfo = this.fieldDateInfoService.getFieldDateInfo(field);
    if (field.max_length !== undefined) {
      options.validators.push(Validators.maxLength(field.max_length));
    }
    if (field.min_length !== undefined) {
      options.validators.push(Validators.minLength(field.min_length));
    }
    if (dateInfo?.validation?.compare_field) {
      options.validators.push(FieldValidators.validateCompare(
        this.dateTools, this.commons, this.fieldDateInfoService, object, field))
    }
    if (field.regex || field.pattern) {
      options.validators.push(Validators.pattern(field.regex || field.pattern));
    }
    if (field.ge !== undefined) {
      options.validators.push(Validators.min(field.ge));
    }
    if (field.le !== undefined) {
      options.validators.push(Validators.max(field.le));
    }
  }

  private setLegacyValidators(options: any, field: MetaField, object: BaseModel) {
    for (const [validationKey, validationValue] of Object.entries(field.validation || {})) {
      switch (validationKey) {
        case ValidationType.MAX_LENGTH:
          options.validators.push(Validators.maxLength(validationValue));
          break;
        case ValidationType.MIN_LENGTH:
          options.validators.push(Validators.minLength(validationValue));
          break;
        case ValidationType.COMPARE:
          options.validators.push(FieldValidators.validateCompare(
            this.dateTools, this.commons, this.fieldDateInfoService, object, field));
          break;
        case ValidationType.REG_EXP:
          options.validators.push(Validators.pattern(validationValue));
          break;
        case ValidationType.MIN_NUMBER:
          options.validators.push(Validators.min(validationValue));
          break;
        case ValidationType.MAX_NUMBER:
          options.validators.push(Validators.max(validationValue));
          break;
        default:
          this.logger.warn('Missing implementation of validation type ' + validationKey);
      }
    }
  }

  private checkRequiredShowIf(field: MetaField, object: BaseModel) {
    let required = !!field.is_required;
    const fieldIfs = this.fieldConditionService.getFieldConditions(field);
    if (required && fieldIfs && fieldIfs.filter(fieldIf => fieldIf.if_type === IfType.SHOW).length) {
      required = this.fieldConditionService.runIf(IfType.SHOW, {field: field, object: object, edit: true} as FieldParameters).result;
    }
    return required;
  }

  private async getCopyOptions(): Promise<any> {
    if (!this.copyOptions) {
      this.copyOptions = await this.userSettings.getCopyOptions();
    }
    return this.copyOptions;
  }

  private async setCopyOptions(sectionsContainer: SectionsContainer) {
    const copyOptions = await this.getCopyOptions();
    const objectType = sectionsContainer.rootObject.object_type;
    if (!copyOptions[objectType]) {
      const objCopyOptions = {
        allSections: {keep: true},
        sections: {}
      };
      for (const sect of sectionsContainer.sections) {
        objCopyOptions.sections[sect.name] = {
          showKeepCheckbox: sect.order > 1,
          keep: true
        };
      }
      copyOptions[objectType] = objCopyOptions;
      this.userSettings.storeCopyOptions(copyOptions);
    }
    sectionsContainer.isCopy = true;
    sectionsContainer.copyOptions = copyOptions;
  }

  private isInlineArray(metaField: MetaField) {
    return [
      FieldInputType.INLINE_ARRAY.toString(),
      FieldInputType.SEARCH_SELECTOR_MULTIPLE.toString()
    ].indexOf(metaField.input_type) !== -1;
  }
}
