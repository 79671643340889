import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef, OnInit
} from '@angular/core';
import {ReportUserGenerated} from "../../../../core/ModelStore/models/ReportUserGenerated";

@Component({
  selector: 'grid-report',
  templateUrl: './grid-report.component.html',
  styleUrls: ['./grid-report.component.scss'],
})
export class GridReportComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input() reportTemplate: ReportUserGenerated;
  @Input() extractedArtifacts: any;
  @Input() viewOptionsCheckboxValues: any;
  @Input() preview: boolean = false;
  @Input() numColumnsInReport = 2;

  constructor(private renderer: Renderer2,
              private cdRef: ChangeDetectorRef) {

  }

  async ngOnInit(): Promise<void> {

  }

  isLedeteksterSelected() {
    return this.viewOptionsCheckboxValues.find((option) => option.key === 'vis_ledetekster')?.value;
  }

  isOnlyPhotosSelected() {
    return this.viewOptionsCheckboxValues.find((option) => option.key === 'kun_bilder')? this.viewOptionsCheckboxValues.find((option) => option.key === 'kun_bilder').value : false;
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngAfterViewChecked() {

  }
}
