import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SearchHandlerService, SearchMenuPath} from '../../search-handler.service';
import {FocusServiceImplementation, SearchFocusService} from '../../search-focus.service';
import {SearchExecutorService} from '../../search-executor.service';
import {SearchContainer} from '../../../core/definitions/search-container';
import { SearchFilterService } from '../../search-filter.service';
import { FilterGroupHierarchyNode } from 'src/app/core/definitions/search-objects';

@Component({
  selector: 'app-search-filter-menu-path',
  templateUrl: './search-filter-menu-path.component.html',
  styleUrls: ['./search-filter-menu-path.component.scss']
})
export class SearchFilterMenuPathComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;

  searchMenuPath: SearchMenuPath[] = [];
  private fsi: FocusServiceImplementation;

  constructor(private searchExecutorService: SearchExecutorService,
              private searchHandler: SearchHandlerService,
              private searchFocusService: SearchFocusService,
              private readonly searchFilterService: SearchFilterService
          ) {
  }

  ngOnInit() {
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.setSearchMenuPath);
  }

  ngOnDestroy(): void {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.setSearchMenuPath);
  }

  goToMenuPath(menuPath: SearchMenuPath) {
    if (menuPath.path === 'home') {
      this.searchFilterService.resetFilter(this.searchContainer);
      if (this.searchContainer.filtersFacets?.hierarchicFilterGroup?.childrenArray?.length > 0) {
        this.checkHierarchicFiltersChecked(this.searchContainer.filtersFacets?.hierarchicFilterGroup?.childrenArray, true);
      }

      this.searchExecutorService.runSearch(this.searchContainer).then();
    }

    if (this.searchContainer.focus.curFocus) {
      this.fsi = this.searchFocusService.createFSI(this.searchContainer);
      const exists = this.searchFocusService.checkPathParamsExists(menuPath.path, this.searchContainer);
      if (!exists) {
        this.fsi.setCurrentFocus(null);
      }
    }
    this.searchContainer.objectCount = 0;
    this.searchContainer.targetId = menuPath.targetId;
    this.searchContainer.path = menuPath.path;
    this.searchHandler.goPathView(menuPath.path, this.searchContainer).then();
  }


  private setSearchMenuPath = () => {
    const path = this.searchHandler.getSearchMenuPath(this.searchContainer);
    if (path) {
      this.searchMenuPath = path;
    }
  }

  private checkHierarchicFiltersChecked(filterGroup: FilterGroupHierarchyNode[], deselect: boolean = false): boolean {
    let isChecked = false;

    for (const filter of filterGroup) {
      if (filter.facet?.selected) {
        if (deselect) {
          filter.facet.selected = false;
          filter.open = false;
        }
        isChecked = true;
      }

      if(filter.childrenArray?.length) {
        isChecked = this.checkHierarchicFiltersChecked(filter.childrenArray);
      }
    }

    return isChecked;
  }
}
