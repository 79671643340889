<div>
  <div mat-dialog-title>
    <span>
      {{ data.title | translate }}
    </span>
  </div>

  <div mat-dialog-content>
    <span>
      {{ data.content | translate }}
    </span>
  </div>

  <div mat-dialog-actions>
    <button
      mat-button
      color="primary"
      (click)="dialogRef.close()"
    >
      <span>
        {{ data.buttonText ?? 'TRANS__CONFIRM_DIALOG__SUBMIT' | translate }}
      </span>
    </button>
  </div>
</div>
